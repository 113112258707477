import { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { REFACTOR_SUM, checkingPermissions } from "assets/constants/constants";
import {
  getProducts,
  changeFilter,
  chooseProduct,
  deleteProduct,
  deleteProducts,
  checkedProduct,
  changePagination,
  checkedAllProduct,
} from "store/slices/productSlice";
import MUIPagination from "components/mui-pagination/MUIPagination";
import AdminNavbar from "layers/admin-navbar/AdminNavbar";
import MUIDrawer from "components/mui-drawer/MUIDrawer";
import MUIModal from "components/mui-modal/MUIModal";
import Checkbox from "components/checkbox/Checkbox";
import HtmlInput from "components/input/HtmlInput";
import ChangeCategories from "./ChangeCategories";
import MUIMenu from "components/mui-menu/MUIMenu";
import ProductsFilter from "./ProductsFilter";
import DeleteProduct from "./DeleteProduct";
import CreateProduct from "./CreateProduct";
import { Divider } from "@mui/material";
import Icon from "assets/icons/icons";

function Products() {
  const dispatch = useDispatch();
  const {
    products: { checkedAll, checkedCount, products, pagination, filter, total, refresh },
  } = useSelector((store) => store);
  const [openMenu, setOpenMenu] = useState({ anchorEl: null, product: null });
  const [openDrawer, setOpenDrawer] = useState({ open: false, action: "" });
  const [openModal, setOpenModal] = useState({ open: false, action: "" });

  const handleOpenModal = (action) => {
    handleCloseMenu();
    setOpenModal({ open: true, action });
  };

  const handleCloseModal = () => setOpenModal({ ...openModal, open: false });

  const handleOpenMenu = (event, product) =>
    setOpenMenu({ anchorEl: event.currentTarget, product });

  const handleCloseMenu = () => setOpenMenu({ ...openMenu, anchorEl: null });

  const handleOpenDrawer = (action, product) => {
    handleCloseMenu();
    setOpenDrawer({ open: true, action, product });
  };

  const handleCloseDrawer = () => setOpenDrawer({ ...openDrawer, open: false });

  const handleChangePagination = (name, value) => dispatch(changePagination({ name, value }));

  const handleCheckedAllProduct = () => dispatch(checkedAllProduct());

  const handleCheckedProduct = (id) => dispatch(checkedProduct(id));

  const handleSearch = (value) => dispatch(changeFilter({ ...filter, search: value }));

  const handleDeleteProduct = () => {
    if (openModal?.action === "delete-products") {
      const ids = [];
      products?.map((product) => product?.checked && ids.push(product?.id));
      dispatch(deleteProducts(ids));
    } else dispatch(deleteProduct(openMenu?.product?.id));
  };

  useEffect(() => {
    handleCloseModal();
    handleCloseDrawer();
    dispatch(getProducts({ filter, pagination }));
  }, [pagination, refresh, filter]);

  return (
    <>
      <MUIDrawer open={openDrawer?.open} onClose={handleCloseDrawer}>
        {(openDrawer?.action === "create" || openDrawer?.action === "update") && (
          <CreateProduct action={openDrawer?.action} currentProduct={openMenu?.product} />
        )}
        {openDrawer?.action === "filter" && <ProductsFilter />}
      </MUIDrawer>

      <MUIModal
        position="center"
        hideBackdrop={false}
        hideCloseButton={false}
        open={openModal?.open}
        onClose={handleCloseModal}
      >
        {openModal?.action === "change" && (
          <ChangeCategories data={products} onClose={handleCloseModal} />
        )}
        {(openModal?.action === "delete" || openModal?.action === "delete-products") && (
          <DeleteProduct onDelete={handleDeleteProduct} onClose={handleCloseModal} />
        )}
      </MUIModal>

      <MUIMenu
        open={openMenu?.anchorEl ? true : false}
        handleClose={handleCloseMenu}
        anchorEl={openMenu?.anchorEl}
        children={
          <div
            className="bg-c36 flex flex-col gap-2 px-2"
            onClick={(event) => event.stopPropagation()}
          >
            {checkingPermissions(["PRODUCT.UPDATE", "CATEGORY.READ", "UNIT.READ"]) && (
              <div
                onClick={() => handleOpenDrawer("update")}
                className="flex px-2 gap-3 cursor-pointer"
              >
                <Icon name="edit_gray" />
                <span className="text-c2">Tahrirlash</span>
              </div>
            )}

            {/* <Divider sx={{ borderColor: "#E8ECF4" }} />

            <div className="flex px-2 gap-3 cursor-pointer">
              <Icon name="print" />
              <span className="text-c2">Chop etish</span>
            </div> */}

            {checkingPermissions([
              "UNIT.READ",
              "CATEGORY.READ",
              "PRODUCT.DELETE",
              "PRODUCT.UPDATE",
            ]) && <Divider sx={{ borderColor: "#E8ECF4" }} />}

            {checkingPermissions(["PRODUCT.DELETE"]) && (
              <div
                className="flex px-2 gap-3 cursor-pointer"
                onClick={() => handleOpenModal("delete")}
              >
                <Icon name="trash_red" />
                <span className="text-c14">O'chirish</span>
              </div>
            )}
          </div>
        }
      />

      <AdminNavbar
        selected={checkedCount}
        rightElement={
          <div>
            {checkingPermissions(["PRODUCT.CREATE", "CATEGORY.READ", "UNIT.READ"]) ? (
              <button
                onClick={() => handleOpenDrawer("create")}
                className={`bg-c10 h-10 px-4 flex justify-center items-center rounded-xl gap-2 sm:p-0 sm:w-10 ${
                  checkedCount ? "sm:hidden" : ""
                }`}
              >
                <Icon name="add" />
                <span className="text-c24 sm:hidden">Mahsulot qo’shish</span>
              </button>
            ) : (
              <div className="w-10 h-10" />
            )}

            <div className={`gap-2 hidden ${checkedCount ? "sm:flex" : ""}`}>
              {checkingPermissions(["PRODUCT.UPDATE"]) && (
                <button
                  onClick={() => handleOpenModal("change")}
                  className="bg-c46 rounded-xl p-1 w-10 h-10"
                >
                  <Icon name="exchange_white" className="mx-auto" />
                </button>
              )}

              {checkingPermissions(["PRODUCT.DELETE"]) && (
                <button
                  onClick={() => handleOpenModal("delete")}
                  className="bg-c46 rounded-xl p-1 w-10 h-10"
                >
                  <Icon name="trash" className="mx-auto" />
                </button>
              )}
            </div>
          </div>
        }
      />

      <div className="flex flex-col gap-4 p-4">
        <div className="border border-c32 bg-c24 text-c2 rounded-lg overflow-x-auto">
          <div className="flex justify-between items-center border-b border-c32 px-4 py-2 gap-2">
            <HtmlInput
              type="search"
              placeholder="Qidiruv"
              value={filter?.search}
              classNameInput="border h-10 rounded-xl outline-0 bg-c31 text-sm"
              onChange={(e) => handleSearch(e.target.value)}
            />
            {(checkingPermissions(["CATEGORY.READ"]) ||
              checkingPermissions(["UNIT.READ"]) ||
              checkingPermissions(["BRAND.READ"])) && (
              <button
                onClick={() => handleOpenDrawer("filter")}
                className="bg-c36 h-10 flex justify-center items-center gap-2 py-2 px-4 rounded-xl border border-c32 sm:p-0 sm:w-10"
              >
                <Icon name="filter" />

                <span className="text-c2 sm:hidden">Filter</span>
              </button>
            )}
          </div>

          <div className="bg-c24 text-c2 rounded-lg overflow-x-auto">
            <table
              className={`w-full border-collapse mb-6 sm:mb-0 sm:whitespace-nowrap ${
                !checkingPermissions(["PRODUCT.UPDATE", "CATEGORY.READ"]) &&
                !checkingPermissions(["PRODUCT.DELETE"])
                  ? "mb-0"
                  : ""
              }`}
            >
              <thead>
                <tr className="text-c9 font-thin bg-c36">
                  <td className="py-3 px-2 pl-4">
                    {checkingPermissions(["PRODUCT.UPDATE", "CATEGORY.READ"]) ||
                    checkingPermissions(["PRODUCT.DELETE"]) ? (
                      <Checkbox
                        label="№"
                        bgColor="bg-c24"
                        checked={checkedAll}
                        classNameLabel="text-c9"
                        classNameCheckbox="text-c2"
                        onChange={handleCheckedAllProduct}
                      />
                    ) : (
                      "№"
                    )}
                  </td>
                  <td className="py-3 px-2">Nomi</td>
                  <td className="py-3 px-2">O’lchov birligi</td>
                  <td className="py-3 px-2">Brend</td>
                  <td className="py-3 px-2">BrCode</td>
                  <td className="py-3 px-2">Kategoriya</td>
                  <td className="py-3 px-2">Subcategory</td>
                  <td
                    className={`py-3 px-2 ${
                      !checkingPermissions(["PRODUCT.UPDATE", "CATEGORY.READ"]) &&
                      !checkingPermissions(["PRODUCT.DELETE"])
                        ? "pr-4 text-end"
                        : ""
                    }`}
                  >
                    Narxi
                  </td>
                  {(checkingPermissions(["PRODUCT.UPDATE", "CATEGORY.READ"]) ||
                    checkingPermissions(["PRODUCT.DELETE"])) && (
                    <td className="py-3 px-2 pr-4 text-end">Amallar</td>
                  )}
                </tr>
              </thead>

              <tbody className="overflow-y-auto">
                {products?.map((product, idx) => (
                  <tr key={product?.id} className="even:bg-c36 odd:bg-white">
                    <td className="border-t border-c32 py-3 pl-4 px-2">
                      {checkingPermissions(["PRODUCT.UPDATE", "CATEGORY.READ"]) ||
                      checkingPermissions(["PRODUCT.DELETE"]) ? (
                        <Checkbox
                          bgColor="bg-c24"
                          classNameLabel="text-c2"
                          classNameCheckbox="text-c2 "
                          checked={product?.checked ? true : false}
                          onChange={() => handleCheckedProduct(product?.id)}
                          label={Math.abs(idx + pagination.pageSize * (pagination.page - 1) + 1)}
                        />
                      ) : (
                        Math.abs(idx + pagination.pageSize * (pagination.page - 1) + 1)
                      )}
                    </td>
                    <td className="border-t border-c32 py-3 px-2">{product?.name}</td>
                    <td className="border-t border-c32 py-3 px-2">{product?.unit_name || "-"}</td>
                    <td className="border-t border-c32 py-3 px-2">{product?.brand_name || "-"}</td>
                    <td className="border-t border-c32 px-2">
                      {product?.barcode ? (
                        <div className="bg-money h-10 text-c17 px-4 rounded-xl flex items-center justify-center gap-2 w-max">
                          {product?.barcode}
                        </div>
                      ) : (
                        "-"
                      )}
                    </td>
                    <td className="border-t border-c32 py-3 px-2">
                      {product?.root_category_name || "-"}
                    </td>
                    <td className="border-t border-c32 py-3 px-2">
                      {product?.category_name || "-"}
                    </td>
                    <td
                      className={`border-t border-c32 py-3 px-2 ${
                        !checkingPermissions(["PRODUCT.UPDATE", "CATEGORY.READ"]) &&
                        !checkingPermissions(["PRODUCT.DELETE"])
                          ? "pr-4 text-end"
                          : ""
                      }`}
                    >
                      {product?.price ? `${REFACTOR_SUM(product?.price)} UZS` : "-"}
                    </td>
                    {(checkingPermissions(["PRODUCT.UPDATE", "CATEGORY.READ"]) ||
                      checkingPermissions(["PRODUCT.DELETE"])) && (
                      <td className="border-t border-c32 px-2 pr-4 text-end">
                        <button
                          className="w-10 h-10 bg-c31 rounded-xl border"
                          onClick={(e) => {
                            handleOpenMenu(e, product);
                            dispatch(chooseProduct(product));
                          }}
                        >
                          <Icon name="more_black" className="mx-auto" />
                        </button>
                      </td>
                    )}
                  </tr>
                ))}
              </tbody>
            </table>
          </div>

          <div
            className={`bg-c36 border-t border-c32 px-4 pb-2 pt-6 relative sm:pt-2 ${
              !checkingPermissions(["PRODUCT.UPDATE", "CATEGORY.READ"]) &&
              !checkingPermissions(["PRODUCT.DELETE"])
                ? "pt-2"
                : ""
            }`}
          >
            <MUIPagination
              count={total}
              bgColor="c36"
              page={pagination?.page}
              pageSize={pagination?.pageSize}
              onChangePagination={(name, value) => handleChangePagination(name, value)}
            />

            {(checkingPermissions(["PRODUCT.UPDATE", "CATEGORY.READ"]) ||
              checkingPermissions(["PRODUCT.DELETE"])) && (
              <div className="bg-c24 whitespace-nowrap flex items-center absolute left-1/2 top-[-35%] translate-x-[-50%] py-3 px-4 rounded-xl border border-c10 sm:hidden">
                <div className="px-4 border-r border-c32">
                  <span className="text-c9">Tanlandi: {checkedCount}</span>
                </div>

                {checkingPermissions(["PRODUCT.UPDATE", "CATEGORY.READ"]) && (
                  <div
                    className={`flex gap-2 px-4 ${
                      checkedCount ? "cursor-pointer" : "cursor-not-allowed"
                    } ${checkingPermissions(["PRODUCT.DELETE"]) ? "border-r border-c32" : ""}`}
                    onClick={() => checkedCount && handleOpenModal("change")}
                  >
                    <Icon name="exchange" />
                    <span className={checkedCount ? "text-c1" : "text-c9"}>
                      Kategoriyasini almashtirish
                    </span>
                  </div>
                )}

                {checkingPermissions(["PRODUCT.DELETE"]) && (
                  <div
                    className={`flex gap-2 px-4 ${
                      checkedCount ? "cursor-pointer" : "cursor-not-allowed"
                    }`}
                    onClick={() => checkedCount && handleOpenModal("delete-products")}
                  >
                    <Icon name="trash_red" />
                    <span className={checkedCount ? "text-c1" : "text-c9"}>
                      Mahsulotlarni o'chirish
                    </span>
                  </div>
                )}
              </div>
            )}
          </div>
        </div>
      </div>
    </>
  );
}

export default Products;
