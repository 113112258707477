import { useEffect } from "react";
import { useParams } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { REFACTOR_SUM } from "assets/constants/constants";
import { getOrdersHistoryItem } from "store/slices/orderSlice";
import { paymentTypeOptions } from "constants/PaymentTypeOptions";
import AdminNavbar from "layers/admin-navbar/AdminNavbar";
import Icon from "assets/icons/icons";

function ReturnedOrderProducts() {
  const dispatch = useDispatch();
  const params = useParams();

  const {
    ordersHistory: { historyItem },
  } = useSelector((store) => store.orders);

  useEffect(() => {
    dispatch(getOrdersHistoryItem(params?.id));
  }, [dispatch, params?.id]);

  return (
    <>
      <AdminNavbar
        prevButton={
          <button
            onClick={() => window.history.back()}
            className="h-10 px-4 bg-c32 rounded-xl flex justify-center items-center sm:bg-c46 sm:p-1 sm:w-10"
          >
            <Icon name="arrowLeftGray" className="sm:hidden" />
            <Icon name="left_arrow" className="hidden sm:flex" />
            <span className="text-c2 sm:hidden">Orqaga qaytish</span>
          </button>
        }
        rightElement={<div className="hidden sm:flex sm:w-10 sm:h-10" />}
      />

      {historyItem?.id ? (
        <div className="flex sm:flex-col items-start my-2 px-2 gap-2">
          <div className="bg-c24 text-c2 w-2/5 sm:w-full border border-c32 rounded-lg overflow-hidden px-4 py-2">
            <table className="w-full border-collapse">
              <tbody>
                <tr>
                  <td className="border-b border-c32 py-4 text-start text-c9">Buyurtma raqami</td>
                  <td className="border-b border-c32 py-4 text-end">{historyItem?.id}</td>
                </tr>
                <tr>
                  <td className="border-b border-c32 py-4 text-start text-c9">Vaqti</td>
                  <td className="border-b border-c32 py-4 text-end">
                    {historyItem?.created_at?.substring(0, 16)}
                  </td>
                </tr>
                <tr>
                  <td className="border-b border-c32 py-4 text-start text-c9">Kassir</td>
                  <td className="border-b border-c32 py-4 text-end">
                    {historyItem?.user_full_name}
                  </td>
                </tr>
                <tr>
                  <td className="border-b border-c32 py-4 text-start text-c9">Mijoz</td>
                  <td className="border-b border-c32 py-4 text-end">
                    {historyItem?.user_full_name}
                  </td>
                </tr>

                <tr>
                  <td className="border-b border-c32 py-4 text-start text-c9">Buyurtma turi</td>

                  <td className="border-b border-c32 py-4 flex items-center justify-end gap-2">
                    <Icon name={paymentTypeOptions[historyItem?.payment_type]?.icon} />
                    <span>{paymentTypeOptions[historyItem?.payment_type]?.label}</span>
                  </td>
                </tr>

                {/* {historyItem?.payment_type?.map((pay, idx) => (
                <tr key={idx}>
                  <td className="pt-4 pb-2 text-start text-c9">
                    {paymentTypeOptions[pay?.type]?.label}
                  </td>
                  <td className="pt-4 pb-2 text-end">{pay?.pay}</td>
                </tr>
              ))} */}

                <tr>
                  <td className="pt-4 pb-2 text-start text-c9">
                    Qaytarildi
                    {/* {paymentTypeOptions[historyItem?.payment_type]?.label} */}
                  </td>
                  <td className="pt-4 pb-2 text-end">{REFACTOR_SUM(historyItem?.paid)} UZS</td>
                </tr>

                <tr>
                  <td className="py-2 text-start text-c9">Umumiy summa</td>
                  <td className=" text-end">
                    <span
                      className={`${
                        historyItem?.payment_type === "debt" ? "c14" : "c10"
                      } text-xl font-medium`}
                      sx={{ fontSize: "1.2rem", fontWeight: "bold" }}
                    >
                      {REFACTOR_SUM(historyItem?.amount)} UZS
                    </span>
                  </td>
                </tr>
              </tbody>
            </table>
          </div>
          <div className="bg-c24 text-c2 w-3/5 sm:w-full border border-c32 rounded-lg overflow-hidden">
            <table className="w-full border-collapse table-odd-c36">
              <thead>
                <tr className="text-c9 bg-c36">
                  <td className="py-3 pl-4">Nomi</td>
                  <td className="py-3">Miqdori</td>
                  <td className="py-3 pr-4 text-end">Narxi</td>
                </tr>
              </thead>

              <tbody className="text-c9">
                {historyItem?.items?.map((product, idx) => (
                  <tr key={product.id} className={`text-c2`}>
                    <td
                      className={`border-t py-3 pl-4 ${
                        !product?.quantity && product?.returned_quantity
                          ? "line-through text-c9"
                          : ""
                      }`}
                    >
                      {product?.product_name}
                    </td>
                    <td className="border-t py-1 ">
                      <div className="flex items-center gap-2">
                        <span
                          className={`${
                            !product?.quantity && product?.returned_quantity
                              ? "line-through text-c9"
                              : ""
                          }`}
                        >
                          {product?.quantity}
                        </span>
                        {product?.returned_quantity ? (
                          <span className="bg-c14 text-c24 px-3 py-1 rounded-lg no-underline">
                            {product?.returned_quantity}
                          </span>
                        ) : (
                          ""
                        )}
                      </div>
                    </td>
                    <td
                      className={`border-t py-3 pr-4 text-end ${
                        !product?.quantity && product?.returned_quantity
                          ? "line-through text-c9"
                          : ""
                      }`}
                    >
                      {REFACTOR_SUM(product?.price)} UZS
                    </td>
                  </tr>
                ))}
              </tbody>
            </table>
          </div>
        </div>
      ) : (
        <div className="text-center p-4 bg-c24 m-4 rounded-md">
          <span>{params?.id}</span> - <span>id bo'yicha ma'lumot topilmadi.</span>
        </div>
      )}
    </>
  );
}

export default ReturnedOrderProducts;
