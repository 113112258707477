import { TreeItem, TreeView } from "@mui/lab";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import ChevronRightIcon from "@mui/icons-material/ChevronRight";

function MUITreeItemRecursive({ nodeId, label, children, onClick }) {
  return (
    <TreeItem nodeId={nodeId} label={label} onClick={onClick}>
      {children?.map((child) => (
        <MUITreeItemRecursive
          key={child?.id}
          nodeId={child?.id}
          label={child?.name}
          children={child?.children}
          // onClick={(e, v) => console.log(e, v)}
        />
      ))}
    </TreeItem>
  );
}

function MUITreeItem({ nodeId, label, children }) {
  return (
    <TreeItem nodeId={nodeId} label={label} sx={{ padding: "0.25rem 0" }}>
      {children}
    </TreeItem>
  );
}

function MUITree({ children, expanded, onSelect }) {
  return (
    <TreeView
      expanded={expanded}
      aria-label="rich object"
      onNodeSelect={(e, v) => onSelect(v)}
      defaultCollapseIcon={<ExpandMoreIcon />}
      defaultExpandIcon={<ChevronRightIcon />}
    >
      {children}
    </TreeView>
  );
}

export { MUITree, MUITreeItem, MUITreeItemRecursive };
