import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import { axiosPrivate } from "services/axiosPrivate";

const initialState = {
  isLoading: false,
  refresh: "",
  packages: [],
  checkedAll: false,
  checkedCount: 0,
  total: null,
  pagination: { page: 1, pageSize: 10 },
};

export const getPackages = createAsyncThunk(
  "package/get-packages-list",
  async ({ type, pagination = { page: 1, pageSize: 100 } }) => {
    const response = await axiosPrivate.get(
      `package/list/?page=${pagination?.page}&page_size=${pagination?.pageSize}&type=${type || ""}`
    );
    return response.data;
  }
);

export const createPackage = createAsyncThunk("package/create-package", async (pack) => {
  const response = await axiosPrivate.post("package/post/", pack);
  return response.data;
});

export const updatePackage = createAsyncThunk(
  "package/update-package",
  async ({ pack_id, pack }) => {
    const response = await axiosPrivate.put(`package/put/${pack_id}`, pack);
    return response.data;
  }
);

export const deletePackage = createAsyncThunk("package/delete-package", async (pack_id) => {
  const response = await axiosPrivate.delete(`package/delete/${pack_id}`);
  return response.data;
});

export const deletePackages = createAsyncThunk("package/delete-package-by-ids", async (ids) => {
  const response = await axiosPrivate.post(`package/delete/by-ids`, { ids });
  return response.data;
});

export const packageSlice = createSlice({
  name: "package",
  initialState,
  reducers: {
    checkedAllPackage: (state) => {
      state.checkedAll = !state.checkedAll;
      state.packages = state.packages.map((pack) => ({
        ...pack,
        checked: state.checkedAll,
      }));

      state.checkedCount = state.checkedAll ? state.packages.length : 0;
    },
    checkedPackage: (state, action) => {
      let checkedCount = 0;
      state.packages = state.packages.map((pack) =>
        pack.id === action.payload ? { ...pack, checked: pack?.checked ? false : true } : pack
      );

      state.packages.map((pack) => pack.checked && (checkedCount += 1));
      state.checkedCount = checkedCount;

      checkedCount === state.packages.length
        ? (state.checkedAll = true)
        : (state.checkedAll = false);
    },
    changePagination: (state, action) => {
      state.pagination[action.payload.name] = action.payload.value;
      action.payload.name === "pageSize" &&
        Math.ceil(state.total / state.pagination.pageSize) <= state.pagination.page &&
        (state.pagination.page = Math.ceil(state.total / state.pagination.pageSize));
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(createPackage.fulfilled, (state) => {
        state.refresh = `create-package-${new Date()}`;
      })
      .addCase(updatePackage.fulfilled, (state) => {
        state.refresh = `update-package-${new Date()}`;
      })
      .addCase(deletePackage.fulfilled, (state) => {
        state.refresh = `delete-package-${new Date()}`;
      })
      .addCase(deletePackages.fulfilled, (state) => {
        state.refresh = `delete-package-by-ids-${new Date()}`;
      })
      .addCase(getPackages.pending, (state) => {
        state.isLoading = true;
      })
      .addCase(getPackages.fulfilled, (state, action) => {
        state.packages = action.payload.data;
        state.checkedCount = 0;
        state.total = action.payload.total;
      });
  },
});

const { reducer, actions } = packageSlice;

export const { checkedAllPackage, checkedPackage, changePagination } = actions;

export default reducer;
