import { useState, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { REFACTOR_SUM, checkingPermissions } from "assets/constants/constants";
import {
  getDebtOrders,
  changeDebtOrdersFilter,
  changePaginationDebtOrders,
} from "store/slices/orderSlice";
import MUIPagination from "components/mui-pagination/MUIPagination";
import AdminNavbar from "layers/admin-navbar/AdminNavbar";
import MUIDrawer from "components/mui-drawer/MUIDrawer";
import MUIModal from "components/mui-modal/MUIModal";
import HtmlInput from "components/input/HtmlInput";
import DebtOrdersFilter from "./DebtOrdersFilter";
import PaymentDebtOrder from "./PaymentDebtOrder";
import Icon from "assets/icons/icons";

function DebtOrders() {
  const dispatch = useDispatch();
  const {
    orders: {
      debtOrders: { debts, total, filter, pagination },
      refresh,
    },
  } = useSelector((store) => store);

  const [debt, setDebt] = useState({ paid: 0, amount: 0 });
  const [openModal, setOpenModal] = useState({ open: false });
  const [openDrawer, setOpenDrawer] = useState({ open: false, action: null });

  const handleOpenModal = () => setOpenModal({ open: true });

  const handleCloseModal = () => {
    setOpenModal({ ...openModal, open: false });
    setDebt({});
  };

  const handleOpenDrawer = () => setOpenDrawer({ open: true });

  const handleCloseDrawer = () => setOpenDrawer({ ...openDrawer, open: false });

  const handleChangePagination = (name, value) =>
    dispatch(changePaginationDebtOrders({ name, value }));

  const handleSearch = (value) => dispatch(changeDebtOrdersFilter({ ...filter, search: value }));

  useEffect(() => {
    dispatch(getDebtOrders({ filter, pagination }));
    handleCloseModal();
    handleCloseDrawer();
  }, [dispatch, refresh, filter, pagination]);

  return (
    <>
      <MUIDrawer open={openDrawer?.open} onClose={handleCloseDrawer}>
        <DebtOrdersFilter />
      </MUIDrawer>

      <MUIModal hideBackdrop={false} open={openModal?.open} onClose={handleCloseModal}>
        <PaymentDebtOrder
          color="c2"
          bgColor="c24"
          paid={debt.paid}
          order_id={debt?.id}
          amount={debt.amount}
          client_id={debt?.user_id}
          onClose={handleCloseModal}
        />
      </MUIModal>

      <AdminNavbar rightElement={<div className="w-10 h-10" />} />

      <div className="m-4 border border-c32 rounded-lg bg-c24">
        <div className="flex justify-between items-center border-b border-c32 px-4 py-2 gap-2">
          <HtmlInput
            type="search"
            placeholder="Qidiruv"
            value={filter?.search}
            onChange={(e) => handleSearch(e.target.value)}
            classNameInput="border h-10 rounded-xl outline-0 bg-c31 text-sm"
          />
          <button
            className="bg-c36 flex justify-center items-center gap-2 py-2 px-6 rounded-xl border border-c32 sm:px-2 sm:w-10 sm:h-10"
            onClick={handleOpenDrawer}
          >
            <Icon name="filter" />
            <span className="text-c2 sm:hidden">Filter</span>
          </button>
        </div>

        <div className="bg-c24 text-c2 rounded-lg overflow-x-auto">
          <table className="sm:whitespace-nowrap w-full border-collapse even:bg-c36 odd:bg-white">
            <thead>
              <tr className="text-c9 fw-100 bg-c36">
                <td className="px-2 py-3 pl-4">№</td>
                <td className="px-2 py-3">Mijoz</td>
                <td className="px-2 py-3">Telefon raqami</td>
                <td className="px-2 py-3">To'landi</td>
                <td className="px-2 py-3 fw-100 bg-c36">Qoldiq</td>
                <td
                  className={`px-2 py-3 ${
                    !checkingPermissions(["ORDER_DEBT.PAYMENT"]) ? "text-end pr-4" : ""
                  }`}
                >
                  Umumiy qarzi
                </td>
                {checkingPermissions(["ORDER_DEBT.PAYMENT"]) && (
                  <td className="px-2 py-3 pr-4 text-end">Nasiyani yopish</td>
                )}
              </tr>
            </thead>

            <tbody>
              {debts?.map((order, idx) => (
                <tr key={order?.id}>
                  <td className="px-2 py-3 pl-4 border-t border-c32">
                    {Math.abs(idx + pagination.pageSize * (pagination.page - 1) + 1)}
                  </td>
                  <td className="px-2 py-3 border-t border-c32">{order?.user_full_name || "-"}</td>
                  <td className="px-2 py-3 border-t border-c32">{order?.phone_number || "-"}</td>
                  <td className="px-2 py-3 border-t border-c32 text-c17">
                    {order?.paid ? `${REFACTOR_SUM(order?.paid)} UZS` : "-"}
                  </td>
                  <td className="px-2 border-t border-c32 text-c14">
                    {REFACTOR_SUM(order.amount - order.paid)} UZS
                  </td>
                  <td
                    className={`px-2 py-3 border-t border-c32 ${
                      !checkingPermissions(["ORDER_DEBT.PAYMENT"]) ? "text-end pr-4" : ""
                    }`}
                  >
                    {order?.amount ? `${REFACTOR_SUM(order?.amount)} UZS` : "-"}
                  </td>
                  {checkingPermissions(["ORDER_DEBT.PAYMENT"]) && (
                    <td className="px-2 pr-4 border-t border-c32 text-end">
                      <button
                        onClick={() => {
                          setDebt(order);
                          handleOpenModal();
                        }}
                        className="w-10 h-10 px-2 border border-c32 rounded-xl"
                      >
                        <Icon name="arrowRightBlue" className="mx-auto" />
                      </button>
                    </td>
                  )}
                </tr>
              ))}
            </tbody>
          </table>
        </div>

        <div className="bg-c36 border-t border-c32 px-4 py-2 rounded-b-xl">
          <MUIPagination
            count={total}
            page={pagination?.page}
            pageSize={pagination?.pageSize}
            onChangePagination={(name, value) => handleChangePagination(name, value)}
          />
        </div>
      </div>
    </>
  );
}

export default DebtOrders;
