import Icon from "assets/icons/icons";
import {
  ResponsiveContainer,
  LineChart,
  Line,
  Tooltip,
  XAxis,
  YAxis,
  CartesianGrid,
  Brush,
  Customized,
} from "recharts";

const CustomTooltip = ({ active, payload, label }) => {
  if (active && payload && payload.length) {
    return (
      <div className="bg-c24 flex flex-col rounded-xl border border-slate-200 p-4 gap-2">
        <div className="flex items-center gap-2">
          <Icon name="calendar" />
          <span className="text-c2">{`${label} `}</span>
        </div>

        {payload.map((data) => (
          <div key={data.dataKey} className="flex justify-between gap-8">
            <span className="text-c34">{data.name.replace("_", " ")}</span>
            <span className="text-c2">{data.value}</span>
          </div>
        ))}
      </div>
    );
  }

  return null;
};

const LineCharts = ({ data }) => {
  const CustomBrush = ({ x, y, width, height }) => {
    return (
      <rect
        x={x}
        y={y}
        width={width}
        height={height}
        fill="rgba(0, 0, 0, 0.2)"
        radius={10}
        sx={{ border: 0, display: "none" }}
      />
    );
  };

  const renderCustomBrush = (props) => {
    const { x, y, width, height } = props;
    return <CustomBrush x={x} y={y} width={width} height={height} />;
  };

  return (
    <ResponsiveContainer className="p-0 w-full h-full">
      <LineChart data={data}>
        <CartesianGrid type="line" strokeDasharray="0" stroke="#EEF2F6" />
        <Tooltip animationEasing="ease-out" active content={<CustomTooltip />} />
        <Line dot={false} type="monotone" dataKey="Naqd" stroke="#359F46" strokeWidth={5} />
        <Line dot={false} type="monotone" dataKey="Plastik" stroke="#008EF5" strokeWidth={5} />
        <Line dot={false} type="monotone" dataKey="Umumiy_savdo" stroke="#BC9D30" strokeWidth={5} />
        <XAxis fontSize="0.8rem" dataKey="name" strokeOpacity="0" fill="#677581" />
        <YAxis fontSize="0.8rem" width={80} strokeOpacity="0" fill="#677581" tickMargin={20} />
        {data?.length > 10 && (
          <Brush
            startIndex={0}
            endIndex={9}
            dataKey="name"
            height={30}
            stroke="#8884d8"
            travellerWidth={15}
          >
            <Customized coordinate="x" customBrush={renderCustomBrush} />
          </Brush>
        )}
      </LineChart>
    </ResponsiveContainer>
  );
};

export default LineCharts;
