import { axiosPrivate } from "services/axiosPrivate";
import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";

const initialState = {
  total: null,
  refresh: "",
  isLoading: false,
  checkedAll: false,
  permissions: [],
  defaultPermissions: [],
  permissionsGroups: [],
  checkedPermissionsCount: 0,
  pagination: { page: 1, pageSize: 10 },
};

export const createPermission = createAsyncThunk("permission/create-permission", async (role) => {
  try {
    const response = await axiosPrivate.post("role/post/", role);
    return response.status;
  } catch (error) {
    console.log(error);
  }
});

export const getPermissions = createAsyncThunk(
  "permission/get-permissions",
  async ({ search, pagination: { page, pageSize } }) => {
    const response = await axiosPrivate.get(
      `role/list/?page=${page || ""}&page_size=${pageSize || ""}&search=${search || ""}`
    );

    return response.data;
  }
);

export const getDefaultPermissions = createAsyncThunk(
  "permission/get-default-permissions",
  async () => {
    const response = await axiosPrivate.get("role/list_default/");
    return response?.data?.data;
  }
);

export const getPermissionsByGroup = createAsyncThunk(
  "permission/get-permissions-by-group",
  async () => {
    const response = await axiosPrivate.get("role/permissions_by_group/");
    return response?.data?.data;
  }
);

export const updatePermission = createAsyncThunk(
  "permission/update-permission",
  async ({ id, permission }) => {
    const response = await axiosPrivate.patch(`role/patch/${id}`, permission);
    return response.data;
  }
);

export const deletePermission = createAsyncThunk("permission/delete-permission", async (id) => {
  const response = await axiosPrivate.delete(`role/delete/${id}`);
  return response.data;
});

const checkingPermission = (modul, permission_value) => {
  let checkedPermissionsCount = 0;
  let checkedAll = false;

  typeof permission_value === "boolean" && (checkedAll = permission_value);

  permission_value === undefined && (checkedAll = !modul?.checkedAll);

  const permissions = modul?.permissions?.map((permission) => {
    if (permission_value && typeof permission_value === "string") {
      if (permission?.value === permission_value) {
        !permission?.checked && (checkedPermissionsCount += 1);
        return { ...permission, checked: permission?.checked ? false : true };
      } else {
        permission?.checked && (checkedPermissionsCount += 1);
        return permission;
      }
    } else {
      checkedAll && (checkedPermissionsCount += 1);
      return { ...permission, checked: checkedAll };
    }
  });

  typeof permission_value === "string" &&
    (checkedAll = checkedPermissionsCount === permissions?.length ? true : false);

  return {
    ...modul,
    checkedAll,
    permissions,
    checkedPermissionsCount,
  };
};

const checkingGroup = (group, modul_name, permission_value) => {
  let checkedPermissionsCount = 0;
  let checkedCount = 0;
  let checkedAll = false;

  typeof modul_name === "boolean" && (checkedAll = modul_name);

  modul_name === undefined && (checkedAll = !group?.checkedAll);

  const moduls = group?.moduls?.map((modul) => {
    if (modul_name && typeof modul_name === "string") {
      if (modul?.name === modul_name) {
        const changedModul = checkingPermission(modul, permission_value);
        changedModul?.checkedAll && (checkedCount += 1);
        checkedPermissionsCount += changedModul?.checkedPermissionsCount || 0;
        return changedModul;
      } else {
        modul?.checkedAll && (checkedCount += 1);
        checkedPermissionsCount += modul?.checkedPermissionsCount || 0;
        return modul;
      }
    } else {
      const changedModul = checkingPermission(modul, checkedAll);
      changedModul?.checkedAll && (checkedCount += 1);
      checkedPermissionsCount += changedModul?.checkedPermissionsCount || 0;
      return changedModul;
    }
  });

  typeof modul_name === "string" && (checkedAll = checkedCount === moduls?.length ? true : false);

  return {
    ...group,
    moduls,
    checkedAll,
    checkedPermissionsCount,
  };
};

export const permissionSlice = createSlice({
  name: "permission",
  initialState,
  reducers: {
    changePagination: (state, action) => {
      state.pagination[action.payload.name] = action.payload.value;
      action.payload.name === "pageSize" &&
        Math.ceil(state.total / state.pagination.pageSize) <= state.pagination.page &&
        (state.pagination.page = Math.ceil(state.total / state.pagination.pageSize));
    },

    checkedPermissionsDefaultValue: (state, action) => {
      let checkedCount = 0;
      let checkedAllGroupsCount = 0;

      const permissionsGroups = state?.permissionsGroups?.map((group) => {
        let checkedAllPermissionsCount = 0;
        let checkedCountModul = 0;

        const moduls = group?.moduls?.map((modul) => {
          let checkedPermissionsCount = 0;

          const permissions = modul?.permissions?.map((permission) => {
            if (action?.payload?.includes(permission?.value)) {
              checkedCount += 1;
              checkedCountModul += 1;
              checkedPermissionsCount += 1;
              return { ...permission, checked: true };
            } else return { ...permission, checked: false };
          });

          checkedAllPermissionsCount =
            checkedPermissionsCount === modul?.permissions?.length &&
            (checkedAllPermissionsCount += 1);

          return {
            ...modul,
            permissions,
            checkedPermissionsCount,
            checkedAll: checkedPermissionsCount === modul?.permissions?.length ? true : false,
          };
        });

        checkedAllGroupsCount =
          checkedAllPermissionsCount === group?.moduls?.length && (checkedAllGroupsCount += 1);

        return {
          ...group,
          moduls,
          checkedPermissionsCount: checkedCountModul,
          checkedAll: checkedAllPermissionsCount === group?.moduls?.length ? true : false,
        };
      });

      state.checkedAll = checkedAllGroupsCount === state?.permissionsGroups?.length ? true : false;

      state.checkedPermissionsCount = checkedCount;

      state.permissionsGroups = permissionsGroups;
    },

    checkedPermission: (state, { payload: { group_name, modul_name, permission_value } }) => {
      let checkedCount = 0;
      let checkedPermissionsCount = 0;

      !group_name && (state.checkedAll = !state?.checkedAll);

      const permissionsGroups = state?.permissionsGroups?.map((group) => {
        if (group_name) {
          if (group?.name === group_name) {
            const changedGroup = checkingGroup(group, modul_name, permission_value);
            changedGroup?.checkedAll && (checkedCount += 1);
            checkedPermissionsCount += changedGroup?.checkedPermissionsCount || 0;
            return changedGroup;
          } else {
            group?.checkedAll && (checkedCount += 1);
            checkedPermissionsCount += group?.checkedPermissionsCount || 0;
            return group;
          }
        } else {
          const changedGroup = checkingGroup(group, state?.checkedAll);
          changedGroup?.checkedAll && (checkedCount += 1);
          checkedPermissionsCount += changedGroup?.checkedPermissionsCount || 0;
          return changedGroup;
        }
      });

      group_name && (state.checkedAll = checkedCount === permissionsGroups?.length ? true : false);

      state.checkedPermissionsCount = checkedPermissionsCount;

      state.permissionsGroups = permissionsGroups;
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(getDefaultPermissions.fulfilled, (state, action) => {
        state.defaultPermissions = action.payload;
      })
      .addCase(getPermissionsByGroup.fulfilled, (state, action) => {
        state.permissionsGroups = action.payload;
        state.checkedPermissionsCount = 0;
        state.checkedAll = false;
      })
      .addCase(getPermissions.pending, (state) => {
        state.isLoading = true;
      })
      .addCase(getPermissions.fulfilled, (state, action) => {
        state.isLoading = false;
        state.permissions = action.payload.data;
        state.total = action.payload.total;
      })
      .addCase(createPermission.fulfilled, (state, action) => {
        if (action.payload === 200) state.refresh = `create-permission-${new Date()}`;
      })
      .addCase(updatePermission.fulfilled, (state, action) => {
        state.refresh = `update-permission-${new Date()}`;
      })
      .addCase(deletePermission.fulfilled, (state, action) => {
        state.refresh = `delete-permission-${new Date()}`;
      });
  },
});

const { reducer, actions } = permissionSlice;

export const { changePagination, checkedPermission, checkedPermissionsDefaultValue } = actions;

export default reducer;
