import { styled } from "@mui/material/styles";
import Tabs from "@mui/material/Tabs";
import Tab from "@mui/material/Tab";

const StyledTabs = styled((props) => (
  <Tabs {...props} TabIndicatorProps={{ children: <span className="MuiTabs-indicatorSpan" /> }} />
))({
  "& .MuiTabs-indicator": {
    display: "none",
  },
});

export default function MUITabs({
  bgColor = "c5",
  color = "c24",
  panel,
  setPanel,
  tabs,
  className = "",
}) {
  const handleChange = (event, newValue) => setPanel(newValue);

  const StyledTab = styled((props) => <Tab disableRipple {...props} />)(({ theme }) => ({
    textTransform: "none",
    padding: 0,
    fontWeight: theme.typography.fontWeightRegular,
    fontSize: theme.typography.pxToRem(14),
    color: `var(--${color})`,
    width: "50%",

    "&.Mui-selected": {
      color: "#fff",
      backgroundColor: "#008ef5",
      borderRadius: "3rem",
    },

    "&.Mui-focusVisible": {
      backgroundColor: "rgba(100, 95, 228, 0.32)",
    },
  }));

  return (
    <div className={`bg${bgColor} ${className}`}>
      <StyledTabs value={panel} onChange={handleChange} aria-label="styled tabs example">
        {tabs?.map((tab, idx) => (
          <StyledTab key={idx} label={tab} />
        ))}
      </StyledTabs>
    </div>
  );
}
