import { useState } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import Icon from "assets/icons/icons";
import MUIDrawer from "components/mui-drawer/MUIDrawer";
import OrdersHistory from "layers/orders-history/OrdersHistory";
import MUIModal from "components/mui-modal/MUIModal";
import { logout } from "store/slices/authSlice";
import { useDispatch, useSelector } from "react-redux";
import { openSession } from "store/slices/authSlice";
import { checkingPermissions } from "assets/constants/constants";

function Topbar() {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const { pathname } = useLocation();
  const {
    auth: {
      session: { session_id },
    },
  } = useSelector((store) => store);
  const [openDrawer, setOpenDrawer] = useState({ open: false, action: "" });
  const [openModal, setOpenModal] = useState(false);

  const currentPath = pathname.split("/");

  const handleOpenDrawer = (action, position, closable) =>
    setOpenDrawer({ open: true, action, position, closable });
  const handleCloseDrawer = () => setOpenDrawer({ ...openDrawer, open: false });

  const handleOpenModal = () => setOpenModal(true);
  const handleCloseModal = () => setOpenModal(false);

  const handleLogout = () => dispatch(logout());

  const handleChangeSession = () => {
    session_id ? navigate("/close-day") : dispatch(openSession());
    handleCloseModal();
  };

  return (
    <>
      <MUIModal open={openModal} position="center" onClose={handleCloseModal}>
        <div className="bg-c30 text-c24 p-4 flex flex-col gap-4 rounded sm:w-[90vw]">
          <div>
            <p>Faxriyor</p>
            <p className="text-c25 text-xs">Kassir</p>
          </div>
          <button className="bg-c5 flex justify-center items-center gap-2 p-3 rounded">
            <Icon name="person" />
            <span>Shaxsiy kabinet</span>
          </button>
          {checkingPermissions(["SESSION.OPEN", "SESSION.CLOSE"]) && (
            <button
              onClick={handleChangeSession}
              className={`bg-c17 flex justify-center items-center gap-2 p-3 rounded ${
                session_id ? "bg-c21" : "bg-c17"
              }`}
            >
              {session_id ? "Kassani yopish" : "Sessiya ochish"}
            </button>
          )}
          <button
            onClick={handleLogout}
            className="bg-c5 flex justify-center items-center gap-2 p-3 rounded"
          >
            <Icon name="logout" />
            <span>Chiqish</span>
          </button>
        </div>
      </MUIModal>

      <MUIDrawer open={openDrawer?.open} onClose={handleCloseDrawer}>
        <OrdersHistory onClose={handleCloseDrawer} />
      </MUIDrawer>

      <div className="bg-c1 text-c24 w-full flex justify-between items-center px-2 py-1 border-b border-c7">
        <button onClick={handleOpenModal} className="flex border-0">
          <Icon name="menu" />
        </button>
        <span>
          {currentPath?.[1] === "home" && "Kassir"}
          {currentPath?.[1] === "history" && "Tarix"}
          {currentPath?.[1] === "debt" && "Nasiyalar"}
          {currentPath?.[1] === "income" && "Mahsulot kiritish"}
        </span>
        <div className="flex items-center gap-4">
          {checkingPermissions(["ORDER_HISTORY.HISTORIES"]) && (
            <button onClick={handleOpenDrawer}>
              <Icon name="history" />
            </button>
          )}
          <button onClick={() => {}}>
            <Icon name="qrcode" />
          </button>
          <button>
            <Icon name="search" />
          </button>
        </div>
      </div>
    </>
  );
}

export default Topbar;
