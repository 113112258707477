import Checkbox from "components/checkbox/Checkbox";
import { MultiSelect } from "react-multi-select-component";

const Localized = {
  allItemsAreSelected: "Barchasi",
  clearSearch: "O'chirish",
  clearSelected: "O'chirish",
  noOptions: "Opsiya yo'q",
  search: "Qidirish",
  selectAll: "Barchasini tanlash",
  selectAllFiltered: "Barchasini tanlash",
  selectSomeItems: "Tanlang",
  create: "Qo'shish",
};

const ItemRenderer = ({ checked, option, onClick, disabled }) => (
  <div className={`flex items-center`}>
    <Checkbox
      tabIndex={-1}
      bgColor="bg-c24"
      checked={checked}
      onChange={onClick}
      disabled={disabled}
      classNameLabel="text-c2"
      classNameCheckbox="text-c2 w-6 h-6"
    />

    <span>{option.label}</span>
  </div>
);

const Multiselect = ({
  value,
  onChange,
  label = "",
  searchable,
  options = [],
  classNameLabel = "text-sm",
  classNameSelect = "border h-10 border-c32 rounded-xl text-c2 text-sm outline-none",
}) => {
  return (
    <div className="w-full">
      {label && <p className={classNameLabel}>{label}</p>}
      <MultiSelect
        value={value}
        options={options}
        labelledBy="Select"
        onChange={onChange}
        className={classNameSelect}
        ItemRenderer={ItemRenderer}
        overrideStrings={Localized}
        disableSearch={!searchable}
      />
    </div>
  );
};

export default Multiselect;
