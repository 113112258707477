import { useState } from "react";
import { useDispatch } from "react-redux";
import { changeInputValue, openOrCloseKeyboard } from "store/slices/keyboardSlice";

import KeyboardReact from "react-simple-keyboard";
import inputMask from "simple-keyboard-input-mask";

import "react-simple-keyboard/build/css/index.css";

function Keyboard({
  width = "20rem",
  onlyOne = false,
  inputMode = "text",
  closeButton = true,
  enterKeyPress = () => {},
}) {
  const dispatch = useDispatch();

  const [layout, setLayout] = useState({ name: "default", key: "" });
  const [keyboard, setKeyboard] = useState(null);

  const handleCloseKeyboard = () => dispatch(openOrCloseKeyboard(false));

  const handleKeyPress = (key) => {
    switch (key) {
      case "{shift}":
        return setLayout({ name: layout?.name === "shift" ? "default" : "shift", key });

      case "{lock}":
        return setLayout({ name: layout?.name === "shift" ? "default" : "shift", key });

      case "{enter}":
        return enterKeyPress();

      case "{close}":
        return handleCloseKeyboard();

      default:
        return (
          key !== "{shift}" &&
          key !== "{lock}" &&
          layout?.name === "shift" &&
          layout?.key === "{shift}" &&
          setLayout({ name: "default", key: "" })
        );
    }
  };

  const handleChangeKeyboard = (e, v) => {
    if (keyboard?.activeInputElement) {
      keyboard.activeInputElement.value = keyboard?.getInput();
      dispatch(changeInputValue(keyboard?.getInput()));
    }
  };

  switch (inputMode) {
    case "numeric":
      return (
        <div className={`mx-auto w-[${width}] h-full`}>
          <KeyboardReact
            mergeDisplay={true}
            layoutName="numeric"
            onKeyPress={handleKeyPress}
            onChange={handleChangeKeyboard}
            keyboardRef={(e) => setKeyboard(e)}
            theme={`hg-theme-default hg-layout-tel ${onlyOne ? "only-one" : "bg-dark"}`}
            layout={{
              numeric: ["1 2 3", "4 5 6", "7 8 9", ". 0 {bksp}"],
            }}
            display={{
              "{bksp}": "&#9003",
              "{close}": "⌵",
            }}
          />
        </div>
      );

    case "tel":
      return (
        <div className={`mx-auto w-[${width}]`}>
          <KeyboardReact
            disableCaretPositioning={true}
            mergeDisplay={true}
            modules={[inputMask]}
            inputMask={{
              default: {
                mask: "+998 99 999 99 99",
                regex: /^[0-9]+$/,
              },
            }}
            layoutName="tel"
            onKeyPress={handleKeyPress}
            onChange={handleChangeKeyboard}
            keyboardRef={(e) => setKeyboard(e)}
            theme={`hg-theme-default hg-layout-tel ${onlyOne ? "bg-none" : "bg-dark"}`}
            layout={{
              tel: closeButton
                ? ["1 2 3", "4 5 6", "7 8 9", "+ 0 {bksp}", "{close}"]
                : ["1 2 3", "4 5 6", "7 8 9", "+ 0 {bksp}"],
            }}
            display={{
              "{bksp}": "&#9003",
              "{close}": "⌵",
            }}
          />
        </div>
      );

    default:
      return (
        <KeyboardReact
          name="Simple-keyboard"
          mergeDisplay={true}
          layoutName={layout.name}
          onKeyPress={handleKeyPress}
          onChange={handleChangeKeyboard}
          keyboardRef={(e) => setKeyboard(e)}
          theme={"hg-theme-default hg-layout-default bg-dark"}
          layout={{
            default: [
              "` 1 2 3 4 5 6 7 8 9 0 - = {bksp}",
              "{tab} q w e r t y u i o p [ ] \\",
              "{lock} a s d f g h j k l ; ' {enter}",
              "{shift} z x c v b n m , . / {shift}",
              "{space} {close}",
            ],
            shift: [
              "~ ! @ # $ % ^ &amp; * ( ) _ + {bksp}",
              "{tab} Q W E R T Y U I O P { } |",
              '{lock} A S D F G H J K L : " {enter}',
              "{shift} Z X C V B N M &lt; &gt; ? {shift}",
              "{space} {close}",
            ],
          }}
          display={{
            "{close}": "⌵",
          }}
        />
      );
  }
}

export default Keyboard;
