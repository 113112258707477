import React, { useEffect } from "react";
import { useParams } from "react-router-dom";
import { getOrder } from "store/slices/orderSlice";
import { useDispatch, useSelector } from "react-redux";
import { REFACTOR_SUM } from "assets/constants/constants";
import { paymentTypeOptions } from "constants/PaymentTypeOptions";
import AdminNavbar from "layers/admin-navbar/AdminNavbar";
import Icon from "assets/icons/icons";

function OrderProducts() {
  const dispatch = useDispatch();
  const params = useParams();

  const {
    ordersList: { order },
  } = useSelector((store) => store.orders);

  useEffect(() => {
    dispatch(getOrder(params?.id));
  }, [dispatch, params?.id]);

  return (
    <>
      <AdminNavbar
        prevButton={
          <button
            onClick={() => window.history.back()}
            className="h-10 px-4 bg-c32 rounded-xl flex justify-center items-center sm:p-1 sm:w-10 sm:bg-c46"
          >
            <Icon name="arrowLeftGray" className="sm:hidden" />
            <Icon name="left_arrow" className="hidden sm:flex" />
            <span className="text-c2 sm:hidden">Orqaga qaytish</span>
          </button>
        }
        rightElement={<div className="hidden sm:flex sm:w-10 sm:h-10" />}
      />
      {order?.id ? (
        <div className="flex h-full items-start my-2 gap-2 px-2 sm:flex-col">
          <div className="text-c2 bg-c24 w-2/5 sm:w-full border border-c32 rounded-lg overflow-hidden py-2 px-4">
            <table className="w-full border-collapse">
              <tbody>
                <tr>
                  <td className="border-b border-c32 py-4 text-start text-c9">Buyurtma raqami: </td>
                  <td className="border-b border-c32 py-4 text-end">{order?.id}</td>
                </tr>
                <tr>
                  <td className="border-b border-c32 py-4 text-start text-c9">Vaqti: </td>
                  <td className="border-b border-c32 py-4 text-end">
                    {order?.created_at?.substring(0, 16)}
                  </td>
                </tr>

                <tr>
                  <td className="border-b border-c32 py-4 text-start text-c9">Kassir: </td>
                  <td className="border-b border-c32 py-4 text-end">{order?.seller_name}</td>
                </tr>

                {order?.user_full_name && (
                  <tr>
                    <td className="border-b border-c32 py-4 text-start text-c9">Mijoz: </td>
                    <td className="border-b border-c32 py-4 text-end">{order?.user_full_name}</td>
                  </tr>
                )}

                <tr>
                  <td className="border-b border-c32 py-4 text-start text-c9">Buyurtma turi: </td>

                  <td className="border-b border-c32 py-4 flex items-center justify-end gap-2">
                    <Icon name={paymentTypeOptions[order?.payment_type]?.icon} />
                    <span className="text-c2">
                      {paymentTypeOptions[order?.payment_type]?.label}
                    </span>
                  </td>
                </tr>

                {order?.payments?.map((pay) => (
                  <tr key={pay?.id}>
                    <td className="pt-4 pb-2 text-start text-c9">
                      {paymentTypeOptions[pay?.type]?.label}
                    </td>
                    <td className="pt-4 pb-2 text-end">{REFACTOR_SUM(pay?.amount)}</td>
                  </tr>
                ))}

                <tr>
                  <td className="py-2 text-start text-c9 whitespace-nowrap">Umumiy summa: </td>
                  <td className="text-end">
                    <span
                      className={`${
                        order?.payment_type === "debt" ? "text-c14" : "text-c10"
                      } text-lg bold`}
                    >
                      {REFACTOR_SUM(order?.amount)} UZS
                    </span>
                  </td>
                </tr>
              </tbody>
            </table>
          </div>

          <div className="bg-c24 text-c2 w-3/5 sm:w-full border border-c32 rounded-lg overflow-hidden">
            <table className="w-full border-collapse even:bg-c36 odd:bg-white">
              <thead>
                <tr className="text-c9 bg-c36">
                  <td className="px-2 py-3 pl-4">№</td>
                  <td className="px-2 py-3 ">Nomi</td>
                  <td className="px-2 py-3 text-center">Miqdori</td>
                  <td className="px-2 py-3 pr-4 text-end">Narxi</td>
                </tr>
              </thead>

              <tbody className="text-c2">
                {order?.items?.map((product, idx) => (
                  <tr key={product?.id}>
                    <td className="border-t border-c32 whitespace-nowrap px-2 py-3 pl-4">
                      {idx + 1}
                    </td>
                    <td
                      className={`border-t border-c32 whitespace-nowrap px-2 py-3 ${
                        product?.returned_quantity && !product?.quantity
                          ? "line-through text-c9"
                          : ""
                      }`}
                    >
                      {product?.product_name}
                    </td>
                    <td
                      className={`border-t border-c32 whitespace-nowrap px-2 py-3 text-center relative`}
                    >
                      <span
                        className={`${
                          product?.returned_quantity && !product?.quantity
                            ? "line-through text-c9"
                            : ""
                        }`}
                      >
                        {product?.quantity}
                      </span>
                      {product?.returned_quantity && (
                        <span className="bg-c14 text-c24 px-2 py-1 rounded-xl ml-2 absolute top-2">
                          {product?.returned_quantity}
                        </span>
                      )}
                    </td>
                    <td
                      className={`border-t border-c32 whitespace-nowrap px-2 py-3 pr-4 text-end ${
                        product?.returned_quantity && !product?.quantity
                          ? "line-through text-c9"
                          : ""
                      }`}
                    >
                      {product?.price ? `${REFACTOR_SUM(product?.price)} UZS` : "-"}
                    </td>
                  </tr>
                ))}
              </tbody>
            </table>
          </div>
        </div>
      ) : (
        <div className="text-center p-4 bg-c24 m-4 rounded-md">
          <span>{params?.id}</span> - <span>id bo'yicha ma'lumot topilmadi.</span>
        </div>
      )}
    </>
  );
}

export default OrderProducts;
