import { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { checkingPermissions } from "assets/constants/constants";
import { getUsers, changePagination, deleteUser } from "store/slices/userSlice";
import MUIPagination from "components/mui-pagination/MUIPagination";
import AdminNavbar from "layers/admin-navbar/AdminNavbar";
import MUIModal from "components/mui-modal/MUIModal";
import HtmlInput from "components/input/HtmlInput";
import MUIMenu from "components/mui-menu/MUIMenu";
import CreateUser from "./CreateUser";
import DeleteUser from "./DeleteUser";
import { Divider } from "@mui/material";
import Icon from "assets/icons/icons";

function Users() {
  const dispatch = useDispatch();
  const { users, pagination, total, refresh } = useSelector((store) => store.users);
  const [openModal, setOpenModal] = useState({ open: false, action: "" });
  const [openMenu, setOpenMenu] = useState({ anchorEl: null, action: "", user: null });

  const handleOpenMenu = (e, action, user) =>
    setOpenMenu({ anchorEl: e.currentTarget, action, user });

  const handleCloseMenu = () => setOpenMenu({ ...openMenu, anchorEl: null });

  const handleOpenModal = (action) => {
    handleCloseMenu();
    setOpenModal({ open: true, action });
  };

  const handleCloseModal = () => setOpenModal({ ...openModal, open: false });

  const handleDelete = () => dispatch(deleteUser(openMenu?.user?.id));

  const handleChangePagination = (name, value) => dispatch(changePagination({ name, value }));

  const handleGetUsers = (search = "") => dispatch(getUsers({ search, pagination }));

  useEffect(() => {
    handleGetUsers();
    handleCloseModal();
  }, [dispatch, pagination, refresh]);

  return (
    <>
      <MUIModal
        position="center"
        hideBackdrop={false}
        open={openModal?.open}
        onClose={handleCloseModal}
      >
        {(openModal?.action === "create" || openModal?.action === "update") && (
          <CreateUser
            action={openModal?.action}
            onClose={handleCloseModal}
            currentUser={openMenu?.user}
          />
        )}
        {openModal?.action === "delete" && (
          <DeleteUser onDelete={handleDelete} onClose={handleCloseModal} />
        )}
      </MUIModal>

      <MUIMenu
        handleClose={handleCloseMenu}
        anchorEl={openMenu?.anchorEl}
        open={openMenu?.anchorEl ? true : false}
      >
        <div
          className="bg-c36 flex flex-col gap-2 px-2"
          onClick={(event) => event.stopPropagation()}
        >
          {checkingPermissions(["USER.UPDATE"]) && (
            <div
              className="flex px-2 gap-3 cursor-pointer"
              onClick={() => handleOpenModal("update")}
            >
              <Icon name="edit_gray" />
              <span className="text-c2">Tahrirlash</span>
            </div>
          )}

          {checkingPermissions(["USER.UPDATE", "USER.DELETE"]) && (
            <Divider sx={{ borderColor: "#E8ECF4" }} />
          )}

          {checkingPermissions(["USER.DELETE"]) && (
            <div
              className="flex px-2 gap-3 cursor-pointer"
              onClick={() => handleOpenModal("delete")}
            >
              <Icon name="trash_red" />
              <span className="text-c14">O'chirish</span>
            </div>
          )}
        </div>
      </MUIMenu>

      <AdminNavbar
        rightElement={
          checkingPermissions(["USER.CREATE"]) ? (
            <button
              className="bg-c10 h-10 px-4 flex justify-center items-center gap-1 rounded-xl sm:p-0 sm:w-10"
              onClick={() => handleOpenModal("create")}
            >
              <Icon name="add" />
              <span className="text-c24 sm:hidden">Xodim qo’shish</span>
            </button>
          ) : (
            <div className="w-10 h-10" />
          )
        }
      />

      <div className="bg-c24 m-4 border border-c32 rounded-lg overflow-hidden">
        <div className="flex justify-between items-center border-b border-c32 px-4 py-2">
          <HtmlInput
            type="search"
            placeholder="Qidiruv"
            onChange={(e) => handleGetUsers(e.target.value)}
            classNameInput="border h-10 rounded-xl outline-0 bg-c31 text-sm"
          />
        </div>

        <div className="rounded-lg overflow-x-auto">
          <table className="w-full border-collapse sm:whitespace-nowrap">
            <thead>
              <tr className="text-c9 font-thin bg-c36">
                <td className="px-2 py-3 pl-4">№</td>
                <td className="px-2 py-3">Ismi</td>
                <td className="px-2 py-3">Familiyasi</td>
                <td className="px-2 py-3">Otasining ismi</td>
                <td
                  className={`px-2 py-3 ${
                    !checkingPermissions(["USER.UPDATE"]) && !checkingPermissions(["USER.DELETE"])
                      ? "pr-4 text-end"
                      : ""
                  }`}
                >
                  Lavozimi
                </td>
                {(checkingPermissions(["USER.UPDATE"]) || checkingPermissions(["USER.DELETE"])) && (
                  <td className="px-2 py-3 pr-4 text-end">Amallar</td>
                )}
              </tr>
            </thead>

            <tbody>
              {users?.map((user, idx) => (
                <tr key={user?.id} className="even:bg-c36 odd:bg-white">
                  <td className="border-t px-2 py-3 pl-4">
                    {Math.abs(idx + pagination.pageSize * (pagination.page - 1) + 1)}
                  </td>
                  <td className="border-t px-2 py-3">{user?.first_name || "-"}</td>
                  <td className="border-t px-2 py-3">{user?.last_name || "-"}</td>
                  <td className="border-t px-2 py-3">{user?.middle_name || "-"}</td>
                  <td
                    className={`border-t px-2 py-3 ${
                      !checkingPermissions(["USER.UPDATE"]) && !checkingPermissions(["USER.DELETE"])
                        ? "pr-4 text-end"
                        : ""
                    }`}
                  >
                    {user?.type || "-"}
                  </td>
                  {(checkingPermissions(["USER.UPDATE"]) ||
                    checkingPermissions(["USER.DELETE"])) && (
                    <td className="border-t px-2 pr-4 text-end">
                      <button
                        className="bg-c31 w-10 h-10 border rounded-xl"
                        onClick={(e) => handleOpenMenu(e, "", user)}
                      >
                        <Icon name="more_black" className="mx-auto" />
                      </button>
                    </td>
                  )}
                </tr>
              ))}
            </tbody>
          </table>
        </div>

        <div className="bg-c36 border-t border-c32 px-4 py-2">
          <MUIPagination
            count={total}
            page={pagination?.page}
            pageSize={pagination?.pageSize}
            onChangePagination={(name, value) => handleChangePagination(name, value)}
          />
        </div>
      </div>
    </>
  );
}

export default Users;
