import { useEffect, useState } from "react";
import { useLocation } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { getCategories, chooseCategory } from "store/slices/categorySlice";
import MUIAccordionCategory from "components/mui-accordion/MUIAccordionCategory";
import CreateCategories from "layers/categories/CreateCategories";
import MUIDrawer from "components/mui-drawer/MUIDrawer";
import Select from "components/select/Select";
import Icon from "assets/icons/icons";
import { checkingPermissions } from "assets/constants/constants";

function Categories() {
  const dispatch = useDispatch();
  const { pathname } = useLocation();
  const { categories, refresh, activeCategory, pagination } = useSelector(
    (store) => store.categories
  );
  const [openDrawer, setOpenDrawer] = useState(false);

  const currentPath = pathname.split("/");

  const handleOpenDrawer = () => setOpenDrawer(true);
  const handleCloseDrawer = () => setOpenDrawer(false);

  const handleChooseCategory = (parent, child) => dispatch(chooseCategory({ parent, child }));

  useEffect(() => {
    dispatch(getCategories({ onlyRootCategories: true, withChildren: true, pagination }));

    handleCloseDrawer();
  }, [dispatch, refresh]);

  return (
    <>
      <MUIDrawer open={openDrawer} onClose={handleCloseDrawer}>
        <CreateCategories />
      </MUIDrawer>
      <div className="max-h-full whitespace-nowrap flex flex-col gap-2 overflow-y-scroll sm:hidden">
        {currentPath?.[1] === "income" && checkingPermissions(["CATEGORY.CREATE"]) && (
          <button
            onClick={handleOpenDrawer}
            className="bg-c17 text-c24 gap-2 flex justify-center items-center py-3 rounded"
          >
            <Icon name="plus" /> Yangi kategoriya
          </button>
        )}

        {categories?.map((category) => (
          <MUIAccordionCategory
            {...category}
            key={category?.id}
            activeCategory={activeCategory}
            onChooseCategory={(id, child) => handleChooseCategory(id, child)}
          />
        ))}
      </div>
      <div className="gap-1 w-max h-full overflow-x-auto hidden rounded sm:flex">
        {currentPath?.[1] === "income" && checkingPermissions(["CATEGORY.CREATE"]) && (
          <div
            onClick={handleOpenDrawer}
            className="bg-c17 flex items-center justify-center px-4 rounded"
          >
            <Icon name="plus" className="max-w-none" />
          </div>
        )}
        {categories?.map((category) => (
          <Select
            key={category?.id}
            firstOption={false}
            value={activeCategory?.child}
            optionsLabel={{ value: "id", label: "name" }}
            onChange={(e) => handleChooseCategory(category?.id, e.target.value)}
            options={[{ id: null, name: category?.name }, ...category?.children]}
            classNameSelect={`rounded text-c24 p-3 outline-0 w-[160px] h-full bg-arrow-down bg-no-repeat bg-right bg-[center_right_0.24rem] select-arrow-none ${
              activeCategory?.parent === category?.id ? "bg-c17" : "bg-c1"
            }`}
          />
        ))}
      </div>
    </>
  );
}

export default Categories;
