import { axiosPrivate } from "services/axiosPrivate";
import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";

const initialState = {
  isLoading: false,
  refresh: "",
  products: [],
  checkedAll: false,
  checkedCount: 0,
  currentProduct: {},
  totalPrice: 0,
  filter: { root_category: "", category: "", brand: "", unit: "", search: "" },
  total: null,
  pagination: { page: 1, pageSize: 10 },
};

export const getProducts = createAsyncThunk(
  "product/get-products",
  async ({
    filter: { search, category, brand, unit },
    pagination = { page: 1, pageSize: 100 },
  }) => {
    const response = await axiosPrivate.get(
      `product/list/?category=${category || ""}&brand=${brand || ""}&unit=${unit || ""}&search=${
        search || ""
      }&page=${pagination?.page}&page_size=${pagination?.pageSize}`
    );
    return response.data;
  }
);

export const createProduct = createAsyncThunk("product/create-product", async (product) => {
  const response = await axiosPrivate.post("product/post/", product);
  return response.data;
});

export const updateProduct = createAsyncThunk(
  "product/update-product",
  async ({ product_id, product }) => {
    const response = await axiosPrivate.patch(`product/patch/${product_id}`, product);
    return response.data;
  }
);

export const changeProductsCategory = createAsyncThunk(
  "product/change-products-category",
  async (data) => {
    const response = await axiosPrivate.put(`product/put-categories/`, data);
    return response.data;
  }
);

export const deleteProduct = createAsyncThunk("product/delete-product", async (product_id) => {
  const response = await axiosPrivate.delete(`product/delete/${product_id}`);
  return response.data;
});

export const deleteProducts = createAsyncThunk("product/delete-product-by-ids", async (ids) => {
  const response = await axiosPrivate.post(`product/delete/by-ids`, { ids });
  return response.data;
});

export const productSlice = createSlice({
  name: "product",
  initialState,
  reducers: {
    chooseProduct: (state, action) => {
      state.currentProduct = action.payload;
    },
    checkedAllProduct: (state) => {
      state.checkedAll = !state.checkedAll;
      state.products = state.products.map((product) => ({
        ...product,
        checked: state.checkedAll,
      }));

      state.checkedCount = state.checkedAll ? state.products.length : 0;
    },
    checkedProduct: (state, action) => {
      let checkedCount = 0;
      state.products = state.products.map((product) =>
        product.id === action.payload
          ? { ...product, checked: product?.checked ? false : true }
          : product
      );

      state.products.map((product) => product.checked && (checkedCount += 1));
      state.checkedCount = checkedCount;

      checkedCount === state.products.length
        ? (state.checkedAll = true)
        : (state.checkedAll = false);
    },
    changePagination: (state, action) => {
      state.pagination[action.payload.name] = action.payload.value;
      action.payload.name === "pageSize" &&
        Math.ceil(state.total / state.pagination.pageSize) <= state.pagination.page &&
        (state.pagination.page = Math.ceil(state.total / state.pagination.pageSize));
    },
    changeFilter: (state, action) => {
      state.filter = action.payload;
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(createProduct.pending, (state, action) => {
        state.isLoading = true;
      })
      .addCase(createProduct.fulfilled, (state, action) => {
        state.isLoading = false;
        state.currentProduct = action.payload;
        state.refresh = `create-product-${new Date()}`;
      })
      .addCase(updateProduct.fulfilled, (state) => {
        state.refresh = `update-product-${new Date()}`;
      })
      .addCase(changeProductsCategory.fulfilled, (state) => {
        state.refresh = `change-products-category-${new Date()}`;
      })
      .addCase(deleteProduct.fulfilled, (state) => {
        state.refresh = `delete-product-${new Date()}`;
      })
      .addCase(deleteProducts.fulfilled, (state) => {
        state.refresh = `delete-products-${new Date()}`;
      })
      .addCase(getProducts.pending, (state) => {
        state.isLoading = true;
      })
      .addCase(getProducts.fulfilled, (state, action) => {
        state.isLoading = false;
        state.total = action.payload.total;
        state.products = action.payload.data;
        state.totalPrice = action.payload.total_price;
      });
  },
});

const { reducer, actions } = productSlice;

export const { chooseProduct, checkedAllProduct, checkedProduct, changePagination, changeFilter } =
  actions;

export default reducer;
