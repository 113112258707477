import { useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { paymentDebtOrder } from "store/slices/orderSlice";
import Keyboard from "layers/keyboard/Keyboard";
import Select from "components/select/Select";
import Input from "components/input/Input";

function Payment() {
  const dispatch = useDispatch();
  const {
    orders: {
      debtOrders: { client },
    },
  } = useSelector((store) => store);
  const [payment, setPayment] = useState({ type: "cash", amount: client.amount - client.paid });

  const handleChangePayment = (name, value) => setPayment({ ...payment, [name]: value });

  const handleSave = () => {
    dispatch(paymentDebtOrder({ ...payment, user_id: client?.user_id, order_id: client?.id }));
  };

  return (
    <div className="bg-c2 text-c24 w-[30rem] h-full overflow-y-auto flex flex-col justify-between sm:w-screen">
      <div className="flex flex-col p-4 gap-3">
        <p className="text-xl">To'lov kiritish</p>
        <div className="flex gap-6">
          {client?.paid ? (
            <div className="flex flex-col">
              <p>To'landi</p>
              <p className="text-c20 font-bold">{client?.paid}</p>
            </div>
          ) : (
            ""
          )}
          {client.amount - client.paid ? (
            <div className="flex flex-col">
              <p>Qoldi</p>
              <p className="text-c16 font-bold">{client.amount - client.paid}</p>
            </div>
          ) : (
            ""
          )}
          <div className="flex flex-col">
            <p>Summa</p>
            <p className="font-bold">{client?.amount}</p>
          </div>
        </div>

        <div className="flex flex-col">
          <Input
            type="number"
            label="To'lov summasi"
            value={payment?.amount || ""}
            onChange={(value) => handleChangePayment("amount", value)}
            inputMode="numeric"
            classNameInput="border border-c7 text-c24 rounded text-sm bg-c1 p-3 outline-none"
          />
          {client.amount - client.paid ? (
            <button
              className="bg-c5 w-max px-4 py-2 mt-2 mb-6 rounded"
              onClick={() => handleChangePayment("amount", client.amount - client.paid)}
            >
              {client.amount - client.paid}
            </button>
          ) : (
            ""
          )}
          <Select
            value={payment?.type}
            label="To'lov turi"
            options={[
              { value: "card", label: "Karta" },
              { value: "cash", label: "Naqd" },
            ]}
            onChange={(e) => handleChangePayment("type", e.target.value)}
            classNameSelect="bg-c1 w-full px-2 py-3 border border-c7 rounded outline-0 bg-arrow-down bg-no-repeat bg-right bg-[center_right_0.8rem] select-arrow-none"
          />
        </div>
      </div>

      <div className="p-4 h-1/2 flex flex-col gap-3 sm:h-auto">
        <div className="h-full sm:hidden">
          <Keyboard inputMode="numeric" width="full" onlyOne={true} />
        </div>
        <button onClick={handleSave} className="bg-c17 text-center px-4 py-3 rounded">
          Saqlash
        </button>
      </div>
    </div>
  );
}

export default Payment;
