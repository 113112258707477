import { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { REFACTOR_SUM, checkingPermissions } from "assets/constants/constants";
import { getProducts, changePagination, changeFilter } from "store/slices/productSlice";
import MUIPagination from "components/mui-pagination/MUIPagination";
import RemainingProductsFilter from "./RemainingProductsFilter";
import AdminNavbar from "layers/admin-navbar/AdminNavbar";
import MUIDrawer from "components/mui-drawer/MUIDrawer";
import HtmlInput from "components/input/HtmlInput";
import Icon from "assets/icons/icons";

function RemainingProducts() {
  const dispatch = useDispatch();
  const {
    products: { products, pagination, total, totalPrice, filter },
  } = useSelector((store) => store);
  const [openDrawer, setOpenDrawer] = useState({ open: false, action: "" });

  const handleChangePagination = (name, value) => dispatch(changePagination({ name, value }));

  const handleOpenDrawer = (action) => setOpenDrawer({ open: true, action });

  const handleCloseDrawer = () => setOpenDrawer({ ...openDrawer, open: false });

  const handleSearch = (value) => dispatch(changeFilter({ ...filter, search: value }));

  useEffect(() => {
    dispatch(getProducts({ filter, pagination }));
    handleCloseDrawer();
  }, [pagination, filter]);

  return (
    <>
      <MUIDrawer open={openDrawer?.open} onClose={handleCloseDrawer}>
        <RemainingProductsFilter />
      </MUIDrawer>

      <AdminNavbar rightElement={<div className="w-10 h-10" />} />

      <div className="bg-c24 text-c2 m-4 border border-c32 rounded-lg overflow-hidden">
        <div className="flex justify-between items-center border-b border-c32 px-4 py-2 gap-2">
          <HtmlInput
            type="search"
            placeholder="Qidiruv"
            value={filter?.search}
            classNameInput="border h-10 rounded-xl outline-0 bg-c31 text-sm"
            onChange={(e) => handleSearch(e.target.value)}
          />

          {(checkingPermissions(["CATEGORY.READ"]) ||
            checkingPermissions(["UNIT.READ"]) ||
            checkingPermissions(["BRAND.READ"])) && (
            <button
              onClick={handleOpenDrawer}
              className="bg-c36 h-10 flex justify-center items-center gap-2 py-2 px-4 rounded-xl border border-c32 sm:p-0 sm:w-10"
            >
              <Icon name="filter" />
              <span className="text-c2 sm:hidden">Filter</span>
            </button>
          )}
        </div>

        <div className="text-c2 rounded-lg overflow-x-auto">
          {products?.length ? (
            <table className="sm:whitespace-nowrap w-full border-collapse">
              <thead>
                <tr className="text-c9 font-thin bg-c36">
                  <td className="px-2 py-3 pl-4">№</td>
                  <td className="px-2 py-3 ">Mahsulot nomi</td>
                  <td className="px-2 py-3 ">Kategoriya</td>
                  <td className="px-2 py-3 ">Ichki kategoriya</td>
                  <td className="px-2 py-3 ">Qoldiq</td>
                  <td className="px-2 py-3 ">Narxi</td>
                  <td className="px-2 py-3 pr-4 text-end">Umumiy summa</td>
                </tr>
              </thead>

              <tbody>
                {products?.map((order, idx) => (
                  <tr key={order?.id} className="even:bg-c36 odd:bg-white">
                    <td className="px-2 py-3 pl-4 border-t ">
                      {Math.abs(idx + pagination.pageSize * (pagination.page - 1) + 1)}
                    </td>
                    <td className="px-2 py-3 border-t ">{order?.name || "-"}</td>
                    <td className="px-2 py-3 border-t ">{order?.root_category_name || "-"}</td>
                    <td className="px-2 py-3 border-t ">{order?.category_name || "-"}</td>
                    <td className="px-2 py-3 border-t ">
                      {order?.quantity} {order?.unit_name}
                    </td>
                    <td className="px-2 py-3 border-t ">
                      {order?.price ? `${REFACTOR_SUM(order?.price)} UZS` : "-"}
                    </td>
                    <td className="px-2 py-3 pr-4 text-end border-t ">
                      {order.price && order.quantity
                        ? `${REFACTOR_SUM(order.price * order.quantity)} UZS`
                        : "-"}
                    </td>
                  </tr>
                ))}
              </tbody>
            </table>
          ) : (
            <h1 className="text-center m-5 text-xl">Ma'lumot topilmadi</h1>
          )}
        </div>
        {totalPrice ? (
          <div className="flex items-center justify-between py-3 px-4 border-t border-c32 shadow-lg">
            <span className="text-c2">Umumiy summa</span>
            <span className="text-c10 text-lg font-medium">{REFACTOR_SUM(totalPrice)} UZS</span>
          </div>
        ) : (
          ""
        )}

        <div className="bg-c36 border-t border-c32 px-4 py-2 rounded-b-xl">
          <MUIPagination
            count={total}
            bgColor="c36"
            page={pagination?.page}
            pageSize={pagination?.pageSize}
            onChangePagination={(name, value) => handleChangePagination(name, value)}
          />
        </div>
      </div>
    </>
  );
}

export default RemainingProducts;
