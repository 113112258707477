import { addDays } from "date-fns";
import { axiosPrivate } from "services/axiosPrivate";
import { REFACTOR_DATE } from "assets/constants/constants";
import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";

const initialState = {
  refresh: "",

  // ------------ order

  orders: {},

  // ------------ order

  // ------------ order list

  ordersList: {
    order: {},
    ordersList: [],
    filter: {
      search: "",
      seller_id: "",
      payment_types: [],
      selection: {
        startDate: null,
        endDate: addDays(0, 0),
      },
    },
    total: null,
    pagination: { page: 1, pageSize: 10 },
  },

  // ------------ order list

  // ------------ order history

  ordersHistory: {
    total: null,
    payment: {},
    histories: [],
    historyItem: {},
    isLoading: false,
    pagination: { page: 1, pageSize: 10 },
  },

  // ------------ order history

  // ------------ return order

  returnedOrders: {
    returnedOrdersList: [],
    filter: {
      search: "",
      seller_id: "",
      payment_types: [],
      selection: {
        startDate: null,
        endDate: addDays(0, 0),
      },
    },
    total: null,
    pagination: { page: 1, pageSize: 10 },
  },

  // ------------ return order

  // ------------ debt order

  debtOrders: {
    debts: [],
    client: {},
    total: 0,
    filter: {
      search: "",
      customer_id: "",
      selection: {
        startDate: null,
        endDate: addDays(0, 0),
      },
    },
    pagination: { page: 1, pageSize: 10 },
  },

  // ------------ debt order
};

// ----------------------------------------------------------

// order list

export const createOrder = createAsyncThunk("order/create-order", async ({ order_id, sell }) => {
  const response = await axiosPrivate.post("order/post/", sell);
  return { result: response.data, order_id };
});

export const getOrder = createAsyncThunk("order/get-order-item", async (order_id) => {
  const response = await axiosPrivate.get(`order/get/${order_id}`);
  return response.data;
});

export const getOrders = createAsyncThunk(
  "order/get-orders-list",
  async ({
    filter: {
      search,
      seller_id,
      payment_types,
      selection: { startDate, endDate },
    },
    pagination: { page, pageSize },
  }) => {
    const response = await axiosPrivate.get(
      `order/list/?page=${page}&page_size=${pageSize}&search=${search}&seller_id=${seller_id}&start_date=${REFACTOR_DATE(
        startDate
      )}&end_date=${
        new Date(endDate).toDateString() !== "Thu Jan 01 1970" ? REFACTOR_DATE(endDate) : ""
      }&payment_types=${payment_types?.map((type) => `${type.value}`)}`
    );

    return response.data;
  }
);

const calculateTotalPrice = (products) => {
  let totalPrice = 0;
  products?.map((pr) => (totalPrice += parseFloat(pr?.sell_price || 0, 10)));
  return totalPrice;
};

// order list

// ----------------------------------------------------------

// order history

export const getOrdersHistory = createAsyncThunk(
  "order/get-orders-history",
  async ({ pagination: { page, pageSize } }) => {
    const response = await axiosPrivate.get(
      `order_history/list/?page=${page}&page_size=${pageSize || ""}`
    );
    return response.data;
  }
);

export const getOrdersHistoryItem = createAsyncThunk(
  "order/get-orders-history-item",
  async (order_id) => {
    const response = await axiosPrivate.get(`order_history/get/${order_id}`);
    return response.data;
  }
);

// order history

// ----------------------------------------------------------

// return order

export const returnOrder = createAsyncThunk("order/return-order", async (order) => {
  const response = await axiosPrivate.post(`return_order/post`, order);
  return response.data;
});

export const getReturnedOrders = createAsyncThunk(
  "order/get-returned-orders",
  async ({
    filter: {
      seller_id,
      payment_types,
      selection: { startDate, endDate },
    },
    pagination: { page, pageSize },
  }) => {
    const response = await axiosPrivate.get(
      `return_order/list/?page=${page}&page_size=${pageSize}&start_date=${REFACTOR_DATE(
        startDate
      )}&end_date=${
        new Date(endDate).toDateString() !== "Thu Jan 01 1970" ? REFACTOR_DATE(endDate) : ""
      }&seller_id=${seller_id}&payment_types=${payment_types?.map((type) => `${type.value}`)}`
    );
    return response.data;
  }
);

const calculateReturnProductsPrice = (products, amount, paid) => {
  let totalPrice = 0;
  products?.map((pr) => pr?.returnStatus && (totalPrice += pr.returnQuantity * pr.price));
  const debt = amount - paid - totalPrice;
  if (debt > 0) return { totalPrice, debt: totalPrice };
  else return { totalPrice, cash: Math.abs(debt), debt: totalPrice + debt };
};

// return order

// ----------------------------------------------------------

// debt order

export const getDebtOrders = createAsyncThunk(
  "order/get-debt-orders",
  async ({
    filter: {
      search,
      customer_id,
      selection: { startDate, endDate },
    },
    pagination: { page, pageSize },
  }) => {
    const response = await axiosPrivate.get(
      `order_debt/list/?search=${search || ""}&customer_id=${
        customer_id || ""
      }&start_date=${REFACTOR_DATE(startDate)}&end_date=${
        new Date(endDate).toDateString() !== "Thu Jan 01 1970" ? REFACTOR_DATE(endDate) : ""
      }&page=${page || ""}&page_size=${pageSize || ""}`
    );
    return response.data;
  }
);

export const paymentDebtOrder = createAsyncThunk("order/payment-debt-order", async (payment) => {
  const response = await axiosPrivate.post(`payment/post/`, payment);
  return response.data;
});

// debt order

// ----------------------------------------------------------

export const orderSlice = createSlice({
  name: "order",
  initialState,
  reducers: {
    // ----------------------------------------------------------

    // order

    addOrder: (state, action) => {
      state.orders = {
        ...state.orders,
        [action.payload]: { products: [], totalPrice: 0, client: {} },
      };
    },

    chooseClient: (state, { payload: { id, order_id } }) => {
      state.orders[order_id].client = id;
    },

    addOrderProduct: (state, { payload: { order_id, product } }) => {
      const testProduct = state.orders?.[order_id]?.products?.filter((p) => p.id === product?.id);

      if (testProduct?.length) {
        const products = state?.orders?.[order_id]?.products?.map((p) =>
          p.id === product?.id
            ? {
                ...p,
                sell_quantity: parseFloat(p.sell_quantity || 0) + 1,
                sell_price: p.price * (parseFloat(p.sell_quantity || 0) + 1),
              }
            : p
        );

        state.orders[order_id].products = products;
        state.orders[order_id].activeProduct = {
          ...products?.filter((p) => p?.id === product?.id)?.[0],
          field: "sell_quantity",
        };
      } else {
        state.orders[order_id].products.unshift({
          ...product,
          sell_quantity: 1,
          sell_price: product?.price,
        });
        state.orders[order_id].activeProduct = {
          ...product,
          sell_quantity: 1,
          sell_price: product?.price,
          field: "sell_quantity",
        };
      }

      state.orders[order_id].totalPrice = calculateTotalPrice(state.orders[order_id].products);
    },

    chooseActiveProduct: (state, { payload: { product, order_id } }) => {
      state.orders[order_id].activeProduct = product;
    },

    changeOrderProduct: (state, { payload: { order_id, name, value, id } }) => {
      const products = state.orders[order_id].products.map((p) =>
        p?.id === id
          ? {
              ...p,
              [name]: value,
              [name === "sell_quantity" ? "sell_price" : "sell_quantity"]:
                name === "sell_quantity"
                  ? p.price * value
                  : p.unit_type === "piece"
                  ? Math.trunc(value / p.price)
                  : value / p.price,
            }
          : p
      );
      state.orders[order_id].products = products;
      state.orders[order_id].totalPrice = calculateTotalPrice(products);
    },

    removeOrderProduct: (state, { payload: { id, order_id } }) => {
      const products = state.orders[order_id].products.filter((p) => p.id !== id);
      state.orders[order_id].products = products;
      state.orders[order_id].totalPrice = calculateTotalPrice(products);
    },

    // order

    // ----------------------------------------------------------

    // order list

    changePaginationOrderList: (state, action) => {
      state.ordersList.pagination[action.payload.name] = action.payload.value;
      action.payload.name === "pageSize" &&
        Math.ceil(state.total / state.ordersList.pagination.pageSize) <=
          state.ordersList.pagination.page &&
        (state.ordersList.pagination.page = Math.ceil(
          state.total / state.ordersList.pagination.pageSize
        ));
    },

    changeOrderListFilter: (state, action) => {
      state.ordersList.filter = action.payload;
    },

    // order list

    // ----------------------------------------------------------

    // order history

    changePaginationOrderHistory: (state, action) => {
      state.ordersHistory.pagination[action.payload.name] = action.payload.value;
      action.payload.name === "pageSize" &&
        Math.ceil(state.ordersHistory.total / state.ordersHistory.pagination.pageSize) <=
          state.ordersHistory.pagination.page &&
        (state.ordersHistory.pagination.page = Math.ceil(
          state.ordersHistory.total / state.ordersHistory.pagination.pageSize
        ));
    },

    // order history

    // ----------------------------------------------------------

    // return product

    chooseReturnProducts: (state, action) => {
      const historyItem = state?.ordersHistory?.historyItem;
      const items = historyItem?.items?.map((item) =>
        item?.id === action?.payload
          ? {
              ...item,
              returnStatus: item?.returnStatus ? false : true,
              returnQuantity: item?.quantity,
            }
          : item
      );
      state.ordersHistory.historyItem.items = items;
      state.ordersHistory.historyItem.returnPayment = calculateReturnProductsPrice(
        items,
        historyItem?.amount,
        historyItem?.paid
      );
    },

    changeReturnProductQuantity: (state, { payload: { id, returnQuantity } }) => {
      const items = state?.ordersHistory?.historyItem?.items?.map((item) =>
        item?.id === id
          ? {
              ...item,
              returnQuantity,
            }
          : item
      );
      state.ordersHistory.historyItem.items = items;
      state.ordersHistory.historyItem.returnPayment = calculateReturnProductsPrice(
        items,
        state?.ordersHistory?.historyItem?.amount,
        state?.ordersHistory?.historyItem?.paid
      );
    },

    changePaginationReturnedOrders: (state, action) => {
      state.returnedOrders.pagination[action.payload.name] = action.payload.value;
      action.payload.name === "pageSize" &&
        Math.ceil(state.returnedOrders.total / state.returnedOrders.pagination.pageSize) <=
          state.returnedOrders.pagination.page &&
        (state.returnedOrders.pagination.page = Math.ceil(
          state.returnedOrders.total / state.returnedOrders.pagination.pageSize
        ));
    },

    changeReturnedOrdersFilter: (state, action) => {
      state.returnedOrders.filter = action.payload;
    },

    // return product

    // ----------------------------------------------------------

    // debt order

    chooseDebtOrderClient: (state, action) => {
      state.debtOrders.client = action.payload;
    },

    changePaginationDebtOrders: (state, action) => {
      state.debtOrders.pagination[action.payload.name] = action.payload.value;
      action.payload.name === "pageSize" &&
        Math.ceil(state.total / state.debtOrders.pagination.pageSize) <=
          state.debtOrders.pagination.page &&
        (state.debtOrders.pagination.page = Math.ceil(
          state.debtOrders.total / state.debtOrders.pagination.pageSize
        ));
    },

    changeDebtOrdersFilter: (state, action) => {
      state.debtOrders.filter = action.payload;
    },

    // debt order

    // ----------------------------------------------------------
  },

  extraReducers: (builder) => {
    builder
      // --------------------- order

      .addCase(createOrder.fulfilled, (state, action) => {
        delete state.orders[action.payload.order_id];
        state.refresh = `create-order-${new Date()}`;
      })
      .addCase(getOrder.fulfilled, (state, action) => {
        state.ordersList.order = action.payload;
      })
      .addCase(getOrders.fulfilled, (state, action) => {
        state.ordersList.ordersList = action.payload.data;
        state.ordersList.total = action.payload.total;
      })

      // --------------------- order

      // --------------------- order history

      .addCase(getOrdersHistory.pending, (state, action) => {
        state.ordersHistory.isLoading = true;
      })
      .addCase(getOrdersHistory.fulfilled, (state, action) => {
        state.ordersHistory.histories = action.payload.data;
        state.ordersHistory.payment = action.payload.payment;
        state.ordersHistory.total = action.payload.total;
        state.ordersHistory.isLoading = false;
      })
      .addCase(getOrdersHistoryItem.pending, (state, action) => {
        state.ordersHistory.isLoading = true;
      })
      .addCase(getOrdersHistoryItem.fulfilled, (state, action) => {
        state.ordersHistory.isLoading = false;
        state.ordersHistory.historyItem = action.payload;
      })

      // --------------------- order history

      // --------------------- return order

      .addCase(returnOrder.fulfilled, (state, action) => {
        state.refresh = `return-order-${new Date()}`;
      })
      .addCase(getReturnedOrders.fulfilled, (state, action) => {
        state.returnedOrders.returnedOrdersList = action.payload.data;
        state.returnedOrders.total = action.payload.total;
      })

      // --------------------- return order

      // --------------------- debt order

      .addCase(getDebtOrders.fulfilled, (state, action) => {
        state.debtOrders.debts = action.payload.data;
        state.debtOrders.total = action.payload.total;
      })
      .addCase(paymentDebtOrder.fulfilled, (state) => {
        state.refresh = `payment-debt-order-${new Date()}`;
      });

    // --------------------- debt order
  },
});

const { reducer, actions } = orderSlice;

export const {
  // ------------ order

  addOrder,
  chooseClient,
  addOrderProduct,
  changeOrderProduct,
  removeOrderProduct,
  chooseActiveProduct,

  // ------------ order

  // ------------ order list

  changeOrderListFilter,
  changePaginationOrderList,

  // ------------ order list

  // ------------ order history

  changePaginationOrderHistory,

  // ------------ order history

  // ------------ return order

  chooseReturnProducts,
  changeReturnedOrdersFilter,
  changeReturnProductQuantity,

  // ------------ return order

  // ------------ debt order

  chooseDebtOrderClient,
  changeDebtOrdersFilter,
  changePaginationDebtOrders,

  // ------------ debt order
} = actions;

export default reducer;
