import { v4 } from "uuid";
import Icon from "assets/icons/icons";

function Checkbox({
  classNameCheckbox = "border-c7 p-3",
  classNameLabel = "text-c24",
  bgColor = "bg-c1",
  label,
  disabled = false,
  checked,
  onChange,
}) {
  const id = `checkbox-${v4()}-${label}`;

  return (
    <label htmlFor={id} className="flex gap-2 items-center">
      <input
        id={id}
        disabled={disabled}
        checked={checked}
        type="checkbox"
        onChange={onChange}
        className="hidden"
      />

      <div
        className={`flex justify-center items-center border w-5 h-5 ${
          checked ? "bg-c10" : bgColor
        } rounded opacity-${disabled ? "50" : "100"} ${classNameCheckbox}`}
      >
        {checked && <Icon name="tick" className="" />}
      </div>

      <span className={classNameLabel}>{label}</span>
    </label>
  );
}

export default Checkbox;
