import { useDispatch, useSelector } from "react-redux";
import { useState, useEffect } from "react";
import {
  getBrands,
  deleteBrand,
  deleteBrands,
  checkedBrand,
  checkedAllBrand,
  changePagination,
} from "store/slices/brandSlice";
import { checkingPermissions } from "assets/constants/constants";
import MUIPagination from "components/mui-pagination/MUIPagination";
import AdminNavbar from "layers/admin-navbar/AdminNavbar";
import MUIModal from "components/mui-modal/MUIModal";
import Checkbox from "components/checkbox/Checkbox";
import HtmlInput from "components/input/HtmlInput";
import MUIMenu from "components/mui-menu/MUIMenu";
import { Divider } from "@mui/material";
import CreateBrand from "./CreateBrand";
import DeleteBrand from "./DeleteBrand";
import Icon from "assets/icons/icons";

function Brands() {
  const dispatch = useDispatch();
  const {
    brands: { brands, total, checkedAll, checkedCount, pagination, refresh },
  } = useSelector((store) => store);
  const [openModal, setOpenModal] = useState({ open: false, action: "", brand: "" });
  const [openMenu, setOpenMenu] = useState({ anchorEl: null, brand: "" });

  const handleOpenMenu = (event, brand) => setOpenMenu({ anchorEl: event.currentTarget, brand });
  const handleCloseMenu = () => setOpenMenu({ ...openMenu, anchorEl: null });

  const handleOpenModal = (action, brand) => {
    setOpenModal({ open: true, action, brand });
    handleCloseMenu();
  };
  const handleCloseModal = () => setOpenModal({ ...openModal, open: false });

  const handleChangePagination = (name, value) => dispatch(changePagination({ name, value }));

  const handleCheckedAllBrand = () => dispatch(checkedAllBrand());

  const handleCheckedBrand = (id) => dispatch(checkedBrand(id));

  const handleDeleteBrand = () => {
    if (openModal?.action === "delete-brands") {
      const ids = [];
      brands?.map((brand) => brand?.checked && ids?.push(brand?.id));
      dispatch(deleteBrands(ids));
    } else dispatch(deleteBrand(openMenu?.brand?.id));
  };

  const handleGetBrands = (search = "") => dispatch(getBrands({ search, pagination }));

  useEffect(() => {
    handleGetBrands();
    handleCloseModal();
  }, [pagination, refresh]);

  return (
    <>
      <MUIModal
        position="center"
        hideBackdrop={false}
        open={openModal?.open}
        onClose={handleCloseModal}
      >
        {(openModal?.action === "create" || openModal?.action === "update") && (
          <CreateBrand
            color="c2"
            bgColor="c24"
            action={openModal?.action}
            onClose={handleCloseModal}
            currentBrand={openModal?.brand}
          />
        )}

        {(openModal?.action === "delete" || openModal?.action === "delete-brands") && (
          <DeleteBrand onDelete={handleDeleteBrand} onClose={handleCloseModal} />
        )}
      </MUIModal>

      <MUIMenu
        open={openMenu?.anchorEl ? true : false}
        handleClose={handleCloseMenu}
        anchorEl={openMenu?.anchorEl}
        children={
          <div className="flex flex-col gap-2 px-2" onClick={(event) => event.stopPropagation()}>
            {checkingPermissions(["BRAND.UPDATE"]) && (
              <div
                className="flex px-2 gap-3 cursor-pointer"
                onClick={() => handleOpenModal("update", openMenu?.brand)}
              >
                <Icon name="edit_gray" />
                <div className="text-c2">Tahrirlash</div>
              </div>
            )}

            {checkingPermissions(["BRAND.UPDATE", "BRAND.DELETE"]) && (
              <Divider sx={{ borderColor: "#E8ECF4" }} />
            )}

            {checkingPermissions(["BRAND.DELETE"]) && (
              <div
                className="flex px-2 gap-3 cursor-pointer"
                onClick={() => handleOpenModal("delete", openMenu?.brand)}
              >
                <Icon name="trash_red" />
                <span className="text-c14">O'chirish</span>
              </div>
            )}
          </div>
        }
      />

      <AdminNavbar
        rightElement={
          checkingPermissions(["BRAND.CREATE"]) ? (
            <button
              onClick={() => handleOpenModal("create", "")}
              className="h-10 bg-c10 px-4 flex justify-center items-center rounded-xl sm:p-0 sm:w-10"
            >
              <Icon name="add" />
              <div className="text-c24 sm:hidden">Yangi qo’shish</div>
            </button>
          ) : (
            <div className="w-10 h-10" />
          )
        }
      />

      <div className="text-c2 flex flex-col gap-4 p-4">
        <div className="bg-c24 border border-c32 rounded-lg">
          <div className="flex justify-between items-center border-b border-c32 px-4 py-2">
            <HtmlInput
              type="search"
              placeholder="Qidiruv"
              classNameInput="border h-10 rounded-xl outline-0 bg-c31 text-sm"
              onChange={(e) => handleGetBrands(e.target.value)}
            />
          </div>

          <div className="rounded-lg overflow-x-auto">
            <table
              className={`w-full border-collapse sm:m-0 sm:whitespace-nowrap ${
                !checkingPermissions(["BRAND.DELETE"]) ? "mb-0" : "mb-6"
              }`}
            >
              <thead>
                <tr className="text-c9 font-thin bg-c36">
                  <td className="py-3 pl-4 px-2">
                    {checkingPermissions(["BRAND.DELETE"]) ? (
                      <Checkbox
                        label="№"
                        bgColor="bg-c24"
                        checked={checkedAll}
                        classNameLabel="text-c9"
                        classNameCheckbox="text-c2"
                        onChange={handleCheckedAllBrand}
                      />
                    ) : (
                      "№"
                    )}
                  </td>
                  <td className="py-3 px-2">Nomi</td>
                  <td
                    className={`py-3 px-2 ${
                      !checkingPermissions(["BRAND.UPDATE"]) &&
                      !checkingPermissions(["BRAND.DELETE"])
                        ? "pr-4 text-end"
                        : ""
                    }`}
                  >
                    Mahsulotlar soni
                  </td>
                  {(checkingPermissions(["BRAND.UPDATE"]) ||
                    checkingPermissions(["BRAND.DELETE"])) && (
                    <td className="py-3 px-2 pr-4 text-end">Amallar</td>
                  )}
                </tr>
              </thead>

              <tbody className="overflow-y-auto">
                {brands?.map((brand, idx) => (
                  <tr key={brand?.id} className="even:bg-c36 odd:bg-white">
                    <td className="border-t border-c32 pl-4 py-3 px-2">
                      {checkingPermissions(["BRAND.DELETE"]) ? (
                        <Checkbox
                          bgColor="bg-c24"
                          classNameCheckbox="text-c2"
                          classNameLabel="text-c2"
                          checked={brand?.checked ? true : false}
                          onChange={() => handleCheckedBrand(brand?.id)}
                          label={Math.abs(idx + pagination.pageSize * (pagination.page - 1) + 1)}
                        />
                      ) : (
                        Math.abs(idx + pagination.pageSize * (pagination.page - 1) + 1)
                      )}
                    </td>
                    <td className="border-t border-c32 py-3 px-2">{brand?.name}</td>
                    <td
                      className={`border-t border-c32 py-3 px-2 ${
                        !checkingPermissions(["BRAND.UPDATE"]) &&
                        !checkingPermissions(["BRAND.DELETE"])
                          ? "pr-4 text-end"
                          : ""
                      }`}
                    >
                      {brand?.products_count || "-"}
                    </td>
                    {(checkingPermissions(["BRAND.UPDATE"]) ||
                      checkingPermissions(["BRAND.DELETE"])) && (
                      <td className="border-t border-c32 px-2 pr-4 text-end">
                        <button
                          className="w-10 h-10 border bg-c31 rounded-xl"
                          onClick={(e) => handleOpenMenu(e, brand)}
                        >
                          <Icon name="more_black" className="mx-auto" />
                        </button>
                      </td>
                    )}
                  </tr>
                ))}
              </tbody>
            </table>
          </div>

          <div className="bg-c36 border-t border-c32 px-4 pb-2 pt-6 relative sm:pt-2">
            <MUIPagination
              count={total}
              bgColor="c36"
              page={pagination?.page}
              pageSize={pagination?.pageSize}
              onChangePagination={(name, value) => handleChangePagination(name, value)}
            />
            {checkingPermissions(["BRAND.DELETE"]) && (
              <div className="bg-c24 flex items-center absolute left-1/2 top-[-35%] translate-x-[-50%] py-3 px-4 rounded-xl border border-c10 sm:hidden">
                <div className="px-4 border-r border-c32">
                  <span className="text-c9">Tanlandi: {checkedCount}</span>
                </div>

                <div
                  className={`flex gap-2 px-4 cursor-pointer ${
                    checkedCount ? "opacity-100" : "opacity-50"
                  }`}
                  onClick={() => handleOpenModal("delete-brands", "")}
                >
                  <Icon name="trash_red" />
                  <span className={checkedCount ? "text-c1" : "text-c9"}>O’chirish</span>
                </div>
              </div>
            )}
          </div>
        </div>
      </div>
    </>
  );
}

export default Brands;
