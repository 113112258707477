import { useMemo } from "react";
import { ResponsiveContainer, PieChart, Legend, Pie, Cell, Label } from "recharts";

const renderColorfulLegendText = (value, entry) => {
  return (
    <span className="pr-2 color-dark">
      {value} {entry?.payload?.value} UZS
    </span>
  );
};

const PieCharts = () => {
  const data = [
    { name: "Naqd", value: 9000000 },
    { name: "Plastik", value: 2000000 },
  ];

  const COLORS = ["#359F46", "#BC9D30"];

  const chartData = useMemo(() => {
    return data;
  }, [data]);

  return (
    <ResponsiveContainer className="flex items-start p-0" width={"100%"} height={"100%"}>
      <PieChart data={chartData} width={"100%"} height={500}>
        <Legend
          // height={10}
          iconType="circle"
          layout="vertical"
          verticalAlign="bottom"
          iconSize={15}
          padding={20}
          formatter={renderColorfulLegendText}
        />
        <Pie
          data={chartData}
          dataKey="value"
          cx="50%"
          cy="50%"
          outerRadius={160}
          innerRadius={130}
          labelLine={false}
        >
          <Label value="Sof foyda" position="center" dy={-15} fill="var(--c9)" />
          <Label value="10 000 000" position="center" dy={15} fill="var(--c2)" />

          {chartData.map((entry, index) => (
            <Cell key={`cell-${index}`} fill={COLORS[index % COLORS.length]} />
          ))}
        </Pie>
      </PieChart>
    </ResponsiveContainer>
  );
};

export default PieCharts;
