import { useEffect, useState } from "react";
import { useLocation } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import {
  createPermission,
  updatePermission,
  checkedPermission,
  getPermissionsByGroup,
  getDefaultPermissions,
  checkedPermissionsDefaultValue,
} from "store/slices/permissionSlice";
import { MUITreeItem } from "components/mui-tree/MUITree";
import { MUITree } from "components/mui-tree/MUITree";
import Checkbox from "components/checkbox/Checkbox";
import HtmlInput from "components/input/HtmlInput";
import Select from "components/select/Select";

function CreatePermission({ action, currentPermission, onChangedPermissions }) {
  const dispatch = useDispatch();
  const { pathname } = useLocation();
  const {
    permissions: { defaultPermissions, permissionsGroups, checkedAll, checkedPermissionsCount },
  } = useSelector((store) => store);
  const [expanded, setExpanded] = useState([]);

  const [permission, setPermission] = useState({ name: "" });
  const [permissionTemplate, setPermissionTemplate] = useState({});

  const handleChangePermission = (name, value) => setPermission({ ...permission, [name]: value });

  const handleOpenTreeItem = (name) => {
    expanded.includes(name)
      ? setExpanded(expanded.filter((ex) => ex !== name))
      : setExpanded([...expanded, name]);
  };

  const handleCheckedPermission = (group_name, modul_name, permission_value) => {
    dispatch(checkedPermission({ group_name, modul_name, permission_value }));
    setPermissionTemplate({});
  };

  const handleChangePermissionTemplate = (template_name) => {
    if (template_name)
      defaultPermissions?.map((permission) => {
        if (permission?.name === template_name) {
          setPermissionTemplate(permission);
          dispatch(checkedPermissionsDefaultValue(permission?.permissions));
        }
      });
    else {
      setPermissionTemplate({});
      dispatch(checkedPermissionsDefaultValue([]));
    }
  };

  const handleAdd = () => {
    const permissions = [];
    permissionsGroups?.map((group) =>
      group?.moduls?.map((modul) =>
        modul?.permissions?.map(
          (permission) => permission?.checked && permissions.push(permission?.value)
        )
      )
    );
    pathname?.split("/")?.[2] === "users"
      ? onChangedPermissions(permissions)
      : action === "create"
      ? dispatch(createPermission({ name: permission?.name, permissions }))
      : dispatch(
          updatePermission({
            id: currentPermission?.id,
            permission: { name: permission?.name || currentPermission?.name, permissions },
          })
        );
  };

  useEffect(() => {
    action === "create" && checkedPermissionsCount && dispatch(getPermissionsByGroup());
    dispatch(getDefaultPermissions());
  }, []);

  useEffect(() => {
    action === "update" &&
      currentPermission?.permissions?.length &&
      dispatch(checkedPermissionsDefaultValue(currentPermission?.permissions));
  }, []);

  return (
    <div className="w-[30rem] h-full bg-c24 text-c2 sm:w-screen">
      <div className="h-[10rem] p-4 flex flex-col justify-between gap-2">
        <p className="text-2xl">{action === "create" ? "Rol qo'shish" : "Rolni tahrirlash"}</p>
        <div className="flex w-full justify-between gap-2">
          <HtmlInput
            label="Nomi"
            value={
              action === "create" ? permission?.name : permission?.name || currentPermission?.name
            }
            placeholder="Ruxsat nomini kiriting"
            className="w-1/2"
            onChange={(e) => handleChangePermission("name", e.target.value)}
          />

          <Select
            label="Shablon"
            firstOptionDisabled={true}
            options={defaultPermissions}
            classNameLabel="text-sm w-1/2"
            value={permissionTemplate?.name || ""}
            optionsLabel={{ label: "name", value: "name" }}
            onClear={(e) => handleChangePermissionTemplate("")}
            onChange={(e) => handleChangePermissionTemplate(e?.target?.value)}
            classNameSelect="text-sm text-c2 border border-c32 px-2 w-full h-10 rounded-xl outline-none bg-arrow-down-gray bg-no-repeat bg-right bg-[center_right_0.24rem] select-arrow-none"
          />
        </div>

        <div className="flex justify-between items-center">
          <span className="text-lg font-bolder">Ruxsatlar ro'yxati</span>
          <Checkbox
            bgColor="bg-c24"
            classNameLabel="text-c2"
            classNameCheckbox="w-5 h-5"
            checked={checkedAll}
            label="Barcha ruxsatlarni berish"
            onChange={() => handleCheckedPermission()}
          />
        </div>
      </div>

      <div className="h-[calc(100%-14rem)] overflow-y-auto">
        <MUITree expanded={expanded} onSelect={(e) => handleOpenTreeItem(e)}>
          {permissionsGroups?.map((groups, idx1) => (
            <MUITreeItem
              key={idx1}
              nodeId={`${idx1}`}
              label={
                <p className="flex items-center gap-2">
                  <span>{groups?.name}</span>
                  {groups?.checkedPermissionsCount ? (
                    <span className="bg-c10 text-c24 text-xs h-[1.25rem] flex justify-center items-center px-2 rounded-full">
                      {groups?.checkedPermissionsCount}
                    </span>
                  ) : (
                    ""
                  )}
                </p>
              }
            >
              <Checkbox
                bgColor="bg-c24"
                label="checked all"
                classNameLabel="text-c2"
                classNameCheckbox="w-5 h-5 ml-4"
                checked={groups?.checkedAll ? true : false}
                onChange={() => handleCheckedPermission(groups?.name)}
              />
              {groups?.moduls?.map((modul, idx2) => (
                <MUITreeItem
                  key={idx2}
                  nodeId={`${idx1}-${idx2}`}
                  label={
                    <p className="flex items-center gap-2">
                      <span>{modul?.name}</span>
                      {modul?.checkedPermissionsCount ? (
                        <span className="bg-c10 text-c24 text-xs h-[1.25rem] flex justify-center items-center px-2 rounded-full">
                          {modul?.checkedPermissionsCount}
                        </span>
                      ) : (
                        ""
                      )}
                    </p>
                  }
                >
                  <Checkbox
                    bgColor="bg-c24"
                    label="checked all"
                    classNameLabel="text-c2"
                    classNameCheckbox="w-5 h-5 ml-4"
                    checked={modul?.checkedAll ? true : false}
                    onChange={() => handleCheckedPermission(groups?.name, modul?.name)}
                  />
                  {modul?.permissions?.map((permission, idx3) => (
                    <Checkbox
                      bgColor="bg-c24"
                      key={idx3}
                      label={permission?.name}
                      classNameLabel="text-c2"
                      classNameCheckbox="w-5 h-5 ml-4"
                      onChange={() =>
                        handleCheckedPermission(groups?.name, modul?.name, permission?.value)
                      }
                      checked={permission?.checked ? true : false}
                    />
                  ))}
                </MUITreeItem>
              ))}
            </MUITreeItem>
          ))}
        </MUITree>
      </div>
      <div className="h-[4rem] flex items-center gap-2 px-4 py-1 absolute bottom-0 w-full">
        <button
          onClick={handleAdd}
          disabled={checkedPermissionsCount ? false : true}
          className={`w-full bg-c10 text-c24 px-4 py-3 rounded-xl ${
            !checkedPermissionsCount ? "opacity-50" : ""
          }`}
        >
          Qo'shish
        </button>
      </div>
    </div>
  );
}

export default CreatePermission;
