import { Outlet } from "react-router-dom";
import Icon from "assets/icons/icons";

// Dashboard Page
import Dashboard from "pages/dashboard/Dashboard";

// Sales Pages
import Orders from "./pages/orders/orders/Orders";
import OrderProducts from "./pages/orders/orders/OrderProducts";
import ReturnedOrders from "pages/orders/returned-orders/ReturnedOrders";
import ReturnedOrderProducts from "pages/orders/returned-orders/ReturnedOrderProducts";
import DebtOrders from "./pages/orders/debts-orders/DebtOrders";

// Income Pages
import RemainingProducts from "./pages/income/remaining-products/RemainingProducts";
import Income from "./pages/income/income/Income";
import Incomes from "./pages/income/income/Incomes";
import IncomeProducts from "./pages/income/income/IncomeProducts";
import ReturnedIncomes from "pages/income/return-income/ReturnedIncomes";
import ReturnIncomeProducts from "pages/income/return-income/ReturnIncomeProducts";
import ReturnedIncomeProducts from "pages/income/return-income/ReturnedIncomeProducts";
import Suppliers from "./pages/income/suppliers/Suppliers";

// Product Settings Pages
import Products from "pages/product-settings/products/Products";
import Categories from "pages/product-settings/categories/Categories";
import Brands from "pages/product-settings/brands/Brands";
import Block from "pages/product-settings/blocks/Block";
import Units from "pages/product-settings/units/Units";

// Discounts pages
import Discounts from "pages/discounts/Discounts";

// Users pages
import Users from "pages/users/users/Users";
import Customers from "pages/users/customer/Customers";
import Permissions from "pages/users/permissions/Permissions";

// Pos page
import Home from "pages/home/Home";
import Debts from "pages/debt/Debts";
import CloseDay from "pages/close-day/CloseDay";
import OrdersHistory from "layers/orders-history/OrdersHistory";

export const ADMIN_ROUTES = [
  {
    key: "dashboard",
    type: "screen",
    name: "Asosiy",
    icon: <Icon name="home" />,
    route: "dashboard",
    component: <Dashboard />,
    noCollapse: true,
  },

  {
    key: "orders-index",
    type: "screen",
    name: "Savdo",
    icon: <Icon name="sales" />,
    route: "order",
    component: <Outlet />,
    noCollapse: false,
    // permissions: []
    collapses: [
      {
        key: "orders",
        type: "screen",
        name: "Savdo",
        icon: <Icon name="sales" />,
        route: "orders",
        component: <Outlet />,
        noCollapse: false,
        permissions: ["ORDER.READ"],
        collapses: [
          {
            key: "orders",
            type: "screen",
            name: "Savdo",
            icon: <Icon name="sales" />,
            route: "",
            component: <Orders />,
            noCollapse: true,
            permissions: ["ORDER.READ"],
          },

          {
            key: "id",
            type: "no-screen",
            name: "Savdo haqida",
            icon: <Icon name="sales" />,
            route: ":id",
            component: <OrderProducts />,
            noCollapse: true,
            permissions: ["ORDER.READ"],
          },
        ],
      },

      {
        key: "returned-orders-index",
        type: "screen",
        name: "Qaytarilgan tovarlar",
        icon: <Icon name="sales" />,
        route: "returned",
        component: <Outlet />,
        noCollapse: false,
        permissions: ["RETURN_ORDER.READ"],
        collapses: [
          {
            key: "returned-orders",
            type: "screen",
            name: "Qaytarilgan tovarlar",
            icon: <Icon name="sales" />,
            route: "",
            component: <ReturnedOrders />,
            noCollapse: true,
            permissions: ["RETURN_ORDER.READ"],
          },
          {
            key: ":id",
            type: "no-screen",
            name: "Qaytarilgan mahsulotlar haqida",
            route: ":id",
            component: <ReturnedOrderProducts />,
            noCollapse: true,
            permissions: ["RETURN_ORDER.READ"],
          },
        ],
      },

      {
        key: "debts",
        type: "screen",
        name: "Nasiyalar",
        icon: <Icon name="sales" />,
        route: "debts",
        component: <DebtOrders />,
        noCollapse: true,
        permissions: ["ORDER_DEBT.READ"],
      },
    ],
  },

  {
    key: "income-index",
    type: "screen",
    name: "Mahsulotlar kirimi",
    icon: <Icon name="income" />,
    route: "income",
    component: <Outlet />,
    noCollapse: false,
    collapses: [
      {
        key: "remaining-products",
        type: "screen",
        name: "Umumiy qoldiq",
        route: "remaining-products",
        component: <RemainingProducts />,
        noCollapse: true,
        permissions: ["PRODUCT.READ"],
      },

      {
        key: "incomes",
        type: "screen",
        name: "Kirim qilish",
        route: "incomes",
        component: <Outlet />,
        noCollapse: false,
        permissions: ["INCOME.READ"],
        collapses: [
          {
            key: "incomes",
            type: "screen",
            name: "Kirim qilish",
            route: "",
            component: <Incomes />,
            noCollapse: true,
            permissions: ["INCOME.READ"],
          },

          {
            key: ":id",
            type: "no-screen",
            name: "Batafsil ma’lumot",
            route: ":id",
            component: <IncomeProducts />,
            noCollapse: true,
            permissions: ["INCOME.READ"],
          },

          {
            key: "income",
            type: "no-screen",
            name: "Yangi kirim",
            route: "income",
            component: <Income />,
            noCollapse: true,
            permissions: [
              "INCOME.READ",
              "INCOME.CREATE",
              "SUPPLIER.READ",
              "CATEGORY.READ",
              "PRODUCT.READ",
            ],
          },
        ],
      },

      {
        key: "returned",
        type: "screen",
        name: "Qaytarilgan mahsulotlar",
        icon: <Icon name="sales" />,
        route: "returned",
        component: <Outlet />,
        noCollapse: false,
        permissions: ["RETURN_INCOME.READ"],
        collapses: [
          {
            key: "returned-incomes",
            type: "screen",
            name: "Qaytarilgan mahsulotlar",
            route: "",
            component: <ReturnedIncomes />,
            noCollapse: true,
            permissions: ["RETURN_INCOME.READ"],
          },

          {
            key: "return-income-products",
            type: "no-screen",
            name: "Mahsulot qaytarish",
            route: "returning",
            component: <ReturnIncomeProducts />,
            noCollapse: true,
            permissions: [
              "PRODUCT.READ",
              "CATEGORY.READ",
              "SUPPLIER.READ",
              "RETURN_INCOME.READ",
              "RETURN_INCOME.CREATE",
            ],
          },

          {
            key: ":id",
            type: "no-screen",
            name: "Batafsil ma’lumot",
            route: ":id",
            component: <ReturnedIncomeProducts />,
            noCollapse: true,
            permissions: ["RETURN_INCOME.READ"],
          },
        ],
      },

      {
        key: "suppliers",
        type: "screen",
        name: "Yetkazib beruvchilar",
        route: "suppliers",
        component: <Suppliers />,
        noCollapse: true,
        permissions: ["SUPPLIER.READ"],
      },
    ],
  },

  {
    type: "no-screen",
    name: "Chegirma",
    key: "discount",
    route: "discount",
    icon: <Icon name="discount" />,
    component: <Discounts />,
    noCollapse: true,
  },

  {
    type: "screen",
    name: "Mahsulot sozlamalari",
    key: "product-settings",
    route: "product-settings",
    icon: <Icon name="basket" />,
    component: <Outlet />,
    noCollapse: false,
    collapses: [
      {
        key: "",
        type: "screen",
        name: "Mahsulotlar",
        route: "products",
        component: <Products />,
        noCollapse: true,
        permissions: ["PRODUCT.READ"],
      },

      {
        type: "screen",
        name: "Kategoriyalar",
        key: "categories",
        route: "categories",
        component: <Categories />,
        noCollapse: true,
        permissions: ["CATEGORY.READ"],
      },

      {
        type: "screen",
        name: "Brend",
        key: "brands",
        route: "brands",
        component: <Brands />,
        noCollapse: true,
        permissions: ["BRAND.READ"],
      },

      {
        type: "screen",
        name: "O'lchov birliklari",
        key: "unit-types",
        route: "unit-types",
        component: <Units />,
        noCollapse: true,
        permissions: ["UNIT.READ"],
      },

      {
        type: "screen",
        name: "Blok",
        key: "block",
        route: "block",
        component: <Block />,
        noCollapse: true,
        permissions: ["PACKAGE.READ"],
      },
    ],
  },

  {
    type: "screen",
    name: "Foydalanuvchilar",
    key: "users",
    route: "users",
    icon: <Icon name="users" />,
    component: <Outlet />,
    noCollapse: false,
    collapses: [
      {
        type: "screen",
        name: "Xodimlar",
        key: "users",
        route: "employees",
        icon: <Icon name="sales" />,
        component: <Users />,
        noCollapse: true,
        permissions: ["USER.READ"],
      },

      {
        type: "screen",
        name: "Mijozlar",
        key: "customer",
        route: "customer",
        icon: <Icon name="sales" />,
        component: <Customers />,
        noCollapse: true,
        permissions: ["CUSTOMER.READ"],
      },

      {
        type: "screen",
        name: "Ruxsatlar",
        key: "permissions",
        route: "permissions",
        icon: <Icon name="sales" />,
        component: <Permissions />,
        noCollapse: true,
        permissions: ["ROLE.READ"],
      },
    ],
  },
];

export const OPERATOR_ROUTES = [
  {
    key: "home",
    type: "screen",
    name: "Asosiy",
    route: "home",
    component: <Home />,
    noCollapse: false,
    permissions: [
      "CATEGORY.READ",
      "PRODUCT.READ",
      "ORDER.CREATE",
      "ORDER.READ",
      "SESSION.OPEN",
      "SESSION.CLOSE",
    ],
    collapses: [
      {
        key: "id",
        type: "screen",
        name: "Asosiy",
        route: ":id",
        component: <Home />,
        noCollapse: true,
      },
    ],
  },

  {
    key: "income",
    type: "screen",
    name: "Kirim qilish",
    route: "income",
    component: <Home />,
    noCollapse: true,
    permissions: ["INCOME.CREATE"],
  },

  {
    key: "debts",
    type: "screen",
    name: "Nasiyalar",
    route: "debts",
    component: <Debts />,
    noCollapse: true,
    permissions: ["ORDER_DEBT.READ"],
  },

  {
    key: "history",
    type: "screen",
    name: "Savdo tarixi",
    route: "history",
    component: <OrdersHistory />,
    noCollapse: true,
  },

  {
    key: "close-day",
    type: "screen",
    name: "Savdo tarixi",
    route: "close-day",
    component: <CloseDay />,
    permissions: ["ORDER_HISTORY.READ", "SESSION.CLOSE"],
    noCollapse: true,
  },
];
