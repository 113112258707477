const Card = ({ title, chart }) => {
  return (
    <div className="bg-c24 flex flex-col rounded-xl p-4">
      <div className="flex gap-6 items-center">
        <span className="text-c2 text-lg">{title}</span>

        {title === "Statistika" && (
          <div className="flex gap-4 items-center px-4">
            <div className="flex gap-2 items-center">
              <div className="bg-c17 w-4 h-4 rounded-full" />
              <span className="text-c34 text-sm">Naqd</span>
            </div>

            <div className="flex gap-2 items-center">
              <div className="bg-c10 w-4 h-4 rounded-full" />
              <span className="text-c34 text-sm">Naqd</span>
            </div>
          </div>
        )}
      </div>

      <div className="w-full h-[25rem] flex items-center">{chart}</div>
    </div>
  );
};

export default Card;
