import { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import {
  getPermissions,
  deletePermission,
  changePagination,
  getPermissionsByGroup,
} from "store/slices/permissionSlice";
import { checkingPermissions } from "assets/constants/constants";
import MUIPagination from "components/mui-pagination/MUIPagination";
import AdminNavbar from "layers/admin-navbar/AdminNavbar";
import MUIDrawer from "components/mui-drawer/MUIDrawer";
import MUIModal from "components/mui-modal/MUIModal";
import HtmlInput from "components/input/HtmlInput";
import MUIMenu from "components/mui-menu/MUIMenu";
import DeletePermission from "./DeletePermission";
import CreatePermission from "./CreatePermission";
import { Divider } from "@mui/material";
import Icon from "assets/icons/icons";

function Permissions() {
  const dispatch = useDispatch();
  const {
    permissions: { permissions, total, pagination, refresh },
  } = useSelector((store) => store);
  const [openMenu, setOpenMenu] = useState({ anchorEl: null, permission: null });
  const [openDrawer, setOpenDrawer] = useState({ open: false, action: "" });
  const [openModal, setOpenModal] = useState({ open: false, action: "" });

  const handleOpenMenu = (e, permission) => setOpenMenu({ anchorEl: e.currentTarget, permission });

  const handleCloseMenu = () => setOpenMenu({ ...openMenu, anchorEl: null });

  const handleOpenDrawer = (action) => {
    handleCloseMenu();
    setOpenDrawer({ open: true, action });
  };

  const handleCloseDrawer = () => setOpenDrawer({ ...openDrawer, open: false });

  const handleOpenModal = (action) => {
    handleCloseMenu();
    setOpenModal({ open: true, action });
  };

  const handleCloseModal = () => setOpenModal({ ...openDrawer, open: false });

  const handleChangePagination = (name, value) => dispatch(changePagination({ name, value }));

  const handleDeletePermission = () => dispatch(deletePermission(openMenu?.permission?.id));

  useEffect(() => {
    dispatch(getPermissionsByGroup());
  }, []);

  const handleGetPermissions = (search = "") => dispatch(getPermissions({ search, pagination }));

  useEffect(() => {
    handleCloseModal();
    handleCloseDrawer();
    handleGetPermissions();
  }, [pagination, refresh]);

  return (
    <>
      <MUIDrawer open={openDrawer?.open} onClose={handleCloseDrawer} bgColor="c24">
        <CreatePermission action={openDrawer?.action} currentPermission={openMenu?.permission} />
      </MUIDrawer>

      <MUIModal position="center" open={openModal?.open} onClose={handleCloseModal}>
        <DeletePermission onDelete={handleDeletePermission} onClose={handleCloseModal} />
      </MUIModal>

      <MUIMenu
        handleClose={handleCloseMenu}
        anchorEl={openMenu?.anchorEl}
        open={openMenu?.anchorEl ? true : false}
        children={
          <div className="flex flex-col gap-2 px-2" onClick={(event) => event.stopPropagation()}>
            {checkingPermissions(["ROLE.UPDATE"]) && (
              <div
                className="flex px-2 gap-3 cursor-pointer"
                onClick={() => handleOpenDrawer("update")}
              >
                <Icon name="edit_gray" />
                <div className="text-c2">Tahrirlash</div>
              </div>
            )}

            {checkingPermissions(["ROLE.UPDATE", "ROLE.DELETE"]) && (
              <Divider sx={{ borderColor: "#E8ECF4" }} />
            )}

            {checkingPermissions(["ROLE.DELETE"]) && (
              <div
                className="flex px-2 gap-3 cursor-pointer"
                onClick={() => handleOpenModal("delete")}
              >
                <Icon name="trash_red" />
                <span className="text-c14">O'chirish</span>
              </div>
            )}
          </div>
        }
      />

      <AdminNavbar
        rightElement={
          checkingPermissions(["ROLE.CREATE"]) ? (
            <div
              onClick={() => handleOpenDrawer("create")}
              className="bg-c10 py-2 px-4 sm:p-1 flex items-center gap-1 rounded-lg cursor-pointer"
            >
              <Icon name="add" />
              <span className="text-c24 sm:hidden">Rol qo'shish</span>
            </div>
          ) : (
            <div className="w-10 h-10" />
          )
        }
      />

      <div className="bg-c24 m-4 border border-c32 rounded-lg overflow-hidden">
        <div className="flex justify-between items-center border-b border-c32 px-4 py-2">
          <HtmlInput
            type="search"
            placeholder="Qidiruv"
            onChange={(e) => handleGetPermissions(e.target.value)}
            classNameInput="border h-10 rounded-xl outline-0 bg-c31 text-sm"
          />
        </div>
        <div className="bg-c24 text-c2 rounded-lg overflow-x-auto">
          <table className="sm:whitespace-nowrap w-full border-collapse even:bg-c36 odd:bg-white">
            <thead>
              <tr className="text-c9 font-thin bg-c36">
                <td className="px-2 py-3 pl-4">№</td>
                <td className="px-2 py-3">Nomi</td>
                <td
                  className={`px-2 py-3 ${
                    !checkingPermissions(["ROLE.UPDATE"]) && !checkingPermissions(["ROLE.DELETE"])
                      ? "pr-4 text-end"
                      : ""
                  }`}
                >
                  Ruxsatlar soni
                </td>
                {(checkingPermissions(["ROLE.UPDATE"]) || checkingPermissions(["ROLE.DELETE"])) && (
                  <td className="px-2 py-3 pr-4 text-end">Amallar</td>
                )}
              </tr>
            </thead>

            <tbody>
              {permissions?.map((permission, idx) => (
                <tr key={permission?.id}>
                  <td className="border-t border-c32 px-2 py-3 pl-4">
                    {Math.abs(idx + pagination.pageSize * (pagination.page - 1) + 1)}
                  </td>
                  <td className="border-t border-c32 px-2 py-3">{permission?.name}</td>
                  <td
                    className={`border-t border-c32 px-2 py-3 ${
                      !checkingPermissions(["ROLE.UPDATE"]) && !checkingPermissions(["ROLE.DELETE"])
                        ? "pr-4 text-end"
                        : ""
                    }`}
                  >
                    {permission?.permissions?.length}
                  </td>
                  {(checkingPermissions(["ROLE.UPDATE"]) ||
                    checkingPermissions(["ROLE.DELETE"])) && (
                    <td className="border-t border-c32 px-2 pr-4 text-end">
                      <button
                        className="w-10 h-10 border bg-c31 rounded-xl"
                        onClick={(e) => handleOpenMenu(e, permission)}
                      >
                        <Icon name="more_black" className="mx-auto" />
                      </button>
                    </td>
                  )}
                </tr>
              ))}
            </tbody>
          </table>
        </div>

        <div className="border-t border-c32 px-4 py-2">
          <MUIPagination
            count={total}
            page={pagination?.page}
            pageSize={pagination?.pageSize}
            onChangePagination={(name, value) => handleChangePagination(name, value)}
          />
        </div>
      </div>
    </>
  );
}

export default Permissions;
