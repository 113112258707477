import { useState } from "react";
import { useDispatch } from "react-redux";
import { REFACTOR_SUM } from "assets/constants/constants";
import { paymentDebtOrder } from "store/slices/orderSlice";
import HtmlInput from "components/input/HtmlInput";
import Select from "components/select/Select";

const PaymentDebtOrder = ({ client_id, order_id, amount = "", paid = "" }) => {
  const dispatch = useDispatch();
  const [debt, setDebt] = useState({ amount: "", type: "card" });

  const handleChange = (name, value) => {
    setDebt({ ...debt, [name]: value });
  };

  const handleSave = () => {
    debt?.amount &&
      debt?.type &&
      dispatch(paymentDebtOrder({ ...debt, user_id: client_id, order_id: order_id }));
  };

  return (
    <div className="bg-c24 w-[25rem] h-max sm:w-[90vw] p-6 rounded-lg flex flex-col gap-4">
      <span className="text-c2 text-2xl">Nasiyani yopish</span>

      <HtmlInput
        color="c2"
        value={debt?.amount}
        label="Summa kiritish *"
        placeholder="Kiriting..."
        classNameInput="bg-c24 w-full h-10 p-2 rounded-xl text-c2 text-sm border border-c32 outline-0"
        onChange={(e) => handleChange("amount", e.target.value)}
      />

      <Select
        value={debt?.type}
        label="To’lov turi *"
        classNameLabel="text-c2 text-sm"
        options={[
          { id: 1, label: "Karta", value: "card" },
          { id: 2, label: "Naqd", value: "cash" },
        ]}
        onChange={(e) => handleChange("type", e.target.value)}
        classNameSelect="bg-c24 w-full h-10 p-2 rounded-xl text-c2 border border-c32 outline-none bg-arrow-down-gray bg-no-repeat bg-right bg-[center_right_0.24rem] select-arrow-none"
      />

      <div className="flex flex-col gap-2">
        <div className="flex justify-between">
          <span className="text-c9">Umumiy qarzi</span>
          <span className="text-c2">{REFACTOR_SUM(amount)} UZS</span>
        </div>

        {paid ? (
          <div className="flex justify-between">
            <span className="text-c9">To'landi</span>
            <span className="text-c17">{REFACTOR_SUM(paid)} UZS</span>
          </div>
        ) : (
          ""
        )}

        {amount - paid ? (
          <div className="flex justify-between">
            <span className="text-c9">Qoldiq</span>
            <span className="text-c14">{amount && REFACTOR_SUM(amount - paid)} UZS</span>
          </div>
        ) : (
          ""
        )}
      </div>

      <button
        onClick={handleSave}
        className={`bg-c10 h-10 text-c24 flex items-center justify-center rounded-xl px-3 ${
          !debt?.amount ? "opacity-50 cursor-not-allowed" : ""
        }`}
      >
        Saqlash
      </button>
    </div>
  );
};

export default PaymentDebtOrder;
