import { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import { closeSession } from "store/slices/authSlice";
import { useDispatch, useSelector } from "react-redux";
import { REFACTOR_SUM } from "assets/constants/constants";
import { checkingPermissions } from "assets/constants/constants";
import { getOrdersHistory, changePaginationOrderHistory } from "store/slices/orderSlice";
import MUIPagination from "components/mui-pagination/MUIPagination";
import MUIDrawer from "components/mui-drawer/MUIDrawer";
import Checkbox from "components/checkbox/Checkbox";
import CheckList from "pages/close-day/CheckList";
import Icon from "assets/icons/icons";

const paymentTypeOptions = {
  card: {
    bgColor: "bg-c37",
    color: "text-c10",
    icon: "cardBlue",
    label: "Karta",
  },
  cash: {
    bgColor: "bg-c38",
    color: "text-c17",
    icon: "paymentGreen",
    label: "Naqd",
  },
  debt: {
    bgColor: "bg-c41",
    color: "text-c14",
    icon: "shoppingRed",
    label: "Nasiya",
  },
  other: {
    bgColor: "bg-c39",
    color: "text-c21",
    icon: "otherYellow",
    label: "Boshqa",
  },
};

function CloseDay() {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const {
    orders: {
      ordersHistory: { histories, payment, total, pagination },
    },
  } = useSelector((store) => store);
  const [openDrawer, setOpenDrawer] = useState({ order: null, open: false });
  const [withCard, setWithCard] = useState(false);

  const handleOpenDrawer = (order) => setOpenDrawer({ order, open: true });
  const handleCloseDrawer = () => setOpenDrawer({ ...openDrawer, open: false });

  const handleChoosePaymentType = (type) => {
    if (type.toLowerCase() === "naqd") return "payment";
    else if (type.toLowerCase() === "karta") return "creditCard";
    else if (type.toLowerCase() === "nasiya") return "shopping";
    return "more";
  };

  const handleCloseSession = () => {
    dispatch(closeSession());
    navigate("/home");
  };

  const handleChangePagination = (name, value) =>
    dispatch(changePaginationOrderHistory({ name, value }));

  useEffect(() => {
    dispatch(getOrdersHistory({ pagination }));
  }, [pagination]);

  return (
    <>
      <MUIDrawer open={openDrawer?.open} onClose={handleCloseDrawer}>
        <CheckList currentOrder={openDrawer?.order} />
      </MUIDrawer>
      <div className="bg-c2 text-c24 text-sm h-full overflow-y-auto flex items-start gap-1 p-1 sm:flex-col sm:p-0">
        <div className="bg-c1 w-[30%] h-auto flex flex-col gap-4 rounded p-3 sm:w-full sm:rounded-none">
          <p className="text-lg">Xisobot</p>
          <div className="flex justify-between">
            <div className="flex items-center gap-2 opacity-50">
              <Icon name="payment" /> Naqd
            </div>
            <span>{payment?.cash ? `${REFACTOR_SUM(payment?.cash)} UZS` : "-"}</span>
          </div>
          <div className="flex justify-between">
            <div className="flex items-center gap-2 opacity-50">
              <Icon name="creditCard" /> Karta
            </div>
            <span>{payment?.card ? `${REFACTOR_SUM(payment?.card)} UZS` : "-"}</span>
          </div>
          <div className="flex justify-between">
            <span className="opacity-50">Umumiy savdo</span>
            <span>
              {payment?.cash || payment?.card || payment?.debt
                ? `${REFACTOR_SUM(
                    (payment?.cash || 0) + (payment?.card || 0) + (payment?.debt || 0)
                  )} UZS`
                : "-"}
            </span>
          </div>
          <hr className="border-0_5 border-c26" />
          <div className="flex items-center justify-between">
            <div className="flex items-center gap-2 opacity-50">
              <Icon name="shopping" /> Nasiya
            </div>
            <div className="text-end">
              <p className="text-sm">+30 000 UZS</p>
              <p className="text-c14">
                {payment?.debt ? `-${REFACTOR_SUM(payment?.debt)} UZS` : "-"}
              </p>
            </div>
          </div>
          <div className="bg-c2 flex flex-col items-center justify-center gap-2 p-4 rounded">
            <p className="opacity-50">Kassada bo'lishi kerak</p>
            <p className="text-xl">
              {payment?.cash
                ? `${REFACTOR_SUM(
                    withCard ? (payment?.cash || 0) + (payment?.card || 0) : payment?.cash
                  )} UZS`
                : "-"}
            </p>
            <div className="bg-c1 p-4 rounded">
              <Checkbox
                checked={withCard}
                label="Kartani qo'shib hisoblash"
                onChange={() => setWithCard(!withCard)}
                classNameCheckbox="text-c24 border-c7 p-3 w-2 h-2"
              />
            </div>
          </div>
          <button
            onClick={handleCloseSession}
            className="bg-c10 p-4 flex justify-center items-center rounded"
          >
            Kassani yopish
          </button>
        </div>

        <div className="bg-c1 w-[70%] h-full rounded sm:w-full overflow-hidden sm:rounded-none">
          <div className="h-[calc(100%-3rem)] overflow-y-auto sm:max-h-[150%]">
            <table className="w-full border-collapse">
              <thead className="bg-c1 outline outline-c7 sticky top-0 text-c9">
                <tr>
                  <td className="pl-4 px-2 py-4">№</td>
                  <td className="py-4 px-2">Vaqti</td>
                  <td className="py-4 px-2">Mahsulot soni</td>
                  <td className="py-4 px-2">Turi</td>
                  <td className="py-4 px-2">To'landi</td>
                  <td className="py-4 px-2">Nasiya</td>
                  <td className="py-4 px-2 text-end pr-4">Narxi</td>
                </tr>
              </thead>
              <tbody>
                {histories?.map((order, idx) => (
                  <tr
                    key={order?.id}
                    onClick={() =>
                      checkingPermissions(["ORDER.HISTORY"]) && handleOpenDrawer(order)
                    }
                  >
                    <td className="border-b border-c7 py-4 px-2 pl-4">{idx + 1}</td>
                    <td className="border-b border-c7 py-4 px-2">
                      {order?.created_at?.substr(0, 16)}
                    </td>
                    <td className="border-b border-c7 py-4 px-2">{order?.items_count}</td>
                    <td className="border-b border-c7 py-4 px-2">
                      <div className="flex items-center gap-2">
                        <Icon name={handleChoosePaymentType(order?.payment_type)} />
                        <span>{paymentTypeOptions?.[order?.payment_type]?.label}</span>
                      </div>
                    </td>
                    <td className="border-b border-c7 py-4 px-2">
                      {order?.paid ? `${REFACTOR_SUM(order?.paid)} UZS` : "-"}
                    </td>
                    <td className="border-b border-c7 py-4 px-2">
                      {order?.paid !== order?.total_amount
                        ? `${REFACTOR_SUM((order?.total_amount || 0) - (order?.paid || 0))} UZS`
                        : "-"}
                    </td>
                    <td className="border-b border-c7 py-4 px-2 pr-4 text-end">
                      {order?.total_amount ? `${REFACTOR_SUM(order?.total_amount)} UZS` : "-"}
                    </td>
                  </tr>
                ))}
              </tbody>
            </table>
          </div>

          <div className="h-[3rem] px-4 py-1 border-t border-c7">
            <MUIPagination
              count={total}
              page={pagination?.page}
              pageSize={pagination?.pageSize}
              onChangePagination={(name, value) => handleChangePagination(name, value)}
            />
          </div>
        </div>
      </div>
    </>
  );
}

export default CloseDay;
