import { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { checkingPermissions } from "assets/constants/constants";
import { getDebtOrders, chooseDebtOrderClient } from "store/slices/orderSlice";
import MUIDrawer from "components/mui-drawer/MUIDrawer";
import Payment from "pages/debt/Payment";
import Icon from "assets/icons/icons";

function Debts() {
  const dispatch = useDispatch();
  const {
    orders: {
      refresh,
      debtOrders: { debts, filter, pagination },
    },
  } = useSelector((store) => store);
  const [openDrawer, setOpenDrawer] = useState({ client: null, open: false });

  const handleOpenDrawer = (client) => {
    setOpenDrawer({ client, open: true });
    dispatch(chooseDebtOrderClient(client));
  };
  const handleCloseDrawer = () => {
    setOpenDrawer({ ...openDrawer, open: false });
  };

  useEffect(() => {
    dispatch(getDebtOrders({ filter, pagination }));
    handleCloseDrawer();
  }, [refresh]);

  return (
    <>
      <MUIDrawer open={openDrawer?.open} onClose={handleCloseDrawer}>
        <Payment onClose={handleCloseDrawer} />
      </MUIDrawer>

      <div className="bg-c2 text-c24 relative h-full overflow-y-auto sm:hidden">
        <table className="w-full border-collapse">
          <thead className="bg-c1 sticky top-0">
            <tr>
              <td className="py-4 pl-4 text-start">Ism va familiya</td>
              <td className="py-4 text-start">Telefon raqami</td>
              <td className="py-4 text-center">To'landi</td>
              <td className="py-4 text-center">Qoldi</td>
              <td
                className={`py-4 text-center ${
                  !checkingPermissions(["ORDER_DEBT.PAYMENT"]) ? "pr-4 text-end" : ""
                }`}
              >
                Summa
              </td>
              {checkingPermissions(["ORDER_DEBT.PAYMENT"]) && (
                <td className="py-4 pr-4 text-end">Amallar</td>
              )}
            </tr>
          </thead>

          <tbody>
            {debts?.map((client) => (
              <tr key={client?.id}>
                <td className="border-b border-c7 py-2 pl-4 text-start">
                  {client?.user_full_name}
                </td>
                <td className="border-b border-c7 py-2 text-start">{client?.phone_number}</td>
                <td className="border-b border-c7 py-2 text-center">{client?.paid || "-"}</td>
                <td className="border-b border-c7 py-2 text-center">
                  {client.amount - client.paid || "-"}
                </td>
                <td
                  className={`border-b border-c7 py-2 text-center ${
                    !checkingPermissions(["ORDER_DEBT.PAYMENT"]) ? "pr-4 text-end" : ""
                  }`}
                >
                  {client?.amount}
                </td>
                {checkingPermissions(["ORDER_DEBT.PAYMENT"]) && (
                  <td className="border-b border-c7 py-2 pr-4 text-end flex justify-end">
                    <button
                      onClick={() => handleOpenDrawer(client)}
                      className="bg-c10 flex justify-center items-center gap-2 px-4 py-2 rounded"
                    >
                      <Icon name="plus" /> <span>To'lov kiritish</span>
                    </button>
                  </td>
                )}
              </tr>
            ))}
          </tbody>
        </table>
      </div>

      <div className="bg-c2 text-c24 hidden h-full p-2 sm:flex">
        <div className="relative overflow-y-auto w-full h-full flex flex-col gap-2">
          {debts?.map((client) => (
            <div key={client?.id} className="bg-c6 rounded p-4">
              <p>{client?.user_full_name}</p>
              <p className="text-c25">{client?.phone_number || "+998 90 918 13 43"}</p>
              <div className="flex justify-between items-end gap-2">
                <div className="flex gap-4 text-sm">
                  <div>
                    <p className="text-c25">To'landi</p>
                    <p className="text-c17">{client?.paid || "0"}</p>
                  </div>
                  <div>
                    <p className="text-c25">Qoldi</p>
                    <p className="text-c14">{client.amount - client.paid || "0"}</p>
                  </div>
                  <div>
                    <p className="text-c25">Summa</p>
                    <p>{client.amount}</p>
                  </div>
                </div>
                {checkingPermissions(["ORDER_DEBT.PAYMENT"]) && (
                  <button
                    onClick={() => handleOpenDrawer(client)}
                    className="bg-c10 flex items-center justify-center rounded px-3 py-2 "
                  >
                    <Icon name="plus" className="ml-[-0.25rem]" />
                    <span>Kiritish</span>
                  </button>
                )}
              </div>
            </div>
          ))}
        </div>
      </div>
    </>
  );
}

export default Debts;
