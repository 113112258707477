function Select({
  id,
  label,
  value = "choose",
  onChange,
  onClear,
  options = [],
  firstOption = true,
  clearButton = false,
  firstOptionDisabled = true,
  optionsLabel = { label: "label", value: "value" },
  classNameLabel = "text-sm",
  classNameSelect = "rounded w-full px-3 py-3 outline-0 border",
}) {
  return (
    <label htmlFor={`select-${id}`} className={`relative ${classNameLabel}`}>
      <span>{label}</span>

      <select
        name={id}
        value={value}
        id={`select-${id}`}
        onChange={(e) => onChange(e)}
        className={`${classNameSelect}`}
      >
        {firstOption && (
          <option value="" disabled={firstOptionDisabled}>
            Tanlang
          </option>
        )}

        {options?.map((option, idx) => (
          <option
            key={idx}
            value={option?.disabled ? "" : option[optionsLabel?.value]}
            disabled={option?.disabled}
          >
            {option[optionsLabel?.label]}
          </option>
        ))}
      </select>

      {clearButton && value && (
        <button onClick={onClear} className="bg-c24 text-c14 px-2 absolute right-1 bottom-[15%]">
          ✕
        </button>
      )}
    </label>
  );
}

export default Select;
