import { debounce } from "lodash";
import { useCallback, useEffect, useState } from "react";
import { useAutocomplete } from "@mui/base/useAutocomplete";
import Icon from "assets/icons/icons";

function MUISearchSelect({
  value = "",
  label = "",
  options = [],
  onChange,
  onCreate,
  onSelected,
  placeholder = "",
  localFilter = false,
  createButton = false,
  optionsLabel = { label: "label", value: "value" },
}) {
  const [open, setOpen] = useState(false);
  const [search, setSearch] = useState(value);
  const [selected, setSelected] = useState({});

  const {
    getRootProps,
    getInputLabelProps,
    getInputProps,
    getListboxProps,
    getOptionProps,
    groupedOptions,
  } = useAutocomplete({
    open: open,
    options: options?.length ? options : [{}],
    blurOnSelect: true,
    id: "use-autocomplete-demo",
    onOpen: () => setOpen(true),
    onClose: () => setOpen(false),
    getOptionLabel: (option) => option[optionsLabel?.label],
  });

  const handleChangeSearch = (e) => {
    setSearch(e?.target?.value);
    !localFilter && debouncedSearch(e?.target?.value);
    if (!e?.target?.value) {
      setOpen(false);
      onSelected("");
      setSelected({});
    } else setOpen(true);
  };

  const handleSelected = (option) => {
    setSearch(option?.[optionsLabel?.label]);
    onSelected(option?.[optionsLabel?.value]);
    setSelected(option);
    setOpen(false);
  };

  const handleClear = () => {
    setOpen(false);
    setSearch("");
    setSelected({});
    onSelected("");
  };

  const debouncedSearch = useCallback(
    debounce((search) => onChange(search), 512),
    []
  );

  // useEffect(() => {
  //   if (value) {
  //     const defaultSelected = options?.filter(
  //       (option) => option?.[optionsLabel?.value] === value
  //     )?.[0];
  //     setSelected(defaultSelected);
  //     setSearch(defaultSelected?.[optionsLabel?.label]);
  //   }
  // }, []);

  useEffect(() => {
    debouncedSearch.cancel();
  }, [debouncedSearch]);

  return (
    <div className="relative">
      <div {...getRootProps()}>
        <label {...getInputLabelProps()} className="text-c2 text-sm">
          {label}
        </label>
        <label className="relative flex items-center bg-c24">
          <Icon name="searchGray" className="absolute left-3" />
          <input
            {...getInputProps()}
            type="text"
            value={search}
            placeholder={placeholder}
            onChange={handleChangeSearch}
            className="text-c2 border w-full h-10 rounded-xl outline-0 pl-11 pr-2 text-sm"
          />
          {search && (
            <button onClick={handleClear} className="absolute right-2 bg-transparent">
              <Icon name="closeBlack" />
            </button>
          )}
        </label>
      </div>
      {groupedOptions.length > 0 ? (
        <div
          className="absolute bg-c24 border text-c2 w-full rounded-xl list-none overflow-hidden text-sm z-10"
          {...getListboxProps()}
        >
          <div className="max-h-[10rem] overflow-y-auto">
            {groupedOptions
              .filter((option) =>
                localFilter && search
                  ? option?.[optionsLabel?.label]?.toLowerCase()?.includes(search?.toLowerCase())
                  : option
              )
              .map((option, index) => (
                <li
                  {...getOptionProps({ option, index })}
                  onClick={() => handleSelected(option)}
                  className={`px-4 py-2 hover:bg-c37 ${
                    selected?.[optionsLabel?.value] === option?.[optionsLabel?.value]
                      ? "bg-c11"
                      : ""
                  }`}
                >
                  {option?.[optionsLabel?.label]}
                </li>
              ))}
          </div>
          {createButton && (
            <button onClick={onCreate} className="w-full text-center text-xl bg-c31 py-1">
              +
            </button>
          )}
        </div>
      ) : null}
    </div>
  );
}

export default MUISearchSelect;
