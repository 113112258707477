import { v4 } from "uuid";
import { addOrder } from "store/slices/orderSlice";
import { useDispatch, useSelector } from "react-redux";
import { useLocation, useNavigate } from "react-router-dom";
import { checkingPermissions } from "assets/constants/constants";
import Icon from "assets/icons/icons";

function Footer() {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const {
    orders: { orders },
  } = useSelector((store) => store);
  const { pathname } = useLocation();

  const currentPath = pathname.split("/");

  const clientsCount = Object.keys(orders).length;

  const handleAddClient = () => clientsCount < 4 && dispatch(addOrder(v4()));

  return (
    <div className="bg-c1 text-c24 h-[7%] flex justify-between items-center border-t-[1px] border-c7 overflow-hidden">
      <div className="flex gap-2 px-2">
        {Object.keys(orders).map((key, idx) => (
          <button
            key={key}
            onClick={() => navigate(`/home/${key}`)}
            className={`px-4 py-2 rounded flex items-center justify-center border-[1px] ${
              currentPath?.[2] === key ? "border-c10 bg-c3" : "border-c5 bg-c5"
            }`}
          >
            {idx + 1}-mijoz
          </button>
        ))}
        <button
          onClick={handleAddClient}
          className="bg-c10 px-4 py-2 rounded flex items-center justify-center"
        >
          <Icon name="plus" />
        </button>
      </div>
      <div className="bg-c2 flex gap-2 px-2 py-1 border-l-[1px] border-c7">
        {checkingPermissions(["ORDER_DEBT.READ"]) && (
          <button
            onClick={() => navigate("/debts")}
            className={`flex items-center px-4 py-2 rounded gap-1 border-[1px] ${
              currentPath?.[1] === "debts" ? "border-c10 bg-c3" : "border-c5 bg-c5"
            }`}
          >
            <Icon name="shopping" /> Nasiya
          </button>
        )}
        <button
          className={`flex items-center px-4 py-2 rounded gap-1 border-[1px] ${
            currentPath === "profile" ? "border-c10 bg-c3" : "border-c5 bg-c5"
          }`}
        >
          <Icon name="person" /> Shaxsiy kabinet
        </button>
        {checkingPermissions(["INCOME.CREATE", "SUPPLIER.READ"]) && (
          <button
            onClick={() => navigate("/income")}
            className={`flex items-center px-4 py-2 rounded gap-1 border-[1px] ${
              currentPath?.[1] === "income" ? "border-c10 bg-c3" : "border-c5 bg-c5"
            }`}
          >
            <Icon name="plus" /> Mahsulot kiritish
          </button>
        )}
      </div>
    </div>
  );
}

export default Footer;
