import { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { createCategory } from "store/slices/categorySlice";
import MUITabs from "components/mui-tabs/MUITabs";
import Spinner from "components/loader/Spinner";
import Select from "components/select/Select";
import Input from "components/input/Input";

function CreateCategories() {
  const dispatch = useDispatch();
  const {
    categories: { categories, isLoading },
  } = useSelector((store) => store);

  const [panel, setPanel] = useState(0);
  const [category, setCategory] = useState();

  const handleChangeCategory = (name, value) => setCategory({ ...category, [name]: value });

  const handleSave = () => {
    ((panel && category?.name && category?.parent_id) || (!panel && category?.name)) &&
      dispatch(createCategory(category));
  };

  useEffect(() => {
    setCategory(panel === 0 ? { name: "" } : { name: "", parent: "" });
  }, [panel]);

  return (
    <div className="bg-c2 text-c24 w-[20rem] h-full sm:w-screen">
      <div className="p-4 flex flex-col gap-4">
        <p className="text-xl">Yangi kategoriya</p>
        <MUITabs
          panel={panel}
          setPanel={setPanel}
          className="bg-c5 rounded-3xl"
          tabs={["Asosiy kategoriya", "Ichki kategoriya"]}
        />

        {panel === 0 ? (
          <Input
            type="text"
            label="Kategoriya nomi *"
            value={category?.name || ""}
            onChange={(value) => handleChangeCategory("name", value)}
            inputMode="text"
            placeholder="Kategoriya"
            classNameInput="w-full border border-c7 text-c24 rounded text-sm bg-c1 p-3 outline-none"
            virtualKeyboard={true}
          />
        ) : (
          // <Input
          //   value={category?.name}
          //   label="Kategoriya nomi"
          //   placeholder="Kategoriya"
          //   id="create_category_name_1"
          //   onChange={(e) => handleChangeCategory("name", e)}
          // />
          <div className="flex flex-col gap-3">
            <Input
              type="text"
              label="Kategoriya nomi *"
              value={category?.name || ""}
              onChange={(value) => handleChangeCategory("name", value)}
              inputMode="text"
              placeholder="Kategoriya"
              classNameInput="w-full border border-c7 text-c24 rounded text-sm bg-c1 p-3 outline-none"
              virtualKeyboard={true}
            />

            <Select
              id="main-category"
              options={categories}
              label="Asosiy kategoriya *"
              value={category?.parent_id || ""}
              optionsLabel={{ label: "name", value: "id" }}
              classNameLabel="flex flex-col text-sm"
              classNameSelect="px-2 py-3 border border-c7 rounded outline-0 bg-c1 bg-arrow-down bg-no-repeat bg-right bg-[center_right_0.3rem] select-arrow-none"
              onChange={(e) => handleChangeCategory("parent_id", e.target.value)}
            />
          </div>
        )}
        <button onClick={handleSave} className="bg-c17 text-center py-3 rounded">
          {isLoading ? <Spinner /> : "Saqlash"}
        </button>
      </div>
    </div>
  );
}

export default CreateCategories;
