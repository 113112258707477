export const paymentTypeOptions = {
  card: {
    bgColor: "bg-c37",
    color: "text-c10",
    icon: "cardBlue",
    label: "Karta",
  },
  cash: {
    bgColor: "bg-c38",
    color: "text-c17",
    icon: "paymentGreen",
    label: "Naqd",
  },
  debt: {
    bgColor: "bg-c41",
    color: "text-c14",
    icon: "shoppingRed",
    label: "Nasiya",
  },
  other: {
    bgColor: "bg-c39",
    color: "text-c21",
    icon: "otherYellow",
    label: "Boshqa",
  },
};

export default { paymentTypeOptions };
