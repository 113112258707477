import { useState } from "react";
import { useDispatch } from "react-redux";
import { createBrand } from "store/slices/brandSlice";
import HtmlInput from "components/input/HtmlInput";
import { updateBrand } from "store/slices/brandSlice";

const CreateBrand = ({ bgColor = "c24", color, action, currentBrand }) => {
  const dispatch = useDispatch();
  const [brand, setBrand] = useState({ name: action === "update" ? currentBrand?.name : "" });

  const handleChangeBrand = (name, value) => {
    setBrand({ ...brand, [name]: value });
  };

  const handleSave = () => {
    brand?.name &&
      dispatch(
        action === "create"
          ? createBrand(brand)
          : updateBrand({ brand_id: currentBrand?.id, brand })
      );
  };

  return (
    <div className={`bg-${bgColor} w-[25rem] p-6 rounded-lg flex flex-col gap-4 sm:w-[90vw]`}>
      <span className={`text-${color} text-2xl`}>
        {action === "create" ? "Brand qo'shish" : "Brandni tahrirlash"}
      </span>

      <HtmlInput
        color="c2"
        label="Brand nomi *"
        placeholder="Brand nomini kiriting"
        value={brand?.name}
        id="client-first-name"
        onChange={(e) => handleChangeBrand("name", e.target.value)}
      />

      <button
        onClick={handleSave}
        className="bg-c10 text-c24 flex items-center justify-center rounded-xl px-3 h-10"
      >
        Saqlash
      </button>
    </div>
  );
};

export default CreateBrand;
