import { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { toast } from "react-toastify";
import { changeIncomeExpenses } from "store/slices/incomeSlice";

function IncomePayment({ onCloseMenu, onChangePayingPrice }) {
  const dispatch = useDispatch();
  const {
    income: {
      income: { totalPrice, expenses },
    },
  } = useSelector((store) => store);
  const [paymentType, setPaymentType] = useState({ ...expenses });

  const handleAddPaymentType = (type) => {
    const payment = paymentType?.[type]?.status
      ? delete paymentType[type]
      : {
          [type]: {
            type,
            price: "",
            status: true,
          },
        };
    setPaymentType({ ...paymentType, ...payment });
  };

  const handleChangePaymentPrice = (type, price) => {
    const payment = { ...paymentType[type], price: price ? parseFloat(price, 10) : "" };
    setPaymentType({ ...paymentType, [type]: payment });
  };

  const calculateDebtOrReturn = () => {
    let totalAmount = 0;
    Object.keys(paymentType).map(
      (key) => key !== "debt" && (totalAmount += paymentType[key]?.price || 0)
    );
    const amount = totalPrice - (totalAmount ? totalAmount : 0);
    paymentType?.debt?.status && handleChangePaymentPrice("debt", amount > 0 ? amount : 0);
  };

  const handleSavePaymentType = () => {
    let totalAmount = 0;

    Object.keys(paymentType).map((key) => (totalAmount += paymentType[key]?.price || 0));
    if (totalAmount === totalPrice) {
      if (Object.keys(paymentType).length === 1) {
        Object.keys(paymentType).map((key) =>
          onChangePayingPrice({
            text: `${key === "cash" ? "Naqd" : key === "card" ? "Karta" : "Nasiya"}: ${
              paymentType[key]?.price
            }`,
            totalAmount: paymentType[key]?.price,
          })
        );
      } else if (Object.keys(paymentType).length > 1)
        onChangePayingPrice({ text: `Boshqa: ${totalAmount}`, totalAmount });
      else onChangePayingPrice({ text: "", totalAmount: 0 });

      dispatch(changeIncomeExpenses(paymentType));
      onCloseMenu();
    }

    totalAmount !== totalPrice &&
      toast.warning("To'lanayotkan summa umumiy summaga teng bo'lishi zarur.");
  };

  useEffect(() => {
    calculateDebtOrReturn();
  }, [paymentType?.cash, paymentType?.card, paymentType?.debt?.status]);

  return (
    <div className="w-[20rem] pl-10 pr-4 py-2 flex flex-col gap-2 sm:w-[90vw] sm:p-4">
      <label htmlFor="cash-input" className="w-full flex flex-col">
        <p
          onClick={() => handleAddPaymentType("cash")}
          className={`text-lg cursor-pointer ${paymentType?.cash?.status ? "text-c10" : ""}`}
        >
          Naqd
        </p>
        {paymentType?.cash?.status && (
          <input
            id="cash-input"
            type="number"
            inputMode="numeric"
            placeholder="Summani kiriting"
            value={paymentType?.cash?.price}
            className=" border p-2 rounded-xl outline-0"
            onChange={(e) => handleChangePaymentPrice("cash", e.target.value)}
          />
        )}
      </label>
      <label htmlFor="card-input" className="flex flex-col">
        <p
          onClick={() => handleAddPaymentType("card")}
          className={`text-lg cursor-pointer ${paymentType?.card?.status ? "text-c10" : ""}`}
        >
          Karta
        </p>
        {paymentType?.card?.status && (
          <input
            id="card-input"
            type="number"
            inputMode="numeric"
            placeholder="Summani kiriting"
            value={paymentType?.card?.price}
            className="border p-2 rounded-xl outline-0"
            onChange={(e) => handleChangePaymentPrice("card", e.target.value)}
          />
        )}
      </label>
      <label htmlFor="debt-input" className="flex flex-col">
        <p
          onClick={() => handleAddPaymentType("debt")}
          className={`text-lg cursor-pointer ${paymentType?.debt?.status ? "text-c10" : ""}`}
        >
          Nasiya
        </p>
        {paymentType?.debt?.status && (
          <input
            id="debt-input"
            type="number"
            inputMode="numeric"
            placeholder="Summani kiriting"
            value={paymentType?.debt?.price}
            className="border p-2 rounded-xl outline-0"
            onChange={(e) => handleChangePaymentPrice("debt", e.target.value)}
          />
        )}
        {(paymentType?.cash?.status || paymentType?.card?.status || paymentType?.debt?.status) && (
          <p className="text-sm mt-2">
            <span className="text-c9">Umumiy summa: </span>{" "}
            <span className="text-c10">{totalPrice} UZS</span>
          </p>
        )}
      </label>
      {(paymentType?.cash?.status || paymentType?.card?.status || paymentType?.debt?.status) && (
        <div className="flex gap-2">
          <button
            onClick={() => {
              onCloseMenu();
              setPaymentType(expenses);
            }}
            className="w-1/2 bg-c14 text-c24 px-4 py-2 rounded-xl"
          >
            Bekor qilish
          </button>
          <button
            onClick={handleSavePaymentType}
            className="w-1/2 bg-c17 text-c24 px-4 py-2 rounded-xl"
          >
            Saqlash
          </button>
        </div>
      )}
    </div>
  );
}

export default IncomePayment;
