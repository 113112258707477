import { useState } from "react";
import { logout } from "store/slices/authSlice";
import { openSession } from "store/slices/authSlice";
import { useDispatch, useSelector } from "react-redux";
// import { changeThemeMode } from "store/slices/themeSlice";
import { useLocation, useNavigate } from "react-router-dom";
import { warningOpenSession } from "constants/notifications";
import { checkingPermissions } from "assets/constants/constants";
import OrdersHistory from "layers/orders-history/OrdersHistory";
import MUIDrawer from "components/mui-drawer/MUIDrawer";
import Footer from "layers/footer/Footer";
// import { Switch } from "@mui/material";
import Icon from "assets/icons/icons";

function Appbar() {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const { pathname } = useLocation();
  const {
    auth: {
      session: { session_id },
    },
  } = useSelector((store) => store);
  const [openDrawer, setOpenDrawer] = useState({
    open: false,
    action: "",
    position: "right",
  });

  const currentPath = pathname.split("/");

  const handleOpenDrawer = (action, position, closable) =>
    setOpenDrawer({ open: true, action, position, closable });

  const handleCloseDrawer = () => setOpenDrawer({ ...openDrawer, open: false });

  const handleLogout = () => dispatch(logout());

  // const handleChangeThemeMode = () => dispatch(changeThemeMode());

  const handleChangeSession = () => {
    session_id ? navigate("/close-day") : dispatch(openSession());
  };

  const requestFullscreen = () => {
    if (document.documentElement.requestFullscreen) {
      document.documentElement.requestFullscreen();
    } else if (document.documentElement.mozRequestFullScreen) {
      /* Firefox */
      document.documentElement.mozRequestFullScreen();
    } else if (document.documentElement.webkitRequestFullscreen) {
      /* Chrome, Safari and Opera */
      document.documentElement.webkitRequestFullscreen();
    } else if (document.documentElement.msRequestFullscreen) {
      /* IE/Edge */
      document.documentElement.msRequestFullscreen();
    }
  };

  return (
    <>
      <MUIDrawer
        open={openDrawer?.open}
        onClose={handleCloseDrawer}
        position={openDrawer?.position}
        closable={openDrawer?.action === "history" ? true : false}
        hideBackdrop={false}
      >
        {openDrawer?.action === "footer" && <Footer />}
        {openDrawer?.action === "history" && <OrdersHistory onClose={handleCloseDrawer} />}
      </MUIDrawer>
      <div
        className={`bg-c1 flex flex-col items-center justify-between overflow-hidden transition sm:hidden ${
          openDrawer?.open ? "h-[93%]" : "h-full"
        }`}
      >
        <div className="mt-1 flex flex-col gap-2">
          <button
            onClick={requestFullscreen}
            className="bg-c5 flex items-center justify-center w-[3rem] h-[3rem] rounded border-0"
          >
            <Icon name="full_screen" />
          </button>

          {checkingPermissions(["ORDER_HISTORY.READ"]) && (
            <button
              onClick={() => {
                session_id && handleOpenDrawer("history", "right", true);
                warningOpenSession(session_id);
              }}
              className={`bg-c5 flex items-center justify-center w-[3rem] h-[3rem] rounded border-0 ${
                !session_id ? "opacity-50 cursor-not-allowed" : ""
              }`}
            >
              <Icon name="history" />
            </button>
          )}

          <button
            onClick={() => {}}
            className="bg-c5 flex items-center justify-center w-[3rem] h-[3rem] rounded border-0"
          >
            <Icon name="qrcode" />
          </button>

          {/* <button className="bg-c5 flex items-center justify-center w-[3rem] h-[3rem] rounded border-0">
            <Switch
              // checked={mode === "light" ? true : false}
              onChange={() => handleChangeThemeMode()}
            />
          </button> */}
        </div>
        <div className="mb-1 flex flex-col gap-2">
          {checkingPermissions(["SESSION.OPEN", "SESSION.CLOSE"]) && (
            <button
              onClick={handleChangeSession}
              className={`flex items-center justify-center w-[3rem] h-[3rem] rounded border-0 ${
                session_id ? "bg-c21" : "bg-c17"
              }`}
            >
              <Icon name="receipt" />
            </button>
          )}

          <button
            onClick={handleLogout}
            className="bg-c14 flex items-center justify-center w-[3rem] h-[3rem] rounded border-0"
          >
            <Icon name="logout" />
          </button>

          <button
            onClick={() => {
              openDrawer?.action === "footer" && openDrawer?.open
                ? handleCloseDrawer()
                : session_id && handleOpenDrawer("footer", "bottom", false);

              warningOpenSession(session_id);
            }}
            className={`bg-c5 flex items-center justify-center w-[3rem] h-[3rem] rounded border-0 ${
              !session_id ? "opacity-50 cursor-not-allowed" : ""
            }`}
          >
            <Icon
              name="arrowDown"
              className={`scale-[1.2] transition-[2s] rotate-[${
                openDrawer?.action === "footer" && openDrawer?.open ? "0" : "180"
              }deg]`}
            />
          </button>
        </div>
      </div>
      <div className="hidden bg-c6 justify-around pb-1 px-2 sm:flex">
        <div className="flex flex-col items-center justify-center gap-2">
          <div
            className={`w-full h-[4px] rounded-b-sm ${
              currentPath?.[1] === "home" ? "bg-c10" : "bg-c6"
            }`}
          />
          <button
            onClick={() => navigate("/home")}
            className={`w-[2rem] h-[2rem] flex items-center justify-center  rounded-full ${
              currentPath?.[1] === "home" ? "bg-c10" : "bg-c6"
            }`}
          >
            <Icon name="sale" className="scale-[1.4]" />
          </button>
        </div>

        {checkingPermissions(["ORDER_DEBT.READ"]) && (
          <div className="flex flex-col items-center justify-center gap-2">
            <div
              className={`w-full h-[4px] rounded-b-sm ${
                currentPath?.[1] === "debts" ? "bg-c10" : "bg-c6"
              }`}
            />
            <button
              onClick={() => navigate("/debts")}
              className={`w-[2rem] h-[2rem] flex items-center justify-center  rounded-full ${
                currentPath?.[1] === "debts" ? "bg-c10" : "bg-c6"
              }`}
            >
              <Icon name="shopping" />
            </button>
          </div>
        )}

        {checkingPermissions(["INCOME.CREATE"]) && (
          <div className="flex flex-col items-center justify-center gap-2">
            <div
              className={`w-full h-[4px] rounded-b-sm ${
                currentPath?.[1] === "income" ? "bg-c10" : "bg-c6"
              }`}
            />

            <button
              onClick={() => navigate("/income")}
              className={`w-[2rem] h-[2rem] flex items-center justify-center  rounded-full ${
                currentPath?.[1] === "income" ? "bg-c10" : "bg-c6"
              }`}
            >
              <Icon name="plus" />
            </button>
          </div>
        )}
      </div>
    </>
  );
}

export default Appbar;
