import { useEffect, useState } from "react";
import { getUnits } from "store/slices/unitSlice";
import { getBrands } from "store/slices/brandSlice";
import { useDispatch, useSelector } from "react-redux";
import { changeFilter } from "store/slices/productSlice";
import { getCategories } from "store/slices/categorySlice";
import Select from "components/select/Select";
import { checkingPermissions } from "assets/constants/constants";

const RemainingProductsFilter = () => {
  const dispatch = useDispatch();
  const {
    products,
    units: { units },
    brands: { brands },
    categories: { categories },
  } = useSelector((store) => store);

  const [filter, setFilter] = useState(products?.filter);

  const handleChangeFilter = (name, value) =>
    setFilter(
      name === "root_category"
        ? { ...filter, [name]: value, category: "" }
        : { ...filter, [name]: value }
    );

  const handleSave = () => dispatch(changeFilter(filter));

  useEffect(() => {
    dispatch(getUnits({}));
    dispatch(getBrands({}));
    dispatch(getCategories({ onlyRootCategories: true, withChildren: true }));
  }, []);

  return (
    <div className="bg-c24 text-c2 w-[20rem] h-full flex flex-col gap-4 p-4 sm:w-screen">
      <span className="text-2xl font-medium">Filter</span>

      {checkingPermissions(["CATEGORY.READ"]) && (
        <Select
          label="Kategoriya"
          options={categories}
          firstOptionDisabled={false}
          value={filter?.root_category}
          classNameLabel="text-c2 text-sm"
          optionsLabel={{ label: "name", value: "id" }}
          onChange={(e) => handleChangeFilter("root_category", e.target.value)}
          classNameSelect="bg-c24 w-full h-10 px-2 pl-8 rounded-xl text-c2 border border-c32 outline-none bg-arrow-down-gray bg-no-repeat bg-left bg-[center_left_0.24rem] select-arrow-none"
        />
      )}
      {checkingPermissions(["CATEGORY.READ"]) && (
        <Select
          label="Sub category"
          classNameLabel="text-c2 text-sm"
          value={filter?.category}
          optionsLabel={{ label: "name", value: "id" }}
          onChange={(e) => handleChangeFilter("category", e.target.value)}
          options={
            categories?.filter((category) => category?.id === filter?.root_category)?.[0]?.children
          }
          classNameSelect="bg-c24 w-full h-10 px-2 pl-8 rounded-xl text-c2 border border-c32 outline-none bg-arrow-down-gray bg-no-repeat bg-left bg-[center_left_0.24rem] select-arrow-none"
        />
      )}

      {checkingPermissions(["UNIT.READ"]) && (
        <Select
          options={units}
          value={filter?.unit}
          label="O’lchov birligi"
          classNameLabel="text-c2 text-sm"
          firstOptionDisabled={false}
          optionsLabel={{ label: "name", value: "id" }}
          onChange={(e) => handleChangeFilter("unit", e.target.value)}
          classNameSelect="bg-c24 w-full h-10 px-2 pl-8 rounded-xl text-c2 border border-c32 outline-none bg-arrow-down-gray bg-no-repeat bg-left bg-[center_left_0.24rem] select-arrow-none"
        />
      )}

      {checkingPermissions(["BRAND.READ"]) && (
        <Select
          label="Brend"
          options={brands}
          value={filter?.brand}
          classNameLabel="text-c2 text-sm"
          firstOptionDisabled={false}
          optionsLabel={{ label: "name", value: "id" }}
          onChange={(e) => handleChangeFilter("brand", e.target.value)}
          classNameSelect="bg-c24 w-full h-10 px-2 pl-8 rounded-xl text-c2 border border-c32 outline-none bg-arrow-down-gray bg-no-repeat bg-left bg-[center_left_0.24rem] select-arrow-none"
        />
      )}

      {/* <Checkbox
      bgColor="bg-c24"
      classNameLabel="text-c2"
      onChange={() => console.log(1)}
      label="Brand bo’limini ko’rsatish"
      classNameCheckbox="text-c2 border-c7"
    /> */}

      <button onClick={handleSave} className="h-10 bg-c10 text-c24 px-4 rounded-xl">
        Saqlash
      </button>
    </div>
  );
};

export default RemainingProductsFilter;
