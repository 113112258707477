import { useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import {
  changeIncomeProduct,
  removeIncomeProduct,
  chooseActiveProduct,
} from "store/slices/incomeSlice";
import SelectSupplier from "layers/supplier/SelectSupplier";
import MUIDrawer from "components/mui-drawer/MUIDrawer";
import Input from "components/input/Input";
import { checkingPermissions } from "assets/constants/constants";

function IncomeProducts() {
  const dispatch = useDispatch();
  const {
    income: {
      income: { activeProduct, products, supplier },
    },
  } = useSelector((store) => store);
  const [openDrawer, setOpenDrawer] = useState(false);

  const handleOpenDrawer = () => setOpenDrawer(true);

  const handleCloseDrawer = () => setOpenDrawer(false);

  const handleRemove = (id) => dispatch(removeIncomeProduct(id));

  const handleChangeIncomeProduct = (id, name, value) =>
    dispatch(changeIncomeProduct({ id, name, value }));

  const handleChooseActiveProduct = (product, field) => {
    dispatch(chooseActiveProduct({ ...product, field }));
  };

  return (
    <>
      <MUIDrawer
        bgColor="c5"
        zIndex={900}
        open={openDrawer}
        onClose={handleCloseDrawer}
        children={<SelectSupplier onClose={handleCloseDrawer} />}
      />

      <div className="bg-c1 h-full rounded overflow-hidden text-sm sm:flex sm:flex-col">
        <div className="flex items-center justify-between p-2 border-b-[1px] border-c7">
          <p className="text-c24">Kiritilayotgan mahsulotlar</p>
          {checkingPermissions(["SUPPLIER.READ"]) && (
            <button onClick={handleOpenDrawer} className="p-2 bg-c2 text-c24 rounded text-xs">
              {supplier?.id ? `Ta'minotchi:  ${supplier?.name}` : "Ta'minotchi"}
            </button>
          )}
        </div>
        <div className="relative h-full overflow-y-auto sm:hidden">
          <table className="w-full border-collapse mb-16 text-c24 sm:m-4">
            <thead className="outline outline-offset-1 outline-c7 text-c9 sticky top-0">
              <tr>
                <td className="py-3 pl-2">Nomi</td>
                <td className="py-3 ">Qoldiq</td>
                <td className="py-3">Miqdori</td>
                <td className="py-3 ">Eski narxi</td>
                <td className="py-3 pr-2 text-end">Narxi</td>
                <td> </td>
              </tr>
            </thead>
            <tbody>
              {products?.map((product) => (
                <tr
                  key={product?.id}
                  className={`${activeProduct?.id === product?.id ? "bg-c2" : ""}`}
                >
                  <td
                    onClick={() => handleChooseActiveProduct(product, "income_quantity")}
                    className="py-3 pl-2 border-b-[1px] border-c7"
                  >
                    {product?.name}
                  </td>
                  <td className="py-3 pl-2 border-b-[1px] border-c7">{product?.quantity}</td>
                  <td className="py-1 border-b-[1px] border-c7 overflow-hidden cursor-pointer">
                    <Input
                      type="text"
                      value={product?.income_quantity}
                      onClick={() => handleChooseActiveProduct(product, "income_quantity")}
                      onChange={(value) =>
                        handleChangeIncomeProduct(product?.id, "income_quantity", value)
                      }
                      autoFocus={
                        activeProduct?.id === product?.id &&
                        activeProduct?.field === "income_quantity"
                      }
                      defaultNull={true}
                      inputMode="numeric"
                      classNameInput={`w-[4rem] py-2 outline-0 border-0  bg-transparent ${
                        activeProduct?.id === product?.id &&
                        activeProduct?.field === "income_quantity"
                          ? "border-b-[1px]"
                          : ""
                      }`}
                      virtualKeyboard={false}
                    />
                  </td>
                  <td className="py-3 border-b-[1px] border-c7">{product?.income_price}</td>
                  <td className="w-[4rem] overflow-hidden py-1 border-b-[1px] border-c7 cursor-pointer text-end pr-2">
                    <Input
                      id="in_price"
                      type="text"
                      value={product?.in_price}
                      onClick={() => handleChooseActiveProduct(product, "in_price")}
                      onChange={(value) =>
                        handleChangeIncomeProduct(product?.id, "in_price", value)
                      }
                      inputMode="numeric"
                      classNameInput={`w-[4rem] py-2 outline-0 border-0  bg-transparent ${
                        activeProduct?.id === product?.id && activeProduct?.field === "in_price"
                          ? "border-b-[1px]"
                          : ""
                      }`}
                      virtualKeyboard={false}
                    />
                  </td>
                  <td
                    onClick={() => handleRemove(product?.id)}
                    className="py-3 pr-2 border-b-[1px] border-c7 text-end text-c16"
                  >
                    ✕
                  </td>
                </tr>
              ))}
            </tbody>
          </table>
        </div>
        <div className="hidden my-2 flex-col relative h-full overflow-y-auto sm:flex">
          <div className="px-2 flex flex-col gap-2 ">
            {products?.map((product) => (
              <div key={product?.id} className="bg-c2 p-2 rounded flex flex-col gap-4">
                <div className="flex justify-between items-center">
                  <p className="text-c24">{product?.name}</p>
                  <div onClick={() => handleRemove(product?.id)} className="text-c14 p-1">
                    ✕
                  </div>
                </div>
                <div className="flex justify-between items-center">
                  <div className="flex gap-1">
                    <span className="text-c25">Eski narxi:</span>
                    <span className="text-c24">{product?.price}</span>
                  </div>
                  <div className="flex gap-1">
                    <span className="text-c25">Qoldi:</span>
                    <span className="text-c24">
                      {product?.quantity} {product?.unit_name}
                    </span>
                  </div>
                </div>
                <div className="flex justify-between gap-2 text-c24">
                  <Input
                    label="Miqdori"
                    inputMode="numeric"
                    value={product?.income_quantity}
                    onChange={(value) =>
                      handleChangeIncomeProduct(product?.id, "income_quantity", value)
                    }
                    classNameInput="border-0 border-b-[1px] border-c7 bg-transparent px-0 py-1 outline-0"
                  />
                  <Input
                    label="Narxi"
                    inputMode="numeric"
                    value={product?.in_price}
                    onChange={(value) => handleChangeIncomeProduct(product?.id, "in_price", value)}
                    classNameInput="border-0 border-b-[1px] border-c7 bg-transparent px-0 py-1 outline-0"
                  />
                </div>
              </div>
            ))}
          </div>
        </div>
      </div>
    </>
  );
}

export default IncomeProducts;
