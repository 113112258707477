import { REFACTOR_SUM } from "assets/constants/constants";
import { useDispatch, useSelector } from "react-redux";
import { changeReturnProductReturnIncome } from "store/slices/incomeSlice";

function CurrentProductIncomeList({ product_id }) {
  const dispatch = useDispatch();
  const {
    income: {
      returnIncome: { products },
    },
  } = useSelector((store) => store);

  const currentIncomeProduct = products?.filter((product) => product?.id === product_id)?.[0];

  const handleChangeReturnIncome = (income_id, name, value) =>
    dispatch(changeReturnProductReturnIncome({ product_id, income_id, name, value }));

  return (
    <div className="w-[50rem] bg-c24 p-6 rounded-lg flex flex-col gap-4 sm:w-[90vw]">
      <div>
        <p className="text-xl">{currentIncomeProduct?.name}</p>
        <p className="text-sm">
          Qaytarilayotgan mahsulot miqdori: {currentIncomeProduct?.return_quantity || 0}{" "}
          {currentIncomeProduct?.unit_name}
        </p>
      </div>
      <div className="w-full rounded-lg overflow-x-auto border">
        <table className="w-full border-collapse text-sm sm:whitespace-nowrap">
          <thead>
            <tr className="text-c9 font-thin bg-c36">
              <td className="py-3 px-2 pl-4">Kirim sanasi</td>
              <td className="px-2">
                <p className="flex flex-col">
                  <span>Miqdori</span>
                  <span className="text-xs">({currentIncomeProduct?.unit_name})</span>
                </p>
              </td>
              <td className="px-2">
                <p className="flex flex-col">
                  <span>Qoldi</span>
                  <span className="text-xs">({currentIncomeProduct?.unit_name})</span>
                </p>
              </td>
              <td className="px-2">
                <p className="flex flex-col">
                  <span>Qaytarilmoqda</span>
                  <span className="text-xs">({currentIncomeProduct?.unit_name})</span>
                </p>
              </td>
              <td className="py-3 px-2">Narxi</td>
              <td className="py-3 px-2 pr-4 text-end">Umumiy summa</td>
            </tr>
          </thead>
          <tbody>
            {currentIncomeProduct?.incomeList?.map((income) => (
              <tr key={income?.id} className="even:bg-c36 odd:bg-white border-t">
                <td className="py-3 px-2 pl-4">
                  {new Date(income?.created_at)?.toLocaleString()?.substr(0, 17)}
                </td>
                <td className="py-3 px-2">{income?.quantity}</td>
                <td className="py-3 px-2">{income?.warehouse_quantity}</td>
                <td className="px-2">
                  <input
                    type="number"
                    inputMode="numeric"
                    value={income?.return_quantity || ""}
                    className="w-[6rem] h-10 border px-2 py-1 rounded-xl bg-c36 outline-0"
                    onChange={(e) =>
                      handleChangeReturnIncome(
                        income?.income_id,
                        "return_quantity",
                        parseInt(
                          e.target.value <= income?.warehouse_quantity
                            ? e.target.value
                            : income?.warehouse_quantity,
                          10
                        )
                      )
                    }
                    // onChange={(e) =>
                    //   handleChangeIncomeProduct(product?.id, "income_quantity", e.target.value)
                    // }
                  />
                </td>
                <td className="py-3 px-2">
                  {income?.price ? `${REFACTOR_SUM(income?.price)} UZS` : "-"}
                </td>
                <td className="py-3 px-2 pr-4 text-end">
                  {income?.totalPrice ? `${REFACTOR_SUM(income?.totalPrice)} UZS` : "-"}
                </td>
              </tr>
            ))}
          </tbody>
        </table>
      </div>
      <div className="flex items-center justify-end gap-2">
        <p>
          <span className="text-c9">Umumiy summa: </span>
          <span className="text-c17">{REFACTOR_SUM(currentIncomeProduct?.totalPrice)} UZS</span>
        </p>
        {/* <button className="bg-c10 text-c24 w-max px-4 py-2 rounded-xl">Saqlash</button> */}
      </div>
    </div>
  );
}

export default CurrentProductIncomeList;

// category_name: "Kompyuterlar";
// created_at: "2023-09-21T07:37:23.419981+03:00";
// id: "4d5d2e74-2aab-4c9a-aa4a-526b99a90305";
// income_id: "07ec2691-e70c-430d-ac0b-c2d853dec08a";
// price: 9000000;
// product_id: "d8d00155-87dd-417a-bfe7-e0ab5ad0bd4f";
// product_name: "Macbook Pro 2020";
// quantity: 10;
// root_category_name: "Elektron mahsulotlar";
// supplier_id: "117905de-b313-4abd-adfc-6f2f398e8979";
// supplier_name: "Apple.com";
// warehouse_quantity: 9;

// d43bfbfb-9def-4ed3-9aef-0eec0ffabaa7
