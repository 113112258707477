import {
  ComposedChart,
  Bar,
  XAxis,
  YAxis,
  Tooltip,
  ResponsiveContainer,
  Cell,
  LabelList,
} from "recharts";

const colors = ["#008EF5", "#359F46", "#A600F5", "#0CBCBC", "#F56700"];

const CustomTooltip = ({ active, payload, label }) => {
  if (active && payload && payload.length) {
    return (
      <div className="bg-c24 flex flex-col rounded-xl border border-slate-200 p-4 gap-2">
        <div className="flex items-center gap-2">
          <span className="text-c2">{`${label} `}</span>
        </div>

        <div className="flex justify-between gap-8">
          <span className="text-c34">Sotildi</span>
          <span className="text-c2">{payload[0].payload.uv}</span>
        </div>

        <div className="flex justify-between gap-8">
          <span className="text-c34">Umumiy summa</span>
          <span className="text-c2">{payload[0].payload.Umumiy_summa}</span>
        </div>
      </div>
    );
  }

  return null;
};

const BarChartVertical = ({ data }) => {
  return (
    <ResponsiveContainer className="w-full h-full p-0">
      <ComposedChart
        layout="vertical"
        data={data}
        margin={{
          top: 20,
          right: 20,
          bottom: 20,
          left: 20,
        }}
      >
        <XAxis hide type="number" fontSize="0.8rem" textAnchor="left" />
        <YAxis hide dataKey="name" type="category" scale="band" strokeWidth={0} fontSize="0.8rem" />
        <Tooltip content={<CustomTooltip />} />
        <LabelList dataKey="name" fill="#000" position="outside" />
        <Bar dataKey="uv" barSize={22} radius={20}>
          <LabelList dataKey="name" fill="#0E172E" position="insideTopLeft" dy={-23} />
          <LabelList dataKey="uv" fill="#fff" position="insideBottomRight" dx={-5} />
          {data.map((data, key) => (
            <Cell key={key} fill={colors[key]} />
          ))}
        </Bar>
      </ComposedChart>
    </ResponsiveContainer>
  );
};

export default BarChartVertical;
