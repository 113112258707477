import { useState } from "react";
import { useLocation } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { warningOpenSession } from "constants/notifications";
import {
  changeOrderProduct,
  removeOrderProduct,
  chooseActiveProduct,
} from "store/slices/orderSlice";
import MUIDrawer from "components/mui-drawer/MUIDrawer";
import SelectClient from "layers/clients/SelectClient";
import ProductInfo from "layers/products/ProductInfo";
import Input from "components/input/Input";
import { checkingPermissions } from "assets/constants/constants";

function SellingProducts() {
  const dispatch = useDispatch();
  const { pathname } = useLocation();
  const {
    orders: { orders },
    auth: {
      session: { session_id },
    },
  } = useSelector((store) => store);

  const [openDrawer, setOpenDrawer] = useState({ action: null, open: false, product: null });

  const currentPath = pathname.split("/");

  const currentOrder = orders[currentPath[2]];

  const handleOpenDrawer = (action, product) => setOpenDrawer({ action, product, open: true });

  const handleCloseDrawer = () => setOpenDrawer({ ...openDrawer, open: false });

  const handleChangeProduct = (product_id, name, value) => {
    dispatch(
      changeOrderProduct({
        name,
        value,
        id: product_id,
        order_id: currentPath[2],
      })
    );
  };

  const handleRemove = (id) => dispatch(removeOrderProduct({ id, order_id: currentPath[2] }));

  const handleChooseActiveProduct = (product, field) => {
    dispatch(chooseActiveProduct({ product: { ...product, field }, order_id: currentPath[2] }));
  };

  return (
    <>
      <MUIDrawer open={openDrawer?.open} onClose={handleCloseDrawer}>
        {openDrawer?.action === "product-info" && <ProductInfo />}
        {openDrawer?.action === "clients" && <SelectClient onClose={handleCloseDrawer} />}
      </MUIDrawer>

      <div className="bg-c1 h-full rounded overflow-hidden text-sm">
        <div className="flex items-center justify-between p-2 border-b-[1px] border-c7">
          <p className="text-c24">Sotilayotgan mahsulotlar</p>
          {checkingPermissions(["CUSTOMER.READ"]) && (
            <button
              onClick={() => {
                session_id && handleOpenDrawer("clients");
                warningOpenSession(session_id);
              }}
              className={`bg-c2 text-c24 py-2 px-4 rounded text-xs ${
                !session_id ? "cursor-not-allowed opacity-50" : ""
              }`}
            >
              Mijoz{currentOrder?.client?.id ? `: ${currentOrder?.client?.first_name}` : ""}
            </button>
          )}
        </div>
        <div className="relative w-full h-full overflow-y-auto">
          <table className="text-c24 w-full border-collapse mb-16 sm:mb-4">
            <thead className="text-sm outline outline-offset-1 outline-c7 text-c9 sticky top-0">
              <tr className="bg-c1">
                <td className="py-3 pl-2">Nomi</td>
                <td className="py-3 sm:hidden">Narxi</td>
                <td className="py-3">Miqdori</td>
                <td className="py-3">Birligi</td>
                <td className="py-3 pr-2 text-end">Umumiy summa</td>
                <td> </td>
              </tr>
            </thead>
            <tbody>
              {currentOrder?.products?.map((product) => (
                <tr
                  key={product?.id}
                  className={`${currentOrder?.activeProduct?.id === product?.id ? "bg-c2" : ""}`}
                >
                  <td
                    onClick={() => handleChooseActiveProduct(product, "sell_quantity")}
                    className="py-3 pl-2 border-b-[1px] border-c7 cursor-pointer"
                  >
                    {product?.name}
                  </td>
                  <td className="py-3 border-b-[1px] border-c7 sm:hidden">{product?.price}</td>
                  <td className="py-1 border-b-[1px] border-c7 overflow-hidden cursor-pointer">
                    <Input
                      type="text"
                      value={product?.sell_quantity}
                      onClick={() => handleChooseActiveProduct(product, "sell_quantity")}
                      onChange={(value) => handleChangeProduct(product?.id, "sell_quantity", value)}
                      autoFocus={
                        currentOrder?.activeProduct?.id === product?.id &&
                        currentOrder?.activeProduct?.field === "sell_quantity"
                      }
                      inputMode="numeric"
                      defaultNull={true}
                      classNameInput={`w-[4rem] py-2 outline-0 border-0 bg-transparent ${
                        currentOrder?.activeProduct?.id === product?.id &&
                        currentOrder?.activeProduct?.field === "sell_quantity"
                          ? "border-b-[1px]"
                          : ""
                      }`}
                      virtualKeyboard={false}
                    />
                  </td>
                  <td className="py-3 border-b-[1px] border-c7">{product?.unit_name}</td>
                  <td
                    className={`max-w-5r overflow-hidden py-1 pr-2 border-b border-c7 ${
                      product?.unit_type === "weight" ? "cursor-pointer" : ""
                    }`}
                  >
                    <div className={`h-2r flex items-center justify-end `}>
                      <Input
                        type="text"
                        value={product?.sell_price}
                        onClick={() =>
                          product?.unit_type !== "piece" &&
                          handleChooseActiveProduct(product, "sell_price")
                        }
                        onChange={(value) =>
                          product?.unit_type !== "piece" &&
                          handleChangeProduct(product?.id, "sell_price", value)
                        }
                        disabled={product?.unit_type !== "piece" ? false : true}
                        inputMode="numeric"
                        classNameInput={`w-[6rem] text-end py-2 outline-0 border-0 bg-transparent ${
                          currentOrder?.activeProduct?.id === product?.id &&
                          currentOrder?.activeProduct?.field === "sell_price" &&
                          currentOrder?.activeProduct?.unit_type !== "piece"
                            ? "border-b-[1px]"
                            : ""
                        }`}
                        virtualKeyboard={false}
                      />
                    </div>
                  </td>
                  <td
                    onClick={() => handleRemove(product?.id)}
                    className="py-3 pr-2 border-b-[1px] border-c7 text-end text-c16"
                  >
                    <button>✕</button>
                  </td>
                </tr>
              ))}
            </tbody>
          </table>
        </div>
      </div>
    </>
  );
}

export default SellingProducts;
