import { Fragment, useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { getSuppliers } from "store/slices/supplierSlice";
import { chooseSupplier } from "store/slices/incomeSlice";
import CreateSupplier from "layers/supplier/CreateSupplier";
import MUIModal from "components/mui-modal/MUIModal";
import Icon from "assets/icons/icons";
import { checkingPermissions } from "assets/constants/constants";

const SelectSupplier = ({ onClose }) => {
  const dispatch = useDispatch();
  const {
    suppliers: { suppliers, refresh },
  } = useSelector((store) => store);
  const [openModal, setOpenModal] = useState(false);

  const handleOpenModal = () => setOpenModal(true);
  const handleCloseModal = () => setOpenModal(false);

  const handleSelectedSupplier = (id) => {
    dispatch(chooseSupplier(id));
    onClose();
  };

  useEffect(() => {
    dispatch(getSuppliers({ pagination: { page: 1, pageSize: 100 } }));
    handleCloseModal();
  }, [dispatch, refresh]);

  return (
    <>
      <MUIModal
        zIndex={950}
        open={openModal}
        onClose={handleCloseModal}
        children={<CreateSupplier onClose={handleCloseModal} />}
      />

      <div className="bg-c5 text-c24 w-[20rem] h-full overflow-y-scroll relative sm:w-screen">
        <div className="bg-c29 p-4 sticky top-0">
          <span className="text-xl">Ta'minotchi tanlang</span>
        </div>
        {suppliers.map((supplier) => (
          <Fragment key={supplier.id}>
            <div
              className="py-2 flex flex-col gap-1 border-b border-c7"
              onClick={() => handleSelectedSupplier(supplier)}
            >
              <div className="flex items-center px-2">
                <Icon name="person_grey" className="px-2" />
                <span>{supplier.name}</span>
              </div>

              <div className="flex items-center px-2">
                <Icon name="phone" className="px-2 w-[2.3rem]" />
                <span className="text-sm">{supplier.phone}</span>
              </div>
            </div>
          </Fragment>
        ))}

        {checkingPermissions(["SUPPLIER.CREATE"]) && (
          <div className="bg-c5 sticky bottom-0 py-3 px-4">
            <button onClick={handleOpenModal} className="bg-c17 w-full rounded py-3">
              Yangi qo'shish
            </button>
          </div>
        )}
      </div>
    </>
  );
};

export default SelectSupplier;
