import { useEffect, useState } from "react";
import { getUnits } from "store/slices/unitSlice";
import { getBrands } from "store/slices/brandSlice";
import { useDispatch, useSelector } from "react-redux";
import { getPackages } from "store/slices/packageSlice";
import { getCategories } from "store/slices/categorySlice";
import { checkingPermissions } from "assets/constants/constants";
import { createProduct, updateProduct } from "store/slices/productSlice";
import MUISearchSelect from "components/mui-select/MUISearchSelect";
import MUIModal from "components/mui-modal/MUIModal";
import Checkbox from "components/checkbox/Checkbox";
import HtmlInput from "components/input/HtmlInput";
import CreateBlock from "../blocks/CreateBlock";
import CreateBrand from "../brands/CreateBrand";
import CreateCategory from "./CreateCategory";
import CreateUnit from "../units/CreateUnit";
import Icon from "assets/icons/icons";

const CreateProduct = ({ action }) => {
  const dispatch = useDispatch();
  const {
    categories,
    units,
    brands,
    packages,
    products: { currentProduct },
  } = useSelector((store) => store);
  const [openModal, setOpenModal] = useState({ open: false, action: "" });
  const [img, setImg] = useState(null);
  const [blockStatus, setBlockStatus] = useState(
    action === "update" && currentProduct?.package_id ? true : false
  );
  const [product, setProduct] = useState(
    action === "create"
      ? {
          quantity: 0,
        }
      : {}
  );

  const handleOpenModal = (action) => setOpenModal({ open: true, action });

  const handleCloseModal = () => setOpenModal({ ...openModal, open: false });

  const handleChangeProduct = (name, value) =>
    setProduct(
      name === "root_category"
        ? { ...product, [name]: value, category_id: "" }
        : name === "unit_id" && product?.package_id
        ? { ...product, [name]: value, package_id: "" }
        : { ...product, [name]: value }
    );

  const handleSave = () => {
    action === "create"
      ? product?.name &&
        product?.price &&
        product?.barcode &&
        product?.category_id &&
        product?.unit_id &&
        dispatch(createProduct(product))
      : dispatch(updateProduct({ product_id: currentProduct?.id, product }));
  };

  useEffect(() => {
    dispatch(getUnits({}));
    handleCloseModal();
  }, [units?.refresh]);

  useEffect(() => {
    dispatch(getBrands({}));
    handleCloseModal();
  }, [brands?.refresh]);

  useEffect(() => {
    dispatch(
      getPackages({
        type: units?.units?.filter((unit) => unit?.id === product?.unit_id)?.[0]?.type,
      })
    );
    handleCloseModal();
  }, [packages?.refresh, product?.unit_id]);

  useEffect(() => {
    dispatch(
      getCategories({
        withChildren: true,
        onlyRootCategories: true,
      })
    );
    handleCloseModal();
  }, [categories?.refresh]);

  return (
    <>
      <MUIModal
        position="center"
        hideBackdrop={false}
        open={openModal?.open}
        onClose={handleCloseModal}
      >
        {(openModal?.action === "root_category" || openModal?.action === "category") && (
          <CreateCategory
            parent={product?.root_category}
            action={openModal?.action}
            onClose={handleCloseModal}
          />
        )}
        {openModal?.action === "brand" && <CreateBrand action="create" />}
        {openModal?.action === "unit" && <CreateUnit action="create" />}
        {openModal?.action === "block" && <CreateBlock unitId={product?.unit_id} action="create" />}
      </MUIModal>

      <div className="bg-c24 w-[25rem] h-full overflow-y-auto flex flex-col gap-2 p-4 sm:w-screen">
        <div className="text-c2 flex flex-col gap-3">
          <span className="text-2xl font-medium">
            {action === "create" ? "Yangi mahsulot" : "Mahsulotni tahrirlash"}
          </span>

          <span className="text-c2 text-sm">Tovar rasmi</span>

          <div className="bg-c45 px-4 py-4 w-1/2 rounded-lg">
            {img ? (
              <div className="flex justify-center items-center relative">
                <img className="w-[20rem]" src={img} alt="product" />

                <div
                  className="bg-c1 absolute right-0 bottom-0 flex justify-center items-center p-1 rounded-lg"
                  onClick={() => setImg()}
                >
                  <Icon name="trash" />
                </div>
              </div>
            ) : (
              <label htmlFor="formId" className="flex flex-col justify-center items-center">
                <input
                  accept="image/*"
                  name=""
                  type="file"
                  id="formId"
                  hidden
                  onChange={(e) => setImg(URL.createObjectURL(e.target.files[0]))}
                />
                <Icon className="w-12 h-12" name="image" />

                <span className="py-1 text-c10">Rasm qo’shish</span>
              </label>
            )}
          </div>

          <HtmlInput
            bgColor="c31"
            label="Tovar nomi *"
            value={action === "update" ? product?.name || currentProduct?.name : product?.name}
            placeholder="Tovar nomini kiriting"
            onChange={(e) => handleChangeProduct("name", e.target.value)}
          />

          {(action === "create" ||
            (action === "update" && checkingPermissions(["PRODUCT.UPDATE_PRICE"]))) && (
            <HtmlInput
              bgColor="c31"
              label="Narxi *"
              value={action === "update" ? product?.price || currentProduct?.price : product?.price}
              placeholder="Tovar narxini kiriting"
              onChange={(e) => handleChangeProduct("price", e.target.value)}
            />
          )}

          <HtmlInput
            bgColor="c31"
            label="Barcode *"
            value={
              action === "update" ? product?.barcode || currentProduct?.barcode : product?.barcode
            }
            placeholder="Tovar kodi kiriting"
            onChange={(e) => handleChangeProduct("barcode", e.target.value)}
          />

          {categories?.categories?.length && (
            <MUISearchSelect
              label="Kategoriya *"
              localFilter={true}
              createButton={true}
              placeholder="Katgoriya nomi"
              options={categories?.categories}
              optionsLabel={{ label: "name", value: "id" }}
              onCreate={() => handleOpenModal("root_category")}
              onSelected={(e) => handleChangeProduct("root_category", e)}
              value={
                action === "update"
                  ? product?.root_category || currentProduct?.root_category_name
                  : product?.root_category
              }
            />
          )}

          {(product?.root_category || action === "update") && (
            <MUISearchSelect
              localFilter={true}
              createButton={true}
              label="Ichki kategoriya *"
              value={
                action === "update"
                  ? product?.category_id || currentProduct?.category_name
                  : product?.category_id
              }
              placeholder="Ichki kategoriya nomi"
              onCreate={() => handleOpenModal("category")}
              optionsLabel={{ label: "name", value: "id" }}
              onSelected={(e) => handleChangeProduct("category_id", e)}
              options={
                product?.root_category &&
                categories?.categories?.filter(
                  (category) => category?.id === product?.root_category
                )?.[0]?.children
              }
            />
          )}

          {checkingPermissions(["BRAND.READ"]) && (
            <MUISearchSelect
              label="Brand"
              localFilter={true}
              createButton={true}
              placeholder="Brand nomi"
              options={brands?.brands}
              onCreate={() => handleOpenModal("brand")}
              optionsLabel={{ label: "name", value: "id" }}
              onSelected={(e) => handleChangeProduct("brand_id", e)}
              value={
                action === "update"
                  ? product?.brand_id || currentProduct?.brand_name
                  : product?.brand_id
              }
            />
          )}

          <MUISearchSelect
            localFilter={true}
            createButton={true}
            options={units?.units}
            label="O'lchov birligi *"
            placeholder="O'lchov birligi"
            onCreate={() => handleOpenModal("unit")}
            optionsLabel={{ label: "name", value: "id" }}
            onSelected={(e) => handleChangeProduct("unit_id", e)}
            value={
              action === "update" ? product?.unit_id || currentProduct?.unit_name : product?.unit_id
            }
          />

          {checkingPermissions(["PACKAGE.READ"]) && (
            <div className="flex flex-col gap-1">
              <Checkbox
                label="Blok"
                bgColor="bg-c24"
                classNameCheckbox="text-c2"
                classNameLabel="text-c2 text-sm"
                checked={blockStatus ? true : false}
                onChange={() => setBlockStatus(blockStatus ? false : true)}
              />

              {blockStatus && (
                <MUISearchSelect
                  localFilter={true}
                  createButton={true}
                  placeholder="Blok nomi"
                  value={
                    action === "update"
                      ? product?.package_id || currentProduct?.package_name
                      : product?.package_id
                  }
                  options={packages?.packages || []}
                  onCreate={() => handleOpenModal("block")}
                  optionsLabel={{ label: "name", value: "id" }}
                  onSelected={(e) => handleChangeProduct("package_id", e)}
                />
              )}
            </div>
          )}
        </div>

        <button onClick={handleSave} className="bg-c10 text-c24 px-4 py-2 rounded-lg">
          <span>Saqlash</span>
        </button>
      </div>
    </>
  );
};

export default CreateProduct;
