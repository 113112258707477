import { addDays } from "date-fns";
import { axiosPrivate } from "services/axiosPrivate";
import { REFACTOR_DATE } from "assets/constants/constants";
import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";

const initialState = {
  refresh: "",

  // --------------- income

  income: {
    products: [],
    supplier: {},
    expenses: {},
    totalPrice: 0,
    activeProduct: {},
    currentIncomeProduct: {},
  },

  // --------------- income

  // --------------- income list

  incomeList: {
    total: null,
    incomeList: [],
    currentIncome: {},
    filter: {
      search: "",
      supplier: "",
      selection: {
        startDate: null,
        endDate: addDays(0, 0),
      },
      expense_types: [],
    },
    pagination: { page: 1, pageSize: 10 },
  },

  // --------------- income list

  // --------------- return income

  returnIncome: {
    products: [],
    totalPrice: 0,
    returnExpenses: {},
  },

  // --------------- return income

  // --------------- returned incomes

  returnedIncomes: {
    returnedIncomes: [],
    currentReturnedIncome: {},
    pagination: { page: 1, pageSize: 10 },
    filter: {
      search: "",
      supplier: "",
      selection: {
        startDate: null,
        endDate: addDays(0, 0),
      },
      expense_types: [],
    },
  },

  // --------------- returned incomes
};

// --------------- income

export const incomeProducts = createAsyncThunk("income/income-products", async (income) => {
  const response = await axiosPrivate.post("income/post/", income);
  return response.data;
});

const calculateTotalPrice = (products) => {
  let totalPrice = 0;
  products?.length
    ? products?.map(
        (pr) =>
          (totalPrice +=
            parseFloat(pr?.in_price || "0", 10) * parseFloat(pr?.income_quantity || "0", 10))
      )
    : (totalPrice = 0);
  return totalPrice;
};

// --------------- income

// --------------- income list

export const getIncomeList = createAsyncThunk(
  "income/income-products-list",
  async ({
    filter: {
      search,
      supplier,
      expense_types,
      selection: { startDate, endDate },
    },
    pagination: { page, pageSize },
  }) => {
    const response = await axiosPrivate.get(
      `income/list/?page=${page}&page_size=${pageSize}&search=${search}&supplier_id=${supplier}&start_date=${REFACTOR_DATE(
        startDate || ""
      )}&end_date=${
        new Date(endDate).toDateString() !== "Thu Jan 01 1970" ? REFACTOR_DATE(endDate) : ""
      }&expense_types=${expense_types?.map((type) => `${type.value}`)}`
    );
    return response.data;
  }
);

export const getIncome = createAsyncThunk("income/income-product-info", async (id) => {
  const response = await axiosPrivate.get(`income/get/${id}`);
  return response.data;
});

// --------------- income list

// --------------- return income

export const returnIncome = createAsyncThunk("income/return-income", async (return_income) => {
  const response = await axiosPrivate.post(`return_income/post/`, return_income);
  return response.data;
});

export const addReturnIncomeProduct = createAsyncThunk(
  "income/add-return-income-product",
  async ({ supplier_id, product }) => {
    const incomeList = await axiosPrivate.get(
      `income/get-income-item-by-product-and-supplier/?supplier_id=${supplier_id}&product_id=${product?.id}`
    );

    return {
      ...product,
      return_quantity: 1,
      ...calculateReturnProductIncomeListReturnQuantity(incomeList?.data?.data, 1),
    };
  }
);

const calculateReturnIncomeTotalPrice = (products) => {
  let totalPrice = 0;
  products?.map((pr) => (totalPrice += pr?.totalPrice));
  return totalPrice;
};

const calculateReturnProductIncomeListReturnQuantity = (list, returning_quantity) => {
  let return_quantity = returning_quantity || 0;
  let totalPrice = 0;
  const incomeList = list?.map((l) => {
    if (return_quantity) {
      if (return_quantity > l?.warehouse_quantity) {
        return_quantity -= l.warehouse_quantity;
        totalPrice += l.warehouse_quantity * l.price;
        return {
          ...l,
          return_quantity: l?.warehouse_quantity,
          totalPrice: l.warehouse_quantity * l.price,
        };
      } else {
        const count = return_quantity;
        return_quantity = 0;
        totalPrice += count * l.price;
        return { ...l, return_quantity: count, totalPrice: count * l.price };
      }
    } else return { ...l, return_quantity: 0, totalPrice: 0 };
  });

  return {
    totalPrice,
    incomeList,
  };
};

// --------------- return income

// --------------- returned incomes

export const getReturnedIncomes = createAsyncThunk(
  "income/get-returned-incomes",
  async ({
    filter: {
      search,
      supplier,
      expense_types,
      selection: { startDate, endDate },
    },
    pagination: { page, pageSize },
  }) => {
    const response = await axiosPrivate.get(
      `return_income/list/?page=${page || ""}&page_size=${pageSize || ""}&search=${
        search || ""
      }&supplier=${supplier}&expense_types=${expense_types?.map(
        (type) => `${type.value}`
      )}&start_date=${REFACTOR_DATE(startDate || "")}&end_date=${
        new Date(endDate).toDateString() !== "Thu Jan 01 1970" ? REFACTOR_DATE(endDate) : ""
      }`
    );
    return response.data;
  }
);

export const getReturnedIncome = createAsyncThunk(
  "income/get-returned-income-by-id",
  async (id) => {
    let returned_expense = 0;
    const response = await axiosPrivate.get(`return_income/get/${id}`);

    response?.data?.items?.map(
      (item) =>
        item?.returned_quantity && (returned_expense += item?.returned_quantity * item?.price)
    );
    return { ...response.data, returned_expense };
  }
);

// --------------- returned incomes

export const incomeSlice = createSlice({
  name: "income",
  initialState,
  reducers: {
    // --------------- income

    chooseSupplier: (state, action) => {
      state.income.supplier = action.payload;
    },

    addIncomeProduct: (state, action) => {
      const testProduct = state.income.products.filter((p) => p.id === action.payload?.id);

      if (testProduct?.length) {
        const products = state?.income?.products?.map((p) =>
          p.id === action.payload?.id
            ? {
                ...p,
                income_quantity: parseFloat(p.income_quantity || 0) + 1,
              }
            : p
        );
        state.income.products = products;
        state.income.activeProduct = {
          ...products?.filter((p) => p?.id === action?.payload?.id)?.[0],
          field: "income_quantity",
        };
      } else {
        state.income.products.unshift({
          ...action.payload,
          income_quantity: action?.payload?.package_quantity || 1,
          in_pack_quantity: action?.payload?.package_quantity ? 1 : 0,
          in_price: action?.payload?.income_price,
        });
        state.income.activeProduct = {
          ...action.payload,
          income_quantity: action?.payload?.package_quantity || 1,
          in_pack_quantity: action?.payload?.package_quantity ? 1 : 0,
          in_price: action?.payload?.income_price,
          field: "income_quantity",
        };
      }
      state.income.totalPrice = calculateTotalPrice(state.income.products);
      state.income.expenses = {
        cash: { type: "cash", status: true, price: state.income.totalPrice },
      };
    },

    chooseActiveProduct: (state, action) => {
      state.income.activeProduct = action.payload;
    },

    changeIncomeProduct: (state, action) => {
      const products = state.income.products.map((p) =>
        p?.id === action.payload?.id
          ? p?.package_quantity &&
            (action?.payload?.name === "income_quantity" ||
              action?.payload?.name === "in_pack_quantity")
            ? {
                ...p,
                [action?.payload?.name]: action?.payload?.value,
                [action?.payload?.name === "income_quantity"
                  ? "in_pack_quantity"
                  : "income_quantity"]:
                  action?.payload?.name === "income_quantity"
                    ? action.payload.value / p.package_quantity
                    : action.payload.value * p.package_quantity,
              }
            : { ...p, [action?.payload?.name]: action?.payload?.value }
          : p
      );
      state.income.products = products;
      state.income.totalPrice = calculateTotalPrice(state.income.products);
      state.income.expenses = state.income.totalPrice
        ? {
            cash: { type: "cash", status: true, price: state.income.totalPrice },
          }
        : {};
    },

    removeIncomeProduct: (state, action) => {
      const products = state.income.products.filter((p) => p.id !== action.payload);
      state.income.expenses = {};
      state.income.products = products;
      state.income.totalPrice = calculateTotalPrice(state.income.products);
      state.income.expenses = state.income.totalPrice
        ? {
            cash: { type: "cash", status: true, price: state.income.totalPrice },
          }
        : {};
    },

    changeIncomeExpenses: (state, action) => {
      state.income.expenses = action.payload;
    },

    // --------------- income

    // --------------- income list

    changePaginationIncomeList: (state, action) => {
      state.incomeList.pagination[action.payload.name] = action.payload.value;
      action.payload.name === "pageSize" &&
        Math.ceil(state.incomeList.total / state.incomeList.pagination.pageSize) <=
          state.incomeList.pagination.page &&
        (state.incomeList.pagination.page = Math.ceil(
          state.incomeList.total / state.incomeList.pagination.pageSize
        ));
    },

    changeIncomeListFilter: (state, action) => {
      state.incomeList.filter = action.payload;
    },

    // --------------- income list

    // --------------- return income

    checkedAllIncomeProduct: (state) => {
      state.incomeList.currentIncome.checkedAll = !state?.incomeList?.currentIncome?.checkedAll;
      state.incomeList.currentIncome.items = state.incomeList.currentIncome.items.map((item) => ({
        ...item,
        checked: state?.incomeList?.currentIncome?.checkedAll,
      }));

      state.incomeList.currentIncome.checkedCount = state?.incomeList?.currentIncome?.checkedAll
        ? state?.currentIncome?.items?.length
        : 0;
    },

    checkedIncomeProduct: (state, action) => {
      let checkedCount = 0;
      state.incomeList.currentIncome.items = state?.incomeList?.currentIncome?.items?.map((item) =>
        item?.id === action.payload ? { ...item, checked: item?.checked ? false : true } : item
      );

      state.incomeList.currentIncome.items.map((item) => item?.checked && (checkedCount += 1));
      state.checkedCount = checkedCount;

      checkedCount === state?.incomeList?.currentIncome?.items?.length
        ? (state.incomeList.currentIncome.checkedAll = true)
        : (state.incomeList.currentIncome.checkedAll = false);
    },

    clearReturnIncome: (state) => {
      state.returnIncome.products = [];
      state.returnIncome.totalPrice = 0;
    },

    changeReturnIncomeProduct: (state, { payload: { id, name, value } }) => {
      const products = state.returnIncome.products.map((p) =>
        p?.id === id
          ? name === "return_quantity"
            ? {
                ...p,
                [name]: value,
                ...calculateReturnProductIncomeListReturnQuantity(p?.incomeList, value),
              }
            : { ...p, [name]: value }
          : p
      );

      state.returnIncome.products = products;
      state.returnIncome.totalPrice = calculateReturnIncomeTotalPrice(state.returnIncome.products);
    },

    changeReturnProductReturnIncome: (
      state,
      { payload: { product_id, income_id, name, value } }
    ) => {
      const products = state.returnIncome.products.map((product) => {
        let totalPrice = 0;
        if (product?.id === product_id) {
          let return_quantity = 0;
          const incomeList = product?.incomeList?.map((income) => {
            if (income?.income_id === income_id) {
              if (name === "return_quantity" && value) {
                if (return_quantity + value <= product?.quantity) {
                  return_quantity += value;
                  totalPrice += value * income.price;
                  return { ...income, [name]: value, totalPrice: value * income.price };
                } else {
                  const add = product.quantity - return_quantity;
                  return_quantity = product.quantity;
                  totalPrice += add * income.price;
                  return { ...income, [name]: add, totalPrice: add * income.price };
                }
              }
              return { ...income, [name]: value, totalPrice: "" };
            } else {
              return_quantity += income?.return_quantity || 0;
              totalPrice += income?.totalPrice || 0;
              return income;
            }
          });
          return { ...product, incomeList, return_quantity, totalPrice };
        } else return product;
      });

      state.returnIncome.products = products;
      state.returnIncome.totalPrice = calculateReturnIncomeTotalPrice(state.returnIncome.products);
    },

    changeReturnExpenses: (state, action) => {
      state.returnIncome.returnExpenses = action.payload;
    },

    removeReturnIncomeProduct: (state, action) => {
      const products = state.returnIncome.products.filter((p) => p.id !== action.payload);
      state.returnIncome.products = products;
      state.returnIncome.totalPrice = calculateReturnIncomeTotalPrice(state.returnIncome.products);
    },

    // --------------- return income

    // --------------- returned incomes
    changeReturnedIncomesFilter: (state, action) => {
      state.returnedIncomes.filter = action.payload;
    },

    // --------------- returned incomes
  },

  extraReducers: (builder) => {
    builder
      // --------------- income

      .addCase(incomeProducts.fulfilled, (state) => {
        state.refresh = `income-${new Date()}`;
        state.income.products = [];
        state.income.supplier = {};
        state.income.totalPrice = 0;
      })

      // --------------- income

      // --------------- income list

      .addCase(getIncomeList.fulfilled, (state, action) => {
        state.incomeList.incomeList = action.payload.data;
        state.incomeList.total = action.payload.total;
      })
      .addCase(getIncome.fulfilled, (state, action) => {
        state.incomeList.currentIncome = action.payload;
      })

      // --------------- income list

      // --------------- return income

      .addCase(returnIncome.fulfilled, (state) => {
        state.refresh = `return-income-${new Date()}`;
        state.returnIncome.products = [];
        state.returnIncome.totalPrice = 0;
      })
      .addCase(addReturnIncomeProduct.fulfilled, (state, action) => {
        const testProduct = state.returnIncome.products.filter((p) => p.id === action.payload?.id);

        if (!testProduct?.length) {
          state.returnIncome.products.unshift(action.payload);
          state.returnIncome.totalPrice = calculateReturnIncomeTotalPrice(
            state.returnIncome.products
          );
        }
      })
      // --------------- return income

      // --------------- returned incomes

      .addCase(getReturnedIncomes.fulfilled, (state, action) => {
        state.returnedIncomes.returnedIncomes = action.payload.data;
        state.returnedIncomes.total = action.payload.total;
      })
      .addCase(getReturnedIncome.fulfilled, (state, action) => {
        state.returnedIncomes.currentReturnedIncome = action.payload;
      });

    // --------------- returned incomes
  },
});

const { reducer, actions } = incomeSlice;

export const {
  // --------------- income

  chooseSupplier,
  addIncomeProduct,
  removeIncomeProduct,
  chooseActiveProduct,
  changeIncomeProduct,
  changeIncomeExpenses,

  // --------------- income

  // --------------- income list

  changeIncomeListFilter,
  changePaginationIncomeList,

  // --------------- income list

  // --------------- return income

  clearReturnIncome,
  changeReturnExpenses,
  checkedIncomeProduct,
  checkedAllIncomeProduct,
  changeReturnIncomeProduct,
  removeReturnIncomeProduct,
  changeReturnProductReturnIncome,

  // --------------- return income

  // --------------- returned incomes

  changeReturnedIncomesFilter,

  // --------------- returned incomes
} = actions;

export default reducer;
