import { useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { createCategory } from "store/slices/categorySlice";
import HtmlInput from "components/input/HtmlInput";

const CreateCategory = ({ action, parent }) => {
  const dispatch = useDispatch();
  const {
    categories: { categories },
  } = useSelector((store) => store);
  const [category, setCategory] = useState(
    action === "parent" ? { name: "" } : { name: "", parent_id: parent }
  );

  const handleSave = () => dispatch(createCategory(category));

  return (
    <div className="w-[20rem] bg-c24 flex flex-col gap-4 px-4 py-6 rounded-lg">
      <span className="text-c2 text-xl font-medium">Kategoriya qo’shish</span>

      {action === "category" ? (
        <span className="text-c2">
          Parent: {categories?.filter((c) => c?.id === parent)?.[0]?.name}
        </span>
      ) : (
        <HtmlInput
          bgColor="c31"
          value={category?.name}
          label="Kategoriya nomi"
          placeholder="Nomini kiriting"
          classNameLabel="text-c2 text-sm mb-1"
          classNameInput="w-full h-10 border border-c32 px-2 rounded-xl outline-none"
          onChange={(e) => setCategory({ ...category, name: e.target.value })}
        />
      )}

      {action === "category" && (
        <HtmlInput
          bgColor="c31"
          value={category?.name}
          label="Sub kategoriya nomi"
          placeholder="Nomini kiriting"
          classNameLabel="text-c2 text-sm mb-1"
          classNameInput="w-full h-10 border border-c32 px-2 rounded-xl outline-none"
          onChange={(e) => setCategory({ ...category, name: e.target.value })}
        />
      )}

      <button onClick={handleSave} className="h-10 bg-c10 text-c24 p-2 rounded-xl">
        Saqlash
      </button>
    </div>
  );
};

export default CreateCategory;
