import { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { REFACTOR_SUM } from "assets/constants/constants";
import { getOrdersHistoryItem } from "store/slices/orderSlice";
import Icon from "assets/icons/icons";

const paymentTypeOptions = {
  card: {
    bgColor: "bg-c37",
    color: "text-c10",
    icon: "cardBlue",
    label: "Karta",
  },
  cash: {
    bgColor: "bg-c38",
    color: "text-c17",
    icon: "payment",
    label: "Naqd",
  },
  debt: {
    bgColor: "bg-c41",
    color: "text-c14",
    icon: "shopping",
    label: "Nasiya",
  },
  other: {
    bgColor: "bg-c39",
    color: "text-c21",
    icon: "more",
    label: "Boshqa",
  },
};

function CheckList({ currentOrder }) {
  const dispatch = useDispatch();
  const {
    orders: {
      ordersHistory: {
        historyItem: { id, payment_type, items, paid, created_at, total_amount },
      },
    },
  } = useSelector((store) => store);

  useEffect(() => {
    dispatch(getOrdersHistoryItem(currentOrder?.id));
  }, []);

  return (
    <div className="bg-c2 text-c24 w-[30rem] h-full flex flex-col sm:w-screen">
      <div className="flex flex-col justify-center gap-1 px-4 h-[6rem]">
        <p className="text-xl">Buyurtma: {id}</p>
        <div className="flex items-center gap-6 text-sm">
          {created_at && (
            <div className="flex items-center gap-2">
              <Icon name="timer" className="opacity-50" />
              {new Date(created_at)?.toLocaleString()?.substr(12, 5)}
            </div>
          )}
          {payment_type && (
            <div className="flex items-center gap-2">
              <Icon name={paymentTypeOptions?.[payment_type]?.icon} className="opacity-50" />
              <span>{paymentTypeOptions?.[payment_type]?.label}</span>
            </div>
          )}
        </div>
      </div>

      <div className="h-calc([100%-6rem-8rem]) relative overflow-y-auto">
        <table className="w-full border-collapse">
          <thead className="sticky top-0">
            <tr>
              <td className="bg-c1 py-3 pl-4 text-start">Nomi</td>
              <td className="bg-c1 py-3 text-center">Hajmi/kg</td>
              <td className="bg-c1 py-3 pr-4 text-end">Narxi</td>
            </tr>
          </thead>

          <tbody>
            {items?.map((product, idx) => (
              <tr key={product?.id}>
                <td className="border-b border-c7 py-3 pl-4 text-start">{product?.product_name}</td>
                <td className="border-b border-c7 py-3 text-center">{product?.quantity}</td>
                <td className="border-b border-c7 py-3 pr-4 text-end">
                  {product?.price ? `${REFACTOR_SUM(product?.price)} UZS` : "-"}
                </td>
              </tr>
            ))}
          </tbody>
        </table>
      </div>

      <div className="bg-c5 h-[8rem] w-full flex flex-col justify-center absolute bottom-0">
        <div className="flex justify-between items-center border-t border-c7">
          <span className="px-4 py-2 text-c9">To'landi</span>
          <span className="px-4 py-2">{REFACTOR_SUM(total_amount)} UZS</span>
        </div>

        <div className="flex justify-between items-center border-t border-c7">
          <span className="px-4 py-2 text-c9">Nasiya</span>
          <span className="px-4 py-2 text-c14">
            {total_amount !== paid ? `${REFACTOR_SUM(total_amount - paid)} UZS` : "-"}
          </span>
        </div>

        <div className="flex justify-between items-center border-t border-c7">
          <span className="px-4 py-2 text-c9">Umumiy savdo</span>
          <span className="px-4 py-2 text-lg">{REFACTOR_SUM(total_amount)} UZS</span>
        </div>
      </div>
    </div>
  );
}

export default CheckList;
