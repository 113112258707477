import { Modal } from "@mui/material";
import Icon from "assets/icons/icons";

function MUIModal({
  open,
  zIndex,
  onClose,
  children,
  position,
  hideBackdrop = false,
  hideCloseButton = false,
}) {
  return (
    <Modal
      open={open}
      style={{ zIndex }}
      onClose={onClose}
      hideBackdrop={hideBackdrop}
      className={`flex justify-center m-4 outline-none ${
        position === "center" ? "items-center" : "sm:items-center"
      }`}
    >
      <div className="relative outline-none">
        {children}

        {!hideCloseButton && (
          <button
            onClick={onClose}
            className="bg-c14 flex items-center justify-center absolute top-2 right-2 p-2 rounded"
          >
            <Icon name="close" />
          </button>
        )}
      </div>
    </Modal>
  );
}

export default MUIModal;
