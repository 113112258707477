import { createSlice } from "@reduxjs/toolkit";

const initialState = {
  id: "",
  value: "",
  input: null,
  inputMode: "",
  defaultNull: false,
  openKeyboard: false,
};

export const keyboardSlice = createSlice({
  name: "keyboard",
  initialState,
  reducers: {
    chooseInput: (state, action) => {
      state.id = action.payload.id;
      state.input = action.payload.input;
      state.value = action.payload.value;
      state.inputMode = action.payload.inputMode;
      state.defaultNull = action.payload.defaultNull;
    },

    openOrCloseKeyboard: (state, action) => {
      state.openKeyboard = action.payload.status;
      state.inputMode = action.payload.inputMode;
    },

    changeInputValue: (state, action) => {
      // if (state.input) {
      // state.input.value = action.payload;
      state.value = action.payload;
      // }
    },
  },
});

const { actions, reducer } = keyboardSlice;

export const { chooseInput, changeInputValue, openOrCloseKeyboard } = actions;

export default reducer;
