import { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { createCategory, updateCategory } from "store/slices/categorySlice";
import HtmlInput from "components/input/HtmlInput";
import MUITabs from "components/mui-tabs/MUITabs";
import Select from "components/select/Select";

function CreateCategory({ bgColor, color, action, currentCategory }) {
  const dispatch = useDispatch();
  const {
    categories: { categories },
  } = useSelector((store) => store);

  const [panel, setPanel] = useState(action === "create" ? 0 : currentCategory?.parent_id ? 1 : 0);
  const [category, setCategory] = useState({});

  const handleChangeCategory = (name, value) => setCategory({ ...category, [name]: value });

  const handleSave = () =>
    action === "create"
      ? ((panel && category?.name && category?.parent_id) || (!panel && category?.name)) &&
        dispatch(createCategory(category))
      : dispatch(
          updateCategory(
            category?.parent_id
              ? {
                  category_id: currentCategory?.id,
                  category: { name: category?.name, parent_id: category?.parent_id },
                }
              : {
                  category_id: currentCategory?.id,
                  category: { name: category?.name },
                }
          )
        );

  useEffect(() => {
    action === "create"
      ? setCategory(panel === 0 ? { name: "" } : { name: "", parent: "" })
      : setCategory(currentCategory);
  }, [panel, action]);

  return (
    <div
      className={`p-4 w-[20rem] h-full flex flex-col gap-4 overflow-hidden bg-${bgColor} sm:w-screen`}
    >
      <span className={`text-${color} text-2xl`}>
        {action === "create" ? " Yangi kategoriya" : "Kategoriyani tahrirlash"}
      </span>

      <MUITabs
        color="c2"
        className="bg-c45 rounded-3xl"
        panel={panel}
        setPanel={setPanel}
        tabs={["Asosiy kategoriya", "Ichki kategoriya"]}
      />

      {panel === 0 ? (
        <HtmlInput
          value={category?.name || ""}
          label="Kategoriya nomi *"
          placeholder="Kategoriya"
          onChange={(e) => handleChangeCategory("name", e.target.value)}
        />
      ) : (
        <div className={`text-${bgColor} flex flex-col gap-3`}>
          <HtmlInput
            label="Kategoriya nomi *"
            placeholder="Kategoriya"
            value={category?.name || ""}
            onChange={(e) => handleChangeCategory("name", e.target.value)}
          />

          <Select
            options={categories}
            id="main-category"
            label="Asosiy kategoriya *"
            classNameLabel="text-c2 text-sm"
            value={category?.parent_id || ""}
            arrowPosition="select-arrow-start-dark"
            optionsLabel={{ label: "name", value: "id" }}
            onChange={(e) => handleChangeCategory("parent_id", e.target.value)}
            classNameSelect="bg-c24 h-10 w-full px-2 rounded-xl text-c2 border border-c32 outline-none bg-arrow-down-gray bg-no-repeat bg-right bg-[center_right_0.5rem] select-arrow-none"
          />
        </div>
      )}

      <button onClick={handleSave} className="bg-c10 text-c24 text-center px-3 rounded-xl h-10">
        Saqlash
      </button>
    </div>
  );
}

export default CreateCategory;
