import { useState } from "react";
import { ADMIN_ROUTES } from "routes";
import { Link, useLocation } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { changeCollapse } from "store/slices/sidebarSlice";
import { checkingPermissions } from "assets/constants/constants";
import { openSession, closeSession, logout } from "store/slices/authSlice";
import MUIAccordionMenu from "components/mui-accordion/MUIAccordionMenu";
import Icon from "assets/icons/icons";

const handleCheckingCollapsesPermissions = (route) => {
  if (!route?.noCollapse) {
    let count = 0;
    route?.collapses?.map((collapse) => checkingPermissions(collapse?.permissions) && count++);

    return count > 0 ? true : false;
  } else return route?.noCollapse;
};

const AdminSidebar = () => {
  const { pathname } = useLocation();
  const dispatch = useDispatch();
  const [activeRoute, setActiveRoute] = useState(pathname?.split("/")?.[2]);

  const {
    auth: {
      session: { session_id },
    },
    sidebar: { isCollapsed },
  } = useSelector((store) => store);

  const currentPath = pathname?.split("/");

  const handleChangeSession = () => {
    dispatch(session_id ? closeSession() : openSession());
  };

  const handleLogout = () => dispatch(logout());

  const handleChangeCollapse = () => dispatch(changeCollapse(true));

  // const handleNavigateNextRoute = (route) => {
  //   return `${route?.route}/${
  //     route?.collapses?.filter((col) => checkingPermissions(col?.permissions))?.[0]?.route
  //   }`;
  // };

  return (
    <div
      className={`bg-c2 flex flex-col gap-2 justify-between w-[20rem] h-full relative sm:fixed sm:top-0 sm:left-0 overflow-y-auto transition duration-200 ease-in-out z-[1] ${
        isCollapsed ? "sm:translate-x-[-100%]" : ""
      }`}
    >
      <div className="flex flex-col gap-2">
        <div className="flex flex-col gap-2 p-4">
          <div className="bg-c24 p-4 rounded">
            <span className="text-c1">Logo</span>
          </div>

          <div className="bg-c25 flex items-center p-2 gap-2 rounded">
            <div className="bg-c24 w-8 h-8 rounded-full" />
            <div>
              <span className="text-c24 text-sm">Fakhriyor</span>
              <span className="text-c25 text-xs block">CEO</span>
            </div>

            <Icon className="absolute right-4" name="next" />
          </div>
        </div>

        {ADMIN_ROUTES.map(
          (route) =>
            route?.type === "screen" &&
            handleCheckingCollapsesPermissions(route) && (
              <MUIAccordionMenu
                key={route?.key}
                id={route?.route}
                active={activeRoute}
                noCollapse={route?.noCollapse}
                onClick={() => setActiveRoute(route?.route)}
                summary={
                  route?.noCollapse ? (
                    <Link
                      to={`/admin/${route?.route}`}
                      key={route?.key}
                      state={{ goBack: pathname }}
                      onClick={!route?.collapses?.length && handleChangeCollapse}
                      className="w-full"
                    >
                      <div className="flex items-center gap-2">
                        {route?.icon}
                        <span
                          className={`whitespace-nowrap ${
                            currentPath?.[2] === route?.route ? "text-c10" : "text-c24"
                          }`}
                        >
                          {route?.name}
                        </span>
                      </div>
                    </Link>
                  ) : (
                    <div className="flex items-center gap-2">
                      {route?.icon}
                      <span
                        className={`whitespace-nowrap ${
                          currentPath?.[2] === route?.route ? "text-c10" : "text-c24"
                        }`}
                      >
                        {route?.name}
                      </span>
                    </div>
                  )
                }
              >
                <div className="bg-c35 flex flex-col gap-4 py-2">
                  {route?.collapses?.map(
                    (collapse) =>
                      collapse?.type === "screen" &&
                      checkingPermissions(collapse?.permissions) && (
                        <Link
                          to={{ pathname: `/admin/${route?.route}/${collapse?.route}` }}
                          key={collapse?.key}
                          state={{ goBack: pathname }}
                          className={`flex text-sm pl-12 justify-between`}
                        >
                          <span
                            className={`text-sm ${
                              currentPath?.[2] === route?.route &&
                              currentPath?.[3] === collapse?.route
                                ? "text-c10"
                                : "text-c24"
                            }`}
                            onClick={handleChangeCollapse}
                          >
                            {collapse?.name}
                          </span>
                          <div
                            className={`w-[4px] rounded-l-2xl ${
                              currentPath?.[3] === collapse?.route ? "bg-c10" : ""
                            }`}
                          />
                        </Link>
                      )
                  )}
                </div>
              </MUIAccordionMenu>
            )
        )}
      </div>

      <div className="flex flex-col gap-2 w-full p-4">
        <button
          onClick={handleChangeSession}
          className="w-full mx-auto bg-c10 text-c24 text-md py-2 px-3 rounded"
        >
          {session_id ? "Sessiyani yopish" : "Sessiya ochish"}
        </button>
        <button
          onClick={handleLogout}
          className="w-full mx-auto bg-c14 text-c24 text-md py-2 px-3 rounded"
        >
          Chiqish
        </button>
      </div>
    </div>
  );
};

export default AdminSidebar;
