import { useState } from "react";
import { useDispatch } from "react-redux";
import { UNIT_TYPES } from "assets/constants/constants";
import { createUnit, updateUnit } from "store/slices/unitSlice";
import HtmlInput from "components/input/HtmlInput";
import Select from "components/select/Select";

const CreateUnit = ({ bgColor = "c24", color, action, currentUnit }) => {
  const dispatch = useDispatch();
  const [unit, setUnit] = useState(action === "create" ? { name: "", type: "" } : currentUnit);

  const handleChangeClient = (name, value) => setUnit({ ...unit, [name]: value });

  const handleSave = () =>
    action === "create"
      ? unit?.name && unit?.type && dispatch(createUnit(unit))
      : dispatch(updateUnit({ unit_id: currentUnit?.id, unit }));

  return (
    <div className={`bg-${bgColor} w-[25rem] p-6 rounded-lg flex flex-col gap-4 sm:w-[90vw]`}>
      <span className={`text-${color} text-2xl`}>
        {action === "create" ? "O'lchov birliklari qo'shish" : "O'lchov birligini tahrirlash"}
      </span>

      <HtmlInput
        color="c2"
        label="Nomi *"
        value={unit?.name}
        placeholder="Nomini kiriting"
        onChange={(e) => handleChangeClient("name", e.target.value)}
      />

      <Select
        label="Turi *"
        value={unit?.type}
        options={UNIT_TYPES}
        classNameLabel="text-c2 text-sm"
        optionsLabel={{ label: "name", value: "value" }}
        onChange={(e) => handleChangeClient("type", e.target.value)}
        classNameSelect="bg-c24 w-full h-10 px-2 pl-8 rounded-xl text-c2 border border-c32 outline-none bg-arrow-down-gray bg-no-repeat bg-left bg-[center_left_0.4rem] select-arrow-none"
      />

      <button
        onClick={handleSave}
        className="bg-c10 text-c24 flex items-center justify-center rounded-xl px-3 h-10"
      >
        Saqlash
      </button>
    </div>
  );
};

export default CreateUnit;
