import { ResponsiveContainer, BarChart, Tooltip, XAxis, YAxis, Bar } from "recharts";

const CustomTooltip = ({ active, payload, label }) => {
  if (active && payload && payload.length) {
    return (
      <div className="bg-c24 flex flex-col rounded-xl border border-slate-200 p-4 gap-2">
        <div className="flex items-center gap-2">
          <span className="text-c2">{`${label}`}</span>
        </div>

        <div className="flex justify-between gap-8">
          <span className="text-c34">Sotildi</span>
          <span className="text-c2">{payload[0].payload.Sotildi}</span>
        </div>

        <div className="flex justify-between gap-8">
          <span className="text-c34">Umumiy summa</span>
          <span className="text-c2">{payload[0].payload.Umumiy_summa}</span>
        </div>

        <div className="flex justify-between gap-8">
          <span className="text-c34">Sof foyda</span>
          <span className="text-c17" sx={{ color: "#359f46" }}>
            {payload[0].payload.Sof_foyda}
          </span>
        </div>
      </div>
    );
  }

  return null;
};

function CustomizedAxisTick({ x, y, stroke, payload }) {
  return (
    <g transform={`translate(${x},${y})`}>
      <text
        x={15}
        y={0}
        dy={4}
        fill="#fff"
        textAnchor="start"
        transform="rotate(-90)"
        className="text-end text-sm"
      >
        {payload.value}
      </text>
    </g>
  );
}

const BarCharts = ({ data }) => {
  return (
    <ResponsiveContainer className="p-0 w-full">
      <BarChart data={data}>
        <Bar animationDuration={1000} radius={20} barSize={20} dataKey="Sotildi" fill="#359F46" />
        <XAxis dx={0} interval={0} dataKey="name" strokeOpacity="0" tick={<CustomizedAxisTick />} />
        <YAxis fontSize="0.8rem" width={35} fill="#677581" strokeOpacity="0" tickMargin={5} />
        <Tooltip
          animationEasing="ease-out"
          active
          cursor={{ fill: "transparent" }}
          content={<CustomTooltip />}
        />
      </BarChart>
    </ResponsiveContainer>
  );
};

export default BarCharts;
