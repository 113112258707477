import { useState } from "react";
import { useDispatch } from "react-redux";
import { createSupplier } from "store/slices/supplierSlice";
import Input from "components/input/Input";

const CreateSupplier = () => {
  const dispatch = useDispatch();
  const [supplier, setSupplier] = useState({ name: "", contact_name: "", phone: "" });

  const handleChangeSupplier = (name, value) => {
    setSupplier({ ...supplier, [name]: value });
  };

  const handleSave = () => {
    supplier?.name &&
      supplier?.contact_name &&
      supplier?.phone &&
      dispatch(createSupplier(supplier));
  };

  return (
    <div className="bg-c2 text-c24 w-[25rem] p-6 rounded flex flex-col gap-4 sm:w-[90vw]">
      <p className="text-xl">Ta'minotchi qo'shish</p>

      <Input
        value={supplier?.name}
        label="Ta'minotchi nomi *"
        onChange={(e) => handleChangeSupplier("name", e)}
        inputMode="text"
        placeholder="Nomi"
        classNameInput="w-full border border-c7 text-c24 rounded text-sm bg-c1 p-3 outline-none"
        virtualKeyboard={true}
      />

      <Input
        value={supplier?.contact_name}
        label="Ta'minotchi ism va familiyasi *"
        onChange={(e) => handleChangeSupplier("contact_name", e)}
        inputMode="text"
        placeholder="Ism va familiyasi"
        classNameInput="w-full border border-c7 text-c24 rounded text-sm bg-c1 p-3 outline-none"
        virtualKeyboard={true}
      />

      <Input
        mask="+\9\98 99 999 99 99"
        label="Telefon raqami *"
        value={supplier?.phone}
        onChange={(e) => handleChangeSupplier("phone", e)}
        inputMode="tel"
        placeholder="+998 99 999 99 99"
        classNameInput="w-full border border-c7 text-c24 rounded text-sm bg-c1 p-3 outline-none"
        virtualKeyboard={true}
      />

      <button onClick={handleSave} className="bg-c17 flex items-center justify-center rounded p-3">
        Saqlash
      </button>
    </div>
  );
};

export default CreateSupplier;
