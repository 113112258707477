import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import { axiosPrivate } from "services/axiosPrivate";
import { axiosPublic } from "services/axiosPublic";
import { toast } from "react-toastify";
// import { ADMIN_ROUTES } from "routes";

const initialState = {
  refresh: "",
  session: JSON.parse(localStorage?.getItem("session")) || {},
  userInfo: JSON.parse(localStorage.getItem("user_data"))?.user_info || {},
  routes: { admin: [], operator: [] },
  isLoading: false,
};

const url = "auth/login/";

// const adminRoutesFilter = (permissions) => {
//   console.log(permissions);
// };

export const login = createAsyncThunk("auth/login", async (user) => {
  try {
    const response = await axiosPublic.post(url, user);

    const user_data = await axiosPublic.post("auth/me/", {
      access_token: response.data.access_token,
    });

    localStorage.setItem(
      "user_data",
      JSON.stringify({ user_info: user_data?.data, token: response?.data?.access_token })
    );

    window.location.replace("/");

    return user_data?.data;
  } catch (error) {
    toast.error("Telefon nomer yoki parol xato.");
  }
});

export const getUserInfo = createAsyncThunk("auth/get-user-info", async () => {
  const token = JSON.parse(localStorage.getItem("user_data"))?.token;
  if (token) {
    const response = await axiosPublic.post("auth/me/", {
      access_token: token,
    });

    // adminRoutesFilter(response?.data?.permissions);

    localStorage.setItem("user_data", JSON.stringify({ user_info: response?.data, token: token }));

    // console.log(response);
    return response.data;
  }
});

export const logout = createAsyncThunk("auth/logout", async () => {
  // await axiosPrivate.get("auth/logout/");

  localStorage.clear();
  window.location.replace("/");

  // return response.data;
});

export const openSession = createAsyncThunk("auth/open-session", async () => {
  const response = await axiosPrivate.post("session/start/", {
    token: localStorage.getItem("user_data").token,
  });

  localStorage.setItem(
    "session",
    JSON.stringify({ session: response.data, session_id: response.data.id })
  );

  return response.data;
});

export const closeSession = createAsyncThunk("auth/close-session", async () => {
  const response = await axiosPrivate.post(
    "session/stop/",
    localStorage.getItem("user_data").token
  );

  localStorage.removeItem("session");

  return response.data;
});

export const authSlice = createSlice({
  name: "auth",
  initialState,
  reducers: {
    setUserInfo: (state, action) => {
      state.userInfo = action.payload;
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(login.pending, (state) => {
        state.isLoading = true;
      })
      .addCase(login.fulfilled, (state, action) => {
        console.log(action.payload);
        state.userInfo = action.payload;
        state.isLoading = false;
      })
      .addCase(login.rejected, (state, action) => {
        state.isLoading = false;
      })
      .addCase(getUserInfo.fulfilled, (state, action) => {
        state.userInfo = action.payload;
      })
      .addCase(openSession.fulfilled, (state, action) => {
        state.session = action.payload;
        state.session.session_id = action.payload.id;
      })
      .addCase(closeSession.fulfilled, (state, action) => {
        state.session = [];
      });
  },
});

const { reducer, actions } = authSlice;

export const { setUserInfo } = actions;

export default reducer;
