import { useSelector } from "react-redux";
import { useEffect, useRef, useState } from "react";
import { deviceType } from "constants/getDeviceType";
import ReactInputMask from "react-input-mask";
// import { openOrCloseKeyboard } from "store/slices/keyboardSlice";

function Input({
  id = "",
  mask = "",
  label = "",
  value = "",
  onClick = () => {},
  onChange = () => {},
  disabled = false,
  autoFocus = false,
  className = "flex flex-col",
  inputMode = "text",
  placeholder = "",
  defaultNull = false,
  classNameInput = "",
  classNameLabel = "text-sm",
  virtualKeyboard = false,
}) {
  //   const dispatch = useDispatch();
  const inputRef = useRef(null);
  const { keyboard } = useSelector((store) => store);
  const [changedInput, setChangedInput] = useState(false);

  const innerWidth = window.innerWidth;

  const handleChange = (e) => {
    onChange(e.target.value);
    window?.SimpleKeyboardInstances?.reactSimpleKeyboard?.setInput(e.target.value);
  };

  //   const handleOpenOrCloseKeyboard = (status, inputMode) =>
  //     dispatch(openOrCloseKeyboard({ status, inputMode }));

  const handleClick = (e) => {
    !virtualKeyboard &&
      !disabled &&
      setTimeout(() => {
        onClick && onClick();
        // virtualKeyboard && e.target && handleOpenOrCloseKeyboard(true, inputMode);
        window?.SimpleKeyboardInstances?.reactSimpleKeyboard?.setInput(e.target.value?.toString());
        const {
          scrollWidth,
          value: { length },
        } = e.target;
        e.target.setSelectionRange(length, length);
        e.target.scrollLeft = scrollWidth;
      }, 0);
  };

  //   const handleClickOutside = (e) => {
  //     e?.srcElement?.attributes?.id?.nodeValue !== inputRef?.current?.props?.id &&
  //       e?.srcElement?.parentElement?.parentElement?.parentElement?.attributes?.["data-skinstance"]
  //         ?.nodeValue !== "reactSimpleKeyboard" &&
  //       handleOpenOrCloseKeyboard(false);
  //   };

  //   useEffect(() => {
  //     innerWidth > 768 &&
  //       deviceType === "mobile" &&
  //       window.addEventListener("click", handleClickOutside, true);
  //   }, []);

  useEffect(() => {
    if (
      inputRef?.current?.getInputDOMNode() ===
        window?.SimpleKeyboardInstances?.reactSimpleKeyboard?.activeInputElement &&
      !disabled
    ) {
      if (changedInput) {
        const res = window?.SimpleKeyboardInstances?.reactSimpleKeyboard?.getInput()?.substr(-1);
        onChange(res);
        setChangedInput(false);
      } else onChange(window?.SimpleKeyboardInstances?.reactSimpleKeyboard?.getInput());
    }
  }, [keyboard?.value]);

  useEffect(() => {
    if (autoFocus && !disabled) {
      window.SimpleKeyboardInstances.reactSimpleKeyboard.activeInputElement =
        inputRef?.current?.getInputDOMNode();

      window?.SimpleKeyboardInstances?.reactSimpleKeyboard?.setInput(value?.toString());
    }
  }, [autoFocus, value]);

  useEffect(() => {
    defaultNull && setChangedInput(true);
  }, [defaultNull, autoFocus]);

  return (
    <div className={`${className}`} onClick={onClick}>
      <label htmlFor={id} className={`${classNameLabel}`}>
        {label}
      </label>
      <ReactInputMask
        id={id}
        ref={inputRef}
        type="text"
        mask={mask}
        // mask={deviceType === "desktop" || (deviceType === "mobile" && innerWidth < 768) ? mask : ""}
        value={value || ""}
        onClick={handleClick}
        disabled={disabled}
        onChange={handleChange}
        autoFocus={autoFocus}
        className={`${disabled ? "opacity-50" : ""} ${classNameInput}`}
        inputMode={
          virtualKeyboard || (deviceType === "mobile" && innerWidth < 768) ? inputMode : "none"
        }
        placeholder={placeholder}
      />
    </div>
  );
}

export default Input;
