import { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import {
  getPermissions,
  getPermissionsByGroup,
  checkedPermissionsDefaultValue,
} from "store/slices/permissionSlice";
import { createUser, updateUser } from "store/slices/userSlice";
import CreatePermission from "../permissions/CreatePermission";
import MUIDrawer from "components/mui-drawer/MUIDrawer";
import HtmlInput from "components/input/HtmlInput";
import Select from "components/select/Select";
import { checkingPermissions } from "assets/constants/constants";

const CreateUser = ({ action, currentUser }) => {
  const dispatch = useDispatch();
  const {
    permissions: { permissions },
  } = useSelector((store) => store);
  const [openDrawer, setOpenDrawer] = useState({ open: false, action: "" });
  const [user, setUser] = useState(
    action === "create"
      ? {
          gender: "",
          role_id: "",
          last_name: "",
          password1: "",
          password2: "",
          first_name: "",
          middle_name: "",
          phone_number: "",
          permissions: [],
          is_verified: true,
        }
      : currentUser
  );

  const handleOpenDrawer = (action) => setOpenDrawer({ open: true, action });

  const handleCloseDrawer = () => setOpenDrawer({ ...openDrawer, open: false });

  const handleGetCurrentRolePermissions = (role_id) => {
    const permission = permissions?.filter((permission) => permission?.id === role_id);

    return permission?.length ? permission?.[0]?.permissions : [];
  };

  const handleEditPermissions = () => {
    dispatch(checkedPermissionsDefaultValue(user?.permissions));
    handleOpenDrawer();
  };

  const handleChange = (name, value) => {
    name === "role_id"
      ? setUser({ ...user, [name]: value, permissions: handleGetCurrentRolePermissions(value) })
      : setUser({ ...user, [name]: value });
  };

  const handleSave = () => {
    action === "create"
      ? user?.first_name &&
        user?.phone_number &&
        user?.password1 &&
        user?.password2 &&
        dispatch(createUser(user))
      : dispatch(updateUser({ id: currentUser?.id, user }));
  };

  useEffect(() => {
    dispatch(getPermissions({ pagination: { page: 1, pageSize: 100 } }));
    dispatch(getPermissionsByGroup());
  }, []);

  return (
    <>
      <MUIDrawer zIndex={1310} open={openDrawer?.open} onClose={handleCloseDrawer}>
        <CreatePermission
          onChangedPermissions={(permissions) => {
            handleChange("permissions", permissions);
            handleCloseDrawer();
          }}
        />
      </MUIDrawer>

      <div className="bg-c24 w-[25rem] sm:w-[90vw] p-6 rounded-lg flex flex-col gap-3">
        <span className="text-c2 text-2xl">
          {action === "create" ? "Foydalanuvchi qo'shish" : "Foydalanuvchini tahrirlash"}
        </span>

        <HtmlInput
          label="Ismi *"
          value={user?.first_name || ""}
          placeholder="Ismni kiriting"
          onChange={(e) => handleChange("first_name", e.target.value)}
        />
        <HtmlInput
          label="Familiyasi"
          value={user?.last_name || ""}
          placeholder="Familiyani kiriting"
          onChange={(e) => handleChange("last_name", e.target.value)}
        />

        <HtmlInput
          value={user?.middle_name || ""}
          label="Otasining ismi"
          placeholder="Loginni kiriting"
          onChange={(e) => handleChange("middle_name", e.target.value)}
        />

        <HtmlInput
          inputMode="tel"
          label="Telefon nomeri *"
          value={user?.phone_number || ""}
          mask="+\9\98999999999"
          placeholder="Telefon nomeri"
          onChange={(e) => handleChange("phone_number", e.target.value)}
        />

        <Select
          label="Jinsi"
          value={user?.gender || ""}
          options={[
            { id: 1, label: "Erkak", value: "male" },
            { id: 2, label: "Ayol", value: "female" },
          ]}
          classNameLabel="text-c2 text-sm"
          onClear={(e) => handleChange("gender", "")}
          onChange={(e) => handleChange("gender", e.target.value)}
          classNameSelect="bg-c24 w-full h-10 px-2 rounded-xl text-c2 border border-c32 outline-none bg-arrow-down-gray bg-no-repeat bg-right bg-[center_right_0.4rem] select-arrow-none"
        />

        <Select
          label={
            <span className="flex justify-between">
              <span>Lavozimi *</span>
              {user?.role_id && (
                <span onClick={handleEditPermissions} className="text-c10 cursor-pointer">
                  Tahrirlash
                </span>
              )}
            </span>
          }
          value={user?.role_id || ""}
          options={permissions}
          classNameLabel="text-c2 text-sm"
          onClear={(e) => handleChange("role_id", "")}
          optionsLabel={{ label: "name", value: "id" }}
          onChange={(e) => handleChange("role_id", e.target.value)}
          classNameSelect="bg-c24 w-full h-10 px-2 rounded-xl text-c2 border border-c32 outline-none bg-arrow-down-gray bg-no-repeat bg-right bg-[center_right_0.4rem] select-arrow-none"
        />

        {(action === "create" ||
          (action === "update" && checkingPermissions(["USER.SET_PASSWORD"]))) && (
          <div className="flex gap-2 justify-between">
            <HtmlInput
              label="Parol *"
              type="password"
              placeholder="Parol"
              value={user?.password1 || ""}
              className="w-1/2 text-sm"
              onChange={(e) => handleChange("password1", e.target.value)}
            />
            <HtmlInput
              type="password"
              value={user?.password2 || ""}
              label="Parolni qaytaring *"
              className="w-1/2 text-sm"
              placeholder="Parolni qaytaring"
              onChange={(e) => handleChange("password2", e.target.value)}
            />
          </div>
        )}

        <button
          onClick={handleSave}
          className="h-10 bg-c10 text-c24 flex items-center justify-center rounded-xl px-3"
        >
          Saqlash
        </button>
      </div>
    </>
  );
};

export default CreateUser;
