import { Pagination } from "@mui/material";
import Icon from "assets/icons/icons";

const PaginationItem = ({ type, page, selected, onClick, pageCount }) => {
  return (
    <div
      className={`${
        type === "next" || type === "previous"
          ? page === 0 || page - 1 === pageCount
            ? "bg-c11"
            : "bg-c10"
          : "bg-c24"
      } w-8 h-8 flex justify-center items-center m-1 rounded-lg border ${
        selected ? "border-c10" : "border-c32"
      }`}
      onClick={() =>
        !(
          (type === "previous" && page === 0) ||
          (type === "next" && page - 1 === pageCount) ||
          type === "start-ellipsis" ||
          type === "end-ellipsis"
        ) && onClick(page)
      }
    >
      <span className={`${selected ? "text-c10" : "text-c2"} font-thin`}>
        {type === "page" && page}
        {(type === "start-ellipsis" || type === "end-ellipsis") && "..."}
      </span>
      {type === "next" && <Icon name="arrowRight" />}
      {type === "previous" && <Icon name="arrowRight" className="rotate-180" />}
    </div>
  );
};

function MUIPagination({ count, page, pageSize, onChangePagination }) {
  const pageCount = Math.ceil(count / pageSize);

  return (
    <div className={`flex justify-between items-center gap-1 sm:flex-col`}>
      <label htmlFor="page-size" className="flex items-center gap-2">
        <span className="text-c9 font-thin">Ko'rsatish</span>

        <select
          id="page-size"
          value={pageSize}
          onChange={(e) => onChangePagination("pageSize", e.target.value)}
          className="bg-c24 w-14 h-8 pl-1 text-sm text-c1 rounded-lg border border-c32 outline-none bg-arrow-down-gray bg-no-repeat bg-right bg-[center_right_0.25rem] select-arrow-none"
        >
          <option value={10}>10</option>
          <option value={20}>20</option>
          <option value={30}>50</option>
          <option value={40}>100</option>
        </select>
      </label>

      <Pagination
        count={pageCount}
        page={page}
        variant="outlined"
        renderItem={(item) => (
          <PaginationItem
            pageCount={pageCount}
            {...item}
            onClick={(page) => onChangePagination("page", page)}
          />
        )}
      />
    </div>
  );
}

export default MUIPagination;
