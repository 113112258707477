import { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { getSuppliers } from "store/slices/supplierSlice";
import { checkingPermissions } from "assets/constants/constants";
import { changeIncomeListFilter } from "store/slices/incomeSlice";
import RangeCalender from "components/mui-date-range-picker/MUIDateRangePicker";
import Multiselect from "components/multiselect/Multiselect";
import MUIMenu from "components/mui-menu/MUIMenu";
import Select from "components/select/Select";
import Icon from "assets/icons/icons";

const IncomesFilter = () => {
  const dispatch = useDispatch();
  const {
    income: { incomeList },
    suppliers: { suppliers },
  } = useSelector((store) => store);
  const [filter, setFilter] = useState(incomeList?.filter);
  const [openMenu, setOpenMenu] = useState({ anchorEl: null });

  const handleOpenMenu = (event) => setOpenMenu({ anchorEl: event.currentTarget });

  const handleCloseMenu = () => setOpenMenu({ ...openMenu, anchorEl: null });

  const handleChange = (name, value) => setFilter({ ...filter, [name]: value });

  const handleSave = () => dispatch(changeIncomeListFilter(filter));

  useEffect(() => {
    dispatch(getSuppliers({ pagination: { page: 1, pageSize: 100 } }));
  }, []);

  return (
    <div className="bg-c24 text-c2 w-[20rem] sm:w-[80vw] h-full py-4">
      <MUIMenu
        open={openMenu?.anchorEl ? true : false}
        handleClose={handleCloseMenu}
        anchorEl={openMenu?.anchorEl}
        children={
          <div onClick={(event) => event.stopPropagation()}>
            <RangeCalender value={filter?.selection} handleChange={handleChange} />
          </div>
        }
      />

      <div className="flex flex-col justify-between gap-4 p-4">
        <span className="text-c2 text-2xl">Filter</span>

        <div onClick={handleOpenMenu} className="w-full flex flex-col gap-1">
          <span className="text-c2 text-sm">Sana bo'yicha filter</span>

          <div className="bg-c24 h-10 flex items-center border border-c32 rounded-xl">
            <div className="w-1/2 flex justify-center px-8 gap-2">
              <Icon name="calendar_gray" />

              <div className="flex flex-col">
                <span className="text-c27 text-xs">Dan</span>

                <span className="text-c2 text-sm">
                  {filter?.selection?.startDate
                    ? new Date(filter?.selection?.startDate)?.toLocaleDateString()?.substring(0, 10)
                    : ""}
                </span>
              </div>
            </div>

            <div className="w-1/2 flex justify-center px-8 gap-2 border-l border-c32">
              <Icon name="calendar_gray" />

              <div className="flex flex-col">
                <span className="text-c27 text-xs">Gacha</span>

                <span className="text-c2 text-sm">
                  {new Date(filter?.selection?.endDate).toDateString() !== "Thu Jan 01 1970"
                    ? new Date(filter?.selection?.endDate)?.toLocaleDateString()?.substring(0, 10)
                    : ""}
                </span>
              </div>
            </div>
          </div>
        </div>

        {checkingPermissions(["SUPPLIER.READ"]) && (
          <Select
            options={suppliers}
            value={filter?.supplier}
            label="Yetkazib beruvchi"
            firstOptionDisabled={false}
            classNameLabel="text-sm text-c2"
            optionsLabel={{ label: "name", value: "id" }}
            onChange={(e) => handleChange("supplier", e.target.value)}
            classNameSelect="w-full h-10 px-3 bg-c24 border border-c32 outline-0 rounded-xl bg-arrow-down-gray bg-no-repeat bg-right bg-[center_right_0.7rem] select-arrow-none"
          />
        )}

        <Multiselect
          label="To'lov turi"
          value={filter?.expense_types}
          options={[
            { label: "Naqd", value: "cash" },
            { label: "Karta", value: "card" },
            { label: "Qarz", value: "debt" },
            { label: "Boshqa", value: "other" },
          ]}
          onChange={(e) => handleChange("expense_types", e)}
          classNameSelect="border border-c32 rounded-xl text-sm outline-none"
        />

        <button className="bg-c10 text-c24 h-10 px-8 rounded-xl" onClick={handleSave}>
          Filter
        </button>
      </div>
    </div>
  );
};

export default IncomesFilter;
