// import React, { useState } from "react";
// import { addDays } from "date-fns";
// import DateRangePicker from "react-date-range/dist/components/DateRangePicker";
import uz from "date-fns/locale/uz";
// import { defaultStaticRanges, defaultInputRanges } from "react-date-range/dist/defaultRanges";

// import "react-date-range/dist/styles.css";
// import "react-date-range/dist/theme/default.css";

// const staticRangesLabels = {
//   Today: "Bugun",
//   Yesterday: "Kecha",
//   "This Week": "Bu hafta",
//   "Last Week": "Oxirgi hafta",
//   "This Month": "Bu oy",
//   "Last Month": "Oxirgi oy",
//   "Last Year": "Oxirgi yil",
// };

// const inputRangesLabels = {
//   "days up to today": "bugungacha bo'lgan kun",
//   "days starting today": "bugundan keyingi kun",
// };

// function translateRange(dictionary) {
//   return (item) => (dictionary[item.label] ? { ...item, label: dictionary[item.label] } : item);
// }

// const ruStaticRanges = defaultStaticRanges.map(translateRange(staticRangesLabels));
// const ruInputRanges = defaultInputRanges.map(translateRange(inputRangesLabels));

// const DateRangePickerComponent = () => {
//   const [state, setState] = useState([
//     {
//       startDate: new Date(),
//       endDate: addDays(new Date(), 7),
//       key: "selection",
//     },
//   ]);

//   return (
//     <DateRangePicker
//       locale={uz}
//       months={1}
//       minDate={new Date("01.01.2016")}
//       direction="vertical"
//       scroll={{ enabled: true }}
//       ranges={state}
//       onChange={(item) => setState([item.selection])}
//       staticRanges={ruStaticRanges}
//       inputRanges={ruInputRanges}
//     />
//   );
// };

// export default DateRangePickerComponent;

import { useMemo } from "react";
import { DateRangePicker, createStaticRanges } from "react-date-range";

import {
  addDays,
  endOfDay,
  startOfDay,
  startOfMonth,
  endOfMonth,
  addMonths,
  startOfWeek,
  endOfWeek,
  startOfYear,
  endOfYear,
  addYears,
} from "date-fns";

import "react-date-range/dist/styles.css";
import "react-date-range/dist/theme/default.css";

const defineds = {
  startOfWeek: startOfWeek(new Date()),
  endOfWeek: endOfWeek(new Date()),
  startOfLastWeek: startOfWeek(addDays(new Date(), -7)),
  endOfLastWeek: endOfWeek(addDays(new Date(), -7)),
  startOfToday: startOfDay(new Date()),
  startOfLastSevenDay: startOfDay(addDays(new Date(), -7)),
  startOfLastThirtyDay: startOfDay(addDays(new Date(), -30)),
  startOfLastNintyDay: startOfDay(addDays(new Date(), -90)),
  endOfToday: endOfDay(new Date()),
  startOfYesterday: startOfDay(addDays(new Date(), -1)),
  endOfYesterday: endOfDay(addDays(new Date(), -1)),
  startOfMonth: startOfMonth(new Date()),
  endOfMonth: endOfMonth(new Date()),
  startOfLastMonth: startOfMonth(addMonths(new Date(), -1)),
  endOfLastMonth: endOfMonth(addMonths(new Date(), -1)),
  startOfYear: startOfYear(new Date()),
  endOfYear: endOfYear(new Date()),
  startOflastYear: startOfYear(addYears(new Date(), -1)),
  endOflastYear: endOfYear(addYears(new Date(), -1)),
};

const sideBarOptions = () => {
  const customDateObjects = [
    // {
    //   label: "Bugun",
    //   range: () => ({
    //     startDate: defineds.startOfToday,
    //     endDate: defineds.endOfToday,
    //   }),
    // },

    {
      label: "Bu hafta",
      range: () => ({
        startDate: defineds.startOfWeek,
        endDate: defineds.endOfWeek,
      }),
    },

    // {
    //   label: "Oxirgi hafta",
    //   range: () => ({
    //     startDate: defineds.startOfLastWeek,
    //     endDate: defineds.endOfLastWeek,
    //   }),
    // },

    {
      label: "Bu oy",
      range: () => ({
        startDate: defineds.startOfMonth,
        endDate: defineds.endOfMonth,
      }),
    },

    // {
    //   label: "Oxirgi oy",
    //   range: () => ({
    //     startDate: defineds.startOfLastMonth,
    //     endDate: defineds.endOfLastMonth,
    //   }),
    // },

    {
      label: "Bu yil",
      range: () => ({
        startDate: defineds.startOfYear,
        endDate: defineds.endOfYear,
      }),
    },
    // {
    //   label: "Oxirgi yil",
    //   range: () => ({
    //     startDate: defineds.startOflastYear,
    //     endDate: defineds.endOflastYear,
    //   }),
    // },
  ];

  return customDateObjects;
};

function RangeCalender({ value, handleChange }) {
  //   console.log(new Date(state.selection.startDate).toISOString());

  const sideBar = sideBarOptions();

  const staticRanges = [...createStaticRanges(sideBar)];

  const dateRangePicker = useMemo(() => {
    return (
      <DateRangePicker
        locale={uz}
        ranges={[value]}
        onChange={(ranges) => handleChange("selection", ranges.range1)}
        months={1}
        // minDate={new Date("01.01.2018")}
        direction="vertical"
        scroll={{ enabled: true }}
        showMonthAndYearPickers={true}
        staticRanges={staticRanges}
        inputRanges={[]}
      />
    );
  }, [value]);

  return <>{dateRangePicker}</>;
}

export default RangeCalender;
// { startDate: new Date(), endDate: addDays(new Date(), 0) }
