import { Accordion, AccordionDetails, AccordionSummary } from "@mui/material";
import Icon from "assets/icons/icons";

function MUIAccordionCategory({
  id,
  name = "Category name",
  children = [],
  onChooseCategory,
  activeCategory,
}) {
  return (
    <Accordion id={id} expanded={activeCategory?.parent === id} sx={{ background: "none" }}>
      <AccordionSummary
        expandIcon={<Icon name="arrowDown" />}
        id="panel1a-header"
        aria-controls="panel1a-content"
        className={`flex-col bg-gradient-to-b from-c5 from-75% to-c7 to-35%`}
        sx={{
          borderRadius: "0.25rem",
        }}
        onClick={() =>
          onChooseCategory(
            activeCategory?.parent === id ? null : id,
            children?.length ? children[0]?.id : null
          )
        }
      >
        <p className="py-4 text-c24">{name}</p>
      </AccordionSummary>
      <AccordionDetails
        sx={{
          padding: 0,
          bgcolor: "var(--c2)",
        }}
      >
        {children?.map((category) => (
          <div
            key={category?.id}
            onClick={() => onChooseCategory(activeCategory?.parent, category?.id)}
            className={`mt-1 py-6 text-c24 text-center border-right-5 cursor-pointer ${
              activeCategory?.child === category?.id
                ? "bg-c3 border-c10"
                : "bg-c7 border-transparent"
            }`}
          >
            {category?.name}
          </div>
        ))}
      </AccordionDetails>
    </Accordion>
  );
}

export default MUIAccordionCategory;
