import { useEffect } from "react";
import { useParams } from "react-router-dom";
import { getIncome } from "store/slices/incomeSlice";
import { useDispatch, useSelector } from "react-redux";
import { REFACTOR_SUM } from "assets/constants/constants";
import { checkedAllIncomeProduct, checkedIncomeProduct } from "store/slices/incomeSlice";
import AdminNavbar from "layers/admin-navbar/AdminNavbar";
import Checkbox from "components/checkbox/Checkbox";
import Icon from "assets/icons/icons";

const PAYMENT_TYPES = {
  cash: {
    label: "Naqd",
    icon: "paymentGray",
  },
  card: {
    label: "Karta",
    icon: "cardGray",
  },
  debt: {
    label: "Nasiya",
    icon: "shoppingGray",
  },
  other: {
    label: "Boshqa",
    icon: "moreGray",
  },
};

function IncomeProducts() {
  const params = useParams();
  const dispatch = useDispatch();
  const {
    income: {
      incomeList: { currentIncome },
    },
  } = useSelector((store) => store);

  const handleCheckedAllIncomeProduct = () => dispatch(checkedAllIncomeProduct());

  const handleCheckedIncomeProduct = (id) => dispatch(checkedIncomeProduct(id));

  useEffect(() => {
    params?.id && dispatch(getIncome(params?.id));
  }, [dispatch, params?.id]);

  return (
    <>
      <AdminNavbar
        prevButton={
          <button
            onClick={() => window.history.back()}
            className="bg-c32 h-10 px-4 py-2 rounded-xl flex justify-center items-center sm:p-0 sm:bg-c46 sm:w-10"
          >
            <Icon name="arrowLeftGray" className="sm:hidden" />
            <Icon name="left_arrow" className="hidden sm:flex" />
            <span className="text-c2 sm:hidden">Orqaga qaytish</span>
          </button>
        }
        rightElement={<div className="hidden sm:flex sm:w-10 sm:h-10" />}
      />

      {currentIncome?.id ? (
        <>
          <div className="bg-c24 flex items-center mx-4 my-2 py-3 border border-c32 rounded-lg overflow-auto whitespace-nowrap sm:flex-col sm:gap-4">
            <div className="flex items-center gap-2 border-r px-4 sm:w-full sm:border-0 sm:justify-between ">
              <span className="text-c9 text-sm">Vaqti:</span>
              <span className="text-c2">{currentIncome?.created_at?.substr(0, 16)}</span>
            </div>

            <div className="flex items-center gap-2 border-r px-4 sm:w-full sm:border-0 sm:justify-between ">
              <span className="text-c9 text-sm">Yetkazib beruvchi:</span>
              <span className="text-c2">{currentIncome?.supplier_name}</span>
            </div>

            <div className="flex items-center gap-2 border-r px-4 sm:w-full sm:border-0 sm:justify-between">
              <span className="text-c9 text-sm">To’lov turi:</span>
              <span className="flex gap-1 items-center text-c2">
                <Icon name={PAYMENT_TYPES?.[currentIncome?.expense_type]?.icon} className="w-5" />
                {PAYMENT_TYPES?.[currentIncome?.expense_type]?.label}
              </span>
            </div>
          </div>

          <div className="bg-c24 text-c2 mx-4 border border-c32 rounded-lg">
            <div className="rounded-lg overflow-x-auto">
              <table className="sm:whitespace-nowrap w-full border-collapse even:bg-c36 odd:bg-white">
                <thead>
                  <tr className="text-c9 font-thin bg-c36">
                    <td className="px-2 pl-4 py-3">
                      <Checkbox
                        label="№"
                        bgColor="bg-c24"
                        classNameCheckbox="text-c2"
                        classNameLabel="text-c9"
                        checked={currentIncome?.checkedAll}
                        onChange={handleCheckedAllIncomeProduct}
                      />
                    </td>
                    <td className="px-2 py-3">Mahsulot nomi</td>
                    <td className="px-2 py-3">Kategoriya</td>
                    <td className="px-2 py-3">Ichki kategoriya</td>
                    <td className="px-2 py-3">Miqdori</td>
                    <td className="px-2 py-3">Narxi</td>
                    <td className="px-2 pr-4 py-3 text-end">Umumiy narxi</td>
                  </tr>
                </thead>

                <tbody>
                  {currentIncome?.items?.map((item, idx) => (
                    <tr key={item?.id}>
                      <td className="border-t border-c32 px-2 pl-4 py-3">
                        <Checkbox
                          label={idx + 1}
                          bgColor="bg-c24"
                          classNameLabel="text-c2"
                          classNameCheckbox="text-c2"
                          checked={item?.checked ? true : false}
                          onChange={() => handleCheckedIncomeProduct(item?.id)}
                        />
                      </td>

                      <td className="border-t border-c32 py-3 px-2">{item?.product_name || "-"}</td>
                      <td className="border-t border-c32 py-3 px-2">
                        {item?.root_category_name || "-"}
                      </td>
                      <td className="border-t border-c32 py-3 px-2">
                        {item?.category_name || "-"}
                      </td>
                      <td className="border-t border-c32 py-3 px-2">{item?.quantity || "-"}</td>
                      <td className="border-t border-c32 py-3 px-2">
                        {item?.price ? `${REFACTOR_SUM(item?.price)} UZS` : "-"}
                      </td>
                      <td className="border-t border-c32 py-3 px-2 pr-4 text-end">
                        {item?.price && item?.quantity
                          ? `${REFACTOR_SUM(item.quantity * item.price)} UZS`
                          : "-"}
                      </td>
                    </tr>
                  ))}
                </tbody>
              </table>
            </div>

            <div className="flex justify-end gap-4 p-3 border-t border-c32 sm:flex-col">
              {currentIncome?.paid ? (
                <div className="flex items-center gap-2 sm:justify-between">
                  <span className="text-c9">To’landi: </span>
                  <span className="text-c33 text-lg font-medium">
                    {REFACTOR_SUM(currentIncome?.paid)}
                  </span>
                </div>
              ) : (
                ""
              )}

              {currentIncome?.amount - currentIncome?.paid ? (
                <div className="flex items-center gap-2 sm:justify-between">
                  <span className="text-c9">Nasiya: </span>
                  <span className="text-c14 text-lg font-medium">
                    {REFACTOR_SUM(currentIncome?.amount - currentIncome?.paid)}
                  </span>
                </div>
              ) : (
                ""
              )}

              <div className="flex items-center gap-2 sm:justify-between">
                <span className="text-c9">Umumiy summa: </span>
                <span className="text-c10 text-lg font-medium">
                  {REFACTOR_SUM(currentIncome?.total_amount)}
                </span>
              </div>
            </div>
          </div>
        </>
      ) : (
        <div className="text-center p-4 bg-c24 m-4 rounded-md">
          <span>{params?.id}</span> - <span>id bo'yicha ma'lumot topilmadi.</span>
        </div>
      )}
    </>
  );
}

export default IncomeProducts;
