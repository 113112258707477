import { useState } from "react";
import Icon from "assets/icons/icons";
import MUIPagination from "components/mui-pagination/MUIPagination";
import AdminNavbar from "layers/admin-navbar/AdminNavbar";
import MUIModal from "components/mui-modal/MUIModal";
import CreateDiscount from "./CreateDiscount";
import MUIMenu from "components/mui-menu/MUIMenu";
import DeleteDiscount from "./DeleteDiscount";

const tableCols = [
  {
    id: 1,
    key: "id",
    label: "№",
    textAlign: "text-start pl-4",
  },
  {
    id: 2,
    key: "name",
    label: "Chegirma nomi",
    textAlign: "text-start",
  },
  {
    id: 3,
    key: "percent",
    label: "Chegirma foizi",
    textAlign: "text-start",
  },
  {
    id: 4,
    key: "actions",
    label: "Amallar",
    textAlign: "text-center",
  },
];

const orders = [
  {
    id: 1,
    name: "20 foizlik chegirma",
    percent: "20%",
  },
  {
    id: 2,
    name: "20 foizlik chegirma",
    percent: "20%",
  },
  {
    id: 3,
    name: "20 foizlik chegirma",
    percent: "20%",
  },
  {
    id: 4,
    name: "20 foizlik chegirma",
    percent: "20%",
  },
  {
    id: 5,
    name: "20 foizlik chegirma",
    percent: "20%",
  },
  {
    id: 6,
    name: "20 foizlik chegirma",
    percent: "20%",
  },
  {
    id: 7,
    name: "20 foizlik chegirma",
    percent: "20%",
  },
];

const Discounts = () => {
  const [action, setAction] = useState("create");
  const [openModal, setOpenModal] = useState(false);
  const [anchorEl, setAnchorEl] = useState(null);

  const [pagination, setPagination] = useState({
    page: 1,
    pageSize: 10,
  });

  const open = Boolean(anchorEl);

  const handleOpenModal = () => setOpenModal(true);
  const handleCloseModal = () => setOpenModal(false);

  // const handleChangePage = (page) => setPagination({ ...pagination, page });
  // const handleChangePageSize = (pageSize) => setPagination({ ...pagination, pageSize, page: 1 });

  const handleOpenMenu = (event) => setAnchorEl(event.currentTarget);
  const handleCloseMenu = () => setAnchorEl(null);

  return (
    <>
      <MUIModal
        hideBackdrop={false}
        open={openModal}
        onClose={handleCloseModal}
        children={
          action === "create" ? (
            <CreateDiscount onClose={handleCloseModal} />
          ) : (
            <DeleteDiscount onClose={handleCloseModal} />
          )
        }
      />

      <MUIMenu
        open={open}
        handleClose={handleCloseMenu}
        anchorEl={anchorEl}
        children={
          <div className="flex flex-col gap-2 p-2" onClick={(event) => event.stopPropagation()}>
            <div className="flex p-2 gap-2 cursor-pointer">
              <Icon name="edit_gray" />
              <span className="text-c2">Tahrirlash</span>
            </div>

            <div
              className="flex p-2 gap-2 cursor-pointer"
              onClick={() => {
                setAction("delete");
                handleOpenModal();
              }}
            >
              <Icon name="trash_red" />
              <span className="text-c14">O'chirish</span>
            </div>
          </div>
        }
      />

      <AdminNavbar
        rightElement={
          <div
            className="bg-c10 py-2 px-4 sm:p-1 flex items-center justify-center gap-1 rounded-lg cursor-pointer"
            onClick={() => {
              setAction("create");
              handleOpenModal();
            }}
          >
            <Icon name="add" />
            <span className="text-c24 sm:hidden">Qo'shish</span>
          </div>
        }
      />

      <div className="bg-c24 text-c2 m-4 border border-c32 rounded-lg">
        <div className="rounded-xl overflow-x-auto">
          <table className="sm:whitespace-nowrap w-full border-collapse even:bg-c36 odd:bg-white">
            <thead>
              <tr className="text-c9">
                {tableCols?.map((col) => (
                  <td key={col?.id} className={`sm:px-2 py-3 font-thin bg-c36 ${col?.textAlign}`}>
                    {col?.label}
                  </td>
                ))}
              </tr>
            </thead>

            <tbody>
              {orders?.map((order) => (
                <tr key={order?.id}>
                  {tableCols?.map((col) => (
                    <td
                      key={col?.id}
                      className={`sm:px-2 border-t border-c32 ${col?.textAlign} py-2`}
                    >
                      {col?.key === "actions" ? (
                        <div
                          className="flex items-center mx-auto w-max px-2 py-1 rounded-lg cursor-pointer"
                          onClick={handleOpenMenu}
                        >
                          <Icon name="more_black" />
                        </div>
                      ) : (
                        <span className={col?.key === "debt" ? "text-c14" : ""}>
                          {order[col?.key]}
                        </span>
                      )}
                    </td>
                  ))}
                </tr>
              ))}
            </tbody>
          </table>
        </div>

        <div className="bg-c36 border-t border-c32 px-4 py-2 rounded-b-xl">
          <MUIPagination
            count={10}
            bgColor="c36"
            page={pagination?.page}
            pageSize={pagination?.pageSize}
            // onChangePagination={(name, value) => handleChangePagination(name, value)}
          />
        </div>
      </div>
    </>
  );
};

export default Discounts;
