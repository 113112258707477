import { useState, useEffect } from "react";
import { useNavigate } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { REFACTOR_SUM } from "assets/constants/constants";
import { paymentTypeOptions } from "constants/PaymentTypeOptions";
import { getOrders, changePaginationOrderList } from "store/slices/orderSlice";
import MUIPagination from "components/mui-pagination/MUIPagination";
import AdminNavbar from "layers/admin-navbar/AdminNavbar";
import MUIDrawer from "components/mui-drawer/MUIDrawer";
import MiniCard from "components/cards/MiniCard";
import OrdersFilter from "./OrdersFilter";
import Icon from "assets/icons/icons";

function Orders() {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const [openDrawer, setOpenDrawer] = useState(false);

  const {
    ordersList: { ordersList, filter, total, pagination },
  } = useSelector((store) => store.orders);

  const handleChangePagination = (name, value) =>
    dispatch(changePaginationOrderList({ name, value }));

  const handleOpenDrawer = () => setOpenDrawer(true);

  const handleCloseDrawer = () => setOpenDrawer(false);

  useEffect(() => {
    dispatch(getOrders({ filter, pagination }));
    handleCloseDrawer();
  }, [dispatch, filter, pagination]);

  return (
    <>
      <MUIDrawer open={openDrawer} onClose={handleCloseDrawer}>
        <OrdersFilter />
      </MUIDrawer>

      <div>
        <AdminNavbar rightElement={<div className="w-10 h-10" />} />

        <div className="grid grid-cols-4 px-4 mt-4 sm:grid-cols-2 gap-2">
          <MiniCard icon="money" bgColor="bg-money" title="Naqd" sum="6 000 000 UZS" />
          <MiniCard icon="card" bgColor="bg-card" title="Plastik karta" sum="4 000 000 UZS" />
          <MiniCard icon="overall" bgColor="bg-overall" title="Umumiy savdo" sum="10 000 000 UZS" />
          <MiniCard
            icon="increase_chart"
            bgColor="bg-profit"
            title="Sof foyda"
            sum="12 000 000 UZS"
          />
        </div>

        <div className="bg-c24 m-4 border border-c32 rounded-lg">
          <div className="flex justify-end items-center border-b border-c32 px-4 py-2 gap-2">
            <button
              onClick={handleOpenDrawer}
              className="bg-c36 h-10 flex justify-center items-center gap-2 py-2 px-4 rounded-xl border border-c32 sm:p-0 sm:w-10"
            >
              <Icon name="filter" />
              <span className="text-c1 mx-auto sm:hidden">Filter</span>
            </button>
          </div>

          <div className="bg-c24 text-c2 overflow-x-scroll rounded-lg">
            <table className="sm:whitespace-nowrap w-full border-collapse">
              <thead>
                <tr className="text-c9 font-thin bg-c36">
                  <td className="py-3 px-2 pl-4">№</td>
                  <td className="py-3 px-2">Vaqti</td>
                  <td className="py-3 px-2">Kassir</td>
                  <td className="py-3 px-2">Mahsulot soni</td>
                  <td className="py-3 px-2">Turi</td>
                  <td className="py-3 px-2">Umumiy summa</td>
                  <td className="py-3 px-2 pr-4 text-end">Batafsil</td>
                </tr>
              </thead>

              <tbody>
                {ordersList?.map((order, idx) => (
                  <tr key={order?.id} className="even:bg-c36 odd:bg-white">
                    <td className="py-3 px-2 pl-4 border-t">
                      {Math.abs(idx + pagination.pageSize * (pagination.page - 1) + 1)}
                    </td>
                    <td className="py-3 px-2 border-t">{order?.created_at?.substr(0, 19)}</td>
                    <td className="py-3 px-2 border-t">{order?.seller_name}</td>
                    <td className="py-3 px-2 border-t">{order?.items_count}</td>
                    <td className=" px-2 border-t">
                      <div
                        className={`px-4 py-2 rounded-xl flex items-center justify-center gap-2 w-max ${
                          paymentTypeOptions[order?.payment_type]?.bgColor
                        }`}
                      >
                        <Icon name={paymentTypeOptions?.[order?.payment_type]?.icon} />
                        <span className={`${paymentTypeOptions?.[order?.payment_type]?.color}`}>
                          {paymentTypeOptions?.[order?.payment_type]?.label}
                        </span>
                      </div>
                    </td>
                    <td className="py-3 px-2 border-t">
                      {order?.amount ? `${REFACTOR_SUM(order?.amount)} UZS` : "-"}{" "}
                    </td>

                    <td className="px-2 pr-4 border-t text-end">
                      <button
                        onClick={() => navigate(`/admin/order/orders/${order?.id}`)}
                        className="w-10 h-10 border border-c32 bg-c24 rounded-xl "
                      >
                        <Icon className="mx-auto" name="arrowRightBlue" />
                      </button>
                    </td>
                  </tr>
                ))}
              </tbody>
            </table>
          </div>

          <div className="bg-c36 border-t border-c32 px-4 py-2 rounded-b-xl">
            <MUIPagination
              count={total}
              page={pagination?.page}
              pageSize={pagination?.pageSize}
              onChangePagination={(name, value) => handleChangePagination(name, value)}
            />
          </div>
        </div>
      </div>
    </>
  );
}

export default Orders;
