import { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { REFACTOR_SUM, checkingPermissions } from "assets/constants/constants";
import { getReturnedOrders, changePaginationOrderHistory } from "store/slices/orderSlice";
import ReturnedOrdersFilter from "pages/orders/returned-orders/ReturnedOrdersFilter";
import MUIPagination from "components/mui-pagination/MUIPagination";
import AdminNavbar from "layers/admin-navbar/AdminNavbar";
import MUIDrawer from "components/mui-drawer/MUIDrawer";
import MiniCard from "components/cards/MiniCard";
import Icon from "assets/icons/icons";

function ReturnedOrders() {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const {
    returnedOrders: { returnedOrdersList, total, filter, pagination },
  } = useSelector((store) => store.orders);
  const [openDrawer, setOpenDrawer] = useState(false);

  const handleChangePagination = (name, value) =>
    dispatch(changePaginationOrderHistory({ name, value }));

  const handleOpenDrawer = () => setOpenDrawer(true);

  const handleCloseDrawer = () => setOpenDrawer(false);

  useEffect(() => {
    dispatch(getReturnedOrders({ filter, pagination }));
    handleCloseDrawer();
  }, [dispatch, pagination, filter]);

  return (
    <>
      <MUIDrawer open={openDrawer} onClose={handleCloseDrawer}>
        <ReturnedOrdersFilter />
      </MUIDrawer>

      <div>
        <AdminNavbar rightElement={<div className="w-10 h-10" />} />

        <div className="grid grid-cols-3 sm:grid-cols-2 gap-2 px-4 mt-4">
          <MiniCard icon="money" bgColor="bg-money" title="Naqd" sum="6 000 000 UZS" />
          <MiniCard icon="card" bgColor="bg-card" title="Plastik karta" sum="4 000 000 UZS" />
          <MiniCard
            icon="overall"
            bgColor="bg-overall"
            title="Umumiy savdo"
            sum="10 000 000 UZS"
            className="sm:items-center sm:col-span-2"
          />
        </div>

        <div className="bg-c24 text-c2 m-4 border border-c32 rounded-lg">
          <div className="flex justify-end items-center border-b border-c32 px-4 py-2 gap-2">
            <button
              className="bg-c36 h-10 flex justify-center items-center gap-2 py-2 px-4 rounded-xl border border-c32 sm:p-0 sm:w-10"
              onClick={handleOpenDrawer}
            >
              <Icon name="filter" />
              <span className="text-c2 sm:hidden">Filter</span>
            </button>
          </div>

          <div className="bg-c24 text-c2 rounded-lg overflow-x-auto">
            <table className="sm:whitespace-nowrap w-full border-collapse odd:bg-white even:bg-c36">
              <thead>
                <tr className="text-c9 font-thin bg-c36">
                  <td className="px-2 py-3 pl-4">№</td>
                  <td className="px-2 py-3">Vaqti</td>
                  <td className="px-2 py-3">Kassir</td>
                  <td className="px-2 py-3">Mahsulot soni</td>
                  <td className="px-2 py-3">Savdo summa</td>
                  <td className="px-2 py-3">Qaytarilgan summa</td>
                  <td className="px-2 py-3 pr-4 text-end">Batafsil</td>
                </tr>
              </thead>

              <tbody>
                {returnedOrdersList?.map((order, idx) => (
                  <tr key={order?.id}>
                    <td className="px-2 py-2 pl-4 border-t border-c32">
                      {Math.abs(idx + pagination.pageSize * (pagination.page - 1) + 1)}
                    </td>

                    <td className="px-2 py-2 pr-4 border-t border-c32">
                      {order?.created_at?.substr(0, 16) || "-"}
                    </td>
                    <td className="px-2 py-2 border-t border-c32">{order?.seller_name || "-"}</td>
                    <td className="px-2 py-2 border-t border-c32">{order?.items_count || "-"}</td>
                    <td className="px-2 py-2 border-t border-c32">
                      {order?.amount ? `${REFACTOR_SUM(order?.amount)} UZS` : "-"}
                    </td>
                    <td
                      className={`px-2 py-2 border-t border-c32 ${
                        !checkingPermissions(["RETURN_ORDER.READ_ITEM"]) ? "pr-4 text-end" : ""
                      }`}
                    >
                      {order?.paid ? `${REFACTOR_SUM(order?.paid)} UZS` : "-"}
                    </td>

                    <td className="px-2 py-1 pr-4 border-t border-c32 text-end">
                      <button
                        className="w-10 h-10 px-2 py-1 border border-c32 rounded-xl"
                        onClick={() => navigate(`/admin/order/returned/${order?.id}`)}
                      >
                        <Icon name="arrowRightBlue" className="mx-auto" />
                      </button>
                    </td>
                  </tr>
                ))}
              </tbody>
            </table>
          </div>

          <div className="bg-c36 border-t border-c32 px-4 py-2 rounded-b-xl">
            <MUIPagination
              count={total}
              bgColor="c36"
              page={pagination?.page}
              pageSize={pagination?.pageSize}
              onChangePagination={(name, value) => handleChangePagination(name, value)}
            />
          </div>
        </div>
      </div>
    </>
  );
}

export default ReturnedOrders;
