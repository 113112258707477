import { createSlice } from "@reduxjs/toolkit";

const initialState = {
  mode: "dark",
};

export const themeSlice = createSlice({
  name: "theme",
  initialState,
  reducers: {
    changeThemeMode: (state) => {
      state.mode = state.mode === "dark" ? "light" : "dark";
    },
  },
});

const { reducer, actions } = themeSlice;

export const { changeThemeMode } = actions;

export default reducer;
