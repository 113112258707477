import { v4 } from "uuid";
import { useEffect } from "react";
import { addOrder } from "store/slices/orderSlice";
import { useDispatch, useSelector } from "react-redux";
import { checkingPermissions } from "assets/constants/constants";
import { openOrCloseKeyboard } from "store/slices/keyboardSlice";
import { Outlet, useLocation, useNavigate } from "react-router-dom";
import MUIDrawer from "components/mui-drawer/MUIDrawer";
import Keyboard from "layers/keyboard/Keyboard";
import Topbar from "layers/topbar/Topbar";
import Appbar from "layers/appbar/Appbar";

function Operator() {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const { pathname } = useLocation();
  const {
    orders: { orders },
    keyboard: { openKeyboard, inputMode },
  } = useSelector((store) => store);

  document.title = "POS SYSTEM - Operator";

  const clientsCount = Object.keys(orders).length;
  const currentPath = pathname.split("/");

  const handleCloseKeyboard = () => dispatch(openOrCloseKeyboard(false));

  useEffect(() => {
    !clientsCount && dispatch(addOrder(v4()));
  }, [clientsCount]);

  useEffect(() => {
    clientsCount === 1 &&
      currentPath[1] === "home" &&
      Object.keys(orders).map((key) => navigate(`/home/${key}`));
  }, [clientsCount, pathname]);

  return (
    <div className="w-full h-[100dvh] flex sm:flex-col">
      <div className="hidden sm:flex sm:h-[3rem]">
        <Topbar />
      </div>

      <div
        className={`w-[calc(100%-4rem)] overflow-y-auto sm:w-full  ${
          !checkingPermissions(["ORDER_DEBT.READ"]) && !checkingPermissions(["INCOME.CREATE"])
            ? "sm:h-[calc(100%-3rem)]"
            : "sm:h-[calc(100%-6rem)]"
        }`}
      >
        <Outlet />
      </div>

      <div
        className={`w-[4rem] bg-c1 sm:w-full ${
          !checkingPermissions(["ORDER_DEBT.READ"]) && !checkingPermissions(["INCOME.CREATE"])
            ? "sm:hidden"
            : ""
        }`}
      >
        <Appbar />
      </div>

      <MUIDrawer
        zIndex={10000}
        closable={false}
        position="bottom"
        hideBackdrop={true}
        onClose={handleCloseKeyboard}
        open={openKeyboard}
      >
        <div className="w-full bg-c1">
          <Keyboard inputMode={inputMode} />
        </div>
      </MUIDrawer>
    </div>
  );
}

export default Operator;
