import { axiosPrivate } from "services/axiosPrivate";
import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";

const initialState = {
  total: 0,
  refresh: "",
  suppliers: [],
  isLoading: false,
  currentSupplier: {},
  pagination: { page: 1, pageSize: 10 },
};

export const getSuppliers = createAsyncThunk(
  "supplier/get-suppliers",
  async ({ search = "", pagination = { page: 1, pageSize: 100 } }) => {
    const response = await axiosPrivate.get(
      `supplier/list/?search=${search}&page=${pagination?.page}&page_size=${pagination?.pageSize}`
    );
    return response.data;
  }
);

export const createSupplier = createAsyncThunk("supplier/create-supplier", async (supplier) => {
  const response = await axiosPrivate.post("supplier/post/", supplier);
  return response.data;
});

export const getCurrentSupplier = createAsyncThunk("supplier/get-supplier", async (id) => {
  const response = await axiosPrivate.get(`supplier/get/${id}`);
  return response.data;
});

export const editSupplier = createAsyncThunk(
  "supplier/edit-supplier",
  async ({ supplier_id, supplier }) => {
    const response = await axiosPrivate.patch(`supplier/patch/${supplier_id}`, supplier);
    return response.data;
  }
);

export const payForSupplier = createAsyncThunk("supplier/pay-for-supplier", async (expense) => {
  const response = await axiosPrivate.post("expense/post/", expense);
  return response.data;
});

export const deleteSupplier = createAsyncThunk("supplier/delete-supplier", async (supplier_id) => {
  const response = await axiosPrivate.delete(`supplier/delete/${supplier_id}`);
  return response.data;
});

export const supplierSlice = createSlice({
  name: "supplier",
  initialState,
  reducers: {
    changePagination: (state, action) => {
      state.pagination[action.payload.name] = action.payload.value;
      action.payload.name === "pageSize" &&
        Math.ceil(state.total / state.pagination.pageSize) <= state.pagination.page &&
        (state.pagination.page = Math.ceil(state.total / state.pagination.pageSize));
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(createSupplier.fulfilled, (state, action) => {
        state.refresh = `create-supplier-${new Date()}`;
      })
      .addCase(editSupplier.fulfilled, (state) => {
        state.refresh = `updated-supplier-${new Date()}`;
      })
      .addCase(deleteSupplier.fulfilled, (state) => {
        state.refresh = `delete-supplier-${new Date()}`;
      })
      .addCase(payForSupplier.fulfilled, (state, action) => {
        state.refresh = `pay-for-supplier-${new Date()}`;
      })
      .addCase(getSuppliers.pending, (state) => {
        state.isLoading = true;
      })
      .addCase(getSuppliers.fulfilled, (state, action) => {
        state.suppliers = action.payload.data;
        state.total = action.payload.total;
        state.totalDebt = action.payload.total_debt;
      })
      .addCase(getCurrentSupplier.fulfilled, (state, action) => {
        state.currentSupplier = action.payload;
      });
  },
});

const { reducer, actions } = supplierSlice;

export const { chooseProduct, getProductsSQL, changePagination } = actions;

export default reducer;
