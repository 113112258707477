import { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { getCategories } from "store/slices/categorySlice";
import { changeProductsCategory } from "store/slices/productSlice";
// import HtmlInput from "components/input/HtmlInput";
import Select from "components/select/Select";
// import Icon from "assets/icons/icons";

const ChangeCategories = ({ currentCategory, onClose }) => {
  const dispatch = useDispatch();
  const {
    products: { products },
    categories: { categories },
  } = useSelector((store) => store);
  const [category, setCategory] = useState();

  const handleSave = () => {
    const ids = [];
    products?.map((pr) => pr?.checked && ids.push(pr?.id));
    dispatch(changeProductsCategory({ ids, category_id: category }));
  };

  useEffect(() => {
    dispatch(getCategories({}));
  }, []);

  return (
    <div className="bg-c24 text-x2 w-[25rem] p-6 rounded-lg flex flex-col gap-4 sm:w-[90vw]">
      <span className="text-xl font-medium">Kategoriyani almashtirish</span>

      {/* <HtmlInput
        disabled
        inputMode="text"
        value="Category name"
        label="Amaldagi kategoriya"
        classNameLabel="text-c2 text-sm"
        classNameInput="h-10 border border-c32 px-2 rounded-xl outline-none"
      />

      <div className="flex justify-center">
        <Icon name="exchange" className="rotate-90" />
      </div> */}

      <Select
        firstOptionDisabled={false}
        value={category}
        classNameLabel="text-sm text-c2"
        label="Yangi kategoriyani tanlang"
        optionsLabel={{ label: "name", value: "id" }}
        onChange={(e) => setCategory(e.target.value)}
        options={categories?.filter((category) => category?.parent_id)}
        classNameSelect="bg-c24 w-full h-10 pl-8 rounded-xl text-c2 border border-c32 outline-none bg-arrow-down-gray bg-no-repeat bg-left bg-[center_left_0.3rem] select-arrow-none"
      />

      <button onClick={handleSave} className="bg-c10 text-c24 w-full h-10 rounded-xl px-3">
        Saqlash
      </button>
    </div>
  );
};

export default ChangeCategories;
