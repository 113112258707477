const user = JSON.parse(localStorage.getItem("user_data"))?.user_info;

export const UNIT_TYPES = [
  {
    id: 1,
    name: "Qism",
    value: "piece",
  },
  {
    id: 2,
    name: "Uzunlik",
    value: "length",
  },
  {
    id: 3,
    name: "Sig'im",
    value: "capacity",
  },
  {
    id: 4,
    name: "Og'irlik",
    value: "weight",
  },
  {
    id: 5,
    name: "Yuza",
    value: "surface",
  },
];

export const PAYMENT_TYPES = [
  { id: 1, label: "Naqd", value: "cash" },
  { id: 2, label: "Karta", value: "card" },
  { id: 3, label: "Nasiya", value: "debt" },
];

export const REFACTOR_SUM = (sum) =>
  sum
    ?.toString()
    ?.split("")
    ?.reverse()
    ?.map((s, idx) => ((idx + 1) % 3 === 0 ? ` ${s}` : s))
    ?.reverse()
    ?.join("");

export const REFACTOR_DATE = (date) =>
  date ? new Date(date)?.toLocaleDateString()?.substring(0, 10)?.replaceAll(".", "/") : "";

export const checkingPermissions = (permissions) => {
  if (user?.is_superadmin) return true;
  else {
    let count = 0;
    permissions?.map((p) => user?.permissions?.includes(p) && count++);
    return count === permissions?.length;
  }
};
