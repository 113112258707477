import { Accordion, AccordionDetails, styled } from "@mui/material";
import MuiAccordionSummary from "@mui/material/AccordionSummary";
import Icon from "assets/icons/icons";

const AccordionSummary = styled((props) => <MuiAccordionSummary {...props} />)(({ theme }) => ({
  padding: 0,
  minHeight: "auto !important",
  flexDirection: "reverse",
  "& .MuiAccordionSummary-expandIconWrapper": {
    transform: "rotate(0deg)",
  },
  "& .MuiAccordionSummary-expandIconWrapper.Mui-expanded": {
    transform: "rotate(180deg)",
  },
  "& .MuiAccordionSummary-content": {
    margin: theme.spacing(0.5),
    marginLeft: theme.spacing(2),
  },
}));

function MUIAccordionMenu({ id, noCollapse, active, summary, children = [], onClick }) {
  return (
    <Accordion
      id={id}
      expanded={active === id}
      disableGutters
      sx={{ background: "none", boxShadow: "none", position: "initial" }}
    >
      <AccordionSummary
        onClick={() => onClick && onClick()}
        expandIcon={!noCollapse && <Icon name="arrowDown" />}
        sx={{
          margin: 0,
          border: "none",
          backgroundColor: "var(--c2)",
        }}
      >
        {summary}
      </AccordionSummary>
      {!noCollapse && (
        <AccordionDetails
          sx={{
            padding: 0,
          }}
        >
          {children}
        </AccordionDetails>
      )}
    </Accordion>
  );
}

export default MUIAccordionMenu;
