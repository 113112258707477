import { useState } from "react";
import { useDispatch } from "react-redux";
import { createSupplier } from "store/slices/supplierSlice";
import HtmlInput from "components/input/HtmlInput";

const CreateDiscount = () => {
  const dispatch = useDispatch();
  const [discount, setDiscount] = useState({ name: "", percent: "" });

  const handleChangeDiscount = (name, value) => {
    setDiscount({ ...discount, [name]: value });
  };

  const handleSave = () => {
    discount?.name && discount?.percent && dispatch(createSupplier(discount));
  };

  return (
    <div className="bg-c24 w-[25rem] p-6 rounded-lg flex flex-col gap-5">
      <span className="text-c2 text-2xl">Yangi chegirma qo'shish</span>

      <HtmlInput
        inputMode="text"
        value={discount?.name}
        label="Chegirma nomi"
        onChange={(e) => handleChangeDiscount("name", e)}
      />

      <HtmlInput
        inputMode="text"
        value={discount?.percent}
        label="Chegirma foizi"
        onChange={(e) => handleChangeDiscount("percent", e)}
      />

      <button onClick={handleSave} className="bg-c10 text-c24 rounded-xl p-3">
        Saqlash
      </button>
    </div>
  );
};

export default CreateDiscount;
