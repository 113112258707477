import { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { REFACTOR_SUM } from "assets/constants/constants";
import { paymentTypeOptions } from "constants/PaymentTypeOptions";
import { getReturnedIncomes, changeReturnedIncomesFilter } from "store/slices/incomeSlice";
import ReturnedIncomesFilter from "pages/income/return-income/ReturnedIncomesFilter";
import MUIPagination from "components/mui-pagination/MUIPagination";
import AdminNavbar from "layers/admin-navbar/AdminNavbar";
import MUIDrawer from "components/mui-drawer/MUIDrawer";
import HtmlInput from "components/input/HtmlInput";
import MiniCard from "components/cards/MiniCard";
import Icon from "assets/icons/icons";
import { checkingPermissions } from "assets/constants/constants";

function ReturnedIncomes() {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const {
    income: {
      returnedIncomes: { returnedIncomes, filter, pagination },
    },
  } = useSelector((store) => store);

  const [openDrawer, setOpenDrawer] = useState(false);

  const handleOpenDrawer = () => setOpenDrawer(true);

  const handleCloseDrawer = () => setOpenDrawer(false);

  const handleSearch = (value) =>
    dispatch(changeReturnedIncomesFilter({ ...filter, search: value }));

  useEffect(() => {
    dispatch(getReturnedIncomes({ filter, pagination }));
    handleCloseDrawer();
  }, [dispatch, filter, pagination]);

  return (
    <>
      <MUIDrawer open={openDrawer} onClose={handleCloseDrawer}>
        <ReturnedIncomesFilter />
      </MUIDrawer>

      <>
        <AdminNavbar
          rightElement={
            checkingPermissions([
              "PRODUCT.READ",
              "SUPPLIER.READ",
              "CATEGORY.READ",
              "RETURN_INCOME.CREATE",
            ]) ? (
              <button
                onClick={() => navigate("/admin/income/returned/returning")}
                className="h-10 bg-c10 flex justify-center items-center gap-1 px-4 rounded-xl sm:p-0 sm:w-10"
              >
                <Icon name="exchange_white" />
                <span className="text-c24 sm:hidden">Mahsulot qaytarish</span>
              </button>
            ) : (
              <div className="w-10 h-10" />
            )
          }
        />

        <div className="grid grid-cols-3 sm:grid-cols-2 gap-2 mt-4 px-4">
          <MiniCard icon="money" bgColor="bg-money" title="Naqd" sum="6 000 000 UZS" />
          <MiniCard icon="card" bgColor="bg-card" title="O’tkazma" sum="4 000 000 UZS" />
          <MiniCard
            icon="overall"
            bgColor="bg-overall"
            title="Umumiy qaytarildi"
            sum="10 000 000 UZS"
            className="items-center sm:col-span-2"
          />
        </div>

        <div className="bg-c24 text-c2 m-4 border border-c32 rounded-lg">
          <div className="flex justify-between items-center border-b border-c32 py-2 px-4 gap-2">
            <HtmlInput
              type="search"
              placeholder="Qidiruv"
              value={filter?.search}
              classNameInput="border h-10 rounded-xl outline-0 bg-c31 text-sm"
              onChange={(e) => handleSearch(e.target.value)}
            />

            <button
              className="bg-c36 h-10 flex justify-center items-center gap-2 py-2 px-4 rounded-xl border border-c32 sm:p-0 sm:w-10"
              onClick={handleOpenDrawer}
            >
              <Icon name="filter" />
              <span className="text-c2 sm:hidden">Filter</span>
            </button>
          </div>

          <div className="bg-c24 text-c2 rounded-lg overflow-x-auto">
            <table className="w-full border-collapse sm:whitespace-nowrap">
              <thead>
                <tr className="text-c9 font-thin bg-c36">
                  <td className="px-2 py-3 pl-4">№</td>
                  <td className="px-2 py-3">Vaqti</td>
                  <td className="px-2 py-3">Yetkazib beruvchi</td>
                  <td className="px-2 py-3">Mahsulot soni</td>
                  <td className="px-2 py-3">To'lov turi</td>
                  <td className="px-2 py-3">To'landi</td>
                  <td className="px-2 py-3">Umumiy summa</td>
                  <td className="px-2 py-3 pr-4 text-end">Batafsil</td>
                </tr>
              </thead>

              <tbody>
                {returnedIncomes?.map((income, idx) => (
                  <tr key={income?.id} className="even:bg-c36 odd:bg-white">
                    <td className="px-2 py-3 pl-4 border-t">
                      {Math.abs(idx + pagination.pageSize * (pagination.page - 1) + 1)}
                    </td>
                    <td className="px-2 py-3 border-t">{income?.created_at?.substr(0, 16)}</td>
                    <td className="px-2 py-3 border-t">{income?.shipper || "-"}</td>
                    <td className="px-2 py-3 border-t">{income?.items_count || "-"}</td>
                    <td className="px-2 py-3 border-t">
                      <div className={`flex items-center justify-center gap-2 w-max`}>
                        <Icon name={paymentTypeOptions[income?.expense_type].icon} />
                        <span className={`${paymentTypeOptions[income?.expense_type].color}`}>
                          {paymentTypeOptions[income?.expense_type].label}
                        </span>
                      </div>
                    </td>
                    <td className="px-2 py-3 border-t">
                      {income?.paid ? `${REFACTOR_SUM(income?.paid)} UZS` : "-"}
                    </td>
                    <td className="px-2 py-3 border-t">
                      {income?.total_amount ? `${REFACTOR_SUM(income?.total_amount)} UZS` : "-"}
                    </td>
                    <td className="px-2 pr-4 border-t text-end">
                      <button
                        className="w-10 h-10 px-2 border border-c32 bg-c36 rounded-xl"
                        onClick={() =>
                          navigate(`/admin/income/returned/${income?.id}`)
                        }
                      >
                        <Icon name="arrowRightBlue" />
                      </button>
                    </td>
                  </tr>
                ))}
              </tbody>
            </table>
          </div>

          <div className="bg-c36 border-t border-c32 px-4 py-2 rounded-b-xl">
            <MUIPagination
              count={2}
              bgColor="c36"
              page={pagination?.page}
              pageSize={pagination?.pageSize}
              // onChangePagination={(name, value) => handleChangePagination(name, value)}
            />
          </div>
        </div>
      </>
    </>
  );
}

export default ReturnedIncomes;
