import { useState } from "react";
import { useDispatch } from "react-redux";
import { createSupplier } from "store/slices/supplierSlice";
import Icon from "assets/icons/icons";

const DeleteDiscount = () => {
  const dispatch = useDispatch();
  const [discount, setDiscount] = useState({ name: "", percent: "" });

  const handleSave = () => {
    discount?.name && discount?.percent && dispatch(createSupplier(discount));
  };

  return (
    <div className="bg-c24 text-c2 w-[25rem] sm:w-[80vw] p-6 rounded-lg flex flex-col gap-4">
      <div className="flex justify-center items-center">
        <div className="w-16 h-16 flex justify-center items-center bg-delete p-6 rounded-full">
          <Icon name="trash_red" className="w-10 h-10 max-w-none" />
        </div>
      </div>

      <span className="text-xl text-center">
        Siz rostan ham, ushbu chegirmani o’chirmoqchimisiz?
      </span>

      <div className="flex gap-4">
        <button
          onClick={handleSave}
          className="bg-c31 w-6/12 flex items-center justify-center rounded-xl p-3"
        >
          Yo’q, chiqish
        </button>

        <button
          onClick={handleSave}
          className="bg-c10 text-c24 w-6/12 flex items-center justify-center rounded-xl p-3"
        >
          Xa, o’chirish
        </button>
      </div>
    </div>
  );
};

export default DeleteDiscount;
