import { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { createSupplier, editSupplier, getCurrentSupplier } from "store/slices/supplierSlice";
import HtmlInput from "components/input/HtmlInput";

const CreateSupplier = ({ action, supplier_id }) => {
  const dispatch = useDispatch();
  const {
    suppliers: { currentSupplier },
  } = useSelector((store) => store);
  const [supplier, setSupplier] = useState({ name: "", contact_name: "", phone: "" });

  const handleChangeSupplier = (name, value) => {
    setSupplier({ ...supplier, [name]: value });
  };

  const handleSave = () => {
    action === "create"
      ? supplier?.name &&
        supplier?.contact_name &&
        supplier?.phone &&
        dispatch(createSupplier(supplier))
      : dispatch(editSupplier({ supplier_id, supplier }));
  };

  useEffect(() => {
    action === "update" && supplier_id && dispatch(getCurrentSupplier(supplier_id));
  }, [action, supplier_id, dispatch]);

  useEffect(() => {
    action === "update" && supplier_id && setSupplier(currentSupplier);
  }, [currentSupplier]);

  return (
    <div className="bg-c24 text-c2 w-[25rem] p-6 rounded-xl flex flex-col gap-4 sm:w-[90vw]">
      <span className="text-2xl">
        {action === "create" ? "Ta'minotchi qo'shish" : "Ta'minotchini tahrirlash"}
      </span>

      <HtmlInput
        inputMode="text"
        placeholder="Nomi"
        value={supplier?.name}
        label="Ta'minotchi nomi *"
        onChange={(e) => handleChangeSupplier("name", e.target.value)}
      />

      <HtmlInput
        inputMode="text"
        placeholder="Ism va familiya"
        value={supplier?.contact_name}
        label="Ta'minotchi ism va familiyasi *"
        onChange={(e) => handleChangeSupplier("contact_name", e.target.value)}
      />

      <HtmlInput
        inputMode="tel"
        label="Telefon raqami *"
        value={supplier?.phone}
        mask="+\9\98 99 999 99 99"
        placeholder="+998 99 999 99 99"
        onChange={(e) => handleChangeSupplier("phone", e.target.value)}
      />

      <button onClick={handleSave} className="bg-c10 text-c24 rounded-xl p-3">
        Saqlash
      </button>
    </div>
  );
};

export default CreateSupplier;
