import { useState, useEffect } from "react";
import { useNavigate } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { paymentTypeOptions } from "constants/PaymentTypeOptions";
import { REFACTOR_SUM, checkingPermissions } from "assets/constants/constants";
import { getIncomeList, changePaginationIncomeList } from "store/slices/incomeSlice";
import MUIPagination from "components/mui-pagination/MUIPagination";
import AdminNavbar from "layers/admin-navbar/AdminNavbar";
import MUIDrawer from "components/mui-drawer/MUIDrawer";
import MiniCard from "components/cards/MiniCard";
import IncomesFilter from "./IncomesFilter";
import Icon from "assets/icons/icons";

function Incomes() {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const {
    income: {
      refresh,
      incomeList: { incomeList, filter, total, pagination },
    },
  } = useSelector((store) => store);
  const [openDrawer, setOpenDrawer] = useState({ open: false, action: "" });

  const handleOpenDrawer = (action) => setOpenDrawer({ open: true, action });

  const handleCloseDrawer = () => setOpenDrawer({ ...openDrawer, open: false });

  const handleChangePagination = (name, value) =>
    dispatch(changePaginationIncomeList({ name, value }));

  useEffect(() => {
    dispatch(getIncomeList({ filter, pagination }));
    handleCloseDrawer();
  }, [dispatch, refresh, filter, pagination]);

  return (
    <>
      <MUIDrawer open={openDrawer?.open} onClose={handleCloseDrawer}>
        {openDrawer?.action === "filter" && <IncomesFilter />}
      </MUIDrawer>

      <>
        <AdminNavbar
          rightElement={
            <div className="flex gap-4">
              {checkingPermissions([
                "INCOME.CREATE",
                "SUPPLIER.READ",
                "CATEGORY.READ",
                "PRODUCT.READ",
              ]) ? (
                <button
                  onClick={() => navigate("/admin/income/incomes/income")}
                  className="bg-c10 h-10 px-4 py-2 rounded-xl flex justify-center items-center gap-1 sm:p-0 sm:w-10"
                >
                  <Icon name="add" />
                  <span className="text-c24 sm:hidden">Yangi kirim</span>
                </button>
              ) : (
                <div className="w-10 h-10" />
              )}
            </div>
          }
        />

        <div className="grid grid-cols-3 sm:grid-cols-2 gap-2 px-4 mt-4">
          <MiniCard icon="money" bgColor="bg-money" title="Naqd" sum="6 000 000 UZS" />
          <MiniCard icon="card" bgColor="bg-card" title="Plastik karta" sum="4 000 000 UZS" />
          <MiniCard
            icon="overall"
            bgColor="bg-overall"
            title="Umumiy savdo"
            sum="10 000 000 UZS"
            className="items-center sm:col-span-2"
          />
        </div>

        <div className="bg-c24 text-c2 m-4 border border-c32 rounded-lg">
          <div className="flex justify-end items-center border-b border-c32 px-4 py-2 gap-2">
            <button
              onClick={() => handleOpenDrawer("filter")}
              className="bg-c36 h-10 flex justify-center items-center gap-2 py-2 px-4 rounded-xl border border-c32 sm:p-0 sm:w-10"
            >
              <Icon name="filter" />
              <span className="text-c2 sm:hidden">Filter</span>
            </button>
          </div>

          <div className="bg-c24 text-c2 rounded-lg overflow-x-auto">
            <table className="sm:whitespace-nowrap w-full border-collapse even:bg-c36 odd:bg-white">
              <thead>
                <tr className="text-c9 font-thin bg-c36">
                  <td className="py-3 pl-4 px-2">№</td>
                  <td className="py-3 px-2">Vaqti</td>
                  <td className="py-3 px-2">Yetkazib beruvchi</td>
                  <td className="py-3 px-2">Mahsulot soni</td>
                  <td className="py-3 px-2">Turi</td>
                  <td className="py-3 px-2">To'landi</td>
                  <td className="py-3 px-2">Umumiy summa</td>
                  <td className="py-3 px-2 pr-4 text-end">Batafsil</td>
                </tr>
              </thead>

              <tbody>
                {incomeList?.map((order, idx) => (
                  <tr key={order?.id}>
                    <td className={`px-2 py-3 pl-4 border-t border-c32`}>
                      {Math.abs(idx + pagination.pageSize * (pagination.page - 1) + 1)}
                    </td>
                    <td className={`px-2 py-3 border-t border-c32`}>
                      {order?.created_at?.substring(0, 16)}
                    </td>
                    <td className={`px-2 py-3 border-t border-c32`}>{order?.supplier_name}</td>
                    <td className={`px-2 py-3 border-t border-c32`}>{order?.items_count}</td>
                    <td className={`px-2 border-t border-c32`}>
                      <div
                        className={`${
                          paymentTypeOptions[order?.expense_type].bgColor
                        } px-2 py-2 rounded-xl flex items-center justify-center gap-2 w-max`}
                      >
                        <Icon name={paymentTypeOptions[order?.expense_type].icon} />
                        <span className={`${paymentTypeOptions[order?.expense_type].color}`}>
                          {paymentTypeOptions[order?.expense_type].label}
                        </span>
                      </div>
                    </td>
                    <td
                      className={`py-2 border-t border-c32 px-2 ${order?.paid ? "text-c33" : ""}`}
                    >
                      {order?.paid ? `${REFACTOR_SUM(order?.paid)} UZS` : "-"}
                    </td>
                    <td className={`px-2 py-2 border-t border-c32`}>
                      {order?.total_amount ? `${REFACTOR_SUM(order?.total_amount)} UZS` : "-"}
                    </td>
                    <td className={`px-2 pr-4 border-t border-c32 text-end`}>
                      <button
                        className="w-10 h-10 border border-c32 rounded-xl"
                        onClick={() => navigate(`/admin/income/incomes/${order?.id}`)}
                      >
                        <Icon name="arrowRightBlue" className="mx-auto" />
                      </button>
                    </td>
                  </tr>
                ))}
              </tbody>
            </table>
          </div>

          <div className="bg-c36 border-t border-c32 px-4 py-2">
            <MUIPagination
              count={total}
              bgColor="c36"
              page={pagination?.page}
              pageSize={pagination?.pageSize}
              onChangePagination={(name, value) => handleChangePagination(name, value)}
            />
          </div>
        </div>
      </>
    </>
  );
}

export default Incomes;
