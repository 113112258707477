import { Menu } from "@mui/material";

const MUIMenu = ({
  anchorEl,
  open,
  handleClose,
  sx,
  children,
  transformOrigin = { horizontal: "right", vertical: "top" },
  anchorOrigin = { horizontal: "right", vertical: "top" },
  ...props
}) => {
  return (
    <Menu
      {...props}
      anchorEl={anchorEl}
      id="account-menu"
      open={open}
      onClose={handleClose}
      // onClick={handleClose}
      PaperProps={{
        elevation: 0,
        sx: {
          borderRadius: "0.5rem",
          bgcolor: "white",
          color: "#212c47",
          overflow: "visible",
          filter: "drop-shadow(0px 2px 8px rgba(0,0,0,0.32))",
          mt: 5,
          ...sx,
          "& .MuiAvatar-root": {
            width: 32,
            height: 32,
            ml: -0.5,
            mr: 1,
          },
          "&:before": {
            content: '""',
            display: "block",
            position: "absolute",
            top: 0,
            right: 14,
            width: 10,
            height: 10,
            transform: "translateY(-50%) rotate(45deg)",
            zIndex: 0,
          },
        },
      }}
      transformOrigin={transformOrigin}
      anchorOrigin={anchorOrigin}
    >
      {children}
    </Menu>
  );
};

export default MUIMenu;
