import { axiosPrivate } from "services/axiosPrivate";
import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";

const initialState = {
  total: null,
  refresh: "",
  customers: [],
  isLoading: false,
  pagination: { page: 1, pageSize: 10 },
};

export const createCustomer = createAsyncThunk("customers/create-customer", async (customer) => {
  try {
    const response = await axiosPrivate.post("customer/post", customer);
    return response.status;
  } catch (error) {
    console.log(error);
  }
});

export const getCustomers = createAsyncThunk(
  "customers/get-customers",
  async ({ search, pagination: { page, pageSize } }) => {
    const response = await axiosPrivate.get(
      `customer/list/?page=${page || ""}&page_size=${pageSize || ""}&search_text=${search || ""}`
    );
    return response.data;
  }
);

export const updateCustomer = createAsyncThunk(
  "customers/update-customer",
  async ({ id, customer }) => {
    const response = await axiosPrivate.patch(`customer/patch/${id}`, customer);
    return response.data;
  }
);

export const deleteCustomer = createAsyncThunk("customers/delete-customer", async (id) => {
  const response = await axiosPrivate.delete(`customer/delete/${id}`);
  return response.data;
});

export const customerSlice = createSlice({
  name: "customer",
  initialState,
  reducers: {
    changePagination: (state, action) => {
      state.pagination[action.payload.name] = action.payload.value;
      action.payload.name === "pageSize" &&
        Math.ceil(state.total / state.pagination.pageSize) <= state.pagination.page &&
        (state.pagination.page = Math.ceil(state.total / state.pagination.pageSize));
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(getCustomers.pending, (state) => {
        state.isLoading = true;
      })
      .addCase(getCustomers.fulfilled, (state, action) => {
        state.isLoading = false;
        state.customers = action.payload.data;
        state.total = action.payload.total;
      })
      .addCase(createCustomer.fulfilled, (state, action) => {
        if (action.payload === 200) state.refresh = `create-customer-${new Date()}`;
      })
      .addCase(updateCustomer.fulfilled, (state, action) => {
        state.refresh = `update-customer-${new Date()}`;
      })
      .addCase(deleteCustomer.fulfilled, (state, action) => {
        state.refresh = `delete-customer-${new Date()}`;
      });
  },
});

const { reducer, actions } = customerSlice;

export const { changePagination } = actions;

export default reducer;
