import Icon from "assets/icons/icons";

const DeleteUnit = ({ onDelete, onClose }) => {
  return (
    <div className="bg-c24 w-[25rem] sm:w-[90vw] p-6 rounded-lg flex flex-col gap-4">
      <div className="flex justify-center items-center">
        <div className="w-16 h-16 flex justify-center items-center bg-delete p-3 rounded-full">
          <Icon name="trash_red" className="w-12 h-12" />
        </div>
      </div>

      <span className="text-c2 text-xl text-center">
        Siz rostan ham ushbu birlikni o’chirmoqchimisiz?
      </span>

      <div className="flex gap-4">
        <button
          onClick={onClose}
          className="bg-c31 text-x2 w-1/2 flex items-center justify-center rounded-xl p-3"
        >
          Yo’q, chiqish
        </button>

        <button
          onClick={onDelete}
          className="bg-c10 text-c24 w-1/2 flex items-center justify-center rounded-xl p-3"
        >
          Xa, o’chirish
        </button>
      </div>
    </div>
  );
};

export default DeleteUnit;
