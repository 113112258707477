// const platform = navigator.platform.toLowerCase();

// console.log(platform);

// export const deviceType =
//   platform.includes("win") || platform.includes("mac") || platform.includes("linux")
//     ? "pc"
//     : platform.includes("iphone") ||
//       platform.includes("ipad") ||
//       platform.includes("ipod") ||
//       platform.includes("android")
//     ? "mobile"
//     : "unknown";

const userAgent = navigator.userAgent;

export const deviceType = /Android|webOS|iPhone|iPad|iPod|BlackBerry|IEMobile|Opera Mini/i.test(
  userAgent
)
  ? "mobile"
  : "desktop";
