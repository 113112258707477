import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import { axiosPrivate } from "services/axiosPrivate";

const initialState = {
  isLoading: false,
  refresh: "",
  currencies: [],
  checkedAll: false,
  checkedCount: 0,
  total: null,
  pagination: { page: 1, pageSize: 10 },
};

export const getCurrencies = createAsyncThunk(
  "currency/get-currencies",
  async ({ search, pagination = { page: 1, pageSize: 100 } }) => {
    const response = await axiosPrivate.get(
      `currency/list/?page=${pagination?.page}&page_size=${pagination?.pageSize}&search=${
        search || ""
      }`
    );
    return response.data;
  }
);

// export const createBrand = createAsyncThunk("brand/create-brand", async (brand) => {
//   const response = await axiosPrivate.post("brand/post/", brand);
//   return response.data;
// });

// export const updateBrand = createAsyncThunk("brand/update-brand", async ({ brand_id, brand }) => {
//   const response = await axiosPrivate.patch(`brand/patch/${brand_id}`, brand);
//   return response.data;
// });

// export const deleteBrand = createAsyncThunk("brand/delete-brand", async (brand_id) => {
//   const response = await axiosPrivate.delete(`brand/delete/${brand_id}`);
//   return response.data;
// });

// export const deleteBrands = createAsyncThunk("brand/delete-brand-by-ids", async (ids) => {
//   const response = await axiosPrivate.post(`brand/delete/by-ids`, { ids });
//   return response.data;
// });

export const currencySlice = createSlice({
  name: "currency",
  initialState,
  reducers: {
    // checkedAllBrand: (state) => {
    //   state.checkedAll = !state.checkedAll;
    //   state.brands = state.brands.map((brand) => ({
    //     ...brand,
    //     checked: state.checkedAll,
    //   }));
    //   state.checkedCount = state.checkedAll ? state.brands.length : 0;
    // },
    // checkedBrand: (state, action) => {
    //   let checkedCount = 0;
    //   state.brands = state.brands.map((brand) =>
    //     brand.id === action.payload ? { ...brand, checked: brand?.checked ? false : true } : brand
    //   );
    //   state.brands.map((brand) => brand.checked && (checkedCount += 1));
    //   state.checkedCount = checkedCount;
    //   checkedCount === state.brands.length ? (state.checkedAll = true) : (state.checkedAll = false);
    // },
    // changePagination: (state, action) => {
    //   state.pagination[action.payload.name] = action.payload.value;
    //   action.payload.name === "pageSize" &&
    //     Math.ceil(state.total / state.pagination.pageSize) <= state.pagination.page &&
    //     (state.pagination.page = Math.ceil(state.total / state.pagination.pageSize));
    // },
  },

  extraReducers: (builder) => {
    builder
      // .addCase(createBrand.fulfilled, (state) => {
      //   state.refresh = `create-brand-${new Date()}`;
      // })
      // .addCase(updateBrand.fulfilled, (state) => {
      //   state.refresh = `update-brand-${new Date()}`;
      // })
      // .addCase(deleteBrand.fulfilled, (state) => {
      //   state.refresh = `delete-brand-${new Date()}`;
      // })
      // .addCase(deleteBrands.fulfilled, (state) => {
      //   state.refresh = `delete-brand-by-ids-${new Date()}`;
      // })
      .addCase(getCurrencies.pending, (state) => {
        state.isLoading = true;
      })
      .addCase(getCurrencies.fulfilled, (state, action) => {
        state.brands = action.payload.data;
        state.total = action.payload.total;
      });
  },
});

const { reducer, actions } = currencySlice;

// export const { checkedAllBrand, checkedBrand, changePagination } = actions;

export default reducer;
