import { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import {
  returnOrder,
  chooseReturnProducts,
  changeReturnProductQuantity,
} from "store/slices/orderSlice";
import { checkingPermissions } from "assets/constants/constants";
import MUIModal from "components/mui-modal/MUIModal";
import Checkbox from "components/checkbox/Checkbox";
import Keyboard from "layers/keyboard/Keyboard";
import Input from "components/input/Input";
import { toast } from "react-toastify";
import Icon from "assets/icons/icons";

const paymentTypeOptions = {
  card: {
    icon: "creditCard",
    label: "Karta",
  },
  cash: {
    icon: "payment",
    label: "Naqd",
  },
  debt: {
    icon: "shopping",
    label: "Nasiya",
  },
  other: {
    icon: "more",
    label: "Boshqa",
  },
};

function OrderHistoryItem() {
  const dispatch = useDispatch();
  const {
    orders: {
      refresh,
      ordersHistory: {
        historyItem: { id, user_id, items, amount, payment_type, created_at, returnPayment },
      },
    },
    auth: { session },
  } = useSelector((store) => store);

  const [openModal, setOpenModal] = useState(false);

  const handleOpenModal = () => setOpenModal(true);

  const handleCloseModal = () => setOpenModal(false);

  const handleAddReturnProduct = (item) => {
    session?.session_id && dispatch(chooseReturnProducts(item?.id));
    !session?.session_id && toast.warning("Sessiya ochilmagan.");
  };

  const handleChangeReturnProduct = (id, returnQuantity) =>
    dispatch(changeReturnProductQuantity({ id, returnQuantity }));

  const handleReturn = () => {
    const products = [];
    const payments = [];
    Object.keys(returnPayment).map(
      (key) =>
        returnPayment[key] &&
        key !== "totalPrice" &&
        payments.push({ type: key, amount: returnPayment[key] })
    );
    items?.map(
      (item) =>
        item?.returnStatus &&
        products.push({ order_item_id: item?.id, quantity: item.returnQuantity })
    );
    dispatch(returnOrder({ order_id: id, user_id, items: products, payments }));
  };

  useEffect(() => {
    handleCloseModal();
  }, [refresh]);

  return (
    <>
      <MUIModal open={openModal} position="center" onClose={handleCloseModal}>
        <div className="bg-c2 text-c24 w-[20rem] flex flex-col gap-3 p-4 rounded">
          <div className="flex flex-col gap-3 items-center">
            <div className="bg-c37 w-[4rem] h-[4rem] flex items-center justify-center rounded-full">
              <Icon name="checkBlue" />
            </div>

            <p className="text-center text-xl">Qaytarilmoqda</p>
          </div>

          <div className="flex w-full justify-between">
            <span className="text-c9">Qaytarish summasi</span>
            <span>{returnPayment?.totalPrice}</span>
          </div>

          {returnPayment?.cash ? (
            <div className="flex w-full justify-between">
              <span className="text-c9">Mijozga qaytarildi</span>
              <span>{returnPayment?.cash}</span>
            </div>
          ) : (
            ""
          )}

          {returnPayment?.debt ? (
            <>
              <div className="flex w-full justify-between">
                <span className="text-c9">Mijoz nasiyasi</span>
                <span>{returnPayment?.debt}</span>
              </div>
              <div className="bg-c1 p-2 rounded">
                <p>Qaytarish summasi, mijoz nasiyasidan ushlab qolindi.</p>
              </div>
            </>
          ) : (
            ""
          )}

          <button onClick={handleReturn} className="bg-c10 text-center p-3 rounded">
            Tushunarli
          </button>
        </div>
      </MUIModal>

      <div className="flex text-c24 h-full items-end relative">
        {checkingPermissions(["RETURN_ORDER.CREATE"]) && (
          <div className="bg-c2 w-[25rem] h-2/5 p-2 mx-1 rounded-tl sm:hidden">
            <Keyboard inputMode="numeric" width="full" onlyOne={true} />
          </div>
        )}

        <div className="bg-c2 w-[40rem] h-full flex flex-col relative sm:w-screen">
          <div className="flex flex-col justify-center gap-1 px-4 h-[5rem]">
            <p className="text-xl">Buyurtma: {id}</p>
            <div className="flex items-center gap-6">
              {payment_type && (
                <div className="flex items-center gap-2">
                  <Icon name={paymentTypeOptions?.[payment_type]?.icon} className="opacity-50" />
                  <span>{paymentTypeOptions?.[payment_type]?.label}</span>
                </div>
              )}

              {created_at && (
                <div className="flex items-center gap-2">
                  <Icon name="timer" className="opacity-50" />{" "}
                  {new Date(created_at)?.toLocaleString()?.substr(12, 5)}
                </div>
              )}
            </div>
          </div>

          <div
            className={`relative overflow-y-auto h-[calc(100%-8rem)] ${
              returnPayment?.totalPrice ? "sm:h-[calc(100%-12rem)]" : "sm:h-[calc(100%-8rem)]"
            }`}
          >
            <table className="w-full border-collapse">
              <thead className="bg-c1 text-c9 sticky top-0">
                <tr>
                  {checkingPermissions(["RETURN_ORDER.CREATE"]) && (
                    <td className="py-3 pl-4 text-start"></td>
                  )}
                  <td
                    className={`py-3 text-start ${
                      !checkingPermissions(["RETURN_ORDER.CREATE"]) ? "pl-4" : ""
                    }`}
                  >
                    Nomi
                  </td>
                  <td className="py-3 bg-c1">Miqdori</td>
                  <td className="py-3 pr-4 text-end">Narxi</td>
                </tr>
              </thead>
              <tbody>
                {items?.map((history, idx) => (
                  <tr
                    key={history?.id}
                    className={`${
                      !history?.quantity && history?.returned_quantity
                        ? "line-through text-c25"
                        : ""
                    }`}
                  >
                    {checkingPermissions(["RETURN_ORDER.CREATE"]) && (
                      <td className="border-b-[1px] border-c7 py-3 pl-4 text-start">
                        <Checkbox
                          bgColor="bg-c1"
                          classNameLabel="text-c9"
                          checked={history?.returnStatus ? true : false}
                          onChange={() => handleAddReturnProduct(history)}
                          disabled={!history?.quantity && history?.returned_quantity ? true : false}
                          classNameCheckbox={`text-c2 border-c7 p-3 w-3 h-3 ${
                            history?.returnStatus ? "border-c10" : "border-c7"
                          }`}
                        />
                      </td>
                    )}
                    <td
                      className={`border-b-[1px] border-c7 py-3 text-start  ${
                        !checkingPermissions(["RETURN_ORDER.CREATE"]) ? "pl-4" : ""
                      }`}
                    >
                    {history?.product_name}
                    </td>
                    <td className={`w-5r border-b-[1px] border-c7 relative`}>
                      {history?.returnStatus ? (
                        <Input
                          type="text"
                          value={history?.returnQuantity}
                          onChange={(value) =>
                            handleChangeReturnProduct(
                              history?.id,
                              value <= history?.quantity ? value : history?.quantity?.toString()
                            )
                          }
                          autoFocus={true}
                          inputMode="numeric"
                          defaultNull={true}
                          classNameInput="w-[4rem] fs-1 py-2 outline-0 border-0 border-b-[1px] bg-transparent"
                          virtualKeyboard={false}
                        />
                      ) : (
                        <>
                          <span>{history?.returnQuantity || history?.quantity}</span>
                          {history?.returned_quantity ? (
                            <span className="bg-c14 text-c24 px-2 py-1 rounded-xl ml-2 absolute top-2">
                              {history?.returned_quantity}
                            </span>
                          ) : (
                            ""
                          )}
                        </>
                      )}
                    </td>
                    <td className="border-b-[1px] border-c7 py-3 pr-4 text-end gap-2">
                      <span>{history?.price}</span>
                    </td>
                  </tr>
                ))}
              </tbody>
            </table>
          </div>

          <div
            className={`w-full flex flex-col justify-center absolute bottom-0 ${
              returnPayment?.totalPrice ? "h-[3rem] sm:h-[7rem]" : "h-[3rem]"
            } `}
          >
            {returnPayment?.totalPrice ? (
              <div className="flex h-full gap-1 p-1 sm:flex-col">
                <div className="bg-c5 w-1/2 h-full flex items-center justify-between gap-2 rounded px-4 sm:w-full sm:h-1/2">
                  <span className="text-c25">Umumiy savdo: </span>
                  <span className="text-lg">{amount}</span>
                </div>

                <div
                  onClick={handleOpenModal}
                  className="bg-c17 w-1/2 h-full flex items-center justify-between gap-2 rounded px-4 sm:w-full sm:h-1/2"
                >
                  <span className="text-c25">Qaytarish:</span>
                  <span className="text-lg">{returnPayment?.totalPrice}</span>
                </div>
              </div>
            ) : (
              <div className="bg-c5 flex justify-between items-center px-4 h-full">
                <span className="text-c25">Umumiy savdo</span>
                <span className="text-lg">{amount}</span>
              </div>
            )}
          </div>
        </div>
      </div>
    </>
  );
}

export default OrderHistoryItem;
