import { v4 } from "uuid";
import { debounce } from "lodash";
import { useCallback, useEffect, useState } from "react";
import ReactInputMask from "react-input-mask";
import Icon from "assets/icons/icons";

const HtmlInput = ({
  type = "text",
  id = v4(),
  label,
  onChange,
  value = "",
  placeholder,
  bgColor = "",
  className = "",
  classNameLabel = "text-c2 text-sm",
  classNameInput = "text-sm text-c2 border border-c32 px-2 w-full h-10 rounded-xl outline-none",
  ...rest
}) => {
  const [inputType, setInputType] = useState(type);
  const [text, setText] = useState("");

  const debouncedSearch = useCallback(
    debounce((search) => onChange(search), 512),
    []
  );

  const handleChange = (e) => {
    if (type === "search") {
      setText(e.target.value);
      debouncedSearch(e);
    } else onChange(e);
  };

  useEffect(() => debouncedSearch.cancel(), [debouncedSearch, value]);

  return (
    <label htmlFor={id} className={`flex flex-col relative ${className}`}>
      <span className={classNameLabel}>{label}</span>
      <div className="relative w-full flex items-center">
        {type === "search" && <Icon name="searchGray" className="absolute  left-2" />}

        <ReactInputMask
          {...rest}
          id={id}
          type={inputType}
          onChange={handleChange}
          placeholder={placeholder}
          className={`${type === "search" ? "pl-10 pr-2" : ""} ${classNameInput}`}
          value={type === "search" ? text : value}
        />
        {type === "password" && (
          <Icon
            className="absolute right-2"
            name={inputType === "password" ? "visibility" : "visibilityOff"}
            onClick={() => setInputType(inputType === "password" ? "text" : "password")}
          />
        )}
      </div>
    </label>
  );
};

export default HtmlInput;
