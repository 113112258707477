import { useState } from "react";
import MiniCard from "components/cards/MiniCard";
import Card from "components/cards/Card";
import LineCharts from "components/charts/LineChart";
import BarCharts from "components/charts/BarCharts";
import BarChartVertical from "components/charts/BarChartVertical";
import Icon from "assets/icons/icons";
import MUIMenu from "components/mui-menu/MUIMenu";
import AdminNavbar from "layers/admin-navbar/AdminNavbar";
import RangeCalender from "components/mui-date-range-picker/MUIDateRangePicker";
import PieCharts from "components/charts/PieChart";
import { addDays } from "date-fns";

const maxSellProducts = [
  { name: "Coca Cola 1.5", Sotildi: 3056, Umumiy_summa: 4000000, Sof_foyda: 5000000 },
  { name: "Dizzy 0.75", Sotildi: 2956, Umumiy_summa: 4000000, Sof_foyda: 5000000 },
  { name: "Nestle 1.5", Sotildi: 2756, Umumiy_summa: 4000000, Sof_foyda: 5000000 },
  { name: "Shakar", Sotildi: 2456, Umumiy_summa: 4000000, Sof_foyda: 5000000 },
  { name: "Pepsi 1.5", Sotildi: 2256, Umumiy_summa: 4000000, Sof_foyda: 5000000 },
  { name: "Obi non", Sotildi: 2100, Umumiy_summa: 4000000, Sof_foyda: 5000000 },
  { name: "Tarvuz kg", Sotildi: 1834, Umumiy_summa: 4000000, Sof_foyda: 5000000 },
  { name: "Qovun kg", Sotildi: 1734, Umumiy_summa: 4000000, Sof_foyda: 5000000 },
  { name: "Prostokvashino", Sotildi: 1606, Umumiy_summa: 4000000, Sof_foyda: 5000000 },
  { name: "Kartoshka", Sotildi: 1343, Umumiy_summa: 4000000, Sof_foyda: 5000000 },
  { name: "Piyoz", Sotildi: 1031, Umumiy_summa: 4000000, Sof_foyda: 5000000 },
];

const maxSellCategories = [
  {
    name: "Ichimlik suvlari",
    uv: 900,
    Umumiy_summa: 10000000,
    fill: "#008EF5",
  },
  {
    name: "Go'shtli mahsulotlar",
    uv: 868,
    Umumiy_summa: 10000000,
    fill: "#359F46",
  },
  {
    name: "Sutli mahsulotlar",
    uv: 1397,
    Umumiy_summa: 10000000,
    fill: "#A600F5",
  },
  {
    name: "Sabzavotlar",
    uv: 1480,
    Umumiy_summa: 10000000,
    fill: "#F56700",
  },
  {
    name: "Mevalar",
    uv: 1520,
    Umumiy_summa: 10000000,
    fill: "#0CBCBC",
  },
];

const sellStatsitic = [
  { name: "1-aprel", Naqd: 4000000, Plastik: 2000000, Umumiy_savdo: 2000000 },
  { name: "2-aprel", Naqd: 2000000, Plastik: 7000000, Umumiy_savdo: 5000000 },
  { name: "3-aprel", Naqd: 6000000, Plastik: 4000000, Umumiy_savdo: 2000000 },
  { name: "4-aprel", Naqd: 1000000, Plastik: 3000000, Umumiy_savdo: 1000000 },
  { name: "5-aprel", Naqd: 7000000, Plastik: 5000000, Umumiy_savdo: 3000000 },
  { name: "6-aprel", Naqd: 1000000, Plastik: 2000000, Umumiy_savdo: 4000000 },
  { name: "7-aprel", Naqd: 8000000, Plastik: 3000000, Umumiy_savdo: 2000000 },
  { name: "8-aprel", Naqd: 4000000, Plastik: 6000000, Umumiy_savdo: 1000000 },
  { name: "9-aprel", Naqd: 5000000, Plastik: 2000000, Umumiy_savdo: 3000000 },
  { name: "10-aprel", Naqd: 3000000, Plastik: 5000000, Umumiy_savdo: 4000000 },
  { name: "11-aprel", Naqd: 8000000, Plastik: 3000000, Umumiy_savdo: 2000000 },
  { name: "12-aprel", Naqd: 1000000, Plastik: 5000000, Umumiy_savdo: 3000000 },
  { name: "13-aprel", Naqd: 7000000, Plastik: 2000000, Umumiy_savdo: 6000000 },
  { name: "14-aprel", Naqd: 2000000, Plastik: 4000000, Umumiy_savdo: 4000000 },
  { name: "15-aprel", Naqd: 4000000, Plastik: 6000000, Umumiy_savdo: 2000000 },
];

const Dashboard = () => {
  const [filter, setFilter] = useState({
    selection: { startDate: new Date(), endDate: addDays(new Date(), 0) },
  });
  const [openMenu, setOpenMenu] = useState({ anchorEl: null });

  const handleChange = (name, value) => {
    setFilter({ ...filter, [name]: value });
  };

  const handleClick = (event) => {
    setOpenMenu({ anchorEl: event.currentTarget });
  };

  const handleClose = () => {
    setOpenMenu({ ...openMenu, anchorEl: null });
  };

  return (
    <div className="flex flex-col">
      <MUIMenu
        handleClose={handleClose}
        anchorEl={openMenu?.anchorEl}
        open={openMenu?.anchorEl ? true : false}
        children={
          <div onClick={(event) => event.stopPropagation()}>
            <RangeCalender value={filter?.selection} handleChange={handleChange} />
          </div>
        }
      />

      <AdminNavbar
        rightElement={
          <button
            onClick={handleClick}
            className="flex h-10 bg-c32 items-center justify-center gap-2 rounded-xl z-[1] px-2 sm:w-10 sm:bg-c10 sm:p-1"
          >
            <Icon name="calendar_white" className="hidden sm:flex" />
            <Icon name="calendar" className="sm:hidden" />
            <span className="text-c2 text-sm sm:hidden">
              {new Date(filter.selection.startDate)
                .toLocaleDateString()
                .replaceAll("/", ".")
                .substring(0, 10)}{" "}
              -{" "}
              {new Date(filter.selection.endDate)
                .toLocaleDateString()
                .replaceAll("/", ".")
                .substring(0, 10)}
            </span>
            <Icon name="expand_more" className="sm:hidden" />
          </button>
        }
      />

      <div className="flex flex-col gap-4 relative p-4">
        <div className="w-full grid grid-cols-4 sm:grid-cols-2 gap-2">
          <MiniCard icon="money" bgColor="bg-money" title="Naqd" sum="6 000 000 UZS" />
          <MiniCard icon="card" bgColor="bg-card" title="Plastik karta" sum="4 000 000 UZS" />
          <MiniCard icon="overall" bgColor="bg-overall" title="Umumiy savdo" sum="10 000 000 UZS" />
          <MiniCard
            icon="increase_chart"
            bgColor="bg-profit"
            title="Sof foyda"
            sum="6 000 000 UZS"
          />
        </div>

        <div className="w-full h-full hidden sm:inline">
          <Card title="Statistika " chart={<PieCharts />} />
        </div>

        <div className="w-full h-full sm:hidden">
          <Card title="Statistika" chart={<LineCharts data={sellStatsitic} />} />
        </div>

        <div className="w-full flex sm:flex-col gap-4">
          <div className="w-3/5 sm:w-full rounded-xl">
            <Card
              title="Eng ko'p sotilayotkan mahsulotlar"
              chart={<BarCharts data={maxSellProducts} />}
            />
          </div>

          <div className="w-2/5 sm:w-full rounded-xl">
            <Card
              title="Eng ko'p sotayotgan kategoriyalar"
              chart={<BarChartVertical data={maxSellCategories} />}
            />
          </div>
        </div>
      </div>
    </div>
  );
};

export default Dashboard;
