import lock from "assets/icons/lock.svg";
import more from "assets/icons/more.svg";
import plus from "assets/icons/plus.svg";
import plus_gray from "assets/icons/plus-gray.svg";
import close from "assets/icons/close.svg";
import logout from "assets/icons/logout.svg";
import global from "assets/icons/global.svg";
import unlock from "assets/icons/unlock.svg";
import search from "assets/icons/search.svg";
import searchGray from "assets/icons/search-gray.svg";
import qrcode from "assets/icons/qrcode.svg";
import person from "assets/icons/person.svg";
import person_grey from "assets/icons/person_gray.svg";
import payment from "assets/icons/payment.svg";
import shopping from "assets/icons/shopping.svg";
import creditCard from "assets/icons/creditCard.svg";
import arrowRight from "assets/icons/arrowRight.svg";
import arrowLeftGray from "assets/icons/arrow-left-gray.svg";
import arrowRightBlue from "assets/icons/arrow-right-blue.svg";
import input from "assets/icons/input.svg";
import trash from "assets/icons/trash.svg";
import clear from "assets/icons/clear.svg";
import history from "assets/icons/history.svg";
import timer from "assets/icons/timer.svg";
import tick from "assets/icons/tick.svg";
import phone from "assets/icons/phone.svg";
import visibility from "assets/icons/visibility.svg";
import visibilityOff from "assets/icons/visibility-off.svg";
import next from "assets/icons/next.svg";
import next_blue from "assets/icons/right-blue.svg";
import home from "assets/icons/home.svg";
import sales from "assets/icons/sales.svg";
import sale from "assets/icons/sale.svg";
import income from "assets/icons/income.svg";
import discount from "assets/icons/discount.svg";
import basket from "assets/icons/basket.svg";
import users from "assets/icons/users.svg";
import calendar from "assets/icons/calendar.svg";
import money from "assets/icons/money.svg";
import card from "assets/icons/card.svg";
import overall from "assets/icons/overall.svg";
import increase_chart from "assets/icons/increase_chart.svg";
import expand_more from "assets/icons/expand_more.svg";
import calendar_gray from "assets/icons/calendar-gray.svg";
import paymentGreen from "assets/icons/payment-green.svg";
import shoppingRed from "assets/icons/shopping-red.svg";
import otherYellow from "assets/icons/other-yellow.svg";
import cardBlue from "assets/icons/card-blue.svg";
import arrowDownGray from "assets/icons/arrow-down-gray.svg";
import moreGray from "assets/icons/more-gray.svg";
import shoppingGray from "assets/icons/shopping-gray.svg";
import paymentGray from "assets/icons/payment-gray.svg";
import cardGray from "assets/icons/card-gray.svg";
import arrowDown from "assets/icons/arrow-down.svg";
import arrowLeft from "assets/icons/arrow-left.svg";
import full_screen from "assets/icons/full_screen.svg";
import add from "assets/icons/add.svg";
import receipt from "assets/icons/receipt.svg";
import edit from "assets/icons/edit.svg";
import edit_blue from "assets/icons/edit_blue.svg";
import list from "assets/icons/list.svg";
import info from "assets/icons/info.svg";
import time from "assets/icons/time.svg";
import more_black from "assets/icons/more_black.svg";
import trash_red from "assets/icons/trash_red.svg";
import edit_gray from "assets/icons/edit_gray.svg";
import filter from "assets/icons/filter.svg";
import print from "assets/icons/print.svg";
import exchange from "assets/icons/exchange.svg";
import image from "assets/icons/image.svg";
import checkBlue from "assets/icons/check-blue.svg";
import plusBlue from "assets/icons/plusBlue.svg";
import closeBlack from "assets/icons/closeBlack.svg";
import menu from "assets/icons/menu.svg";
import hamburger from "assets/icons/hamburger.svg";
import calendar_white from "assets/icons/calendar_white.svg";
import left_arrow from "assets/icons/left_arrow.svg";
import exchange_white from "assets/icons/exchange_white.svg";

export const icons = {
  more,
  sale,
  menu,
  receipt,
  checkBlue,
  arrowDown,
  arrowLeft,
  moreGray,
  cardGray,
  tick,
  lock,
  plus,
  close,
  input,
  trash,
  timer,
  clear,
  logout,
  global,
  unlock,
  search,
  qrcode,
  person,
  payment,
  history,
  shopping,
  arrowRight,
  arrowLeftGray,
  arrowDownGray,
  arrowRightBlue,
  paymentGray,
  paymentGreen,
  shoppingRed,
  otherYellow,
  cardBlue,
  creditCard,
  shoppingGray,
  phone,
  person_grey,
  visibility,
  visibilityOff,
  next,
  home,
  sales,
  income,
  discount,
  basket,
  users,
  calendar,
  money,
  card,
  overall,
  increase_chart,
  next_blue,
  searchGray,
  expand_more,
  calendar_gray,
  full_screen,
  add,
  edit,
  list,
  info,
  time,
  edit_blue,
  more_black,
  trash_red,
  edit_gray,
  filter,
  print,
  exchange,
  image,
  plusBlue,
  closeBlack,
  hamburger,
  calendar_white,
  left_arrow,
  exchange_white,
  plus_gray,
};

const Icon = ({ name, className, ...props }) => (
  <img className={`max-w-none ${className}`} src={icons[name]} alt={name} {...props} />
);

export default Icon;
