import AdminSidebar from "layers/admin-sidebar/AdminSidebar";
import { Outlet } from "react-router-dom";

const Admin = () => {
  document.title = "POS SYSTEM - Admin";

  return (
    <div className="bg-c31 h-[100dvh] flex">
      <AdminSidebar isCollapsed={true} />

      <div className="bg-c31 w-full h-full overflow-y-auto sm:h-max">
        <Outlet />
      </div>
    </div>
  );
};

export default Admin;
