import { useState, useEffect } from "react";
import { useNavigate } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { getSuppliers } from "store/slices/supplierSlice";
import { checkedProduct } from "store/slices/categorySlice";
import { incomeProducts, changeIncomeProduct, removeIncomeProduct } from "store/slices/incomeSlice";
import SelectProduct from "layers/select-product/SelectProduct";
import AdminNavbar from "layers/admin-navbar/AdminNavbar";
import MUIDrawer from "components/mui-drawer/MUIDrawer";
import MUIMenu from "components/mui-menu/MUIMenu";
import Select from "components/select/Select";
import IncomePayment from "./IncomePayment";
import Icon from "assets/icons/icons";
import { toast } from "react-toastify";
import { REFACTOR_SUM } from "assets/constants/constants";

function Income() {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const {
    income: {
      income: { products, expenses, totalPrice },
      refresh,
    },
    suppliers: { suppliers },
  } = useSelector((store) => store);
  const [openDrawer, setOpenDrawer] = useState({ open: false, action: "" });
  const [openMenu, setOpenMenu] = useState({ anchorEl: null, action: "" });
  const [incomeData, setIncomeData] = useState({});
  const [payingPrice, setPayingPrice] = useState({ text: "", totalAmount: 0 });

  const handleOpenDrawer = (action) => setOpenDrawer({ open: true, action });

  const handleCloseDrawer = () => setOpenDrawer({ ...openDrawer, open: false });

  const handleOpenMenu = (e) => setOpenMenu({ anchorEl: e.currentTarget });

  const handleCloseMenu = () => setOpenMenu({ ...openMenu, anchorEl: null });

  const handleChangeIncomeProduct = (id, name, value) =>
    dispatch(changeIncomeProduct({ id, name, value }));

  const handleChangeIncomeData = (name, value) => setIncomeData({ ...incomeData, [name]: value });

  const handleRemove = (product_id, parent_id, category_id) => {
    dispatch(removeIncomeProduct(product_id));
    dispatch(checkedProduct({ parent_id, category_id, product_id }));
  };

  const handleSaveIncome = () => {
    let totalAmount = 0;

    const items = products?.map((p) => ({
      product_id: p.id,
      price: p.in_price,
      quantity: p.income_quantity,
    }));

    const expense = Object.keys(expenses).map((key) => {
      totalAmount += expenses[key].price;
      return {
        type: key,
        amount: expenses[key].price,
      };
    });

    totalPrice &&
      totalAmount === totalPrice &&
      incomeData?.supplier_id &&
      dispatch(incomeProducts({ supplier_id: incomeData?.supplier_id, items, expenses: expense }));

    !products?.length && toast.warning("Mahsulot tanlang.");

    products?.length && !totalPrice && toast.warning("Mahsulotlarni narxini va sonini kiriting.");

    totalAmount !== totalPrice &&
      toast.warning("To'lov summasi umumiy summaga teng bo'lishi zarur.");

    products?.length && !incomeData?.supplier_id && toast.warning("Yetkazib beruvchini tanlang.");
  };

  useEffect(() => {
    dispatch(getSuppliers({}));
  }, []);

  useEffect(() => {
    refresh && navigate(0);
  }, [refresh]);

  useEffect(() => {
    products?.length && setPayingPrice({ text: `Naqd: ${totalPrice}`, totalAmount: totalPrice });
  }, [products]);

  return (
    <>
      <MUIDrawer open={openDrawer?.open} onClose={handleCloseDrawer}>
        <SelectProduct action="income" onClose={handleCloseDrawer} />
      </MUIDrawer>

      <MUIMenu
        anchorEl={openMenu?.anchorEl}
        handleClose={handleCloseMenu}
        open={openMenu?.anchorEl ? true : false}
        anchorOrigin={{ horizontal: "left", vertical: "top" }}
        transformOrigin={{ horizontal: "left", vertical: "top" }}
      >
        <IncomePayment
          onCloseMenu={handleCloseMenu}
          onChangePayingPrice={(data) => setPayingPrice(data)}
        />
      </MUIMenu>

      <>
        <AdminNavbar
          prevButton={
            <button
              onClick={() => window.history.back(-1)}
              className="bg-c32 h-10 px-4 py-2 rounded-xl flex justify-center items-center sm:p-0 sm:bg-c46 sm:w-10"
            >
              <Icon name="arrowLeftGray" className="sm:hidden" />
              <Icon name="left_arrow" className="hidden sm:flex" />
              <span className="text-c2 sm:hidden">Orqaga qaytish</span>
            </button>
          }
          rightElement={
            <button
              onClick={handleSaveIncome}
              className={`bg-c17 h-10 px-4 py-2 rounded-xl flex justify-center items-center gap-1 sm:p-0 sm:w-10 ${
                totalPrice && incomeData?.supplier_id ? "" : "opacity-50 cursor-not-allowed"
              }`}
            >
              <Icon name="tick" />
              <span className="text-c24 sm:hidden">Saqlash</span>
            </button>
          }
        />

        <div className="flex justify-between p-4 gap-2 sm:flex-col">
          <div className="flex gap-2 w-1/2 sm:w-full sm:flex-col">
            <Select
              options={suppliers}
              label="Yetkazib beruvchi"
              value={incomeData?.supplier_id || ""}
              optionsLabel={{ label: "name", value: "id" }}
              classNameLabel="w-1/2 text-sm sm:w-full"
              classNameSelect="w-full h-10 bg-c24 border border-c32 outline-0 pl-10 rounded-xl bg-arrow-down-gray bg-no-repeat bg-left bg-[center_left_0.6rem] select-arrow-none"
              onChange={(e) => handleChangeIncomeData("supplier_id", e.target.value)}
            />
            <div className="w-1/2 text-sm sm:w-full">
              <span>To'lov turi</span>
              <button
                onClick={(e) => {
                  totalPrice && handleOpenMenu(e);

                  !products?.length && toast.warning("Mahsulot tanlang.");
                  products?.length &&
                    !totalPrice &&
                    toast.warning("Mahsulotlarni narxini va sonini kiriting.");
                }}
                className={`w-full h-10 border bg-c24 border-c32 px-2 py-2 text-left rounded-xl flex items-center gap-2 ${
                  !totalPrice ? "opacity-50 cursor-not-allowed" : ""
                }`}
              >
                <Icon name="arrowDownGray" />{" "}
                <span>{(totalPrice && payingPrice?.text) || "Tanlang"}</span>
              </button>
            </div>
          </div>
        </div>

        <div className="bg-c24 text-c2 mx-4 border border-c32 rounded-lg">
          <div className="bg-c24 text-c2 rounded-lg overflow-x-auto">
            <table className="sm:whitespace-nowrap w-full border-collapse even:bg-c36 odd:bg-white">
              <thead>
                <tr className="text-c9 font-thin bg-c36 border-b">
                  <td className="py-3 px-2 pl-4">Mahsulot nomi</td>
                  <td className="py-3 px-2">Kategoriya</td>
                  <td className="py-3 px-2">Blok</td>
                  <td className="py-3 px-2">Miqdori</td>
                  <td className="py-3 px-2">O'lchov birligi</td>
                  <td className="py-3 px-2">Eski narxi</td>
                  <td className="py-3 pr-4">Narxi</td>
                  <td className="py-3 pr-4"></td>
                </tr>
              </thead>

              <tbody>
                {!products?.length && (
                  <tr>
                    <td className="py-3 px-2 pl-4 border-b">
                      <span
                        onClick={handleOpenDrawer}
                        className="text-c9 text-sm w-max flex justify-between items-center gap-2 border border-c32 rounded-lg px-2 py-1 cursor-pointer"
                      >
                        <span>Mahsulotni tanlash</span>
                        <Icon name="list" />
                      </span>
                    </td>
                    <td className="py-3 px-2 border-b">-</td>
                    <td className="py-3 px-2 border-b">-</td>
                    <td className="py-3 px-2 border-b">-</td>
                    <td className="py-3 px-2 border-b">-</td>
                    <td className="py-3 px-2 border-b">-</td>
                    <td className="py-3 px-2 pr-4 border-b">-</td>
                  </tr>
                )}
                {products?.map((product, idx) => (
                  <tr key={product?.id}>
                    <td className={`py-2 px-2 pl-4 border-t border-c32`}>{product?.name}</td>
                    <td className={`py-1 px-2 border-t border-c32`}>
                      <p className="flex flex-col">
                        <span>{product?.root_category_name}</span>
                        <span className="text-sm text-c9">{product?.category_name}</span>
                      </p>
                    </td>
                    <td className={`py-1 px-2 border-t border-c32`}>
                      <input
                        type="number"
                        inputMode="numeric"
                        value={product?.in_pack_quantity}
                        disabled={product?.package_id ? false : true}
                        className={`w-[6rem] h-11 border p-2 rounded-xl bg-c36 outline-0 ${
                          !product?.package_id ? "opacity-50 cursor-not-allowed" : ""
                        }`}
                        onChange={(e) =>
                          handleChangeIncomeProduct(product?.id, "in_pack_quantity", e.target.value)
                        }
                      />
                    </td>
                    <td className={`py-1 px-2 border-t border-c32`}>
                      <input
                        type="number"
                        inputMode="numeric"
                        value={product?.income_quantity}
                        className="w-[6rem] h-11 border p-2 rounded-xl bg-c36 outline-0"
                        onChange={(e) =>
                          handleChangeIncomeProduct(product?.id, "income_quantity", e.target.value)
                        }
                      />
                    </td>
                    <td className={`py-2 px-2 border-t border-c32`}>{product?.unit_name || "-"}</td>
                    <td className={`py-2 px-2 border-t border-c32`}>
                      {product?.income_price || 0} UZS
                    </td>
                    <td className={`py-1 pr-4 border-t border-c32`}>
                      <input
                        type="number"
                        inputMode="numeric"
                        value={product?.in_price}
                        className="w-[6rem] h-11 border p-2 rounded-xl bg-c36 outline-0"
                        onChange={(e) =>
                          handleChangeIncomeProduct(product?.id, "in_price", e.target.value)
                        }
                      />
                    </td>
                    <td
                      onClick={() =>
                        handleRemove(product?.id, product?.root_category_id, product?.category_id)
                      }
                      className={`py-2 px-2 pr-4 border-t border-c32 text-c14 font-bold cursor-pointer`}
                    >
                      ✕
                    </td>
                  </tr>
                ))}
              </tbody>
            </table>
          </div>
          {products?.length ? (
            <div className="px-4 py-2 border-t flex justify-between items-center gap-2 sm:flex-col-reverse sm:items-start">
              <button
                onClick={handleOpenDrawer}
                className="bg-c10 text-c24 flex justify-center items-center px-4 py-2 rounded-xl sm:w-full"
              >
                <Icon name="plus" />
                Yangi qo'shish
              </button>
              {totalPrice ? (
                <div className="sm:w-full flex justify-between gap-2">
                  <span>Umumiy summa: </span>
                  <span className="text-c10">{REFACTOR_SUM(totalPrice)} UZS</span>
                </div>
              ) : (
                ""
              )}
            </div>
          ) : (
            ""
          )}
        </div>
      </>
    </>
  );
}

export default Income;
