import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import { axiosPrivate } from "services/axiosPrivate";

const initialState = {
  isLoading: false,
  refresh: "",
  units: [],
  total: null,
  checkedAll: false,
  checkedCount: 0,
  pagination: { page: 1, pageSize: 10 },
};

export const getUnits = createAsyncThunk(
  "unit/get-units",
  async ({ search, pagination = { page: 1, pageSize: 100 } }) => {
    const response = await axiosPrivate.get(
      `unit/list/?page=${pagination?.page}&page_size=${pagination?.pageSize}&search=${search || ""}`
    );
    return response.data;
  }
);

export const createUnit = createAsyncThunk("unit/create-unit", async (unit) => {
  const response = await axiosPrivate.post("unit/post/", unit);
  return response.data;
});

export const updateUnit = createAsyncThunk("unit/update-unit", async ({ unit_id, unit }) => {
  const response = await axiosPrivate.put(`unit/put/${unit_id}`, unit);
  return response.data;
});

export const deleteUnit = createAsyncThunk("unit/delete-unit", async (unit_id) => {
  const response = await axiosPrivate.delete(`unit/delete/${unit_id}`);
  return response.data;
});

export const deleteUnits = createAsyncThunk("unit/delete-unit-by-ids", async (ids) => {
  const response = await axiosPrivate.post(`unit/delete/by-ids`, { ids });
  return response.data;
});

export const unitSlice = createSlice({
  name: "unit",
  initialState,
  reducers: {
    checkedAllUnit: (state) => {
      state.checkedAll = !state.checkedAll;
      state.units = state.units.map((unit) => ({
        ...unit,
        checked: state.checkedAll,
      }));

      state.checkedCount = state.checkedAll ? state.units.length : 0;
    },
    checkedUnit: (state, action) => {
      let checkedCount = 0;
      state.units = state.units.map((unit) =>
        unit.id === action.payload ? { ...unit, checked: unit?.checked ? false : true } : unit
      );

      state.units.map((unit) => unit.checked && (checkedCount += 1));
      state.checkedCount = checkedCount;

      checkedCount === state.units.length ? (state.checkedAll = true) : (state.checkedAll = false);
    },

    changePagination: (state, action) => {
      state.pagination[action.payload.name] = action.payload.value;
      action.payload.name === "pageSize" &&
        Math.ceil(state.total / state.pagination.pageSize) <= state.pagination.page &&
        (state.pagination.page = Math.ceil(state.total / state.pagination.pageSize));
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(createUnit.fulfilled, (state) => {
        state.refresh = `create-unit-${new Date()}`;
      })
      .addCase(updateUnit.fulfilled, (state) => {
        state.refresh = `update-unit-${new Date()}`;
      })
      .addCase(deleteUnit.fulfilled, (state) => {
        state.refresh = `delete-unit-${new Date()}`;
      })
      .addCase(deleteUnits.fulfilled, (state) => {
        state.refresh = `delete-unit-by-ids-${new Date()}`;
      })
      .addCase(getUnits.pending, (state) => {
        state.isLoading = true;
      })
      .addCase(getUnits.fulfilled, (state, action) => {
        state.units = action.payload.data;
        state.total = action.payload.total;
      });
  },
});

const { reducer, actions } = unitSlice;

export const { checkedAllUnit, checkedUnit, changePagination } = actions;

export default reducer;
