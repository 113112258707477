import { useState, useEffect } from "react";
import { login } from "store/slices/authSlice";
import { Link, useNavigate } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import Spinner from "components/loader/Spinner";
import Input from "components/input/Input";
import { toast } from "react-toastify";

const Login = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const {
    auth: { isLoading },
  } = useSelector((store) => store);
  const [user, setUser] = useState({ phone_number: "+998", password: "" });

  const token = JSON.parse(localStorage.getItem("user_data"))?.token;

  const handleChange = (name, value) => {
    setUser({ ...user, [name]: value });
  };

  const handleLogin = (user) => {
    user?.phone_number?.length === 13 &&
      user?.phone_number?.startsWith("+") &&
      user?.password &&
      dispatch(
        login({
          phone_number: user?.phone_number?.startsWith("+")
            ? user?.phone_number
            : `+${user?.phone_number}`,
          password: user?.password,
        })
      );

    !user?.phone_number && toast.warning("Telefon nomerni kiriting.");
    user?.phone_number?.length !== 13 && toast.error("Noto'g'ri telefon nomer kiritildi.");
    user?.phone_number &&
      user?.phone_number?.length === 13 &&
      !user?.password &&
      toast.warning("Parolni kiriting.");
  };

  useEffect(() => {
    if (token) navigate("/home", { replace: true });
  }, []);

  return (
    <div className="bg-c1 w-full h-[100dvh] flex justify-center items-center text-c24">
      <div className="bg-c2 w-[25rem] p-6 rounded flex flex-col gap-2 sm:w-[90vw]">
        <div className="flex flex-col gap-1 justify-center items-center">
          <div className="bg-c29 w-20 h-20 flex justify-center items-center rounded-full">Logo</div>
          <p className="text-xl">Tizimga kirish</p>
        </div>
        <Input
          type="text"
          mask="+\9\98 99 999 99 99"
          label="Telefon nomer"
          value={user?.phone_number}
          onChange={(value) => handleChange("phone_number", value?.replaceAll(" ", ""))}
          inputMode="tel"
          placeholder="Telefon nomer kiriting"
          classNameInput="w-full bg-c1 h-11 p-2 outline-0 border-0 rounded"
          virtualKeyboard={true}
        />

        <Input
          type="password"
          label="Parol"
          placeholder="Parolni kiriting"
          value={user?.password}
          classNameLabel="text-c24 text-sm"
          onChange={(value) => handleChange("password", value)}
          classNameInput="w-full bg-c1 h-11 p-2 outline-0 border-0 rounded"
        />

        <div className="flex justify-end">
          <Link to="/registration" className="text-sm italic">
            Ro'yxatdan o'tish
          </Link>
        </div>

        {/*
        <div className="h-[12rem] sm:hidden">
          <Keyboard inputMode={inputMode || "tel"} closeButton={false} width="full" />
        </div> 
        */}

        <button
          onClick={() => !isLoading && handleLogin(user)}
          className="bg-c10 py-3 mt-4 rounded"
        >
          {isLoading ? <Spinner /> : "Tizimga kirish"}
        </button>
      </div>
    </div>
  );
};

export default Login;
