import { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { getOrdersHistory, getOrdersHistoryItem } from "store/slices/orderSlice";
import OrderHistoryItem from "layers/orders-history/OrderHistoryItem";
import MUIDrawer from "components/mui-drawer/MUIDrawer";
import Icon from "assets/icons/icons";

const paymentTypeOptions = {
  card: {
    icon: "creditCard",
    label: "Karta",
  },
  cash: {
    icon: "payment",
    label: "Naqd",
  },
  debt: {
    icon: "shopping",
    label: "Nasiya",
  },
  other: {
    icon: "more",
    label: "Boshqa",
  },
};

function OrdersHistory({ onClose }) {
  const dispatch = useDispatch();
  const {
    orders: {
      refresh,
      ordersHistory: { histories, payment },
    },
  } = useSelector((store) => store);
  const [openDrawer, setOpenDrawer] = useState({ order: null, open: false });

  const handleOpenDrawer = (order) => {
    setOpenDrawer({ order, open: true });
    dispatch(getOrdersHistoryItem(order));
  };
  const handleCloseDrawer = () => setOpenDrawer({ ...openDrawer, open: false });

  useEffect(() => {
    dispatch(getOrdersHistory({ pagination: { page: 1, pageSize: 100 } }));
    handleCloseDrawer();
  }, [refresh]);

  return (
    <>
      <MUIDrawer hideBackdrop={false} open={openDrawer?.open} onClose={handleCloseDrawer}>
        <OrderHistoryItem />
      </MUIDrawer>
      <div className="bg-c2 text-c24 w-[40rem] h-full relative sm:w-screen">
        <div className="flex flex-col justify-center gap-1 px-4 h-[5rem] sm:h-[4rem]">
          <p className="text-2xl">Tarix</p>
          <div className="flex gap-2 text-xs sm:hidden ">
            {payment?.cash ? (
              <div className="flex items-center gap-2">
                <Icon name="payment" className="opacity-50" />
                <span className="text-c25 font-thin">Naqd:</span>
                <span>{payment?.cash}</span>
              </div>
            ) : (
              ""
            )}
            {payment?.card ? (
              <div className="flex items-center gap-2">
                <Icon name="creditCard" className="opacity-50" />
                <span className="text-c25 font-thin">Karta:</span>
                <span>{payment?.card}</span>
              </div>
            ) : (
              ""
            )}
            <div className="flex items-center gap-2">
              <Icon name="payment" className="opacity-50" />
              <span className="text-c25 font-thin">Umumiy savdo:</span>
              <span>{`${payment?.cash + payment?.card}`}</span>
            </div>
          </div>
        </div>
        <div className="h-[calc(100%-5rem)] relative overflow-y-auto sm:h-[calc(100%-12rem)]">
          <table className="w-full border-collapse ">
            <thead className="bg-c1 text-c9 sticky top-0">
              <tr>
                <td className="py-4 pl-4">№</td>
                <td className="py-4 text-center">Miqdori</td>
                <td className="py-4 text-center">Vaqti</td>
                <td className="py-4 text-center">Turi</td>
                <td className="py-4 text-center sm:hidden">To'landi</td>
                <td className="py-4 pr-4 text-end">Umumiy savdo</td>
              </tr>
            </thead>
            <tbody>
              {histories?.map((history, idx) => (
                <tr key={history?.id} className="" onClick={() => handleOpenDrawer(history?.id)}>
                  <td className="border-b-[1px] border-c7 pl-4 py-4">{idx + 1}</td>
                  <td className="border-b-[1px] border-c7 py-4 text-center">
                    {history?.items_count}
                  </td>
                  <td className="border-b-[1px] border-c7 py-4 text-center">
                    {history?.created_at?.substr(10, 6)}
                  </td>
                  <td className="border-b-[1px] border-c7 py-4">
                    <span className="flex items-center justify-center gap-2">
                      <Icon name={paymentTypeOptions[history?.payment_type].icon} />{" "}
                      {paymentTypeOptions[history?.payment_type].label}
                    </span>
                  </td>
                  <td className="border-b-[1px] border-c7 py-4 text-center sm:hidden">
                    {history?.paid || "-"}
                  </td>
                  <td className="border-b-[1px] border-c7 py-4 pr-4 text-end">{history?.amount}</td>
                </tr>
              ))}
            </tbody>
          </table>
        </div>
        <div className="bg-c1 hidden flex-col gap-2 text-xs p-2 sticky bottom-0 sm:flex sm:h-[8rem]">
          {payment?.cash && (
            <>
              <div className="flex items-center gap-2">
                <Icon name="payment" style={{ opacity: "0.5" }} />
                <span className="text-c25 font-thin">Naqd:</span>
                <span>{payment?.cash}</span>
              </div>
              <hr className="border-0_5 border-c7" />
            </>
          )}
          {payment?.card && (
            <>
              <div className="flex items-center gap-2">
                <Icon name="creditCard" style={{ opacity: "0.5" }} />
                <span className="text-c25 font-thin">Karta:</span>
                <span>{payment?.card}</span>
              </div>
              <hr className="border-0_5 border-c7" />
            </>
          )}
          <div className="flex items-center gap-2 py-1">
            <span className="text-c25 font-thin">Umumiy savdo:</span>
            <span>{`${payment?.cash + payment?.card}`}</span>
          </div>
        </div>
      </div>
    </>
  );
}

export default OrdersHistory;
