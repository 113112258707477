import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import { axiosPrivate } from "services/axiosPrivate";

const initialState = {
  isLoading: false,
  refresh: "",
  payments: [],
  total: null,
  pagination: { page: 1, pageSize: 10 },
};

export const getPayments = createAsyncThunk(
  "payment/get-payments-list",
  async ({ pagination = { page: 1, pageSize: 100 } }) => {
    const response = await axiosPrivate.get(
      `payment/list/?page=${pagination?.page}&page_size=${pagination?.pageSize}`
    );
    return response.data;
  }
);

export const createBrand = createAsyncThunk("brand/create-brand", async (brand) => {
  const response = await axiosPrivate.post("brand/post/", brand);
  return response.data;
});

export const updateBrand = createAsyncThunk("brand/update-brand", async ({ brand_id, brand }) => {
  const response = await axiosPrivate.put(`brand/put/${brand_id}`, brand);
  return response.data;
});

export const deleteBrand = createAsyncThunk("brand/delete-brand", async (brand_id) => {
  const response = await axiosPrivate.delete(`brand/delete/${brand_id}`);
  return response.data;
});

export const paymentSlice = createSlice({
  name: "brand",
  initialState,
  reducers: {
    // checkedAllBrand: (state) => {
    //   state.checkedAll = !state.checkedAll;
    //   state.brands = state.brands.map((brand) => ({
    //     ...brand,
    //     checked: state.checkedAll,
    //   }));

    //   state.checkedCount = state.checkedAll ? state.brands.length : 0;
    // },

    // checkedBrand: (state, action) => {
    //   let checkedCount = 0;
    //   state.brands = state.brands.map((brand) =>
    //     brand.id === action.payload ? { ...brand, checked: brand?.checked ? false : true } : brand
    //   );

    //   state.brands.map((brand) => brand.checked && (checkedCount += 1));
    //   state.checkedCount = checkedCount;

    //   checkedCount === state.brands.length ? (state.checkedAll = true) : (state.checkedAll = false);
    // },

    changePagination: (state, action) => {
      state.pagination[action.payload.name] = action.payload.value;
      action.payload.name === "pageSize" &&
        Math.ceil(state.total / state.pagination.pageSize) <= state.pagination.page &&
        (state.pagination.page = Math.ceil(state.total / state.pagination.pageSize));
    },
  },

  extraReducers: (builder) => {
    builder
      .addCase(createBrand.fulfilled, (state) => {
        state.refresh = `create-brand-${new Date()}`;
      })
      .addCase(updateBrand.fulfilled, (state) => {
        state.refresh = `update-brand-${new Date()}`;
      })
      .addCase(deleteBrand.fulfilled, (state) => {
        state.refresh = `delete-brand-${new Date()}`;
      })
      .addCase(getPayments.pending, (state) => {
        state.isLoading = true;
      })
      .addCase(getPayments.fulfilled, (state, action) => {
        state.payments = action.payload.data;
        state.total = action.payload.total;
      });
  },
});

const { reducer, actions } = paymentSlice;

export const { checkedAllBrand, checkedBrand, changePagination } = actions;

export default reducer;
