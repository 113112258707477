import { useState, useEffect } from "react";
import { useNavigate } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { REFACTOR_SUM } from "assets/constants/constants";
import { getSuppliers } from "store/slices/supplierSlice";
import { checkedProduct, getCategories } from "store/slices/categorySlice";
import SelectProduct from "layers/select-product/SelectProduct";
import AdminNavbar from "layers/admin-navbar/AdminNavbar";
import MUIDrawer from "components/mui-drawer/MUIDrawer";
import MUIMenu from "components/mui-menu/MUIMenu";
import Select from "components/select/Select";
import Icon from "assets/icons/icons";
import MUIModal from "components/mui-modal/MUIModal";
import CurrentProductIncomeList from "./CurrentProductIncomeList";
import { removeReturnIncomeProduct } from "store/slices/incomeSlice";
import { changeReturnIncomeProduct } from "store/slices/incomeSlice";
import { getCurrentSupplier } from "store/slices/supplierSlice";
import { clearReturnIncome } from "store/slices/incomeSlice";
import { changeReturnExpenses } from "store/slices/incomeSlice";
import ReturnIncomeExpenses from "./ReturnIncomeExpenses";
import { returnIncome } from "store/slices/incomeSlice";
import { toast } from "react-toastify";

function ReturnIncomeProducts() {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const {
    income: {
      returnIncome: { products, totalPrice, returnExpenses },
      refresh,
    },
    suppliers: { suppliers, currentSupplier },
  } = useSelector((store) => store);
  const [openModal, setOpenModal] = useState({ open: false, action: "", product: {} });
  const [openDrawer, setOpenDrawer] = useState({ open: false, action: "" });
  const [openMenu, setOpenMenu] = useState({ anchorEl: null, action: "" });
  const [payingPrice, setPayingPrice] = useState({ text: "", totalAmount: 0 });

  const handleOpenModal = (action, product) => setOpenModal({ open: true, action, product });

  const handleCloseModal = () => setOpenModal({ ...openModal, open: false });

  const handleOpenDrawer = (action) => setOpenDrawer({ open: true, action });

  const handleCloseDrawer = () => setOpenDrawer({ ...openDrawer, open: false });

  const handleOpenMenu = (e) => setOpenMenu({ anchorEl: e.currentTarget });

  const handleCloseMenu = () => setOpenMenu({ ...openMenu, anchorEl: null });
  const handleChangeReturnIncomeProduct = (id, name, value) =>
    dispatch(changeReturnIncomeProduct({ id, name, value }));

  const handleChangeSupplier = (value) => {
    dispatch(clearReturnIncome());
    dispatch(getCurrentSupplier(value));
    dispatch(getCategories({ onlyRootCategories: true, withChildren: true }));
  };

  const handleRemove = (product_id, parent_id, category_id) => {
    dispatch(removeReturnIncomeProduct(product_id));
    dispatch(checkedProduct({ parent_id, category_id, product_id }));
  };

  const handleSaveReturnIncome = () => {
    let totalAmount = 0;
    const items = [];

    products?.map((product) =>
      product?.incomeList?.map(
        (income) =>
          income?.return_quantity &&
          items?.push({
            income_item_id: income?.id,
            quantity: income?.return_quantity,
          })
      )
    );
    const expenses = Object.keys(returnExpenses).map((key) => {
      totalAmount += returnExpenses[key].price;
      return {
        type: key,
        amount: returnExpenses[key].price,
      };
    });

    totalAmount === totalPrice &&
      currentSupplier?.id &&
      dispatch(returnIncome({ supplier_id: currentSupplier?.id, items, expenses }));

    !currentSupplier?.id && toast.warning("Yetkazib beruvchi tanlang.");

    !items?.length && toast.warning("Mahsulot tanlang.");
  };

  useEffect(() => {
    dispatch(getSuppliers({}));
  }, []);

  useEffect(() => {
    refresh && navigate(0);
  }, [refresh]);

  useEffect(() => {
    if (currentSupplier?.id && totalPrice) {
      if (currentSupplier?.debt) {
        if (currentSupplier?.debt >= totalPrice) {
          setPayingPrice({ text: `Qarz: ${totalPrice}`, totalAmount: parseInt(totalPrice, 10) });

          dispatch(
            changeReturnExpenses({
              debt: { status: true, type: "debt", price: parseInt(totalPrice, 10) },
            })
          );
        } else {
          setPayingPrice({ text: `Boshqa: ${totalPrice}`, totalAmount: parseInt(totalPrice, 10) });

          dispatch(
            changeReturnExpenses({
              debt: { status: true, type: "debt", price: parseInt(currentSupplier?.debt, 10) },
              cash: {
                status: true,
                type: "cash",
                price: parseInt(totalPrice - currentSupplier.debt, 10),
              },
            })
          );
        }
      } else {
        setPayingPrice({ text: `Naqd: ${totalPrice}`, totalAmount: parseInt(totalPrice, 10) });

        dispatch(
          changeReturnExpenses({
            cash: { status: true, type: "cash", price: parseInt(totalPrice, 10) },
          })
        );
      }
    } else {
      setPayingPrice({ text: "", totalAmount: "" });
    }
  }, [currentSupplier?.id, totalPrice]);

  return (
    <>
      <MUIModal position="center" open={openModal?.open} onClose={handleCloseModal}>
        <CurrentProductIncomeList product_id={openModal?.product?.id} />
      </MUIModal>
      <MUIDrawer open={openDrawer?.open} onClose={handleCloseDrawer}>
        <SelectProduct
          action="return-income"
          onClose={handleCloseDrawer}
          supplier_id={currentSupplier?.id}
        />
      </MUIDrawer>

      <MUIMenu
        anchorEl={openMenu?.anchorEl}
        handleClose={handleCloseMenu}
        open={openMenu?.anchorEl ? true : false}
        anchorOrigin={{ horizontal: "left", vertical: "top" }}
        transformOrigin={{ horizontal: "left", vertical: "top" }}
      >
        <ReturnIncomeExpenses
          onCloseMenu={handleCloseMenu}
          onChangePayingPrice={(data) => setPayingPrice(data)}
        />
      </MUIMenu>

      <>
        <AdminNavbar
          prevButton={
            <button
              onClick={() => window.history.back(-1)}
              className="bg-c32 h-10 px-4 py-2 rounded-xl flex justify-center items-center sm:p-0 sm:bg-c46 sm:w-10"
            >
              <Icon name="arrowLeftGray" className="sm:hidden" />
              <Icon name="left_arrow" className="hidden sm:flex" />
              <span className="text-c2 sm:hidden">Orqaga qaytish</span>
            </button>
          }
          rightElement={
            <button
              onClick={handleSaveReturnIncome}
              className="bg-c17 h-10 px-4 py-2 rounded-xl flex justify-center items-center gap-1 sm:p-0 sm:w-10"
            >
              <Icon name="tick" />
              <span className="text-c24 sm:hidden">Saqlash</span>
            </button>
          }
        />

        <div className="flex justify-between p-4 gap-2 sm:flex-col">
          <div className="flex gap-2 w-1/2 sm:w-full sm:flex-col">
            <Select
              options={suppliers}
              label="Yetkazib beruvchi"
              value={currentSupplier?.id || ""}
              classNameLabel="w-1/2 text-sm sm:w-full"
              optionsLabel={{ label: "name", value: "id" }}
              onChange={(e) => handleChangeSupplier(e.target.value)}
              classNameSelect="w-full h-10 bg-c24 border border-c32 outline-0 pl-10 rounded-xl cursor-pointer bg-arrow-down-gray bg-no-repeat bg-left bg-[center_left_0.5rem] "
            />
            <div className={`w-1/2 text-sm sm:w-full `}>
              <span>To'lov turi</span>
              <button
                onClick={(e) => {
                  totalPrice && handleOpenMenu(e);
                  !currentSupplier?.id && toast.warning("Yetkazib beruvchi tanlang.");
                  !products?.length && toast.warning("Mahsulot tanlang.");
                }}
                className={`w-full h-10 border bg-c24 border-c32 px-2 py-2 text-left rounded-xl flex items-center gap-2 ${
                  totalPrice ? "" : "opacity-50 cursor-not-allowed"
                }`}
              >
                <Icon name="arrowDownGray" /> <span>{payingPrice?.text || "Tanlang"}</span>
              </button>
            </div>
          </div>
        </div>

        <div className="bg-c24 text-c2 mx-4 border border-c32 rounded-lg">
          <div className="bg-c24 text-c2 rounded-lg overflow-x-auto">
            <table className="sm:whitespace-nowrap w-full border-collapse text-sm">
              <thead>
                <tr className="text-c9 font-thin bg-c36 border-b">
                  <td className="py-3 px-2 pl-4">Mahsulot nomi</td>
                  <td className="py-3 px-2">Kategoriya</td>
                  <td className="py-3 px-2">Miqdori</td>
                  <td className="py-3 px-2">Qaytarilmoqda</td>
                  <td className="py-3 px-2">O'lchov birligi</td>
                  <td className="py-3 px-2">Narxi</td>
                  <td className="py-3 px-2 text-end">Umumiy narxi</td>
                  <td className="py-3 pr-4"></td>
                </tr>
              </thead>

              <tbody>
                {!products?.length && (
                  <tr>
                    <td className="py-2 px-2 pl-4 border-b">
                      <button
                        onClick={handleOpenDrawer}
                        onPointerDown={() => {
                          !currentSupplier?.id && toast.warning("Yetkazib beruvchi tanlang.");
                        }}
                        disabled={currentSupplier?.id ? false : true}
                        className={`text-c9 text-sm w-max flex justify-between items-center gap-2 border border-c32 rounded-lg px-2 py-1 ${
                          currentSupplier?.id ? "" : "opacity-50 cursor-not-allowed"
                        }`}
                      >
                        <span>Mahsulotni tanlash</span>
                        <Icon name="list" />
                      </button>
                    </td>
                    <td className="py-3 px-2 border-b">-</td>
                    <td className="py-3 px-2 border-b">-</td>
                    <td className="py-3 px-2 border-b">-</td>
                    <td className="py-3 px-2 border-b">-</td>
                    <td className="py-3 px-2 border-b">-</td>
                    <td className="py-3 px-2 pr-4 border-b">-</td>
                  </tr>
                )}
                {products?.map((product, idx) => (
                  <tr key={product?.id} className="even:bg-c36 odd:bg-white">
                    <td
                      onClick={() => handleOpenModal("product-income-list", product)}
                      className={`py-2 px-2 pl-4 border-t border-c32 cursor-pointer`}
                    >
                      {product?.name}
                    </td>
                    <td className={`py-1 px-2 border-t border-c32`}>
                      <p className="flex flex-col">
                        <span>{product?.root_category_name}</span>
                        <span className="text-sm text-c9">{product?.category_name}</span>
                      </p>
                    </td>
                    <td className={`py-1 px-2 border-t border-c32`}>
                      {product?.quantity}
                      {/* <input
                        type="number"
                        inputMode="numeric"
                        value={product?.in_pack_quantity}
                        disabled={product?.package_id ? false : true}
                        className="w-[6rem] h-11 border p-2 rounded-xl bg-c36 outline-0"
                        onChange={(e) =>
                          handleChangeIncomeProduct(product?.id, "in_pack_quantity", e.target.value)
                        }
                      /> */}
                    </td>
                    <td className={`py-1 px-2 border-t border-c32`}>
                      <input
                        type="number"
                        inputMode="numeric"
                        value={product?.return_quantity || ""}
                        className="w-[6rem] h-11 border p-2 rounded-xl bg-c36 outline-0"
                        onChange={(e) =>
                          handleChangeReturnIncomeProduct(
                            product?.id,
                            "return_quantity",
                            e.target.value <= product?.quantity ? e.target.value : product?.quantity
                          )
                        }
                      />
                    </td>
                    <td className={`py-2 px-2 border-t border-c32`}>{product?.unit_name || "-"}</td>
                    <td className={`py-2 px-2 border-t border-c32`}>
                      {product?.income_price ? `${REFACTOR_SUM(product?.income_price)} UZS` : "-"}
                    </td>
                    <td className={`py-2 px-2 text-end border-t border-c32`}>
                      {product?.totalPrice ? `${REFACTOR_SUM(product?.totalPrice)} UZS` : "-"}
                    </td>
                    <td
                      onClick={() =>
                        handleRemove(product?.id, product?.root_category_id, product?.category_id)
                      }
                      className={`py-2 px-2 pr-4 border-t border-c32 text-c14 font-bold cursor-pointer`}
                    >
                      ✕
                    </td>
                  </tr>
                ))}
              </tbody>
            </table>
          </div>
          {products?.length ? (
            <div className="px-4 py-2 border-t flex justify-between items-center gap-2 sm:flex-col-reverse sm:items-start">
              <button
                onClick={handleOpenDrawer}
                className="bg-c10 text-c24 flex justify-center items-center px-4 py-2 rounded-xl sm:w-full"
              >
                <Icon name="plus" />
                Yangi qo'shish
              </button>
              <div className="sm:w-full flex justify-between gap-2">
                <span>Umumiy summa: </span>
                <span className="text-c10">
                  {totalPrice ? `${REFACTOR_SUM(totalPrice)} UZS` : "-"}
                </span>
              </div>
            </div>
          ) : (
            ""
          )}
        </div>
      </>
    </>
  );
}

export default ReturnIncomeProducts;
