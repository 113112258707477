import { useEffect, useState } from "react";
import { useLocation } from "react-router-dom";
import { createOrder } from "store/slices/orderSlice";
import { useDispatch, useSelector } from "react-redux";
import { REFACTOR_SUM } from "assets/constants/constants";
import { incomeProducts } from "store/slices/incomeSlice";
import MUIDrawer from "components/mui-drawer/MUIDrawer";
import SelectClient from "layers/clients/SelectClient";
import Checkbox from "components/checkbox/Checkbox";
import Keyboard from "layers/keyboard/Keyboard";
import Input from "components/input/Input";
import { toast } from "react-toastify";
import { checkingPermissions } from "assets/constants/constants";

function Payment() {
  const dispatch = useDispatch();
  const { pathname } = useLocation();
  const {
    orders,
    income: { income },
  } = useSelector((store) => store);
  const [paymentType, setPaymentType] = useState({});
  const [openDrawer, setOpenDrawer] = useState(false);
  const [returnAmount, setReturnAmount] = useState(null);
  const [payingPrice, setPayingPrice] = useState("");

  const currentPath = pathname.split("/");

  const currentOrder = orders.orders[currentPath[2]];

  const handleOpenDrawer = () => setOpenDrawer(true);

  const handleCloseDrawer = () => setOpenDrawer(false);

  const handleChangePaymentPrice = (type, price) => {
    const payment = { ...paymentType[type], price: parseFloat(price, 10) };
    setPaymentType({ ...paymentType, [type]: payment });
  };

  const calculateDebtOrReturn = () => {
    let totalAmount = 0;
    Object.keys(paymentType).map(
      (key) => key !== "debt" && (totalAmount += paymentType[key]?.price || 0)
    );
    const amount =
      (currentPath?.[1] === "income" ? income.totalPrice : currentOrder.totalPrice) -
      (totalAmount ? totalAmount : 0);
    paymentType?.debt?.status && handleChangePaymentPrice("debt", amount > 0 ? amount : 0);
    setReturnAmount(amount < 0 ? Math.abs(amount) : null);
    setPayingPrice(paymentType?.debt?.status ? totalAmount + amount : totalAmount);
  };

  const handleAddPaymentType = (type) => {
    const payment = paymentType[type]?.status
      ? delete paymentType[type]
      : {
          [type]: {
            type,
            price: "",
            status: true,
          },
        };
    setPaymentType({ ...paymentType, ...payment });
  };

  const orderPayment = () => {
    let totalPrice = 0;
    const items = currentOrder?.products?.map((p) => ({
      product_id: p.id,
      price: p.sell_price,
      quantity: p.sell_quantity,
    }));
    const payments = Object.keys(paymentType).map((key) => {
      totalPrice += paymentType[key].price;
      return {
        type: key,
        amount:
          returnAmount && key === "cash"
            ? paymentType[key].price - returnAmount
            : paymentType[key].price,
      };
    });

    currentOrder?.totalPrice === totalPrice &&
      dispatch(
        createOrder({
          sell: {
            items,
            payments,
            user_id: currentOrder?.client?.id,
          },
          order_id: currentPath[2],
        })
      );

    currentOrder?.totalPrice && !totalPrice && toast.info("To'lov summasini kiriting.");

    currentOrder?.totalPrice &&
      totalPrice &&
      currentOrder?.totalPrice !== totalPrice &&
      toast.info("To'lov summasi umumiy summaga teng bo'lishi zarur.");
  };

  const incomePayment = () => {
    let totalPrice = 0;
    const items = income?.products?.map((p) => ({
      product_id: p.id,
      price: p.in_price,
      quantity: p.income_quantity,
    }));
    const expenses = Object.keys(paymentType).map((key) => {
      totalPrice += paymentType[key].price;
      return {
        type: key,
        amount: paymentType[key].price,
      };
    });

    income?.totalPrice === totalPrice &&
      dispatch(incomeProducts({ supplier_id: income?.supplier?.id, items, expenses }));
  };

  const handlePayed = () => {
    currentPath?.[1] === "income" ? incomePayment() : orderPayment();
  };

  useEffect(() => {
    setPaymentType({
      cash: {
        type: "cash",
        status: true,
        price: currentPath?.[1] === "income" ? income?.totalPrice : currentOrder?.totalPrice,
      },
    });
  }, []);

  useEffect(() => {
    currentPath[1] !== "income" &&
      paymentType?.debt?.status &&
      !currentOrder?.client?.id &&
      handleOpenDrawer();
  }, [paymentType?.debt?.status]);

  useEffect(() => {
    calculateDebtOrReturn();
  }, [paymentType?.cash, paymentType?.card, paymentType?.debt?.status]);

  return (
    <>
      <MUIDrawer
        zIndex={90}
        open={openDrawer}
        onClose={handleCloseDrawer}
        children={<SelectClient onClose={handleCloseDrawer} />}
      />

      <div className="bg-c8 text-c24 w-[27rem] flex flex-col justify-center p-4 rounded gap-2 sm:w-[90vw]">
        <div className="flex flex-col gap-1">
          <p className="text-xl">To'lov qilish</p>

          <div className="flex items-center justify-between">
            <span className="text-c27 text-xs">UMUMIY SUMMA</span>
            <span>
              {REFACTOR_SUM(
                currentPath?.[1] === "income" ? income?.totalPrice : currentOrder?.totalPrice
              )}{" "}
              UZS
            </span>
          </div>

          {currentPath?.[1] !== "income" && returnAmount && (
            <div className="flex items-center justify-between">
              <span className="text-c27 text-xs">QAYTIM</span>
              <span>{REFACTOR_SUM(returnAmount)} UZS</span>
            </div>
          )}

          {paymentType?.debt?.status && (
            <div className="flex items-center justify-between">
              <span className="text-c27 text-xs">NASIYA</span>
              <span className="text-c14">{REFACTOR_SUM(paymentType?.debt?.price) || 0} UZS</span>
            </div>
          )}
        </div>

        <div className="flex flex-col gap-2">
          <div className="flex justify-between gap-2 sm:flex-col">
            <div className="bg-c5 w-1/2 p-3 rounded flex flex-col justify-center gap-1 sm:w-full">
              <Checkbox
                label="Naqd"
                checked={paymentType?.cash?.status ? true : false}
                onChange={() => handleAddPaymentType("cash")}
              />
              <Input
                id="payment-type-cash"
                type="number"
                value={paymentType?.cash?.price || ""}
                disabled={paymentType?.cash?.status ? false : true}
                onChange={(e) => handleChangePaymentPrice("cash", e)}
                inputMode="numeric"
                placeholder="Summani kiriting"
                classNameInput="w-full border border-c7 text-c24 rounded text-sm bg-c1 p-3 outline-none"
                virtualKeyboard={false}
              />
            </div>
            <div className="bg-c5 w-1/2 p-3 rounded flex flex-col justify-center gap-1 sm:w-full">
              <Checkbox
                label="Karta"
                checked={paymentType?.card?.status ? true : false}
                onChange={() => handleAddPaymentType("card")}
              />
              <Input
                id="payment-type-card"
                value={paymentType?.card?.price || ""}
                disabled={paymentType?.card?.status ? false : true}
                onChange={(e) => handleChangePaymentPrice("card", e)}
                inputMode="numeric"
                placeholder="Summani kiriting"
                classNameInput="w-full border border-c7 text-c24 rounded text-sm bg-c1 p-3 outline-none"
                virtualKeyboard={false}
              />
            </div>
          </div>

          <div className="flex justify-between gap-2 sm:flex-col">
            {checkingPermissions(["CUSTOMER.READ"]) && (
              <div className="bg-c5 w-1/2 p-3 rounded flex flex-col justify-center gap-1 sm:w-full">
                <Checkbox
                  label="Nasiya"
                  checked={paymentType?.debt?.status ? true : false}
                  onChange={() => handleAddPaymentType("debt")}
                />
                {(paymentType?.debt?.status || paymentType?.discount?.status) && (
                  <div
                    onClick={() => currentPath?.[1] !== "income" && handleOpenDrawer()}
                    className={`bg-c2 p-3 border border-c7 rounded text-sm`}
                  >
                    {currentPath?.[1] === "income" ? (
                      <span>{income?.supplier?.name}</span>
                    ) : (
                      currentOrder?.client?.first_name || (
                        <span className="text-sm opacity-50">Mijoz tanlang</span>
                      )
                    )}
                  </div>
                )}
              </div>
            )}

            <div
              className={`bg-c5  p-3 rounded flex flex-col justify-center gap-1 sm:w-full ${
                checkingPermissions(["CUSTOMER.READ"]) ? "w-1/2" : "w-full"
              }`}
            >
              <Checkbox
                label="Chegirma"
                checked={paymentType?.discount?.status ? true : false}
                onChange={() => {}}
                // onChange={() => handleAddPaymentType("discount")}
              />
              {(paymentType?.debt?.status || paymentType?.discount?.status) && (
                <Input
                  id="payment-type-discount"
                  value={paymentType?.discount?.price || ""}
                  disabled={paymentType?.discount?.status ? false : true}
                  onChange={(e) => handleChangePaymentPrice("discount", e)}
                  inputMode="numeric"
                  placeholder="Summani kiriting"
                  classNameInput="w-full border border-c7 text-c24 rounded text-sm bg-c1 p-3 outline-none"
                  virtualKeyboard={false}
                />
              )}
            </div>
          </div>
        </div>

        <div className="h-[15rem] w-full sm:hidden">
          <Keyboard inputMode="numeric" width="full" onlyOne={true} />
        </div>

        <button
          onClick={handlePayed}
          className={`bg-c10 text-center py-3 rounded ${
            (currentPath[1] === "income" && payingPrice === income?.totalPrice) ||
            payingPrice === currentOrder?.totalPrice
              ? ""
              : "opacity-50 cursor-not-allowed"
          }`}
        >
          To'lov qilish {payingPrice ? `- ${REFACTOR_SUM(payingPrice)} UZS` : ""}
        </button>
      </div>
    </>
  );
}

export default Payment;
