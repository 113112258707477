import { useDispatch } from "react-redux";
import { logout } from "store/slices/authSlice";

function NotEnoughPermissions() {
  const dispatch = useDispatch();

  const handleLogout = () => dispatch(logout());

  return (
    <div className="bg-c2 h-[100dvh] flex items-center justify-center">
      <div className="flex flex-col items-center gap-6">
        <p className="text-3xl text-c24 uppercase">Ruxsatlar yetarli emas!</p>
        <button
          onClick={handleLogout}
          className="bg-c10 px-4 py-2 rounded-xl text-c24 text-lg uppercase"
        >
          Orqaga qaytish
        </button>
      </div>
    </div>
  );
}

export default NotEnoughPermissions;
