import { useEffect, useState } from "react";
import { getUnits } from "store/slices/unitSlice";
import { useDispatch, useSelector } from "react-redux";
import { createPackage, updatePackage } from "store/slices/packageSlice";
import HtmlInput from "components/input/HtmlInput";
import Select from "components/select/Select";

const CreateBlock = ({ unitId, action, currentPackage }) => {
  const dispatch = useDispatch();
  const {
    units: { units, pagination },
  } = useSelector((store) => store);
  const [pack, setPack] = useState(
    action === "update" ? currentPackage : { name: "", quantity: "", unit_id: unitId || "" }
  );

  const handleChangePack = (name, value) => {
    setPack({ ...pack, [name]: value });
  };

  const handleSave = () => {
    action === "create"
      ? pack?.name && pack?.quantity && pack?.unit_id && dispatch(createPackage(pack))
      : dispatch(updatePackage({ pack_id: pack?.id, pack }));
  };

  useEffect(() => {
    dispatch(getUnits({ pagination }));
  }, []);

  return (
    <div className="bg-c24 text-c2 w-[25rem] sm:w-[90vw] p-6 rounded-lg flex flex-col gap-4">
      <span className="text-2xl">
        {action === "create" ? "Yangi paket qo’shish" : "Paketni tahrirlash"}
      </span>

      <HtmlInput
        value={pack?.name}
        label="Blok nomi *"
        placeholder="Misol uchun: 1 Blok x 6"
        onChange={(e) => handleChangePack("name", e.target.value)}
      />

      <HtmlInput
        type="number"
        inputMode="numeric"
        value={pack?.quantity}
        placeholder="1 blok ichida nechta mahsulot bor?"
        label="Mahsulot soni *"
        onChange={(e) => handleChangePack("quantity", e.target.value)}
      />

      <Select
        options={units}
        value={pack?.unit_id}
        label="O’lchov birligi *"
        classNameLabel="text-c2 text-sm"
        optionsLabel={{ label: "name", value: "id" }}
        onChange={(e) => handleChangePack("unit_id", e.target.value)}
        classNameSelect="h-10 bg-c24 w-full px-2 rounded-xl text-c2 border border-c32 outline-none bg-arrow-down-gray bg-no-repeat bg-right bg-[center_right_0.4rem] select-arrow-none"
      />

      <button
        onClick={handleSave}
        className="bg-c10 text-c24 flex items-center justify-center rounded-xl px-3 h-10"
      >
        Saqlash
      </button>
    </div>
  );
};

export default CreateBlock;
