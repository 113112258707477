import { ADMIN_ROUTES } from "routes";
import { useDispatch, useSelector } from "react-redux";
import { changeCollapse } from "store/slices/sidebarSlice";
import { useLocation, useNavigate, useParams } from "react-router-dom";
import Icon from "assets/icons/icons";
import { checkingPermissions } from "assets/constants/constants";

const AdminNavbar = ({
  selected = 0,
  prevButton,
  rightElement,
  border = "border-bottom-1 border-c32",
}) => {
  const params = useParams();
  const { pathname } = useLocation();
  const navigate = useNavigate();
  const dispatch = useDispatch();

  const {
    sidebar: { isCollapsed },
  } = useSelector((store) => store);

  const handleNavigate = (route) => navigate(`/admin/${parentRoute?.route}/${route}`);
  const handleChangeCollapse = () => dispatch(changeCollapse(isCollapsed === true ? false : false));

  const parentRoute = ADMIN_ROUTES.filter((route) => route.route === pathname.split("/")?.[2])?.[0];

  const subRoute = parentRoute?.collapses?.filter(
    (collapse) => collapse.route.split("/")[0] === pathname.split("/")?.[3]
  )[0];

  return (
    <div
      className={`flex flex-col px-4 bg-c24 sm:bg-c2 sm:px-2 ${
        prevButton ? "py-0" : ""
      } sm:rounded-b-lg ${border}`}
    >
      <div className="w-full flex items-center justify-between py-2">
        {prevButton ? (
          prevButton
        ) : (
          <button
            className="bg-c46 items-center justify-center rounded-xl cursor-pointer hidden sm:flex sm:w-10 sm:h-10"
            onClick={handleChangeCollapse}
          >
            <Icon name="hamburger" />
          </button>
        )}

        <span className="text-c1 text-2xl sm:hidden">
          {params?.id ? "Batafsil" : subRoute?.name || parentRoute?.name}
        </span>

        <span className="text-c24 text-xl my-2 hidden sm:flex">
          {selected
            ? `Tanlandi: ${selected}`
            : params?.id
            ? "Batafsil"
            : subRoute?.name || parentRoute?.name}
        </span>

        {rightElement}

        {/* <div className="flex justify-end"></div> */}
      </div>

      {!prevButton && !parentRoute?.noCollapse && parentRoute?.collapses?.length && (
        <div
          className={`w-full flex overflow-x-auto gap-4 ${
            prevButton || selected.length ? "sm:hidden" : ""
          }`}
        >
          {parentRoute?.collapses?.map(
            (collapse) =>
              collapse?.type === "screen" &&
              checkingPermissions(collapse?.permissions) && (
                <div
                  key={collapse?.key}
                  onClick={() => handleNavigate(collapse?.route)}
                  className="w-max cursor-pointer gap-2"
                >
                  <span
                    className={`${
                      pathname.split("/")?.[3] === collapse?.route
                        ? "text-c10"
                        : "text-c2 sm:text-c24"
                    }  whitespace-nowrap text-center`}
                  >
                    {collapse?.name}
                  </span>

                  <div
                    className={`h-[4px] mt-1 rounded-t-2xl ${
                      pathname.split("/")?.[3] === collapse?.route ? "bg-c10" : ""
                    }`}
                  />
                </div>
              )
          )}
        </div>
      )}
    </div>
  );
};

export default AdminNavbar;
