import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import { axiosPrivate } from "services/axiosPrivate";

const initialState = {
  isLoading: false,
  refresh: "",
  users: [],
  pagination: { page: 1, pageSize: 10 },
  total: null,
  currentUser: {
    username: "",
    first_name: "",
    role: "",
  },
};

export const getUsers = createAsyncThunk(
  "users/get-users",
  async ({ search, pagination: { page, pageSize } }) => {
    const response = await axiosPrivate.get(
      `user/list/?page=${page || ""}&page_size=${pageSize || ""}&search=${search || ""}`
    );
    return response.data;
  }
);

// export const getUser = createAsyncThunk("users/get-user", async (id) => {
//   const response = await axiosPrivate.get(`user/${id}`);
//   return response.data;
// });

export const createUser = createAsyncThunk("users/create-user", async (user) => {
  try {
    const response = await axiosPrivate.post("user/register_user", user);
    return response.status;
  } catch (error) {
    console.log(error);
  }
});

export const updateUser = createAsyncThunk("users/update-user", async ({ id, user }) => {
  const response = await axiosPrivate.patch(`user/patch/${id}`, user);
  return response.data;
});

export const deleteUser = createAsyncThunk("users/delete-user", async (id) => {
  const response = await axiosPrivate.delete(`user/delete/${id}`);
  return response.data;
});

export const userSlice = createSlice({
  name: "user",
  initialState,
  reducers: {
    changeCurrentUser: (state, action) => {
      state.currentUser[action.payload.name] = action.payload.value;
    },
    changePagination: (state, action) => {
      state.pagination[action.payload.name] = action.payload.value;
      action.payload.name === "pageSize" &&
        Math.ceil(state.total / state.pagination.pageSize) <= state.pagination.page &&
        (state.pagination.page = Math.ceil(state.total / state.pagination.pageSize));
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(getUsers.pending, (state) => {
        state.isLoading = true;
      })
      .addCase(getUsers.fulfilled, (state, action) => {
        state.isLoading = false;
        state.users = action.payload.data;
        state.total = action.payload.total;
      })
      .addCase(createUser.fulfilled, (state, action) => {
        if (action.payload === 200) state.refresh = `create-user-${new Date()}`;
      })
      .addCase(updateUser.fulfilled, (state, action) => {
        state.refresh = `update-user-${new Date()}`;
      })
      .addCase(deleteUser.fulfilled, (state, action) => {
        state.refresh = `delete-user-${new Date()}`;
      });
  },
});

const { reducer, actions } = userSlice;

export const { changeCurrentUser, changePagination } = actions;

export default reducer;
