import { useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { createClient } from "store/slices/clientSlice";
import Spinner from "components/loader/Spinner";
import Input from "components/input/Input";

const CreateClient = () => {
  const dispatch = useDispatch();
  const {
    clients: { isLoading },
  } = useSelector((store) => store);
  const [client, setClient] = useState({
    first_name: "",
    last_name: "",
    middle_name: "",
    phone_number: "",
  });

  const handleChangeClient = (name, value) => {
    setClient({ ...client, [name]: value });
  };

  const handleSave = () => {
    client?.first_name &&
      client?.phone_number &&
      (client?.phone_number?.replaceAll(" ", "")?.length === 13 ||
        client?.phone_number?.replaceAll(" ", "")?.length === 12) &&
      dispatch(
        createClient({
          first_name: client.first_name,
          last_name: client?.last_name,
          middle_name: client?.middle_name,
          phone_number: client?.phone_number?.replaceAll(" ", ""),
        })
      );
  };

  return (
    <div className="bg-c2 w-[25rem] p-6 rounded flex flex-col gap-4 text-c24 sm:w-[90vw]">
      <p className="text-xl">Mijoz qo'shish</p>
      <Input
        type="text"
        label="Ismi *"
        value={client?.first_name}
        onChange={(e) => handleChangeClient("first_name", e)}
        inputMode="text"
        placeholder="Ismini kiriting"
        classNameInput="w-full border border-c7 text-c24 rounded text-sm bg-c1 p-3 outline-none"
        virtualKeyboard={true}
      />

      <Input
        type="text"
        label="Familiyasi"
        value={client?.last_name}
        onChange={(e) => handleChangeClient("last_name", e)}
        inputMode="text"
        placeholder="Familiyasini kiriting"
        classNameInput="w-full border border-c7 text-c24 rounded text-sm bg-c1 p-3 outline-none"
        virtualKeyboard={true}
      />

      <Input
        type="text"
        label="Otasining ismi"
        value={client?.middle_name}
        onChange={(e) => handleChangeClient("middle_name", e)}
        inputMode="text"
        placeholder="Otasining ismini kiriting"
        classNameInput="w-full border border-c7 text-c24 rounded text-sm bg-c1 p-3 outline-none"
        virtualKeyboard={true}
      />

      <Input
        mask="+\9\98 99 999 99 99"
        type="text"
        label="Telefon nomeri *"
        value={client?.phone_number}
        onChange={(e) => handleChangeClient("phone_number", e)}
        inputMode="tel"
        placeholder="+998 99 999 99 99"
        classNameInput="w-full border border-c7 text-c24 rounded text-sm bg-c1 p-3 outline-none"
        virtualKeyboard={true}
      />
      <button
        onClick={handleSave}
        className="bg-c17 flex items-center justify-center rounded px-3 h-11"
      >
        {isLoading ? <Spinner /> : "Saqlash"}
      </button>
    </div>
  );
};

export default CreateClient;
