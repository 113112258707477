import { createSlice } from "@reduxjs/toolkit";

const initialState = {
  isCollapsed: true,
};

export const sidebarSlice = createSlice({
  name: "sidebar",
  initialState,
  reducers: {
    changeCollapse: (state, action) => {
      state.isCollapsed = action.payload;
    },
  },
});

const { reducer, actions } = sidebarSlice;

export const { changeCollapse } = actions;

export default reducer;
