import { useLocation } from "react-router-dom";
import { Fragment, useEffect, useState } from "react";
import { getClients } from "store/slices/clientSlice";
import { useDispatch, useSelector } from "react-redux";
import { chooseClient } from "store/slices/orderSlice";
import CreateClient from "layers/clients/CreateClient";
import MUIModal from "components/mui-modal/MUIModal";
import Icon from "assets/icons/icons";
import { checkingPermissions } from "assets/constants/constants";

const SelectClient = ({ onClose }) => {
  const dispatch = useDispatch();
  const { pathname } = useLocation();
  const {
    clients: { clients, refresh },
  } = useSelector((store) => store);
  const [openModal, setOpenModal] = useState(false);

  const currentPath = pathname.split("/");

  const handleOpenModal = () => setOpenModal(true);
  const handleCloseModal = () => setOpenModal(false);

  const handleSelectedClient = (id) => {
    dispatch(chooseClient({ id, order_id: currentPath[2] }));
    onClose();
  };

  useEffect(() => {
    dispatch(getClients({ pagination: { page: 1, pageSize: 100 } }));
    handleCloseModal();
  }, [dispatch, refresh]);

  return (
    <>
      <MUIModal
        zIndex={9999}
        open={openModal}
        hideBackdrop={false}
        onClose={handleCloseModal}
        children={<CreateClient onClose={handleCloseModal} />}
      />

      <div className="bg-c5 text-c24 w-[20rem] h-full overflow-y-auto relative sm:w-screen">
        <div className="bg-c29 p-4 sticky top-0">
          <p className="text-lg">Mijozni tanlang</p>
        </div>

        {clients.map((client) => (
          <Fragment key={client.id}>
            <div
              className="py-2 flex flex-col gap-1 border-b border-c7"
              onClick={() => handleSelectedClient(client)}
            >
              <div className="flex items-center px-2">
                <Icon name="person_grey" className="px-2" />
                <span>{client.first_name}</span>
              </div>

              <div className="flex items-center px-2">
                <Icon name="phone" className="px-2 w-[2.3rem]" />
                <span className="text-sm">{client.phone_number}</span>
              </div>
            </div>
          </Fragment>
        ))}

        {checkingPermissions(["CUSTOMER.CREATE"]) && (
          <div className="bg-c5 w-full sticky bottom-0 px-2 py-4">
            <button
              onClick={handleOpenModal}
              className="bg-c17 w-full flex flex-col items-center rounded py-3"
            >
              Yangi qo'shish
            </button>
          </div>
        )}
      </div>
    </>
  );
};

export default SelectClient;
