import { useDispatch, useSelector } from "react-redux";
import { useState, useEffect } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import SellingProducts from "layers/check-list/SellingProducts";
import IncomeProducts from "layers/check-list/IncomeProducts";
import ProductsList from "layers/products/ProductsList";
import Categories from "layers/categories/Categories";
import MUIModal from "components/mui-modal/MUIModal";
import Keyboard from "layers/keyboard/Keyboard";
import Payment from "layers/payment/Payment";
import MUIDrawer from "components/mui-drawer/MUIDrawer";
import SelectSupplier from "layers/supplier/SelectSupplier";
import { chooseProduct } from "store/slices/productSlice";
import { REFACTOR_SUM } from "assets/constants/constants";

function Home() {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const { income, orders } = useSelector((store) => store);
  const [openModal, setOpenModal] = useState(false);
  const [openDrawer, setOpenDrawer] = useState({ action: "add", open: false });

  const { pathname } = useLocation();

  const currentPath = pathname.split("/");

  const currentOrder = orders.orders[currentPath[2]];

  const handleOpenModal = () => {
    console.log(income?.income?.totalPrice);
    ((currentPath?.[1] === "income" &&
      income?.income?.totalPrice &&
      income?.income?.supplier?.id) ||
      currentOrder?.totalPrice) &&
      setOpenModal(true);
  };
  const handleCloseModal = () => setOpenModal(false);

  const handleOpenDrawer = (action, product) => {
    dispatch(chooseProduct(product));
    setOpenDrawer({ action, open: true });
  };
  const handleCloseDrawer = () => setOpenDrawer({ ...openDrawer, open: false });

  useEffect(() => {
    handleCloseModal();
  }, [orders.refresh, income.refresh]);

  return (
    <>
      <MUIModal zIndex={80} open={openModal} onClose={handleCloseModal}>
        <Payment onClose={handleCloseModal} />
      </MUIModal>

      <MUIDrawer open={openDrawer?.open} onClose={handleCloseDrawer}>
        {openDrawer?.action === "select-supplier" && <SelectSupplier onClose={handleCloseDrawer} />}
      </MUIDrawer>

      <div className="bg-c2 flex p-1 w-full h-full gap-1 sm:flex-col-reverse sm:justify-end sm:p-0 sm:py-1">
        <div className="hidden gap-1 px-1 sm:flex sm:h-12">
          <button
            onClick={() =>
              currentPath?.[1] === "income" && !income?.income?.supplier?.id
                ? handleOpenDrawer("select-supplier")
                : handleOpenModal()
            }
            className={`bg-c17 w-full text-c24 text-center py-3 rounded items-center justify-center ${
              (currentPath[1] === "income" &&
                income?.income?.totalPrice &&
                income?.income?.supplier?.id) ||
              currentOrder?.totalPrice
                ? ""
                : "opacity-50 cursor-not-allowed"
            }`}
          >
            {currentPath?.[1] === "income"
              ? income?.income?.supplier?.id
                ? income?.income?.totalPrice
                  ? `To'lov qilish - ${REFACTOR_SUM(income?.income?.totalPrice)} UZS`
                  : "To'lov qilish"
                : "Yetkazib beruvchini tanlang"
              : currentOrder?.totalPrice
              ? `To'lov qilish - ${REFACTOR_SUM(currentOrder?.totalPrice)} UZS`
              : "To'lov qilish"}
          </button>

          {currentPath?.[1] === "income" && (
            <button
              onClick={() => navigate("/home")}
              className="bg-c5 text-c14 text-center py-2 px-4 rounded flex items-center justify-center"
            >
              ✕
            </button>
          )}
        </div>

        <div className="w-2/5 flex flex-col gap-1 sm:w-full sm:px-1 sm:h-[calc(50%-3rem)]">
          <div className="h-3/5 sm:h-full">
            {currentPath?.[1] === "income" ? <IncomeProducts /> : <SellingProducts />}
          </div>
          <div className="h-2/5 sm:hidden">
            <Keyboard inputMode="numeric" width="full" onlyOne={true} />
            {/* <NumPad /> */}
          </div>
        </div>

        <div className="w-3/5 h-full gap-1 flex sm:w-full sm:flex-col sm:h-1/2">
          <div className="w-1/4 sm:flex sm:w-full sm:h-[3rem] sm:px-1">
            <Categories />
          </div>
          <hr className="hidden border border-c7 sm:flex" />
          <div className="w-3/4 sm:w-full sm:px-1 sm:pb-1 sm:h-[calc(100%-3rem)]">
            <ProductsList />
          </div>
        </div>
      </div>
    </>
  );
}

export default Home;
