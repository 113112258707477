import Drawer from "@mui/material/Drawer";
import Icon from "assets/icons/icons";
import { styled } from "@mui/material";

const MuiDrawer = styled((props) => <Drawer {...props} />)(({ theme }) => ({
  "& .MuiDrawer-paper ": {
    backgroundColor: "var(--c)",
  },
}));

export default function MUIDrawer({
  open,
  zIndex,
  onClose,
  children,
  closable = true,
  position = "right",
  hideBackdrop = false,
}) {
  return (
    <MuiDrawer
      open={open}
      anchor={position}
      onClose={onClose}
      style={{ zIndex, top: "100%" }}
      hideBackdrop={hideBackdrop}
      className={`relative h-auto`}
    >
      <>
        {children}
        {closable && (
          <button
            onClick={onClose}
            className="bg-c14 flex items-center justify-center absolute top-3 right-2 p-2 rounded "
          >
            <Icon name="close" />
          </button>
        )}
      </>
    </MuiDrawer>
  );
}
