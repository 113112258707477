import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import { axiosPrivate } from "services/axiosPrivate";

const initialState = {
  isLoading: false,
  refresh: "",
  clients: [],
  pagination: { page: 1, pageSize: 10, total: null },
};

export const getClients = createAsyncThunk(
  "client/get-clients",
  async ({ pagination = { page: 1, pageSize: 100 } }) => {
    const response = await axiosPrivate.get(
      `customer/list/?page=${pagination?.page}&page_size=${pagination?.pageSize}`
    );
    return response.data;
  }
);

export const createClient = createAsyncThunk("client/create-client", async (client) => {
  const response = await axiosPrivate.post("customer/post/", client);
  return response.data;
});

export const clientSlice = createSlice({
  name: "client",
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder
      .addCase(createClient.pending, (state, action) => {
        state.isLoading = true;
      })
      .addCase(createClient.fulfilled, (state, action) => {
        state.refresh = `create-client-${new Date()}`;
        state.isLoading = false;
      })
      .addCase(getClients.pending, (state) => {
        state.isLoading = true;
      })
      .addCase(getClients.fulfilled, (state, action) => {
        state.isLoading = false;
        state.clients = action.payload.data;
        state.pagination.total = action.payload.total;
      });
  },
});

const { reducer } = clientSlice;

// export const { chooseProduct, getProductsSQL } = actions;

export default reducer;
