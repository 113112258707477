import { useDispatch } from "react-redux";
import { useEffect, useState } from "react";
import { getCurrencies } from "store/slices/currencySlice";
import Checkbox from "components/checkbox/Checkbox";
import Input from "components/input/Input";

function Registration() {
  const dispatch = useDispatch();
  const [user, setUser] = useState({});

  const handleChangeUser = (name, value) => setUser({ ...user, [name]: value });

  const handleSave = () => console.log(user);

  useEffect(() => {
    dispatch(getCurrencies({ pagination: { page: 1, pageSize: 10 } }));
  }, []);

  return (
    <div className="bg-c1 w-full h-[100dvh] flex flex-col items-center justify-center gap-4 text-c24 p-8">
      {/* <p className="uppercase text-2xl">Ro'yxatdan o'tish</p> */}

      <div className="w-[28rem] flex flex-col gap-4">
        <div className="bg-c2 p-6 rounded flex flex-col gap-2 sm:w-[90vw]">
          <p className="text-lg text-center mb-6 uppercase">Ro'yxatdan o'tish</p>
          <Input
            type="text"
            label="Familiya *"
            value={user?.first_name || ""}
            onChange={(value) => handleChangeUser("first_name", value)}
            placeholder="Familiyani kiriting"
            classNameInput="w-full bg-c1 h-11 p-2 outline-0 border-0 rounded text-sm"
            virtualKeyboard={true}
          />
          <Input
            type="text"
            label="Ismi *"
            value={user?.last_name || ""}
            onChange={(value) => handleChangeUser("last_name", value)}
            placeholder="Ismini kiriting"
            classNameInput="w-full bg-c1 h-11 p-2 outline-0 border-0 rounded text-sm"
            virtualKeyboard={true}
          />
          <Input
            type="text"
            label="Otasining ismi"
            value={user?.middle_name || ""}
            onChange={(value) => handleChangeUser("middle_name", value)}
            placeholder="Otasining ismi kiriting"
            classNameInput="w-full bg-c1 h-11 p-2 outline-0 border-0 rounded text-sm"
            virtualKeyboard={true}
          />
          <Input
            type="text"
            mask="+\9\98 99 999 99 99"
            label="Telefon nomer *"
            value={user?.phone_number || ""}
            onChange={(value) => handleChangeUser("phone_number", value)}
            inputMode="tel"
            placeholder="Telefon nomer kiriting"
            classNameInput="w-full bg-c1 h-11 p-2 outline-0 border-0 rounded text-sm"
            virtualKeyboard={true}
          />

          <div className="flex gap-2 items-end">
            <Input
              type="text"
              label="Parol"
              value={user?.password1 || ""}
              onChange={(value) => handleChangeUser("password1", value)}
              className="w-1/2"
              placeholder="Parolni kiriting"
              classNameInput="w-full bg-c1 h-11 p-2 outline-0 border-0 rounded text-sm"
              virtualKeyboard={true}
            />
            <Input
              type="text"
              label=""
              value={user?.password2 || ""}
              onChange={(value) => handleChangeUser("password2", value)}
              className="w-1/2"
              placeholder="Parolni qayta kiriting"
              classNameInput="w-full bg-c1 h-11 p-2 outline-0 border-0 rounded text-sm"
              virtualKeyboard={true}
            />
          </div>

          <div className="">
            <p className="text-sm">Valyuta turi</p>
            <div className="grid grid-cols-4 gap-2 text-sm">
              <div
                onClick={() => handleChangeUser("currency", "UZS")}
                className={`bg-c1 p-3 rounded flex items-center cursor-pointer border-2 ${
                  user?.currency
                    ? user?.currency === "UZS"
                      ? "border-c3"
                      : "opacity-50 border-c1"
                    : "border-c1"
                }`}
              >
                <Checkbox
                  label="UZS"
                  checked={true}
                  onChange={() => {}}
                  classNameCheckbox="border-c7 p-1"
                />
              </div>
              <div
                onClick={() => handleChangeUser("currency", "USD")}
                className={`bg-c1 p-3 rounded flex items-center cursor-pointer border-2 ${
                  user?.currency
                    ? user?.currency === "USD"
                      ? "border-c3"
                      : "opacity-50 border-c1"
                    : "border-c1"
                }`}
              >
                <Checkbox
                  label="USD"
                  checked={true}
                  onChange={() => {}}
                  classNameCheckbox="border-c7 p-1"
                />
              </div>
              <div
                onClick={() => handleChangeUser("currency", "UZS-USD")}
                className={`bg-c1 p-3 rounded flex items-center cursor-pointer border-2 ${
                  user?.currency
                    ? user?.currency === "UZS-USD"
                      ? "border-c3"
                      : "opacity-50 border-c1"
                    : "border-c1"
                }`}
              >
                <div className="flex flex-col gap-2">
                  <Checkbox
                    label="UZS"
                    checked={true}
                    onChange={() => {}}
                    classNameCheckbox="border-c7 p-1"
                  />
                  <Checkbox
                    label="USD"
                    checked={false}
                    onChange={() => {}}
                    classNameCheckbox="border-c7 p-1"
                  />
                </div>
              </div>
              <div
                onClick={() => handleChangeUser("currency", "USD-UZS")}
                className={`bg-c1 p-3 rounded flex items-center cursor-pointer border-2 ${
                  user?.currency
                    ? user?.currency === "USD-UZS"
                      ? "border-c3"
                      : "opacity-50 border-c1"
                    : "border-c1"
                }`}
              >
                <div className="flex flex-col gap-2">
                  <Checkbox
                    label="UZS"
                    checked={false}
                    onChange={() => {}}
                    classNameCheckbox="border-c7 p-1"
                  />
                  <Checkbox
                    label="USD"
                    checked={true}
                    onChange={() => {}}
                    classNameCheckbox="border-c7 p-1"
                  />
                </div>
              </div>
            </div>
          </div>
          <button onClick={() => handleSave()} className="bg-c10 py-3 mt-3 rounded">
            Saqlash
            {/* {isLoading ? <Spinner /> : "Tizimga kirish"} */}
          </button>
        </div>
      </div>
    </div>
  );
}

export default Registration;
