import { axiosPrivate } from "services/axiosPrivate";
import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";

const initialState = {
  isLoading: false,
  isLoadingProduct: false,
  refresh: "",
  categories: [],
  subCategories: [],
  checkedAll: false,
  checkedCount: 0,
  checkedProductsCount: 0,
  activeCategory: {
    parent: null,
    child: null,
  },
  pagination: { page: 1, pageSize: 10 },
  total: null,
};

export const createCategory = createAsyncThunk("category/create-category", async (category) => {
  const response = await axiosPrivate.post("category/post/", category);
  return response.data;
});

export const updateCategory = createAsyncThunk(
  "category/update-category",
  async ({ category_id, category }) => {
    const response = await axiosPrivate.patch(`category/patch/${category_id}`, category);
    return response.data;
  }
);

export const deleteCategory = createAsyncThunk("category/delete-category", async (category_id) => {
  const response = await axiosPrivate.delete(`category/delete/${category_id}`);
  return response.data;
});

export const deleteCategories = createAsyncThunk("category/delete-category-by-ids", async (ids) => {
  const response = await axiosPrivate.post(`category/delete/by-ids`, { ids });
  return response.data;
});

export const getCategories = createAsyncThunk(
  "category/get-categories",
  async ({
    parent = "",
    withChildren = false,
    onlyRootCategories = false,
    pagination = { page: 1, pageSize: 100 },
  }) => {
    const response = await axiosPrivate.get(
      `category/list/?page=${pagination?.page}&page_size=${pagination?.pageSize}&only_root_categories=${onlyRootCategories}&with_children=${withChildren}&parent=${parent}`
    );

    return response.data;
  }
);

export const getSubCategories = createAsyncThunk(
  "category/get-sub-categories",
  async ({
    parent = "",
    withChildren = false,
    onlyRootCategories = false,
    pagination = { page: 1, pageSize: 100 },
  }) => {
    const response = await axiosPrivate.get(
      `category/list/?page=${pagination?.page}&page_size=${pagination?.pageSize}&only_root_categories=${onlyRootCategories}&with_children=${withChildren}&parent=${parent}`
    );

    return response.data;
  }
);

export const getCurrentCategoryProducts = createAsyncThunk(
  "category/get-current-category-products",
  async ({ category_id, supplier_id, action }) => {
    const response = await axiosPrivate.get(
      action === "return-income"
        ? `product/list-by-supplier-and-category/?supplier_id=${supplier_id}&category_id=${category_id}`
        : `product/list/?category=${category_id}`
    );

    return { products: response.data.data, category_id };
  }
);

export const categorySlice = createSlice({
  name: "category",
  initialState,
  reducers: {
    chooseCategory: (state, action) => {
      state.activeCategory = action.payload;
    },
    getCategoriesSQL: (state, action) => {
      state.categories = action.payload;
      state.activeCategory.parent = action.payload[0]?.id;
      state.activeCategory.child = action.payload[0]?.children[0]?.id;
    },
    checkedAllCategory: (state, action) => {
      state.checkedAll = !state.checkedAll;
      state.categories = state.categories.map((category) => ({
        ...category,
        checked: state.checkedAll,
      }));

      state.checkedCount = state.checkedAll ? state.categories.length : 0;
    },
    checkedCategory: (state, action) => {
      let checkedCount = 0;
      state.categories = state.categories.map((category) =>
        category.id === action.payload
          ? { ...category, checked: category?.checked ? false : true }
          : category
      );

      state.categories.map((category) => category.checked && (checkedCount += 1));
      state.checkedCount = checkedCount;

      checkedCount === state.categories.length
        ? (state.checkedAll = true)
        : (state.checkedAll = false);
    },
    changePagination: (state, action) => {
      state.pagination[action.payload.name] = action.payload.value;
      action.payload.name === "pageSize" &&
        Math.ceil(state.total / state.pagination.pageSize) <= state.pagination.page &&
        (state.pagination.page = Math.ceil(state.total / state.pagination.pageSize));
    },
    changeActiveCategory: (state, action) => {
      state.activeCategory = action.payload;
    },
    checkedProduct: (state, { payload: { parent_id, category_id, product_id } }) => {
      const categories = state.categories.map((category) =>
        category?.id === parent_id
          ? {
              ...category,
              children: category?.children?.map((child) =>
                child?.id === category_id
                  ? {
                      ...child,
                      children: child?.children?.map((product) => {
                        if (product?.id === product_id) {
                          state.checkedProductsCount = !product?.checked
                            ? state.checkedProductsCount + 1
                            : state.checkedProductsCount - 1;
                          return {
                            ...product,
                            root_category_id: parent_id,
                            checked: product?.checked ? false : true,
                          };
                        } else return product;
                      }),
                    }
                  : child
              ),
            }
          : category
      );

      // state.categories[parent_id].children[category_id].children[product_id].checked =
      //   !state.categories?.[parent_id]?.children?.[category_id]?.children?.[product_id]?.checked;

      // state.checkedProductsCount = state.categories[parent_id].children[category_id].children[
      //   product_id
      // ].checked
      // ? state.checkedProductsCount + 1
      // : state.checkedProductsCount - 1;
      state.categories = categories;
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(createCategory.pending, (state) => {
        state.isLoading = true;
      })
      .addCase(createCategory.fulfilled, (state, action) => {
        state.isLoading = false;
        state.currentCategory = action.payload;
        state.refresh = `create-category-${new Date()}`;
      })
      .addCase(updateCategory.fulfilled, (state, action) => {
        state.refresh = `update-category-${new Date()}`;
      })
      .addCase(deleteCategory.fulfilled, (state, action) => {
        state.refresh = `delete-category-${new Date()}`;
      })
      .addCase(deleteCategories.fulfilled, (state, action) => {
        state.refresh = `delete-category-by-id-${new Date()}`;
      })
      .addCase(getCategories.pending, (state) => {
        state.isLoading = true;
      })
      .addCase(getCategories.fulfilled, (state, action) => {
        state.isLoading = false;
        state.checkedProductsCount = 0;
        state.total = action.payload.total;
        state.categories = action.payload.data;
        state.activeCategory.parent = action?.payload?.data?.[0]?.id;
        state.activeCategory.child = action?.payload?.data?.[0]?.children?.[0]?.id;
      })
      .addCase(getSubCategories.fulfilled, (state, action) => {
        state.isLoading = false;
        state.checkedProductsCount = 0;
        state.total = action.payload.total;
        state.subCategories = action.payload.data;
      })
      .addCase(getCurrentCategoryProducts.pending, (state) => {
        state.isLoadingProduct = true;
      })
      .addCase(getCurrentCategoryProducts.fulfilled, (state, action) => {
        const categories = action?.payload?.products?.length
          ? state?.categories?.map((category) => ({
              ...category,
              children: category?.children?.map((c) =>
                c?.id === action?.payload?.category_id
                  ? { ...c, children: action?.payload?.products }
                  : c
              ),
            }))
          : state.categories;
        state.categories = categories;
        state.isLoadingProduct = false;
      });
  },
});

const { reducer, actions } = categorySlice;

export const {
  checkedProduct,
  chooseCategory,
  getCategoriesSQL,
  checkedAllCategory,
  checkedCategory,
  changePagination,
  changeActiveCategory,
} = actions;

export default reducer;
