import { useState, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import {
  getUnits,
  checkedAllUnit,
  checkedUnit,
  changePagination,
  deleteUnit,
  deleteUnits,
} from "store/slices/unitSlice";
import { checkingPermissions } from "assets/constants/constants";
import MUIPagination from "components/mui-pagination/MUIPagination";
import AdminNavbar from "layers/admin-navbar/AdminNavbar";
import MUIModal from "components/mui-modal/MUIModal";
import Checkbox from "components/checkbox/Checkbox";
import HtmlInput from "components/input/HtmlInput";
import MUIMenu from "components/mui-menu/MUIMenu";
import { Divider } from "@mui/material";
import CreateUnit from "./CreateUnit";
import DeleteUnit from "./DeleteUnit";
import Icon from "assets/icons/icons";

function Units() {
  const dispatch = useDispatch();
  const {
    units: { units, pagination, checkedAll, checkedCount, refresh, total },
  } = useSelector((store) => store);
  const [openModal, setOpenModal] = useState({ open: false, action: "" });
  const [openMenu, setOpenMenu] = useState({ anchorEl: null, unit: {} });

  const handleOpenMenu = (event, unit) => setOpenMenu({ anchorEl: event.currentTarget, unit });
  const handleCloseMenu = () => setOpenMenu({ ...openMenu, anchorEl: null });

  const handleOpenModal = (action) => {
    setOpenModal({ open: true, action });
    handleCloseMenu();
  };

  const handleCloseModal = () => setOpenModal({ ...openModal, open: false });

  const handleCheckedAllUnit = () => dispatch(checkedAllUnit());

  const handleCheckedUnit = (id) => dispatch(checkedUnit(id));

  const handleChangePagination = (name, value) => dispatch(changePagination({ name, value }));

  const handleDeleteUnit = () => {
    if (openModal?.action === "delete-units") {
      const ids = [];
      units?.map((unit) => unit?.checked && ids?.push(unit?.id));
      dispatch(deleteUnits(ids));
    } else dispatch(deleteUnit(openMenu?.unit?.id));
  };

  const handleGetUnits = (search = "") => dispatch(getUnits({ search, pagination }));

  useEffect(() => {
    handleGetUnits();
    handleCloseModal();
  }, [refresh, pagination]);

  return (
    <>
      <MUIModal
        position="center"
        hideBackdrop={false}
        open={openModal?.open}
        onClose={handleCloseModal}
      >
        {(openModal?.action === "create" || openModal?.action === "update") && (
          <CreateUnit
            color="c2"
            bgColor="c24"
            onClose={handleCloseModal}
            action={openModal?.action}
            currentUnit={openMenu?.unit}
          />
        )}
        {(openModal?.action === "delete" || openModal?.action === "delete-units") && (
          <DeleteUnit onDelete={handleDeleteUnit} onClose={handleCloseModal} />
        )}
      </MUIModal>

      <MUIMenu
        open={openMenu?.anchorEl ? true : false}
        handleClose={handleCloseMenu}
        anchorEl={openMenu?.anchorEl}
        children={
          <div className="flex flex-col gap-2 px-2" onClick={(event) => event.stopPropagation()}>
            {checkingPermissions(["UNIT.UPDATE"]) && (
              <div
                className="flex px-2 gap-3 cursor-pointer"
                onClick={() => handleOpenModal("update")}
              >
                <Icon name="edit_gray" />
                <span className="text-c2">Tahrirlash</span>
              </div>
            )}

            {checkingPermissions(["UNIT.UPDATE", "UNIT.DELETE"]) && (
              <Divider sx={{ borderColor: "#E8ECF4" }} />
            )}

            {checkingPermissions(["UNIT.DELETE"]) && (
              <div
                className="flex px-2 gap-3 cursor-pointer"
                onClick={() => handleOpenModal("delete")}
              >
                <Icon name="trash_red" />
                <span className="text-c14">O'chirish</span>
              </div>
            )}
          </div>
        }
      />

      <AdminNavbar
        rightElement={
          checkingPermissions(["UNIT.CREATE"]) ? (
            <button
              className="h-10 bg-c10 px-4 flex justify-center items-center rounded-xl gap-2 sm:p-0 sm:w-10"
              onClick={() => handleOpenModal("create")}
            >
              <Icon name="add" />
              <span className="text-c24 sm:hidden">Yangi qo’shish</span>
            </button>
          ) : (
            <div className="w-10 h-10" />
          )
        }
      />

      <div className="flex flex-col gap-4 p-4">
        <div className="bg-c24 text-c2 border border-c32 rounded-lg">
          <div className="flex justify-between items-center border-b border-c32 px-4 py-2">
            <HtmlInput
              type="search"
              placeholder="Qidiruv"
              onChange={(e) => handleGetUnits(e.target.value)}
              classNameInput="border h-10 rounded-xl outline-0 bg-c31 text-sm"
            />
          </div>

          <div className="rounded-lg overflow-x-auto">
            <table
              className={`w-full border-collapse even:bg-c36 odd:bg-white mb-6 sm:mb-0 sm:whitespace-nowrap ${
                !checkingPermissions(["UNIT.DELETE"]) ? "mb-0" : ""
              }`}
            >
              <thead>
                <tr className="text-c9 font-thin bg-c36">
                  <td className="py-3 pl-4 px-2">
                    {checkingPermissions(["UNIT.DELETE"]) ? (
                      <Checkbox
                        label="№"
                        bgColor="bg-c24"
                        classNameCheckbox="text-c2"
                        classNameLabel="text-c9"
                        checked={checkedAll}
                        onChange={handleCheckedAllUnit}
                      />
                    ) : (
                      "№"
                    )}
                  </td>
                  <td className="py-3 px-2">Nomi</td>
                  <td
                    className={`py-3 px-2 ${
                      !checkingPermissions(["UNIT.UPDATE"]) && !checkingPermissions(["UNIT.DELETE"])
                        ? "pr-4 text-end"
                        : ""
                    }`}
                  >
                    Turi
                  </td>
                  {(checkingPermissions(["UNIT.UPDATE"]) ||
                    checkingPermissions(["UNIT.DELETE"])) && (
                    <td className="py-3 px-2 pr-4 text-end">Amallar</td>
                  )}
                </tr>
              </thead>

              <tbody className="overflow-y-auto">
                {units?.map((unit, idx) => (
                  <tr key={unit?.id} className="even:bg-c36 odd:bg-white">
                    <td className="border-t border-c32 py-3 pl-4 px-2">
                      {checkingPermissions(["UNIT.DELETE"]) ? (
                        <Checkbox
                          bgColor="bg-c24"
                          classNameCheckbox="text-c2"
                          classNameLabel="text-c2"
                          checked={unit?.checked ? true : false}
                          onChange={() => handleCheckedUnit(unit?.id)}
                          label={Math.abs(idx + pagination.pageSize * (pagination.page - 1) + 1)}
                        />
                      ) : (
                        Math.abs(idx + pagination.pageSize * (pagination.page - 1) + 1)
                      )}
                    </td>
                    <td className="border-t border-c32 py-3 px-2">{unit?.name}</td>
                    <td
                      className={`border-t border-c32 py-3 px-2 ${
                        !checkingPermissions(["UNIT.UPDATE"]) &&
                        !checkingPermissions(["UNIT.DELETE"])
                          ? "pr-4 text-end"
                          : ""
                      }`}
                    >
                      {unit?.type}
                    </td>
                    {(checkingPermissions(["UNIT.UPDATE"]) ||
                      checkingPermissions(["UNIT.DELETE"])) && (
                      <td className="border-t border-c32 px-2 pr-4 text-end">
                        <button
                          className="w-10 h-10 border bg-c31 rounded-xl"
                          onClick={(e) => handleOpenMenu(e, unit)}
                        >
                          <Icon name="more_black" className="mx-auto" />
                        </button>
                      </td>
                    )}
                  </tr>
                ))}
              </tbody>
            </table>
          </div>

          <div className="bg-c36 border-t border-c32 px-4 pb-2 pt-6 relative sm:pt-2">
            <MUIPagination
              count={total}
              bgColor="c36"
              page={pagination?.page}
              pageSize={pagination?.pageSize}
              onChangePagination={(name, value) => handleChangePagination(name, value)}
            />

            {checkingPermissions(["UNIT.DELETE"]) && (
              <div className="bg-c24 whitespace-nowrap flex items-center absolute left-1/2 top-[-35%] translate-x-[-50%] py-3 px-4 rounded-xl border border-c10 sm:hidden">
                <div className="px-4 border-r border-c32">
                  <span className="text-c9">Tanlandi: {checkedCount}</span>
                </div>

                <div
                  className={`flex gap-2 px-4 ${
                    !checkedCount ? "opacity-50 cursor-not-allowed" : "cursor-pointer"
                  }`}
                  onClick={() => checkedCount && handleOpenModal("delete-units")}
                >
                  <Icon name="trash_red" />
                  <span className="text-c1">O’chirish</span>
                </div>
              </div>
            )}
          </div>
        </div>
      </div>
    </>
  );
}

export default Units;
