import { useState, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import {
  getCategories,
  deleteCategory,
  checkedCategory,
  changePagination,
  deleteCategories,
  getSubCategories,
  checkedAllCategory,
} from "store/slices/categorySlice";
import { checkingPermissions } from "assets/constants/constants";
import MUIPagination from "components/mui-pagination/MUIPagination";
import AdminNavbar from "layers/admin-navbar/AdminNavbar";
import MUIDrawer from "components/mui-drawer/MUIDrawer";
import MUIModal from "components/mui-modal/MUIModal";
import Checkbox from "components/checkbox/Checkbox";
import MUIMenu from "components/mui-menu/MUIMenu";
import MUITabs from "components/mui-tabs/MUITabs";
import CreateCategory from "./CreateCategory";
import DeleteCategory from "./DeleteCategory";
import { Divider } from "@mui/material";
import Icon from "assets/icons/icons";
import HtmlInput from "components/input/HtmlInput";

function Categories() {
  const dispatch = useDispatch();
  const {
    categories: { checkedAll, checkedCount, categories, subCategories, pagination, total, refresh },
  } = useSelector((store) => store);
  const [panel, setPanel] = useState(0);
  const [openModal, setOpenModal] = useState({ open: false, action: "" });
  const [openDrawer, setOpenDrawer] = useState({ open: false, action: "" });
  const [openMenu, setOpenMenu] = useState({ anchorEl: null, action: null, data: null });

  const handleOpenMenu = (event, action, data) =>
    setOpenMenu({ anchorEl: event.currentTarget, action, data });

  const handleCloseMenu = () => setOpenMenu({ ...openMenu, anchorEl: null });

  const handleOpenModal = (action) => {
    handleCloseMenu();
    setOpenModal({ open: true, action });
  };

  const handleCloseModal = () => setOpenModal({ ...openModal, open: false });

  const handleOpenDrawer = (action) => {
    handleCloseMenu();
    setOpenDrawer({ open: true, action });
  };

  const handleCloseDrawer = () => setOpenDrawer({ ...openDrawer, open: false });

  const handleCheckedAllCategory = () => dispatch(checkedAllCategory());

  const handleCheckedCategory = (id) => dispatch(checkedCategory(id));

  const handleDeleteCategory = () => {
    if (openModal?.action === "delete-products") {
      const ids = [];
      categories?.map((category) => category?.checked && ids?.push(category?.id));
      dispatch(deleteCategories(ids));
    } else dispatch(deleteCategory(openMenu?.data?.id));
  };

  const handleChangePagination = (name, value) => dispatch(changePagination({ name, value }));

  const handleChangePanel = (tab) => {
    setPanel(tab);
    handleChangePagination("page", 1);
  };

  const getSupCategoryNames = (child) => {
    let names = "";
    child.map((ch, idx) => idx < 3 && (names += `${ch?.name}, `));
    return names;
  };

  const handleGetCategories = (search = "") =>
    dispatch(
      panel
        ? getSubCategories({ search, pagination, onlyRootCategories: false, withChildren: false })
        : getCategories({
            search,
            pagination,
            onlyRootCategories: true,
            withChildren: true,
          })
    );

  useEffect(() => {
    handleGetCategories();
    handleCloseModal();
    handleCloseDrawer();
  }, [pagination, panel, refresh]);

  return (
    <>
      <MUIDrawer open={openDrawer?.open} onClose={handleCloseDrawer}>
        <CreateCategory
          color="c2"
          bgColor="c24"
          action={openDrawer?.action}
          currentCategory={openMenu?.data}
        />
      </MUIDrawer>

      <MUIModal
        position="center"
        hideBackdrop={false}
        hideCloseButton={true}
        open={openModal?.open}
        onClose={handleCloseModal}
      >
        <DeleteCategory onDelete={handleDeleteCategory} onClose={handleCloseModal} />
      </MUIModal>

      <MUIMenu
        handleClose={handleCloseMenu}
        anchorEl={openMenu?.anchorEl}
        open={openMenu?.anchorEl ? true : false}
      >
        {openMenu?.action === "actions" && (
          <div className="flex flex-col gap-2 p-1" onClick={(event) => event.stopPropagation()}>
            {checkingPermissions(["CATEGORY.UPDATE"]) && (
              <div
                className="flex px-2 gap-3 cursor-pointer"
                onClick={() => handleOpenDrawer("update")}
              >
                <Icon name="edit_gray" />
                <span className="text-c2">Tahrirlash</span>
              </div>
            )}

            {checkingPermissions(["CATEGORY.UPDATE", "CATEGORY.DELETE"]) && (
              <Divider sx={{ borderColor: "#E8ECF4" }} />
            )}

            {checkingPermissions(["CATEGORY.DELETE"]) && (
              <div
                onClick={() => handleOpenModal("delete")}
                className="flex px-2 gap-3 cursor-pointer"
              >
                <Icon name="trash_red" />
                <span className="text-c14">O'chirish</span>
              </div>
            )}
          </div>
        )}

        {openMenu?.action === "category" && (
          <div className="px-4 flex flex-col gap-2">
            {openMenu?.data?.map((ch) => (
              <span key={ch?.id} className="text-c2">
                {ch?.name}
              </span>
            ))}
          </div>
        )}
      </MUIMenu>

      <AdminNavbar
        rightElement={
          checkingPermissions(["CATEGORY.CREATE"]) ? (
            <button
              onClick={() => handleOpenDrawer("create")}
              className="h-10 bg-c10 px-4 flex justify-center items-center rounded-xl gap-2 sm:p-0 sm:w-10"
            >
              <Icon name="add" />
              <span className="text-c24 sm:hidden">Yangi qo’shish</span>
            </button>
          ) : (
            <div className="w-10 h-10" />
          )
        }
      />

      <div className="flex flex-col gap-4 p-4">
        <div className="bg-c24 text-c2 border border-c32 rounded-lg overflow-hidden">
          <div className="flex sm:flex-col justify-between items-center border-b border-c32 px-4 py-2 gap-3">
            <MUITabs
              panel={panel}
              color="text-c2"
              className="bg-c45 w-[20rem] rounded-3xl"
              tabs={["Kategoriyalar", "Subcategory"]}
              setPanel={handleChangePanel}
            />

            <HtmlInput
              type="search"
              placeholder="Qidiruv"
              classNameInput="border h-10 rounded-xl outline-0 bg-c31 text-sm"
              onChange={(e) => handleGetCategories(e.target.value)}
            />
          </div>

          <div className="rounded-lg overflow-x-auto">
            <table className="sm:whitespace-nowrap w-full border-collapse even:bg-c36 odd:bg-white mb-6 sm:m-0">
              <thead>
                <tr className="text-c9 font-thin bg-c36">
                  <td className="py-3 text-start pl-4 px-2">
                    {checkingPermissions(["CATEGORY.DELETE"]) ? (
                      <Checkbox
                        label="№"
                        bgColor="bg-c24"
                        checked={checkedAll}
                        classNameLabel="text-c2"
                        classNameCheckbox="text-c2"
                        onChange={handleCheckedAllCategory}
                      />
                    ) : (
                      "№"
                    )}
                  </td>
                  <td className="py-3 px-2">Nomi</td>
                  <td className={`py-3 px-2 ${panel ? "hidden" : ""}`}>Kategoriyasi</td>
                  <td
                    className={`py-3 px-2 ${
                      !checkingPermissions(["CATEGORY.UPDATE"]) &&
                      !checkingPermissions(["CATEGORY.DELETE"])
                        ? "pr-4 text-end"
                        : ""
                    }`}
                  >
                    Mahsulotlar soni
                  </td>
                  {(checkingPermissions(["CATEGORY.UPDATE"]) ||
                    checkingPermissions(["CATEGORY.DELETE"])) && (
                    <td className="py-3 px-2 pr-4 text-end">Amallar</td>
                  )}
                </tr>
              </thead>

              <tbody className="overflow-y-auto">
                {[...(panel ? subCategories : categories)]?.map((category, idx) => (
                  <tr key={category?.id} className="even:bg-c36 odd:bg-white">
                    <td className={`px-2 border-t border-c32 pl-4 py-3`}>
                      {checkingPermissions(["CATEGORY.DELETE"]) ? (
                        <Checkbox
                          bgColor="bg-c24"
                          classNameLabel="text-c2"
                          classNameCheckbox="text-c2"
                          checked={category?.checked ? true : false}
                          onChange={() => handleCheckedCategory(category?.id)}
                          label={Math.abs(idx + pagination.pageSize * (pagination.page - 1) + 1)}
                        />
                      ) : (
                        Math.abs(idx + pagination.pageSize * (pagination.page - 1) + 1)
                      )}
                    </td>
                    <td className={`px-2 border-t border-c32 py-3`}>{category?.name}</td>
                    <td className={`px-2 border-t border-c32 ${panel ? "hidden" : ""}`}>
                      <div className="flex items-center gap-2">
                        {!panel ? (
                          category?.children?.length ? (
                            <>
                              {getSupCategoryNames(category?.children)}
                              <div
                                onClick={(e) => handleOpenMenu(e, "category", category?.children)}
                                className="bg-card text-c10 py-1 px-3 rounded-lg"
                              >
                                {category?.children?.length}
                              </div>
                            </>
                          ) : (
                            "-"
                          )
                        ) : (
                          category?.parent
                        )}
                      </div>
                    </td>

                    <td
                      className={`px-2 border-t border-c32 py-3 ${
                        !checkingPermissions(["CATEGORY.UPDATE"]) &&
                        !checkingPermissions(["CATEGORY.DELETE"])
                          ? "pr-4 text-end"
                          : ""
                      }`}
                    >
                      {category?.products_count ? category?.products_count : "-"}
                    </td>

                    {(checkingPermissions(["CATEGORY.UPDATE"]) ||
                      checkingPermissions(["CATEGORY.DELETE"])) && (
                      <td className={`px-2 border-t border-c32 pr-4 text-end`}>
                        <button
                          className="w-10 h-10 bg-c31 rounded-xl border"
                          onClick={(e) => handleOpenMenu(e, "actions", category)}
                        >
                          <Icon name="more_black" className="mx-auto" />
                        </button>
                      </td>
                    )}
                  </tr>
                ))}
              </tbody>
            </table>
          </div>

          <div className="bg-c36 border-t border-c32 px-4 pb-2 pt-6 relative sm:pt-2">
            <MUIPagination
              count={total}
              bgColor="c36"
              page={pagination?.page}
              pageSize={pagination?.pageSize}
              onChangePagination={(name, value) => handleChangePagination(name, value)}
            />

            {checkingPermissions(["CATEGORY.DELETE"]) && (
              <div className="bg-c24 flex items-center absolute left-1/2 top-[-35%] translate-x-[-50%] py-3 px-4 rounded-xl border border-c10 sm:hidden">
                <div className="px-4 border-r border-c32">
                  <span className="text-c9">Tanlandi: {checkedCount}</span>
                </div>

                <div
                  className={`flex gap-2 px-4 ${
                    checkedCount ? "cursor-pointer" : "cursor-not-allowed"
                  }`}
                  onClick={() => checkedCount && handleOpenModal("delete-products")}
                >
                  <Icon name="trash_red" />
                  <span className={checkedCount ? "text-c1" : "text-c9"}>O’chirish</span>
                </div>
              </div>
            )}
          </div>
        </div>
      </div>
    </>
  );
}

export default Categories;
