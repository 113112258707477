import { useDispatch } from "react-redux";
import { useState, useEffect } from "react";
import { payForSupplier } from "store/slices/supplierSlice";
import { REFACTOR_SUM } from "assets/constants/constants";
import HtmlInput from "components/input/HtmlInput";
import Select from "components/select/Select";

const PayForSuppliers = ({ currentSupplier }) => {
  const dispatch = useDispatch();
  const [expense, setExpense] = useState({ amount: currentSupplier?.debt || "" });

  const handleChange = (name, value) => {
    setExpense({ ...expense, [name]: value });
  };

  const handleSave = () => dispatch(payForSupplier(expense));

  useEffect(() => {
    setExpense({ ...expense, supplier_id: currentSupplier?.id });
  }, [currentSupplier]);

  return (
    <div className="bg-c24 text-c2 w-[25rem] p-6 rounded-xl flex flex-col gap-4 sm:w-[90vw]">
      <span className="text-2xl">Summa kiritish</span>

      <div>
        <HtmlInput
          type="number"
          inputMode="numeric"
          value={expense?.amount || ""}
          label="Kiritmoqchi bo’lgan summani yozing"
          classNameInput="w-full h-10 border border-c32 px-2 rounded-xl outline-none"
          onChange={(e) => handleChange("amount", e.target.value)}
        />

        <div className="text-sm">
          Umumiy qarzdorlik:
          <span className="text-c10"> {REFACTOR_SUM(currentSupplier?.debt)} UZS</span>
        </div>
      </div>

      <Select
        label="To'lov turi"
        value={expense?.type || ""}
        classNameLabel="text-c2 text-sm"
        options={[
          { id: 1, name: "Karta", value: "card" },
          { id: 2, name: "Naqd", value: "cash" },
          { id: 3, name: "Nasiya", value: "debt" },
          { id: 4, name: "Boshqa", value: "other" },
        ]}
        optionsLabel={{ label: "name", value: "value" }}
        onChange={(e) => handleChange("type", e.target.value)}
        classNameSelect="bg-c24 w-full h-10 px-2 pl-7 rounded-xl text-c2 border border-c32 outline-none bg-arrow-down-gray bg-no-repeat bg-left bg-[center_left_0.25rem] select-arrow-none"
      />

      <button onClick={handleSave} className="bg-c10 text-c24 rounded-xl p-3">
        Saqlash
      </button>
    </div>
  );
};

export default PayForSuppliers;
