import { useEffect, useState } from "react";
import { getUsers } from "store/slices/userSlice";
import { useDispatch, useSelector } from "react-redux";
import { changeOrderListFilter } from "store/slices/orderSlice";
import { checkingPermissions } from "assets/constants/constants";
import RangeCalender from "components/mui-date-range-picker/MUIDateRangePicker";
import MUISearchSelect from "components/mui-select/MUISearchSelect";
import Multiselect from "components/multiselect/Multiselect";
import MUIMenu from "components/mui-menu/MUIMenu";
import Icon from "assets/icons/icons";

const OrdersFilter = () => {
  const dispatch = useDispatch();
  const {
    orders: { ordersList },
    users: { users, pagination },
  } = useSelector((store) => store);
  const [filter, setFilter] = useState(ordersList?.filter);
  const [openMenu, setOpenMenu] = useState({ anchorEl: null, action: null });

  const handleOpenMenu = (e, action) => setOpenMenu({ anchorEl: e.currentTarget, action });

  const handleCloseMenu = () => setOpenMenu({ ...openMenu, anchorEl: null });

  const handleGetUsers = (search = "") =>
    dispatch(getUsers({ search, pagination: { ...pagination, pageSize: 100 } }));

  const handleChangeFilter = (name, value) => setFilter({ ...filter, [name]: value });

  const handleSave = () => dispatch(changeOrderListFilter(filter));

  useEffect(() => {
    handleGetUsers(users?.filter((user) => user?.id === filter?.seller_id)?.[0]?.first_name || "");
  }, []);

  return (
    <div className="w-[23rem] bg-c24 h-full py-4 sm:w-screen">
      <MUIMenu
        open={openMenu?.anchorEl ? true : false}
        handleClose={handleCloseMenu}
        anchorEl={openMenu?.anchorEl}
        transformOrigin={{ horizontal: "right", vertical: "top" }}
        anchorOrigin={{ horizontal: "right", vertical: "center" }}
        children={
          <div onClick={(event) => event.stopPropagation()}>
            <RangeCalender value={filter?.selection} handleChange={handleChangeFilter} />
          </div>
        }
      />

      <div className="flex flex-col justify-between gap-4 p-4">
        <span className="text-c2 text-2xl">Filter</span>

        <div onClick={handleOpenMenu} className="w-full flex flex-col gap-1">
          <span className="text-c2 text-sm">Sana bo'yicha filter</span>

          <div className="h-10 flex justify-center items-center border border-c32 rounded-xl">
            <div className="w-6/12 flex px-8 gap-2">
              <Icon name="calendar_gray" />

              <div className="flex flex-col">
                <span className="text-c27 text-xs">Dan</span>

                <span className="text-c2 text-sm">
                  {filter?.selection?.startDate
                    ? new Date(filter?.selection?.startDate)?.toLocaleDateString()?.substring(0, 10)
                    : ""}
                </span>
              </div>
            </div>

            <div className="w-6/12 flex justify-center px-8 gap-2 border-l border-c32">
              <Icon name="calendar_gray" />

              <div className="flex flex-col">
                <span className="text-c27 text-xs">Gacha</span>

                <span className="text-c2 text-sm">
                  {new Date(filter?.selection?.endDate).toDateString() !== "Thu Jan 01 1970"
                    ? new Date(filter?.selection?.endDate)?.toLocaleDateString()?.substring(0, 10)
                    : ""}
                </span>
              </div>
            </div>
          </div>
        </div>

        {checkingPermissions(["USER.READ"]) && (
          <MUISearchSelect
            label="Kassir"
            options={users}
            placeholder="Qidiruv"
            value={filter?.seller_id}
            onChange={(e) => handleGetUsers(e)}
            optionsLabel={{ label: "first_name", value: "id" }}
            onSelected={(e) => handleChangeFilter("seller_id", e)}
          />
        )}

        <Multiselect
          label="To'lov turi"
          value={filter?.payment_types}
          options={[
            { label: "Naqd", value: "cash" },
            { label: "Karta", value: "card" },
            { label: "Qarz", value: "debt" },
            { label: "Boshqa", value: "other" },
          ]}
          onChange={(e) => handleChangeFilter("payment_types", e)}
          classNameSelect="border border-c32 rounded-xl text-sm outline-none"
        />

        <button className="bg-c10 text-c24 h-10 px-8 rounded-xl" onClick={handleSave}>
          Filter
        </button>
      </div>
    </div>
  );
};

export default OrdersFilter;
