import { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { REFACTOR_SUM } from "assets/constants/constants";
import { addOrderProduct } from "store/slices/orderSlice";
import { addIncomeProduct } from "store/slices/incomeSlice";
import { useLocation, useNavigate } from "react-router-dom";
import { warningOpenSession } from "constants/notifications";
import { getProducts, chooseProduct } from "store/slices/productSlice";
import SelectSupplier from "layers/supplier/SelectSupplier";
import CreateProduct from "layers/products/CreateProduct";
import MUIDrawer from "components/mui-drawer/MUIDrawer";
import ProductInfo from "layers/products/ProductInfo";
import MUIModal from "components/mui-modal/MUIModal";
import Payment from "layers/payment/Payment";
import Icon from "assets/icons/icons";
import { checkingPermissions } from "assets/constants/constants";

const chooseBorderColor = (count) => {
  if (count >= 50) return "border-c18";
  else if (count >= 10) return "border-c22";
  return "border-c14";
};
const chooseBgColor = (count) => {
  if (count >= 50) return "bg-c18";
  else if (count >= 10) return "bg-c22";
  return "bg-c14";
};

function ProductsList() {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const { pathname } = useLocation();
  const {
    income,
    orders,
    categories: {
      activeCategory: { child },
    },
    products: { products, filter, refresh },
    auth: {
      session: { session_id },
    },
  } = useSelector((store) => store);
  const [openDrawer, setOpenDrawer] = useState({ action: "add", open: false });
  const [openModal, setOpenModal] = useState(false);

  const currentPath = pathname.split("/");

  const currentOrder = orders.orders[currentPath[2]];

  const handleOpenModal = () => {
    ((currentPath?.[1] === "income" &&
      income?.income?.totalPrice &&
      income?.income?.supplier?.id) ||
      currentOrder?.totalPrice) &&
      setOpenModal(true);
  };
  const handleCloseModal = () => setOpenModal(false);

  const handleOpenDrawer = (action, product) => {
    dispatch(chooseProduct(product));
    setOpenDrawer({ action, open: true });
  };
  const handleCloseDrawer = () => setOpenDrawer({ ...openDrawer, open: false });

  const handleClickProductItem = (product) => {
    currentPath?.[1] === "income"
      ? dispatch(addIncomeProduct(product))
      : dispatch(addOrderProduct({ order_id: currentPath?.[2], product }));
  };

  useEffect(() => {
    child && dispatch(getProducts({ filter: { ...filter, category: child } }));
    handleCloseDrawer();
    handleCloseModal();
  }, [child, refresh, income.refresh, orders.refresh]);

  return (
    <>
      <MUIDrawer open={openDrawer?.open} onClose={handleCloseDrawer}>
        {openDrawer?.action === "add" && <CreateProduct />}
        {openDrawer?.action === "view" && <ProductInfo />}
        {openDrawer?.action === "select-supplier" && <SelectSupplier onClose={handleCloseDrawer} />}
      </MUIDrawer>

      <MUIModal zIndex={80} open={openModal} position="center" onClose={handleCloseModal}>
        <Payment onClose={handleCloseModal} />
      </MUIModal>

      <div className="h-full relative">
        <div className="grid grid-cols-3 text-c24 gap-1 max-h-full overflow-y-auto rounded sm:grid-cols-2 ">
          {products?.map((product) => (
            <div
              key={product?.id}
              onClick={() => {
                session_id && handleClickProductItem(product);
                warningOpenSession(session_id);
              }}
              className={`bg-c4 py-4 flex flex-col justify-between items-center gap-4 border-t-[5px] rounded  ${chooseBorderColor(
                product?.quantity
              )} ${session_id ? "cursor-pointer" : "cursor-not-allowed"}`}
            >
              <p className="text-center px-1">{product?.name}</p>
              <p className={`rounded px-2 text-center ${chooseBgColor(product?.quantity)}`}>
                {product?.quantity}
              </p>
            </div>
          ))}

          {currentPath?.[1] === "income" && checkingPermissions(["PRODUCT.CREATE"]) && (
            <div
              onClick={() => handleOpenDrawer("add")}
              className={`bg-c17 py-4 flex flex-col justify-center items-center gap-2 rounded cursor-pointer`}
            >
              <Icon name="plus" />
              <p className="px-1 text-center">Yangi tovar qo'shish</p>
            </div>
          )}
        </div>

        <div className="w-full absolute bottom-0 flex gap-1 sm:hidden">
          <button
            onClick={() =>
              currentPath?.[1] === "income" && !income?.income?.supplier?.id
                ? handleOpenDrawer("select-supplier")
                : handleOpenModal()
            }
            className={`bg-c17 w-full text-c24 text-center py-3 rounded items-center justify-center ${
              (currentPath[1] === "income" &&
                income?.income?.totalPrice &&
                income?.income?.supplier?.id) ||
              currentOrder?.totalPrice
                ? ""
                : "opacity-50 cursor-not-allowed"
            }`}
          >
            {currentPath?.[1] === "income"
              ? income?.income?.supplier?.id
                ? income?.income?.totalPrice
                  ? `To'lov qilish - ${REFACTOR_SUM(income?.income?.totalPrice)} UZS`
                  : "To'lov qilish"
                : "Yetkazib beruvchini tanlang"
              : currentOrder?.totalPrice
              ? `To'lov qilish - ${REFACTOR_SUM(currentOrder?.totalPrice)} UZS`
              : "To'lov qilish"}
          </button>

          {currentPath?.[1] === "income" && (
            <button
              onClick={() => navigate("/home")}
              className="bg-c5 text-c14 text-center py-2 px-4 rounded flex items-center justify-center"
            >
              ✕
            </button>
          )}
        </div>
      </div>
    </>
  );
}

export default ProductsList;
