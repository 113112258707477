import { useEffect, useState } from "react";
import { getUnits } from "store/slices/unitSlice";
import { useDispatch, useSelector } from "react-redux";
import { createProduct } from "store/slices/productSlice";
import Spinner from "components/loader/Spinner";
import Select from "components/select/Select";
import Input from "components/input/Input";
import Icon from "assets/icons/icons";

function CreateProduct() {
  const dispatch = useDispatch();
  const {
    units: { units, pagination },
    keyboard: { openKeyboard },
    categories: { activeCategory },
    products: { isLoading },
  } = useSelector((store) => store);
  const [img, setImg] = useState(null);
  const [product, setProduct] = useState({});

  const handleChangeProduct = (name, value) => setProduct({ ...product, [name]: value });

  const handleSave = () => {
    product?.name &&
      product?.price &&
      product?.unit_id &&
      activeCategory?.child &&
      dispatch(
        createProduct({
          name: product?.name,
          price: product?.price,
          category_id: activeCategory?.child,
          unit_id: product?.unit_id,
          quantity: 0,
        })
      );
  };

  useEffect(() => {
    dispatch(getUnits({ pagination }));
  }, [dispatch, pagination]);

  return (
    <div
      className={`bg-c2 text-c24 w-[20rem] h-full overflow-y-auto sm:w-screen ${
        openKeyboard ? "min-h-[140%] overflow-y-auto" : "h-full"
      }`}
    >
      <div className="p-4 flex flex-col gap-4 text-sm">
        <p className="text-xl">Yangi tovar qo’shish</p>

        <p className="text-c27">Tovar rasmi</p>

        <div className="bg-c1 px-4 py-4 w-1/2 rounded">
          {img ? (
            <div className="flex justify-center items-center relative rounded overflow-hidden">
              <img src={img} alt="product" />

              <button
                className="bg-c2 absolute right-0 bottom-0 flex justify-center items-center p-1 rounded-tl"
                onClick={() => setImg()}
              >
                <Icon name="trash" />
              </button>
            </div>
          ) : (
            <label htmlFor="formId" className="flex flex-col justify-center items-center">
              <input
                hidden
                name=""
                type="file"
                id="formId"
                accept="image/*"
                onChange={(e) => setImg(URL.createObjectURL(e.target.files[0]))}
              />
              <Icon name="input" className="scale-[1.1]" />
              <p className="text-c28">Rasm qo’shish</p>
            </label>
          )}
        </div>

        <Input
          label="Tovar nomi *"
          value={product?.name || ""}
          onChange={(e) => handleChangeProduct("name", e)}
          placeholder="Tovar nomi"
          classNameInput="w-full border border-c7 text-c24 rounded text-sm bg-c1 p-3 outline-none"
          virtualKeyboard={true}
        />
        <Input
          label="Tovar kodi *"
          value={product?.barcode || ""}
          onChange={(e) => handleChangeProduct("barcode", e)}
          inputMode="numeric"
          placeholder="Tovar kodi"
          classNameInput="w-full border border-c7 text-c24 rounded text-sm bg-c1 p-3 outline-none"
          virtualKeyboard={true}
        />
        <Input
          id="product-price"
          label="Tovar narxi *"
          value={product?.price || ""}
          onChange={(e) => handleChangeProduct("price", e)}
          inputMode="numeric"
          placeholder="Tovar narxi"
          classNameInput="w-full border border-c7 text-c24 rounded text-sm bg-c1 p-3 outline-none"
          virtualKeyboard={true}
        />
        <Select
          id="product-unit-type"
          label="O'lchov birliklari *"
          value={product?.unit_id || ""}
          options={units}
          onChange={(e) => handleChangeProduct("unit_id", e.target.value)}
          className="rounded-xs"
          optionsLabel={{ value: "id", label: "name" }}
          classNameLabel="flex flex-col"
          classNameSelect="p-3 rounded border border-c7 outline-0 bg-c1 bg-arrow-down bg-no-repeat bg-right bg-[center_right_0.3rem] select-arrow-none"
        />
        <button
          onClick={handleSave}
          className="bg-c17 w-full flex justify-center items-center h-11 rounded"
        >
          {isLoading ? <Spinner /> : "Saqlash"}
        </button>
      </div>
    </div>
  );
}

export default CreateProduct;
