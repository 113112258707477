import React from "react";

function Spinner() {
  return (
    <div className="lds-ellipsis inline-block relative w-[80px] h-2">
      <div className="w-2 h-2 rounded absolute bg-c24"></div>
      <div className="w-2 h-2 rounded absolute bg-c24"></div>
      <div className="w-2 h-2 rounded absolute bg-c24"></div>
      <div className="w-2 h-2 rounded absolute bg-c24"></div>
    </div>
  );
}

export default Spinner;
