import { configureStore } from "@reduxjs/toolkit";
import auth from "store/slices/authSlice";
import units from "store/slices/unitSlice";
import users from "store/slices/userSlice";
import theme from "store/slices/themeSlice";
import brands from "store/slices/brandSlice";
import orders from "store/slices/orderSlice";
import income from "store/slices/incomeSlice";
import clients from "store/slices/clientSlice";
import sidebar from "store/slices/sidebarSlice";
import payments from "store/slices/paymentSlice";
import packages from "store/slices/packageSlice";
import products from "store/slices/productSlice";
import keyboard from "store/slices/keyboardSlice";
import customers from "store/slices/customerSlice";
import suppliers from "store/slices/supplierSlice";
import categories from "store/slices/categorySlice";
import permissions from "store/slices/permissionSlice";
import currencies from "store/slices/currencySlice";

export const store = configureStore({
  reducer: {
    auth,
    users,
    units,
    theme,
    income,
    brands,
    orders,
    clients,
    sidebar,
    payments,
    packages,
    products,
    suppliers,
    keyboard,
    customers,
    categories,
    currencies,
    permissions,
  },
  middleware: (getDefaultMiddleware) =>
    getDefaultMiddleware({
      serializableCheck: false,
    }).concat(),
  devTools: process.env.NODE_ENV !== "production",
});
