import { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { REFACTOR_SUM, checkingPermissions } from "assets/constants/constants";
import { getSuppliers, changePagination, deleteSupplier } from "store/slices/supplierSlice";
import MUIPagination from "components/mui-pagination/MUIPagination";
import AdminNavbar from "layers/admin-navbar/AdminNavbar";
import MUIModal from "components/mui-modal/MUIModal";
import MUIMenu from "components/mui-menu/MUIMenu";
import PayForSuppliers from "./PayForSuppliers";
import CreateSupplier from "./CreateSupplier";
import DeleteSupplier from "./DeleteSupplier";
import { Divider } from "@mui/material";
import Icon from "assets/icons/icons";
import HtmlInput from "components/input/HtmlInput";

function Suppliers() {
  const dispatch = useDispatch();
  const {
    suppliers: { suppliers, refresh, total, pagination, totalDebt },
  } = useSelector((store) => store);
  const [openModal, setOpenModal] = useState({ open: false, action: "" });
  const [openMenu, setOpenMenu] = useState({ anchorEl: null, supplier: null });

  const handleOpenMenu = (e, supplier) => setOpenMenu({ anchorEl: e.currentTarget, supplier });

  const handleCloseMenu = () => setOpenMenu({ ...openMenu, anchorEl: null });

  const handleOpenModal = (action) => {
    setOpenModal({ open: true, action });
    handleCloseMenu();
  };

  const handleCloseModal = () => setOpenModal({ ...openModal, open: false });

  const handleDelete = () => dispatch(deleteSupplier(openMenu?.supplier?.id));

  const handleChangePagination = (name, value) => dispatch(changePagination({ name, value }));

  const handleGetSupplier = (search = "") => dispatch(getSuppliers({ search, pagination }));

  useEffect(() => {
    handleGetSupplier();
    handleCloseModal();
  }, [dispatch, refresh, pagination]);

  return (
    <>
      <MUIModal
        position="center"
        hideBackdrop={false}
        open={openModal?.open}
        onClose={handleCloseModal}
      >
        {(openModal?.action === "create" || openModal?.action === "update") && (
          <CreateSupplier action={openModal?.action} supplier_id={openMenu?.supplier?.id} />
        )}

        {openModal?.action === "pay" && <PayForSuppliers currentSupplier={openMenu?.supplier} />}
        {openModal?.action === "delete" && (
          <DeleteSupplier onClose={handleCloseModal} onDelete={handleDelete} />
        )}
      </MUIModal>

      <MUIMenu
        open={openMenu?.anchorEl ? true : false}
        handleClose={handleCloseMenu}
        anchorEl={openMenu?.anchorEl}
        children={
          <div
            className="flex flex-col items-start gap-2 px-2"
            onClick={(event) => event.stopPropagation()}
          >
            {/* <div className="flex px-2 gap-3 cursor-pointer" onClick={() => handleOpenModal("pay")}>
              <Icon name="plus_gray" className="mx-auto" />
              <div className="text-c2">To'lov qilish</div>
            </div>
            <Divider sx={{ borderColor: "#E8ECF4", width: "100%" }} /> */}

            {checkingPermissions(["SUPPLIER.UPDATE"]) && (
              <div
                className="flex px-2 gap-3 cursor-pointer"
                onClick={() => handleOpenModal("update")}
              >
                <Icon name="edit_gray" className="mx-auto" />
                <div className="text-c2">Tahrirlash</div>
              </div>
            )}

            {checkingPermissions(["SUPPLIER.DELETE"]) &&
              checkingPermissions(["SUPPLIER.UPDATE"]) && (
                <Divider sx={{ borderColor: "#E8ECF4", width: "100%" }} />
              )}

            {checkingPermissions(["SUPPLIER.DELETE"]) && (
              <div
                className="flex px-2 gap-3 cursor-pointer"
                onClick={() => handleOpenModal("delete")}
              >
                <Icon name="trash_red" />
                <span className="text-c14">O'chirish</span>
              </div>
            )}
          </div>
        }
      />

      <AdminNavbar
        rightElement={
          checkingPermissions(["SUPPLIER.CREATE"]) ? (
            <button
              onClick={() => handleOpenModal("create")}
              className="bg-c10 h-10 px-4 py-2 rounded-xl flex justify-center items-center gap-1 sm:p-0 sm:w-10"
            >
              <Icon name="add" />
              <span className="text-c24 sm:hidden">Qo'shish</span>
            </button>
          ) : (
            <div className="w-10 h-10" />
          )
        }
      />

      <div className="bg-c24 text-c2 m-4 border border-c32 rounded-xl">
        <div className="flex justify-between items-center border-b border-c32 py-2 px-4 gap-2">
          <HtmlInput
            type="search"
            placeholder="Qidiruv"
            classNameInput="border h-10 rounded-xl outline-0 bg-c31 text-sm"
            onChange={(e) => handleGetSupplier(e.target.value)}
          />
        </div>
        <div className="rounded-xl overflow-x-auto">
          <table className="sm:whitespace-nowrap w-full border-collapse even:bg-c36 odd:bg-white">
            <thead>
              <tr className="text-c9 bg-c36 font-thin">
                <td className="px-2 py-3 pl-4">№</td>
                <td className="px-2 py-3">Nomi</td>
                <td className="px-2 py-3">Telefon raqami</td>
                <td
                  className={`px-2 py-3 ${
                    !checkingPermissions(["SUPPLIER.DELETE"]) &&
                    !checkingPermissions(["SUPPLIER.UPDATE"])
                      ? "pr-4 text-end"
                      : ""
                  }`}
                >
                  Qarz
                </td>
                {(checkingPermissions(["SUPPLIER.DELETE"]) ||
                  checkingPermissions(["SUPPLIER.UPDATE"])) && (
                  <td className="px-2 py-3 pr-4 text-end">Amallar</td>
                )}
              </tr>
            </thead>

            <tbody>
              {suppliers?.map((supplier, idx) => (
                <tr key={supplier?.id}>
                  <td className="px-2 py-3 pl-4 border-t ">
                    {Math.abs(idx + pagination.pageSize * (pagination.page - 1) + 1)}
                  </td>
                  <td className="px-2 py-3 border-t ">{supplier?.name}</td>
                  <td className="px-2 py-3 border-t ">{supplier?.phone}</td>
                  <td
                    className={`px-2 py-3 border-t text-c14 ${
                      !checkingPermissions(["SUPPLIER.DELETE"]) &&
                      !checkingPermissions(["SUPPLIER.UPDATE"])
                        ? "pr-4 text-end"
                        : ""
                    }`}
                  >
                    {supplier?.debt ? `${REFACTOR_SUM(supplier?.debt)} UZS` : "-"}
                  </td>
                  {(checkingPermissions(["SUPPLIER.DELETE"]) ||
                    checkingPermissions(["SUPPLIER.UPDATE"])) && (
                    <td className="border-t border-c32 px-2 pr-4 text-end">
                      <button
                        className="w-10 h-10 border bg-c31 rounded-xl"
                        onClick={(e) => handleOpenMenu(e, supplier)}
                      >
                        <Icon name="more_black" className="mx-auto" />
                      </button>
                    </td>
                  )}
                  {/* <td className="px-2 pr-4 border-t ">
                    <div className="flex justify-end gap-2">
                      <button
                        className="w-10 h-10 border border-c32 rounded-xl"
                        onClick={() => handleOpenModal("update", supplier)}
                      >
                        <Icon name="edit_blue" className="mx-auto" />
                      </button>

                      <button
                        className="w-10 h-10 bg-c10 rounded-xl"
                        onClick={() => handleOpenModal("pay", supplier)}
                      >
                        <Icon name="plus" className="mx-auto" />
                      </button>
                    </div>
                  </td> */}
                </tr>
              ))}
            </tbody>
          </table>
        </div>

        <div className="flex items-center justify-between py-3 px-6 border-t border-c32 shadow-xl">
          <span className="text-c2">Umumiy qarz</span>
          <span className="text-c10 text-xl font-medium">{REFACTOR_SUM(totalDebt)} UZS</span>
        </div>

        <div className="bg-c36 border-t border-c32 px-4 py-2 rounded-b-xl">
          <MUIPagination
            count={total}
            bgColor="c36"
            page={pagination?.page}
            pageSize={pagination?.pageSize}
            onChangePagination={(name, value) => handleChangePagination(name, value)}
          />
        </div>
      </div>
    </>
  );
}

export default Suppliers;
