import { useEffect, useState } from "react";
import { ADMIN_ROUTES, OPERATOR_ROUTES } from "./routes";
import { Route, Routes, Navigate } from "react-router-dom";
import { checkingPermissions } from "assets/constants/constants";
import Registration from "pages/registration/Registration";
import Operator from "layouts/operator/Operator";
import PrivateRoute from "services/PrivateRoute";
import Messages from "pages/message/Messages";
import Admin from "layouts/admin/Admin";
import Login from "pages/login/Login";

import "assets/styles/tailwind.css";
import "assets/styles/theme.css";

import { ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
// import { useDispatch } from "react-redux";
// import { getCurrencies } from "store/slices/currencySlice";
// import { getUserInfo } from "store/slices/authSlice";

const REQUIRE_PERMISSIONS = [
  "CATEGORY.READ",
  "PRODUCT.READ",
  "ORDER.CREATE",
  "ORDER.READ",
  "SESSION.OPEN",
  "SESSION.CLOSE",
];

const handleNavigateNextRoute = (route) => {
  return `${route?.route}/${
    route?.collapses?.filter((col) => checkingPermissions(col?.permissions))?.[0]?.route
  }`;
};

const renderRoutes = (routes, platform) => {
  return routes?.map((route) => (
    <Route
      key={route?.key}
      path={route?.route}
      element={
        <PrivateRoute routePermissions={route?.permissions} redirectRoute={`/${platform}`}>
          {route?.component}
        </PrivateRoute>
      }
    >
      {!route?.noCollapse && route?.collapses?.length && renderRoutes(route?.collapses)}
      {route?.noCollapse ? (
        <Route path="*" element={<Navigate to={`/${platform}`} replace />} />
      ) : (
        <Route
          path="*"
          element={<Navigate to={`/${platform}/${handleNavigateNextRoute(route)}`} replace />}
        />
      )}
    </Route>
  ));
};

function App() {
  // const dispatch = useDispatch();
  const [darkMode, setDarkMode] = useState(true);

  useEffect(() => {
    if (darkMode === "dark") {
      document.documentElement.classList.add("dark");
    } else {
      document.documentElement.classList.remove("dark");
    }
  }, [darkMode]);

  return (
    <>
      <ToastContainer />
      <Routes>
        <Route
          path="admin/*"
          element={
            <PrivateRoute redirectRoute="/">
              <Admin />
            </PrivateRoute>
          }
        >
          {renderRoutes(ADMIN_ROUTES, "admin")}
          <Route path="*" element={<Navigate to="/admin/dashboard" />} />
        </Route>

        {/* cashier */}
        <Route
          path="*"
          element={
            <PrivateRoute redirectRoute="/message" routePermissions={REQUIRE_PERMISSIONS}>
              <Operator />
            </PrivateRoute>
          }
        >
          {renderRoutes(OPERATOR_ROUTES, "home")}
          <Route path="*" element={<Navigate to="/home" replace />} />
        </Route>

        <Route path="/" element={<Login />} />
        <Route path="/registration" element={<Registration />} />
        {/* <Route path="/admin" element={<Navigate to="/home" replace />} /> */}
        <Route
          path="/message"
          element={
            <PrivateRoute redirectRoute="/">
              <Messages />
            </PrivateRoute>
          }
        />
        {/* <Route path="*" element={<Navigate to="/home" replace />} /> */}
      </Routes>
    </>
  );
}

export default App;
