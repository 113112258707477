import Icon from "assets/icons/icons";

const MiniCard = ({ bgColor, icon, title, sum, className = "" }) => {
  return (
    <div className={`bg-c24 flex sm:flex-col p-4 gap-4 rounded-xl md:items-center ${className}`}>
      <div className={`flex justify-center items-center rounded-full p-2 sm:max-w-max ${bgColor}`}>
        <Icon name={icon} className="w-[25px] h-[25px] sm:w-[32px] sm:h-[32px]" />
      </div>

      <div className={`flex flex-col ${className}`}>
        <span className="text-c9 text-sm">{title}</span>
        <span className="text-c2 text-md">{sum}</span>
      </div>
    </div>
  );
};

export default MiniCard;
