import { useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { changeReturnExpenses } from "store/slices/incomeSlice";
import { toast } from "react-toastify";

function ReturnIncomeExpenses({ onCloseMenu, onChangePayingPrice }) {
  const dispatch = useDispatch();
  const {
    income: {
      returnIncome: { totalPrice, returnExpenses },
    },
    suppliers: { currentSupplier },
  } = useSelector((store) => store);

  const [paymentType, setPaymentType] = useState({ ...returnExpenses });

  const handleAddPaymentType = (type) => {
    let payment = {};

    if (paymentType[type]?.status) {
      delete paymentType[type];
      payment = {
        [type === "cash" ? "debt" : "cash"]: {
          type: type === "cash" ? "debt" : "cash",
          price: totalPrice,
          status: true,
        },
      };
    } else
      payment = {
        [type]: {
          type,
          price: totalPrice - paymentType?.[type === "cash" ? "debt" : "cash"]?.price,
          status: true,
        },
      };
    setPaymentType({ ...paymentType, ...payment });
  };

  const handleChangePaymentPrice = (type, price) => {
    const payment = { ...paymentType[type], price: price ? parseFloat(price, 10) : "" };
    setPaymentType(
      currentSupplier?.debt
        ? {
            ...paymentType,
            [type]: payment,
            [type === "cash" ? "debt" : "cash"]: {
              type: type === "cash" ? "debt" : "cash",
              status: true,
              price: totalPrice - (price ? parseInt(price, 10) : 0),
            },
          }
        : {
            ...paymentType,
            [type]: payment,
          }
    );
  };

  const handleSavePaymentType = () => {
    let totalAmount = 0;
    Object.keys(paymentType).map((key) => (totalAmount += paymentType[key].price));
    if (
      totalAmount === totalPrice &&
      ((paymentType?.debt?.price && currentSupplier?.debt >= paymentType?.debt?.price) ||
        !paymentType?.debt?.price)
    ) {
      if (Object.keys(paymentType).length === 1) {
        Object.keys(paymentType).map((key) =>
          onChangePayingPrice({
            text: `${key === "cash" ? "Naqd" : "Nasiya"}: ${paymentType[key]?.price}`,
            totalAmount: paymentType[key]?.price,
          })
        );
      } else {
        onChangePayingPrice({ text: `Boshqa: ${totalAmount}`, totalAmount });
      }

      dispatch(changeReturnExpenses(paymentType));
      onCloseMenu();
    }

    totalAmount !== totalPrice && toast.warning("Umumiy summaga to'lanayotkan summa teng emas.");
    paymentType?.debt?.price &&
      currentSupplier?.debt < paymentType?.debt?.price &&
      toast.warning(`Yetkazib beruvchidan ${currentSupplier?.debt} UZS qarzi bor.`);
  };

  return (
    <div className="w-[20rem] pl-10 pr-4 py-2 flex flex-col gap-2 sm:w-[90vw] sm:p-4">
      <label htmlFor="cash-input" className="w-full flex flex-col">
        <p
          onClick={() =>
            (!paymentType?.cash?.price || totalPrice < currentSupplier?.debt) &&
            handleAddPaymentType("cash")
          }
          className={`text-lg cursor-pointer ${paymentType?.cash?.status ? "text-c10" : ""}`}
        >
          Naqd
        </p>
        {paymentType?.cash?.status && (
          <input
            id="cash-input"
            type="number"
            inputMode="numeric"
            placeholder="Summani kiriting"
            value={paymentType?.cash?.price || ""}
            className=" border p-2 rounded-xl outline-0"
            onChange={(e) =>
              handleChangePaymentPrice(
                "cash",
                e.target.value <= totalPrice ? e.target.value : totalPrice
              )
            }
          />
        )}
      </label>
      {currentSupplier?.debt ? (
        <label htmlFor="debt-input" className="flex flex-col">
          <p
            onClick={() => handleAddPaymentType("debt")}
            className={`text-lg cursor-pointer  ${paymentType?.debt?.status ? "text-c10" : ""}`}
          >
            Nasiya
          </p>
          {paymentType?.debt?.status && (
            <input
              id="debt-input"
              type="number"
              inputMode="numeric"
              placeholder="Summani kiriting"
              value={paymentType?.debt?.price || ""}
              className="border p-2 rounded-xl outline-0"
              onChange={(e) =>
                handleChangePaymentPrice(
                  "debt",
                  e.target.value <= totalPrice
                    ? e.target.value > currentSupplier?.debt
                      ? currentSupplier?.debt
                      : e.target.value
                    : totalPrice
                )
              }
            />
          )}
        </label>
      ) : (
        ""
      )}
      <p className="text-sm mt-2">
        <span className="text-c9">Umumiy summa: </span>
        <span className="text-c10">{totalPrice} UZS</span>
      </p>
      <div className="flex gap-2">
        <button onClick={onCloseMenu} className="w-1/2 bg-c14 text-c24 px-4 py-2 rounded-xl">
          Bekor qilish
        </button>
        <button
          onClick={handleSavePaymentType}
          className="w-1/2 bg-c17 text-c24 px-4 py-2 rounded-xl"
        >
          Saqlash
        </button>
      </div>
    </div>
  );
}

export default ReturnIncomeExpenses;
