import React from "react";
import NotEnoughPermissions from "./NotEnoughPermissions";

function Messages() {
  return (
    <div>
      <NotEnoughPermissions />
    </div>
  );
}

export default Messages;
