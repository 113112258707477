import { useState } from "react";
import { useDispatch } from "react-redux";
import { createCustomer, updateCustomer } from "store/slices/customerSlice";
import HtmlInput from "components/input/HtmlInput";
import Select from "components/select/Select";

const CreateCustomer = ({ action, currentCustomer }) => {
  const dispatch = useDispatch();
  const [customer, setCustomer] = useState(
    action === "create"
      ? {
          first_name: "",
          last_name: "",
          middle_name: "",
          gender: "",
          phone_number: "",
        }
      : currentCustomer
  );

  const handleChange = (name, value) => {
    setCustomer({ ...customer, [name]: value });
  };

  const handleSave = () => {
    action === "create"
      ? customer?.first_name && customer?.phone_number && dispatch(createCustomer(customer))
      : dispatch(updateCustomer({ id: currentCustomer?.id, customer }));
  };

  return (
    <div className="bg-c24 w-[25rem] sm:w-[90vw] p-6 rounded-lg flex flex-col gap-3">
      <span className="text-c2 text-2xl">
        {action === "create" ? "Foydalanuvchi qo'shish" : "Foydalanuvchini tahrirlash"}
      </span>

      <HtmlInput
        label="Ismi *"
        value={customer?.first_name}
        placeholder="Ismni kiriting"
        onChange={(e) => handleChange("first_name", e.target.value)}
      />
      <HtmlInput
        label="Familiyasi"
        value={customer?.last_name}
        placeholder="Familiyani kiriting"
        onChange={(e) => handleChange("last_name", e.target.value)}
      />

      <HtmlInput
        value={customer?.middle_name}
        label="Otasining ismi"
        placeholder="Loginni kiriting"
        onChange={(e) => handleChange("middle_name", e.target.value)}
      />

      <HtmlInput
        inputMode="tel"
        label="Telefon nomeri *"
        value={customer?.phone_number}
        mask="+\9\98999999999"
        placeholder="Telefon nomeri"
        onChange={(e) => handleChange("phone_number", e.target.value)}
      />

      <Select
        label="Jinsi"
        value={customer?.gender}
        options={[
          { id: 1, label: "Erkak", value: "male" },
          { id: 2, label: "Ayol", value: "female" },
        ]}
        classNameLabel="text-c2 text-sm"
        onChange={(e) => handleChange("gender", e.target.value)}
        classNameSelect="bg-c24 w-full h-10 px-2 rounded-xl text-c2 border border-c32 outline-none bg-arrow-down-gray bg-no-repeat bg-right bg-[center_right_0.4rem] select-arrow-none"
      />

      <button
        onClick={handleSave}
        className="h-10 bg-c10 text-c24 flex items-center justify-center rounded-xl px-3"
      >
        Saqlash
      </button>
    </div>
  );
};

export default CreateCustomer;
