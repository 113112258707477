import { useSelector } from "react-redux";
import { Navigate } from "react-router-dom";

const checkPermissions = (routePermissions, permissions) => {
  let count = 0;
  routePermissions?.map((p) => permissions?.includes(p) && count++);

  return count === routePermissions?.length;
};

function PrivateRoute({ children, routePermissions = [], redirectRoute }) {
  const {
    auth: {
      userInfo: { is_superadmin, permissions },
    },
  } = useSelector((store) => store);
  const token = JSON.parse(localStorage.getItem("user_data"))?.token;

  return token ? (
    is_superadmin ? (
      children
    ) : checkPermissions(routePermissions, permissions) ? (
      children
    ) : (
      <Navigate to={redirectRoute} replace />
    )
  ) : (
    <Navigate to="/" replace />
  );
}

export default PrivateRoute;
